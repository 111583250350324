export function InfoIcon({ size = 12 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 11 11.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.508" cy="6" r="5.5" fill="var(--primary-1)" />
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x="3.5"
        y="1.5"
        width="4"
        height="8"
        fill="#000"
      >
        <path fill="#fff" d="M3.5 1.5h4v8h-4z" />
        <path d="M5.508 4.22a.613.613 0 0 1-.608-.608c0-.336.28-.6.608-.6.336 0 .608.264.608.6a.61.61 0 0 1-.608.608M6.02 8.5H5.004V4.636H6.02z" />
      </mask>
      <path
        d="M5.508 4.22a.613.613 0 0 1-.608-.608c0-.336.28-.6.608-.6.336 0 .608.264.608.6a.61.61 0 0 1-.608.608M6.02 8.5H5.004V4.636H6.02z"
        fill="#fff"
      />
      <path
        d="M6.02 8.5v1h1v-1zm-1.016 0h-1v1h1zm0-3.864v-1h-1v1zm1.016 0h1v-1h-1zM5.508 3.22c.216 0 .392.167.392.392h-2c0 .897.736 1.608 1.608 1.608zm.392.392c0 .242-.194.4-.392.4v-2c-.854 0-1.608.686-1.608 1.6zm-.392.4a.4.4 0 0 1-.392-.4h2a1.6 1.6 0 0 0-1.608-1.6zm-.392-.4c0-.216.176-.392.392-.392v2c.888 0 1.608-.72 1.608-1.608zM6.02 7.5H5.004v2H6.02zm-.016 1V4.636h-2V8.5zm-1-2.864H6.02v-2H5.004zm.016-1V8.5h2V4.636z"
        fill="var(--primary-1)"
        mask="url(#a)"
      />
    </svg>
  );
}
