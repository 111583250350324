import { Fragment, useState } from "react";
import { WEEKDAY_SHORT_STR_MAP } from "src/constants/string-res-map";
import { useLocaleContext } from "src/context/locale-context";
import { useTenantContext } from "src/context/tenant-context";
import {
  getDateDifferenceInDays,
  getFlightDateFormatted,
  getWeekdayFromDate,
} from "src/utils/date-utils";
import { FlightConnectionLineMobile } from "../ConnectionLine/FlightConnetctionLineMobile";
import { WARN_TYPE, getTotalDuration } from "../FlightChainInfo/FlightChainInfo";
import classes from "../FlightChainInfo/FlightInfo.module.css";
import { ThumbstackButton } from "../FlightChainInfo/ThumbstackButton";
import MobileInfoSnackbar from "../MobileInfoSnackbar/MobileInfoSnackbar";

function DepartureDataItem({ flightChain, warn, onShowInfo }) {
  const { stringRes } = useLocaleContext();
  const hasWarning = warn === WARN_TYPE.departure;

  return (
    <div className={classes.carrierDataItem}>
      <span>{flightChain[0].dep.time}</span>
      <span
        onClick={
          hasWarning ? () => onShowInfo(stringRes["res.result.info.differentAirports"]) : null
        }
        className={hasWarning ? classes.warn : ""}>
        {flightChain[0].dep.airport.code}
      </span>
    </div>
  );
}

function TimeInfoItem({ flightChain }) {
  const { stringRes, currentLocale } = useLocaleContext();
  const stopNum = flightChain.length - 1;

  return (
    <div className={classes.carrierDataItem}>
      <div className={classes.timeInfo}>
        <div>{getTotalDuration(flightChain, currentLocale)}</div>
        <FlightConnectionLineMobile
          className={classes.connections}
          hasStops={flightChain.length > 1}
        />
        <div className={stopNum > 0 ? "" : classes.direct}>
          {stopNum > 0 ? (
            <>
              {stopNum}{" "}
              {stopNum > 1
                ? stringRes["res.result.stop.many"]
                : stringRes["res.result.stop.one"]}
              {stopNum === 1 &&
                ` ${flightChain
                  .slice(1)
                  .map((f) => f.dep.airport.code)
                  .join("•")}`}
            </>
          ) : (
            stringRes["res.result.stop.none"]
          )}
        </div>
      </div>
    </div>
  );
}

function ArrivalDataItem({ flightChain, warn, onShowInfo }) {
  const { stringRes } = useLocaleContext();
  const { shortDateFormat } = useTenantContext();
  const lastFlight = flightChain[flightChain.length - 1];
  const hasWarning = warn === WARN_TYPE.arrival;

  const daysOffset = getDateDifferenceInDays(
    flightChain[0].dep.date,
    lastFlight.arr.date,
    "DD.MM.YYYY"
  );

  const weekdayNumEnd = getWeekdayFromDate(lastFlight.arr.date, "DD.MM.YYYY");
  const endDate = getFlightDateFormatted(lastFlight.arr.date, shortDateFormat);
  let daysOffsetMessage =
    daysOffset > 1
      ? stringRes["res.result.info.daysOffset.many"].replace("##DAYS_OFFSET##", daysOffset)
      : stringRes["res.result.info.daysOffset.one"];
  daysOffsetMessage += ` - ${stringRes[WEEKDAY_SHORT_STR_MAP[weekdayNumEnd]]}, ${endDate}`;

  return (
    <div className={classes.carrierDataItem}>
      <span onClick={daysOffset > 0 ? () => onShowInfo(daysOffsetMessage) : null}>
        <span className={daysOffset > 0 ? classes.hasDateOffset : ""}>
          {lastFlight.arr.time}
        </span>
        <sup className={classes.daysOffset}>{daysOffset > 0 ? `+${daysOffset}` : ""}</sup>
      </span>
      <span
        onClick={
          hasWarning ? () => onShowInfo(stringRes["res.result.info.differentAirports"]) : null
        }
        className={hasWarning ? classes.warn : ""}>
        {lastFlight.arr.airport.code}
      </span>
    </div>
  );
}

function CarrierData({ flightChain, warn, onShowInfo }) {
  const props = {
    flightChain,
    warn,
    onShowInfo,
  };

  return (
    <div className={classes.carrierData}>
      <DepartureDataItem {...props} />
      <TimeInfoItem {...props} />
      <ArrivalDataItem {...props} />
    </div>
  );
}

export function CarrierTimeDataMobile({ flightChain, warn, isOneway, onPin, isPinned }) {
  const [snackbarState, setState] = useState({
    isClosing: false,
    doShow: false,
    message: "",
  });

  const onTriggerInfoSnackbar = (message) => {
    setState({ doShow: true, isClosing: false, message: message });
  };

  const onCloseInfoSnackbar = () => {
    setState((prev) => {
      return { ...prev, isClosing: true };
    });
    setTimeout(() => {
      setState((prev) => {
        return { ...prev, doShow: false, message: "" };
      });
    }, 200);
  };

  const props = {
    flightChain,
    warn,
    onShowInfo: onTriggerInfoSnackbar,
  };

  return (
    <Fragment>
      {snackbarState.doShow && (
        <MobileInfoSnackbar
          message={snackbarState.message}
          isClosing={snackbarState.isClosing}
          onClose={onCloseInfoSnackbar}
        />
      )}
      <CarrierData {...props} />
      {!isOneway && <ThumbstackButton isActive={isPinned} onClick={onPin} />}
    </Fragment>
  );
}
