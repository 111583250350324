import { Fragment } from "react";
import classes from "./DetailedFlightsList.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { nanoid } from "@reduxjs/toolkit";
import { Interweave } from "interweave";
import { motion } from "framer-motion";
import { CONNECTION_ELEMENTS } from "./DetailedFlightsList";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import useStaticContent from "src/hooks/useStaticContent";
import { STATIC_ELEMENT } from "src/constants/static";

const transitionProps = {
  duration: 1,
  ease: "easeOut",
  times: [null, 0.7, 1],
};

function transformSelfTransferLinkToBtn(node, children, listener) {
  if (node.tagName === "A" && node.getAttribute("href") === "/self-transfer") {
    return (
      <button className={classes.linkBtn} onClick={listener}>
        {children}
      </button>
    );
  }
}

export function ConnectionElement({ warnings }) {
  const { stringRes } = useLocaleContext();
  const { openModal } = useModalContext();
  const { html: self_transfer } = useStaticContent(STATIC_ELEMENT.self_transfer);

  const onShowSelfTransferDetails = () => {
    openModal(MODAL_TYPE.serviceDetails, { template: self_transfer });
  };

  return (
    <div className={classes.connection}>
      {warnings.length === 0 && <div className={`${classes.line} ${classes.short}`}></div>}
      {warnings.map((warning, i) => {
        let warningStr = stringRes[warning.type.strKey];
        if (warning.type.varStr) {
          warningStr = warningStr.replace(warning.type.varStr, warning.varStrValue);
        }

        const symbolProps = { warning, i, isLast: i === warnings.length - 1 };
        if (i === 0) {
          return (
            <Fragment key={nanoid(5)}>
              <div className={classes.line}></div>
              <SymbolElement {...symbolProps} />
              <div
                className={`${classes.transferNote} ${
                  !symbolProps.isLast ? classes.padded : ""
                }`}>
                <Interweave content={warningStr} transform={transformSelfTransferLinkToBtn} />
              </div>
              {i === warnings.length - 1 && <div className={classes.line}></div>}
            </Fragment>
          );
        } else {
          return (
            <Fragment key={nanoid(5)}>
              <SymbolElement {...symbolProps} />
              <div
                className={`${classes.transferNote} ${
                  !symbolProps.isLast ? classes.padded : ""
                }`}>
                <Interweave
                  content={warningStr}
                  transform={(node, children) =>
                    transformSelfTransferLinkToBtn(node, children, onShowSelfTransferDetails)
                  }
                />
              </div>
              {i === warnings.length - 1 && <div className={classes.line}></div>}
            </Fragment>
          );
        }
      })}
    </div>
  );
}

export function SymbolElement({ warning, i, isLast }) {
  const rgbStr = warning.type.rgb.join(",");
  const isDiffType = warning.type.id === CONNECTION_ELEMENTS.diffDepartureLocation.id;
  return (
    <div
      className={[
        classes.symbol,
        !isLast ? classes.padded : "",
        isDiffType ? classes.diff : "",
      ]
        .filter((cls) => !!cls)
        .join(" ")}>
      <motion.div
        style={{ borderRadius: "50%" }}
        initial={{ scale: 0.85 }}
        whileInView={[
          {
            scale: 1,
            transition: {
              duration: 0.5,
              delay: 0.05 * (i + 1),
            },
          },
          {
            boxShadow: [
              `0 0 0 0 rgba(${rgbStr}, 0)`,
              `0 0 0 4px rgba(${rgbStr}, 0.1)`,
              `0 0 0 1px rgba(${rgbStr}, 0.02)`,
            ],

            transition: { ...transitionProps },
          },
        ]}>
        <motion.div
          style={{ borderRadius: "50%" }}
          whileInView={{
            boxShadow: [
              `0 0 0 0 rgba(${rgbStr}, 0)`,
              `0 0 0 2px rgba(${rgbStr}, 0.1)`,
              `0 0 0 1px rgba(${rgbStr}, 0.02)`,
              `0 0 0 0 rgba(${rgbStr}, 0)`,
            ],
            transition: { ...transitionProps, delay: 0.5 },
          }}>
          {warning.type.element}
        </motion.div>
      </motion.div>
      {!isDiffType && isLast && <div className={classes.line}></div>}
    </div>
  );
}
