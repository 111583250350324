import classes from "./MobileResultsFilter.module.css";
import { SortIcon } from "src/components/UI/Arrow/Arrow";


export function MobileSortItem({ id, onClick, title }) {
  return (
    <button id={id} className={classes.buttonBarItem} onClick={onClick}>
      <SortIcon />
      <span>{title}</span>
    </button>
  );
}
