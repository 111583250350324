import classes from "./Spinner.module.css";

function Spinner({ color = "var(--primary-1)", size = 16 }) {
  return (
    <div className={classes.spinner}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Rolling-1s-20px 1">
          <path
            id="Vector"
            d="M8.00023 13.76C11.1814 13.76 13.7602 11.1812 13.7602 7.99999C13.7602 4.81883 11.1814 2.23999 8.00023 2.23999C4.81907 2.23999 2.24023 4.81883 2.24023 7.99999C2.24023 11.1812 4.81907 13.76 8.00023 13.76Z"
            stroke={color}
            strokeDasharray="6.6 2.49"
          />
        </g>
      </svg>
    </div>
  );
}

export default Spinner;
