import Button from "src/components/UI/Button/Button";
import { StepDirectionArrow } from "src/components/UI/Arrow/Arrow";


export function BookingBackButton({ buttonText, onClick, className }) {
  return (
    <Button
      secondary
      name={<span className={className}>
        <span>{buttonText}</span>
        <StepDirectionArrow back />
      </span>}
      onClick={onClick} />
  );
}
