// import { useLoaderData, useNavigate } from "react-router-dom";
import { useLoaderData, useNavigate } from "react-router-dom";
import Button from "src/components/UI/Button/Button";
import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import { useAuth } from "src/context/auth-context";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import "src/components/UI/Button/Button.css";
import { Interweave, Markup } from "interweave";

export function OfflineOrderReceived() {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { bookingID, paymentType, bookingNumber } = useLoaderData();

  const invoiceUrl = `https://${window.SERVER_DATA.REACT_APP_API_URL}/invoice/generate/${bookingNumber}`;

  const isBankTransfer = paymentType === "bankTransfer";
  const isOfflinePayment = paymentType === "offlinePayment";

  const goToPage = (button, page) => {
    if (button === 1) {
      window.open(page, "_blank");
    } else if (button === 0) {
      navigate(page);
    }
  };

  if (!paymentType) navigate("/notfound");

  return (
    <main id="order-complete-page" className={isMobile ? "mobile" : "desktop"}>
      <div className="order-container">
        <h1>{stringRes["confirmed.order.success.title"]}</h1>
        <h2>
          {stringRes["order.payment.order.num"] + " #"}
          <span>{bookingNumber}</span>
        </h2>

        <div style={{ lineHeight: "normal" }}>
          {isBankTransfer && <p>{stringRes["confirmed.order.payment.method.bank"]}</p>}
          {isOfflinePayment && <p>{stringRes["confirmed.order.payment.method.offline"]}</p>}
        </div>

        {isBankTransfer && (
          <>
            <br />
            <FormInfoElement type="warning">
              <p style={{ textAlign: "left", lineHeight: "normal" }}>
                <Markup content={stringRes["confirmed.order.bank.transfer.info"]} />
              </p>
            </FormInfoElement>
            <br />
            <FormInfoElement>
              <p style={{ textAlign: "left", lineHeight: "normal" }}>
                {stringRes["confirmed.order.bank.transfer.confirm"] + " "}
                <a href={`mailto:${stringRes["confirmed.order.bank.transfer.email"]}`}>
                  {stringRes["confirmed.order.bank.transfer.email"]}
                </a>
              </p>
            </FormInfoElement>
          </>
        )}

        {isOfflinePayment && (
          <>
            <br />
            <FormInfoElement>
              <p style={{ textAlign: "left", lineHeight: "normal" }}>
                {stringRes["confirmed.order.offline.payment.info"]}
              </p>
            </FormInfoElement>
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            columnGap: "1rem",
            rowGap: "1rem",
            marginTop: "1rem",
          }}>
          <Button
            wide
            secondary
            onMouseUp={(e) => goToPage(e.button, "/")}
            name={stringRes["button.general.homepage"]}
          />

          <a
            className="button primary hover"
            style={{ paddingTop: "1rem" }}
            target="_blank"
            href={invoiceUrl}
            download
            rel="noreferrer">
            {stringRes["button.download.invoice"]}
          </a>
        </div>
      </div>
    </main>
  );
}
