import moment from "moment";
import "moment/locale/ru";
import "moment/locale/et";
import "moment/locale/lv";
import "moment/locale/lt";
import "moment/locale/en-gb";
import { customLog } from "./utils";

export const DATE_FORMAT = "YYYY-MM-DD";
const DATE_FORMAT_DISPLAY = "YYYY.MM.DD";
const DATE_FORMAT_NO_YEAR = "MM.DD";
export const FLIGHT_DATE_FORMAT = "DD.MM.YYYY";

export const getDisplayDateValue = (date, format = DATE_FORMAT_DISPLAY) => {
  return date ? moment(date).format(format) : "";
};

export const getDisplayDateValueNoYear = (date, format = DATE_FORMAT_NO_YEAR) => {
  if (!date) return "";
  const parsedDate = moment(date).format(format);
  return parsedDate;
};

export const getDisplayDateFormatted = (date, format, locale) => {
  return date ? moment(date, DATE_FORMAT).locale(locale).format(format) : "";
};

export const getFlightDateFormatted = (date, format) => {
  return date ? moment(date, FLIGHT_DATE_FORMAT).utc(true).format(format) : "";
};

export const isValidDateString = (dateString) => {
  return moment(dateString, DATE_FORMAT, true).isValid();
};

export function isTodayOrLater(dateToCheck) {
  if (dateToCheck) {
    return moment().startOf("day").diff(moment(dateToCheck).startOf("day"), "days") <= 0;
  }
}

export function secondDateIsLater(startDate, endDate) {
  if (startDate && endDate) {
    return moment(endDate).startOf("day").diff(moment(startDate).startOf("day"), "days") > 0;
  }
}

export function isWithinRange(startDate, dateToCheck, endDate) {
  if (!startDate || !endDate) {
    return false;
  }
  const start = startDate.startOf("day");
  const end = endDate.startOf("day");
  const middle = dateToCheck.startOf("day");

  return start.diff(middle, "days") < 0 && end.diff(middle, "days") > 0;
}

export function isDisabled(minDate, dateToCheck, maxDate) {
  const min = moment(moment(minDate).format(DATE_FORMAT), DATE_FORMAT);
  const max = moment(moment(maxDate).format(DATE_FORMAT), DATE_FORMAT);
  const current = moment(moment(dateToCheck).format(DATE_FORMAT), DATE_FORMAT);
  return !(min <= current && current <= max);
}

export function isSameDate(firstDate, secondDate) {
  return (
    moment(firstDate, DATE_FORMAT)
      .startOf("day")
      .diff(moment(secondDate, DATE_FORMAT).startOf("day"), "days", false) === 0
  );
}

export function getDateDifferenceInDays(startDateStr, endDateStr, dateFormat) {
  const startDate = moment(startDateStr, dateFormat ?? DATE_FORMAT);
  const endDate = moment(endDateStr, dateFormat ?? DATE_FORMAT);

  if (startDate.isValid() && endDate.isValid()) {
    const differenceInDays = endDate.diff(startDate, "days");
    return differenceInDays;
  } else {
    return undefined;
  }
}

export function addDaysToDate(dateStr, daysToAdd) {
  const inputDate = moment(dateStr, DATE_FORMAT);
  const newDate = inputDate.add(daysToAdd, "days");
  return newDate.format(DATE_FORMAT);
}

export function getTimeInMinutes(time) {
  if (!time) {
    return "";
  }
  const timeObj = moment(time, "HH:mm");
  return timeObj.hours() * 60 + timeObj.minutes();
}

export function getTimeFromMinutes(minutes) {
  const time = moment().startOf("day").add(minutes, "minutes").format("HH:mm");
  customLog(time);
  return time;
}

export function getTimeDifferenceInMinutes(timestamp1, timestamp2) {
  const millisDiff = Math.abs(timestamp1 * 1000 - timestamp2 * 1000);
  const minutesDiff = Math.floor(millisDiff / (60 * 1000));
  return minutesDiff;
}

export function getTimeDifferenceInDays(timestamp1, timestamp2) {
  const date1 = moment.unix(timestamp1).startOf("day");
  const date2 = moment.unix(timestamp2).startOf("day");

  const daysDiff = date2.diff(date1, "days");
  return Math.abs(daysDiff);
}

export function getFormattedDOBDate(day, month, year) {
  const date = moment({ year, month: month - 1, day });
  if (date.isValid()) {
    return date.format(DATE_FORMAT);
  }
  return "";
}

function calculateAge(dateOfBirth) {
  const today = moment();
  const dob = moment(dateOfBirth, DATE_FORMAT);
  return today.diff(dob, "years");
}

// Function to check if a date of birth falls within a specified age range
export function isValidDOB(dateStr, maxAge, minAge) {
  const today = moment().startOf("day");
  const dob = moment(dateStr).startOf("day");

  const age = calculateAge(dateStr);
  return age >= minAge && age < maxAge && dob.isSameOrBefore(today);
}

export function getDOBObjectFromDateString(dateStr) {
  const date = moment(dateStr, DATE_FORMAT);
  const dobObj = { day: date.date(), month: date.month() + 1, year: date.year() };
  customLog(dobObj);
  return dobObj;
}

export function getMonthFromDate(date) {
  const mDate = moment(date, FLIGHT_DATE_FORMAT);
  return mDate.month();
}

export function getDayFromDate(date) {
  const mDate = moment(date, FLIGHT_DATE_FORMAT);
  return mDate.date();
}

export function getWeekdayFromDate(date, format) {
  const mDate = moment(date, format || FLIGHT_DATE_FORMAT);
  return mDate.weekday();
}

export function reformatDate(date, originalFormat, newFormat) {
  const mDate = moment(date, originalFormat);
  return mDate.format(newFormat);
}

const abbreviations = {
  en_GB: { hour: "h", minute: "min" },
  ru_RU: { hour: "ч", minute: "мин" },
  lv_LV: { hour: "st", minute: "min" },
  lt_LT: { hour: "val", minute: "min" },
  et_EE: { hour: "t", minute: "min" },
};
export function getLocalizedFlightDuration(totalMinutes, locale = "en_GB") {
  if (totalMinutes === undefined) {
    return undefined;
  }
  const hours = Math.trunc(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let str = hours > 0 ? `${hours}${abbreviations[locale].hour} ` : "";
  str += minutes > 0 ? `${minutes}${abbreviations[locale].minute} ` : "";
  return str;
}
