import Card from "src/components/UI/Card/Card";

function SidePanel({ opts, isClosing, children }) {
  return (
    <Card
      className={"sidebar " + (isClosing ? "close" : "open")}
      opts={{ ...opts }}
      shadowLg
    >
      {children}
    </Card>
  );
}
export default SidePanel;
