import { json } from "react-router-dom";
import { paymentApi } from "src/services/api";
import store from "src/store";
import { resetAfterBooking } from "src/store/actions";
import { unpackOrderToken } from "src/utils/order-utils";
import { clearBookingSessionData } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";

export async function loader({ request, params }) {
  customLog(request);

  clearBookingSessionData();
  store.dispatch(resetAfterBooking());

  const FIVE_MINUTES_IN_SECS = 5 * 60;

  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const bookingNumber = searchParams.get("booking-number");
  const ulrToken = searchParams.get("order-token");
  if (!ulrToken) {
    throw json(null, { status: 404 });
  }

  const response = await paymentApi
    .checkPaymentOrderToken(ulrToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw json({ data: err });
    });

  const { token, isValid } = response.data;
  if (!isValid) {
    throw json({ isValid }, { status: 404 });
  }

  const bookingID = params.orderId;
  const decodedOrderData = unpackOrderToken(token);
  if (decodedOrderData.merchant_reference !== bookingID) {
    throw json(null, { status: 404 });
  }

  if (Date.now() / 1000 - decodedOrderData.iat > FIVE_MINUTES_IN_SECS) {
    throw json(null, { status: 410 });
  }

  return { decodedOrderData, bookingID, bookingNumber };
}
