import { Fragment, forwardRef, useEffect, useState } from "react";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./SeatsSelection.module.css";
import { splitStringByNewline } from "src/utils/string-utils";
import seatsStepIcon from "src/assets/img/seatsStepIcon.png";
import { Checkmark } from "src/components/UI/Icon/Checkmark";
import { FormInfoElement } from "../../../UI/FormInfoElement/FormInfoElement";
import { CATALOGUE_IDS } from "src/constants/services";
import {
  AisleSeatIcon,
  LegroomSeatIcon,
  RandomSeatIcon,
  TogetherSeatIcon,
  WindowSeatIcon,
} from "src/components/UI/Icon/SeatTypeIcons";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { CHANGE_EVENT } from "../StepContent/SeatSelectionStepContent";
import { SeatSelectionView } from "./SeatSelectionView";
import { Markup } from "interweave";

export const SEAT_ICON_MAP = {
  [CATALOGUE_IDS.seats.random]: <RandomSeatIcon />,
  [CATALOGUE_IDS.seats.window]: <WindowSeatIcon />,
  [CATALOGUE_IDS.seats.aisle]: <AisleSeatIcon />,
  [CATALOGUE_IDS.seats.extra_legroom]: <LegroomSeatIcon />,
  [CATALOGUE_IDS.seats.sitting_together]: <TogetherSeatIcon />,
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0,
    };
  },
};

export const SeatSelectionContainer = ({
  airlineSeatOptions,
  allFlights,
  changeEvent,
  onAfterChange,
}) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const flightIndex = wrap(0, allFlights.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  useEffect(() => {
    if (changeEvent === CHANGE_EVENT.next && flightIndex < allFlights.length - 1) {
      paginate(1);
    } else if (changeEvent === CHANGE_EVENT.prev && flightIndex > 0) {
      paginate(-1);
    }
    onAfterChange(flightIndex);
  }, [changeEvent]);

  return (
    <AnimatePresence mode="popLayout" initial={false} custom={direction}>
      <motion.div
        style={{ minWidth: "100%" }}
        key={allFlights[flightIndex].segment}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.5 },
        }}>
        <SeatSelectionView
          flightData={allFlights[flightIndex]}
          totalFlights={allFlights.length}
          availableTypes={airlineSeatOptions[allFlights[flightIndex].airline]}
        />
      </motion.div>
    </AnimatePresence>
  );
};

function SeatSelectionContainerLoading() {
  const { stringRes } = useLocaleContext();

  return (
    <div className={classes.loading}>
      <span className={classes.dotPulse}>
        <span className={classes.bounce1}></span>
        <span className={classes.bounce2}></span>
        <span className={classes.bounce3}></span>
      </span>
      <span className={classes.loadingText}>{stringRes["booking.seats.loading"]}</span>
    </div>
  );
}

const MobileSeatSelection = forwardRef(
  (
    { isLoading, changeEvent, allFlightsData, airlineSeatOptions, onAfterChange },
    ref
  ) => {
    return (
      <div ref={ref} className={`${classes.container} ${classes.mobile}`}>
        <div className={classes.hrDotted}></div>
        <div className={classes.selection}>
          {isLoading ? (
            <Fragment>
              <SeatSelectionContainerLoading />
              <div className={classes.hrDotted}></div>
            </Fragment>
          ) : (
            <SeatSelectionContainer
              airlineSeatOptions={airlineSeatOptions}
              allFlights={allFlightsData}
              changeEvent={changeEvent}
              onAfterChange={onAfterChange}
            />
          )}
        </div>
      </div>
    );
  }
);

const DesktopSeatSelection = forwardRef(
  (
    {
      isLoading,
      changeEvent,
      onAfterChange,
      allFlightsData,
      airlineSeatOptions,
      noSeatsToChooseFlights,
    },
    ref
  ) => {
    const { stringRes } = useLocaleContext();
    const features = splitStringByNewline(stringRes["booking.seats.features"]);

    return (
      <div ref={ref} className={`${classes.container} ${classes.desktop}`}>
        <div className={classes.top}>
          <h1>{stringRes["booking.seats.title"]}</h1>
          <ul className={classes.features}>
            {features.map((ft) => (
              <li key={ft} className={classes.feature}>
                <Checkmark color={"#00A991"} />
                <span>{ft}</span>
              </li>
            ))}
          </ul>
          <img src={seatsStepIcon} alt="plane seats" />
        </div>
        {noSeatsToChooseFlights.length > 0 && (
          <FormInfoElement>
            <div className={classes.note}>
              <span>
                <b>{stringRes["booking.seats.note.title"]}</b>
              </span>
              {noSeatsToChooseFlights.map((item) => (
                <span
                  key={item.segment}
                  className={
                    classes.flight
                  }>{`${item.srcCity} (${item.srcIata}) - ${item.destCity} (${item.destIata})`}</span>
              ))}

              <span>
                <Markup content={stringRes["booking.seats.note.text"]} />
              </span>
            </div>
          </FormInfoElement>
        )}
        <div className={classes.divider}></div>
        <div className={classes.selection}>
          {isLoading ? (
            <SeatSelectionContainerLoading />
          ) : (
            <SeatSelectionContainer
              airlineSeatOptions={airlineSeatOptions}
              allFlights={allFlightsData}
              changeEvent={changeEvent}
              onAfterChange={onAfterChange}
            />
          )}
        </div>
        <div className={classes.divider}></div>
      </div>
    );
  }
);

const SeatsSelection = forwardRef(function SeatsSelection(props, ref) {
  const { isMobile } = useDeviceContext();

  const childProps = { ...props, ref };

  if (!isMobile) {
    return <DesktopSeatSelection {...childProps} />;
  } else {
    return <MobileSeatSelection {...childProps} />;
  }
});

export default SeatsSelection;
