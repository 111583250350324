import {
  customLog,
  validateCabinClass,
  validateKeys,
  validatePassengers,
  validateTripFields,
  validateTripType,
  validateTrips,
} from "./utils";
import { searchDataModel } from "src/store/models";

export const KEYS = Object.freeze({
  TRIP_SEARCH_HISTORY: "tripSearchHistory",
  LAST_SAVED_SEARCH: "lastSavedSearch",
  LAST_SAVED_PACKAGE: "lastSavedPackage",
  SESSION_LOCK: "__lock",
  SESSION_RELOAD: "__session_reloading",
  SEARCh_REFRESH: "__search_refresh",
  SEARCh_DIRECT: "__direct_flights",
  BOOKING_SESSION_ID: "__session_id",
  LAST_SEARCHQ_ID: "lastSearchQueryCorrelationID",
  LAST_BOOKING_ID: "lastBookingCorrelationID",
  SELECTED_LANG: "selectedLocaleLang",
});

export function setSearchDataModelFields(data) {
  try {
    const dataModel = JSON.parse(JSON.stringify(searchDataModel));
    if (
      validateKeys(Object.keys(dataModel), data) &&
      validateTripType(data.tripType) &&
      validateCabinClass(data.cabinClass) &&
      validatePassengers(data.passengers) &&
      validateTrips(data.trips)
    ) {
      dataModel.tripType = data.tripType;
      dataModel.cabinClass = data.cabinClass;
      dataModel.passengers = data.passengers;
      dataModel.trips = data.trips;
      return dataModel;
    }
    return undefined;
  } catch (error) {
    customLog(error);
  }
}

export function getLastSavedSearch() {
  try {
    const lastSearchStorageData = JSON.parse(localStorage.getItem(KEYS.LAST_SAVED_SEARCH));
    const lastSearchData = setSearchDataModelFields(lastSearchStorageData);
    return lastSearchData ?? undefined;
  } catch (error) {
    customLog(error);
    return undefined;
  }
}

export function clearLastSavedSearch() {
  try {
    localStorage.removeItem(KEYS.LAST_SAVED_SEARCH);
  } catch (error) {
    customLog(error);
  }
}

export function setLastSavedSearch(searchData) {
  try {
    const clonedData = JSON.parse(JSON.stringify(searchData));
    delete clonedData.userInput;
    clearLastSavedSearch();
    localStorage.setItem(KEYS.LAST_SAVED_SEARCH, JSON.stringify(clonedData));
  } catch (error) {
    customLog(error);
  }
}

function isMaxSavedTripSearches(tripSearchHistory) {
  const MAX_TRIP_SEARCH_HISTORY = 6;
  return tripSearchHistory.length >= MAX_TRIP_SEARCH_HISTORY;
}

function removeExcessSearches(savedSearches) {
  const MAX_TRIP_SEARCH_HISTORY = 6;
  return savedSearches.slice(savedSearches.length - MAX_TRIP_SEARCH_HISTORY + 1);
}

export function saveTripSearchToStorage(trip, tripType) {
  customLog("saveSearchToLocalStorage");
  try {
    const clonedData = JSON.parse(JSON.stringify(trip));
    clonedData.tripType = tripType;
    delete clonedData.id;
    let savedTripSearches = getSavedTripSearches();
    if (isMaxSavedTripSearches(savedTripSearches)) {
      savedTripSearches = removeExcessSearches(savedTripSearches);
    }
    savedTripSearches.push(clonedData);
    localStorage.setItem(KEYS.TRIP_SEARCH_HISTORY, JSON.stringify(savedTripSearches));
  } catch (error) {
    customLog(error);
  }
}

export function getSavedTripSearches() {
  try {
    const savedTrips = JSON.parse(localStorage.getItem(KEYS.TRIP_SEARCH_HISTORY));
    const results = [];
    if (Array.isArray(savedTrips) && savedTrips.length > 0) {
      for (let i = 0; i < savedTrips.length; i++) {
        const trip = { ...savedTrips[i] };
        delete trip.tripType;
        if (
          validateTripFields({ ...trip, id: "" }) &&
          savedTrips[i].tripType &&
          validateTripType(savedTrips[i].tripType)
        ) {
          results.push(savedTrips[i]);
        }
      }
    }
    return results;
  } catch (error) {
    customLog(error);
    return [];
  }
}

export function getLastSavedSessionTravelPackage() {
  try {
    const lastTravelPackageStorageData = JSON.parse(
      sessionStorage.getItem(KEYS.LAST_SAVED_PACKAGE)
    );
    return lastTravelPackageStorageData ?? undefined;
  } catch (error) {
    customLog(error);
    return undefined;
  }
}

export function clearLastSavedSessionTravelPackage() {
  try {
    sessionStorage.removeItem(KEYS.LAST_SAVED_PACKAGE);
  } catch (error) {
    customLog(error);
  }
}

export function setSelectedSessionTravelPackage(travelPackage) {
  try {
    const clonedData = JSON.parse(JSON.stringify(travelPackage));
    clearLastSavedSessionTravelPackage();
    sessionStorage.setItem(KEYS.LAST_SAVED_PACKAGE, JSON.stringify(clonedData));
  } catch (error) {
    customLog(error);
  }
}

export function setSessionId(sessionId) {
  try {
    sessionStorage.setItem(KEYS.BOOKING_SESSION_ID, sessionId);
  } catch (error) {
    customLog(error);
  }
}

export function getSessionId() {
  try {
    return sessionStorage.getItem(KEYS.BOOKING_SESSION_ID);
  } catch (error) {
    customLog(error);
    return undefined;
  }
}

export function markSessionLocked() {
  try {
    sessionStorage.setItem(KEYS.SESSION_LOCK, Date.now().toString());
  } catch (error) {
    customLog(error);
  }
}

export function isSessionLocked() {
  try {
    return sessionStorage.getItem(KEYS.SESSION_LOCK) ? true : false;
  } catch (error) {
    customLog(error);
    return true;
  }
}

export function markSessionUnlocked() {
  try {
    sessionStorage.removeItem(KEYS.SESSION_LOCK);
  } catch (error) {
    customLog(error);
  }
}

export function markSessionIsReloading() {
  try {
    sessionStorage.setItem(KEYS.SESSION_RELOAD, Date.now().toString());
  } catch (error) {
    customLog(error);
  }
}

export function markSessionReloaded() {
  try {
    sessionStorage.removeItem(KEYS.SESSION_RELOAD);
  } catch (error) {
    customLog(error);
  }
}

export function getSessionReloadTimestamp() {
  try {
    return sessionStorage.getItem(KEYS.SESSION_RELOAD);
  } catch (error) {
    customLog(error);
  }
}

export function getSelectedLocale() {
  try {
    return (
      localStorage.getItem(KEYS.SELECTED_LANG) ??
      window.SERVER_DATA.REACT_APP_DEFAULT_DOMAIN_LOCALE
    );
  } catch (error) {
    customLog(error);
    return window.SERVER_DATA.REACT_APP_DEFAULT_DOMAIN_LOCALE; //default
  }
}

export function setSelectedLocale(locale) {
  try {
    localStorage.removeItem(KEYS.SELECTED_LANG);
    localStorage.setItem(KEYS.SELECTED_LANG, locale);
  } catch (error) {
    customLog(error);
  }
}

export function markSearchRefreshRequired() {
  try {
    sessionStorage.setItem(KEYS.SEARCh_REFRESH, "true");
  } catch (error) {
    customLog(error);
  }
}

export function isSearchRefreshRequired() {
  try {
    return sessionStorage.getItem(KEYS.SEARCh_REFRESH) === "true";
  } catch (error) {
    customLog(error);
    return true;
  }
}

export function unmarkSearchRefreshRequired() {
  try {
    sessionStorage.removeItem(KEYS.SEARCh_REFRESH);
  } catch (error) {
    customLog(error);
  }
}

export function markDirectFlightsRequired() {
  try {
    sessionStorage.setItem(KEYS.SEARCh_DIRECT, "true");
  } catch (error) {
    customLog(error);
  }
}

export function isDirectFlightsRequired() {
  try {
    return sessionStorage.getItem(KEYS.SEARCh_DIRECT) === "true";
  } catch (error) {
    customLog(error);
    return true;
  }
}

export function unmarkDirectFlightsRequired() {
  try {
    sessionStorage.removeItem(KEYS.SEARCh_DIRECT);
  } catch (error) {
    customLog(error);
  }
}

export function clearBookingSessionData() {
  try {
    sessionStorage.removeItem(KEYS.BOOKING_SESSION_ID);
    sessionStorage.removeItem(KEYS.SESSION_LOCK);
    sessionStorage.removeItem(KEYS.SESSION_RELOAD);
  } catch (error) {
    customLog(error);
  }
}

export function clearSearchSessionData() {
  try {
    sessionStorage.removeItem(KEYS.SEARCh_DIRECT);
    sessionStorage.removeItem(KEYS.SEARCh_REFRESH);
  } catch (error) {
    customLog(error);
  }
}

export function isSessionStorageAvailable() {
  try {
    return !!sessionStorage;
  } catch (error) {
    customLog(error);
    return false;
  }
}

export function isLocalStorageAvailable() {
  try {
    return !!localStorage;
  } catch (error) {
    customLog(error);
    return false;
  }
}