import { useSelector } from "react-redux";
import { PASSENGER } from "src/constants";
import { BREAKDOWN_ITEM_STR_KEY } from "src/constants/order-str-map";
import { CATALOGUE_IDS } from "src/constants/services";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { selectAllPassengers } from "src/store/booking";
import { getCartOverview } from "src/store/cartOverview";
import { getDisplayPriceValue } from "src/utils/utils";
import classes from "./OrderBreakdown.module.css";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";

const SHOW_WHEN_FREE = [
  "adultFare",
  "childFare",
  "infantFare",
  "cabinBundle",
  "cabinBundleUpgrade",
  "checkedBaggageLarge",
  "checkedBaggageSmall",
  "checkedBaggageDouble",
];

function OrderItem({ children }) {
  return <li className={classes.orderItem}>{children}</li>;
}

function OrderBreakdown() {
  const { stringRes, currencySymbol } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const passengers = useSelector(selectAllPassengers);

  //TODO: fill in with actual data
  const ttText = stringRes["booking.order.tooltip.text"];
  const ttLine = stringRes["booking.order.tooltip.text.total"];
  const ttItem1 =
    stringRes["booking.order.tooltip.text.baseFare"] + ": xx.xx " + currencySymbol;
  const ttItem2 =
    stringRes["booking.order.tooltip.text.serviceFee"] + ": xx.xx " + currencySymbol;
  const ttItem3 = stringRes["booking.order.tooltip.text.otherFees"] + ": x " + currencySymbol;

  const tooltipElement = (
    <div style={{ width: `${18.75}rem` }} className={classes.tooltipText}>
      <p>{ttText}</p>
      <p>{ttLine}</p>
      <ul>
        {[ttItem1, ttItem2, ttItem3].map((text) => (
          <li key={text}>{text}</li>
        ))}
      </ul>
    </div>
  );

  const orderBreakdown = useSelector(getCartOverview);

  return (
    <div className={`${classes.breakdown} ${isMobile ? classes.mobile : classes.desktop}`}>
      <h2>{stringRes["booking.order.breakdown"]}</h2>
      <ul className={classes.orderItems}>
        {orderBreakdown.items.map((item) => {
          let itemName;
          let isSeatItem = Object.values(CATALOGUE_IDS.seats).includes(item.key);
          if (isSeatItem) {
            itemName = `${
              passengers.length -
              passengers.filter((p) => p.ageGroup === PASSENGER.infant).length
            }x ${stringRes[BREAKDOWN_ITEM_STR_KEY[item.key]]}`;
          } else {
            itemName =
              `${item.units ? item.units + "x " : ""}` +
              (!item.isCheckedBag
                ? stringRes[BREAKDOWN_ITEM_STR_KEY[item.key]]
                : `${item.key === "checkedBaggageDouble" ? "2x " : ""}${item.weight} ${
                    stringRes[BREAKDOWN_ITEM_STR_KEY[item.key]]
                  }`);
          }
          if (
            item.isActiveOrderItem &&
            (item.price > 0 || SHOW_WHEN_FREE.includes(item.key))
          ) {
            return (
              <OrderItem key={item.id}>
                <span>
                  {itemName}
                  {isSeatItem && (
                    <span
                      style={{
                        fontSize: "small",
                        color: "var(--ui-4)",
                        paddingLeft: "0.25rem",
                      }}>
                      {item.route}
                    </span>
                  )}
                </span>
                {item.loading ? (
                  <DotPulseLoader opacity={0.4} />
                ) : (
                  <span>{`${getDisplayPriceValue(item.price * (item.units || 1))} ${currencySymbol}`}</span>
                )}
              </OrderItem>
            );
          } else {
            return null;
          }
        })}
      </ul>
      <div className={classes.total}>
        {/* <DarkTooltip anchorId={"total-price-ttip-trigger"}>{tooltipElement}</DarkTooltip> */}
        <span id="total-price-ttip-trigger">
          {`${stringRes["booking.order.breakdown.total"]} (${currencySymbol})`}
        </span>
        {orderBreakdown.items.some((itm) => itm.loading) ? (
          <DotPulseLoader opacity={0.4} />
        ) : (
          <span>{`${getDisplayPriceValue(orderBreakdown.total)} ${currencySymbol}`}</span>
        )}
      </div>
    </div>
  );
}

export default OrderBreakdown;
