import classes from "./BillingVATDetails.module.css";
import { useLocaleContext } from "src/context/locale-context";
import {
  FormCountryInputDropdown,
  FormInputLabel,
  FormInputText,
} from "../../../shared/input/FormInput/FormInput";
import { getCountryGroups } from "./BillingVATDetails";

export function PersonalVATDetails({
  values,
  isValidField,
  onChangePersonalField,
  onFocusField,
  onBlurField,
  state,
  placeholder,
}) {
  const { stringRes } = useLocaleContext();
  const countryGroups = getCountryGroups();

  return (
    <fieldset id="vat-personal-form-fields" className={classes.altFields}>
      <FormInputLabel text={stringRes["booking.checkout.billing.person.name"]}>
        <FormInputText
          name={"vat-person-form-name"}
          onChange={(e) => onChangePersonalField("name", e.target.value)}
          value={values.person.name}
          isValid={isValidField(state.person.name)}
          isActive={state.person.name.isActive}
          onFocus={() => onFocusField("name")}
          onBlur={() => onBlurField("name")}
          placeholder={placeholder.name}
        />
      </FormInputLabel>
      <FormInputLabel text={stringRes["booking.checkout.billing.person.surname"]}>
        <FormInputText
          name={"vat-person-form-surname"}
          onChange={(e) => onChangePersonalField("surname", e.target.value)}
          value={values.person.surname}
          isValid={isValidField(state.person.surname)}
          isActive={state.person.surname.isActive}
          onFocus={() => onFocusField("surname")}
          onBlur={() => onBlurField("surname")}
          placeholder={placeholder.surname}
        />
      </FormInputLabel>
      <FormInputLabel text={stringRes["booking.checkout.billing.person.address"]}>
        <FormInputText
          name={"vat-person-form-address"}
          onChange={(e) => onChangePersonalField("address", e.target.value)}
          value={values.person.address}
          isValid={isValidField(state.person.address)}
          isActive={state.person.address.isActive}
          onFocus={() => onFocusField("address")}
          onBlur={() => onBlurField("address")}
          placeholder={placeholder.address}
        />
      </FormInputLabel>
      <FormInputLabel text={stringRes["booking.checkout.billing.person.country"]}>
        <FormCountryInputDropdown
          name={"vat-person-form-country"}
          onSelect={(value) => onChangePersonalField("country", value)}
          optionGroups={countryGroups}
          value={values.person.country}
          isValid={isValidField(state.person.country)}
          isActive={state.person.country.isActive}
          onFocus={() => onFocusField("country")}
          onBlur={() => onBlurField("country")}
          placeholder={stringRes["booking.form.passenger.field.select"]}
        />
      </FormInputLabel>
    </fieldset>
  );
}
