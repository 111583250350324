import { useEffect, useState } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./ResultsList.module.css";
import Button from "src/components/UI/Button/Button";
import { ResultsItem } from "./elements/ResultItem/ResultsItem";
import { useLocaleContext } from "src/context/locale-context";
import { OnewayArrow } from "src/components/UI/Arrow/Arrow";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectSort } from "src/store/filter-sort";

export function SortTagItem({ name, isBest, isCheapest }) {
  const { isMobile } = useDeviceContext();
  return (
    <span
      className={`${classes.sortTagItem} ${
        isBest ? classes.best : isCheapest ? classes.cheapest : ""
      }`}>
      {isMobile ? name : name.toUpperCase()}
    </span>
  );
}

const RESULT_INCREMENT = 20;
const MIN_SCROLL_FROM_TOP = 250 * 6;

function ResultsList({ filteredResults, onSelectResult, onShowDetails }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const [visibleResultNum, setVisibleResultNum] = useState(RESULT_INCREMENT);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const sortType = useSelector(selectSort);

  const onShowMoreResults = () => {
    setVisibleResultNum((prev) => prev + RESULT_INCREMENT);
  };

  useEffect(() => {
    if (isMobile) {
      const onScroll = () => {
        const scrollYOffset = window.scrollY;
        setShowScrollToTop(scrollYOffset >= MIN_SCROLL_FROM_TOP);
      };

      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [isMobile]);

  useEffect(() => {
    setVisibleResultNum(RESULT_INCREMENT);
  }, [sortType]);

  return (
    <section className="results">
      {filteredResults.slice(0, visibleResultNum).map((result, i) => (
        <ResultsItem
          key={result.id}
          item={result}
          isBest={result.isBest}
          isCheapest={result.isCheapest}
          onSelect={onSelectResult}
          onShowDetails={onShowDetails}
        />
      ))}

      <AnimatePresence>
        {isMobile && showScrollToTop && (
          <motion.button
            className={classes.scrollToTop}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: "backInOut" }}>
            <OnewayArrow />
          </motion.button>
        )}
      </AnimatePresence>

      {visibleResultNum < filteredResults.length && (
        <Button
          secondary
          wide
          fontclass={classes.more}
          name={stringRes["res.result.button.more"]}
          onClick={onShowMoreResults}
        />
      )}
    </section>
  );
}

export default ResultsList;
