import { useEffect, useRef, useState } from "react";

export default function useTooltip(initialState, delay = 300) {
  const tipTriggerRef = useRef(null);
  const [tooltipTrigger, triggerTooltip] = useState(false);
  const [tooltipState, setTooltipState] = useState({ ...initialState, isOpening: false });

  useEffect(() => {
    let timerId = 0;
    if (tooltipTrigger) {
      const elPosition = tipTriggerRef.current.getBoundingClientRect();
      const triggerElWidth = tipTriggerRef.current ? elPosition.width : 0;
      const triggerElHeight = tipTriggerRef.current ? elPosition.height : 0;

      setTooltipState((prev) => {
        return {
          ...prev,
          show: true,
          triggerHalfW: triggerElWidth / 2,
          triggerY: triggerElHeight,
        };
      });
      timerId = setTimeout(
        () => setTooltipState((prev) => ({ ...prev, isOpening: true })),
        delay
      );
    } else {
      setTooltipState((prev) => ({ ...prev, isOpening: false }));
      timerId = setTimeout(
        () =>
          setTooltipState((prev) => {
            return { ...prev, show: false };
          }),
        delay
      );
    }
    return () => clearTimeout(timerId);
  }, [tooltipTrigger]);

  return [tipTriggerRef, tooltipState, triggerTooltip];
}
