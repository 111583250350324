export function PassengerStepIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1069_10832)">
        <path
          d="M14.175 0.000152421H3.825C2.82077 -0.00884665 1.854 0.380963 1.13688 1.08402C0.419761 1.78708 0.0108864 2.74594 0 3.75015L0 6.00015C0 6.19906 0.0790176 6.38983 0.21967 6.53048C0.360322 6.67113 0.551088 6.75015 0.75 6.75015C0.948912 6.75015 1.13968 6.67113 1.28033 6.53048C1.42098 6.38983 1.5 6.19906 1.5 6.00015V3.75015C1.51065 3.1437 1.76142 2.56623 2.19728 2.14443C2.63315 1.72262 3.21852 1.49092 3.825 1.50015H14.175C14.7815 1.49092 15.3669 1.72262 15.8027 2.14443C16.2386 2.56623 16.4893 3.1437 16.5 3.75015V14.2502C16.4893 14.8566 16.2386 15.4341 15.8027 15.8559C15.3669 16.2777 14.7815 16.5094 14.175 16.5002H3.825C3.21852 16.5094 2.63315 16.2777 2.19728 15.8559C1.76142 15.4341 1.51065 14.8566 1.5 14.2502V12.0002C1.5 11.8012 1.42098 11.6105 1.28033 11.4698C1.13968 11.3292 0.948912 11.2502 0.75 11.2502C0.551088 11.2502 0.360322 11.3292 0.21967 11.4698C0.0790176 11.6105 0 11.8012 0 12.0002L0 14.2502C0.0108864 15.2544 0.419761 16.2132 1.13688 16.9163C1.854 17.6193 2.82077 18.0092 3.825 18.0002H14.175C15.1792 18.0092 16.146 17.6193 16.8631 16.9163C17.5802 16.2132 17.9891 15.2544 18 14.2502V3.75015C17.9891 2.74594 17.5802 1.78708 16.8631 1.08402C16.146 0.380963 15.1792 -0.00884665 14.175 0.000152421Z"
          fill="#001B38"
        />
        <path
          d="M2.25 9.00055C2.25 9.19946 2.32902 9.39023 2.46967 9.53088C2.61032 9.67153 2.80109 9.75055 3 9.75055L12.891 9.72805L9.64875 12.9703C9.57712 13.0395 9.51998 13.1222 9.48067 13.2137C9.44137 13.3052 9.42068 13.4037 9.41981 13.5032C9.41895 13.6028 9.43792 13.7016 9.47563 13.7938C9.51334 13.8859 9.56903 13.9697 9.63945 14.0401C9.70987 14.1105 9.79361 14.1662 9.88578 14.2039C9.97796 14.2416 10.0767 14.2606 10.1763 14.2597C10.2759 14.2589 10.3743 14.2382 10.4658 14.1989C10.5573 14.1596 10.6401 14.1024 10.7093 14.0308L14.1488 10.5913C14.5706 10.1694 14.8075 9.59717 14.8075 9.00055C14.8075 8.40393 14.5706 7.83174 14.1488 7.4098L10.7108 3.9703C10.5693 3.83368 10.3798 3.75809 10.1832 3.75979C9.98655 3.7615 9.79844 3.84038 9.65939 3.97944C9.52033 4.11849 9.44145 4.3066 9.43974 4.50325C9.43804 4.6999 9.51363 4.88935 9.65025 5.0308L12.8468 8.22805L3 8.25055C2.80109 8.25055 2.61032 8.32957 2.46967 8.47022C2.32902 8.61087 2.25 8.80164 2.25 9.00055Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_1069_10832">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MoneyBackStepIcon() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.75 2.34375L8.40625 0.59375C8.15625 0.53125 7.875 0.53125 7.625 0.59375L1.25 2.34375C0.59375 2.53125 0.125 3.125 0.125 3.8125V8.4375C0.125 13.2813 3 17.5625 7.4375 19.3438C7.625 19.4062 7.8125 19.4688 8 19.4688C8.1875 19.4688 8.375 19.4375 8.5625 19.3438C13 17.5625 15.875 13.25 15.875 8.40625V3.8125C15.875 3.125 15.4063 2.53125 14.75 2.34375ZM14.4688 8.40625C14.4688 12.5938 11.875 16.4688 8.0625 18.0313C8.03125 18.0313 8 18.0313 7.96875 18.0313C4.0625 16.4688 1.53125 12.6875 1.53125 8.4375V3.8125C1.53125 3.78125 1.5625 3.71875 1.59375 3.71875L7.96875 1.96875H8C8 1.96875 8 1.96875 8.03125 1.96875L14.4062 3.71875C14.4375 3.71875 14.4688 3.78125 14.4688 3.8125V8.40625Z"
        fill="#001B38"
      />
      <path
        d="M11.5313 7.0621L7.21879 10.6871L5.28129 9.0621C4.96879 8.8121 4.53129 8.84335 4.28129 9.15584C4.03129 9.46834 4.06254 9.90585 4.37504 10.1558L6.75004 12.1558C6.87504 12.2808 7.03129 12.3121 7.18754 12.3121C7.34379 12.3121 7.50004 12.2496 7.62504 12.1558L12.4375 8.1246C12.75 7.8746 12.7813 7.4371 12.5313 7.1246C12.2813 6.84335 11.8125 6.8121 11.5313 7.0621Z"
        fill="#001B38"
      />
    </svg>
  );
}

export function InsuranceStepIcon() {
  return <MoneyBackStepIcon />;
}

export function ExtrasStepIcon() {
  return <SeatsStepIcon />;
}

export function SeatsStepIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3337_13503)">
        <path
          d="M17.5002 18.3328H7.50023C7.08106 18.3328 6.86523 18.0562 6.79106 17.9378C6.71773 17.8178 6.5669 17.502 6.7544 17.1262L7.5919 15.452C8.2394 15.6987 8.9369 15.832 9.65856 15.832H15.8327C16.5669 15.832 17.2611 15.5112 17.7377 14.9512C18.2144 14.3912 18.4186 13.6503 18.2994 12.9178C18.1061 11.7287 17.0069 10.832 15.7444 10.832H9.67023C9.29523 10.832 8.96523 10.5795 8.8669 10.2187L8.3519 8.33199H15.8336C16.2936 8.33199 16.6669 7.95949 16.6669 7.49865C16.6669 7.03782 16.2936 6.66532 15.8336 6.66532H7.89606L6.60523 1.94199C6.2669 0.652821 5.06356 -0.178846 3.80356 0.00615449C3.10023 0.109488 2.4719 0.508654 2.08106 1.10032C1.69023 1.69199 1.56856 2.42532 1.75106 3.11782L4.02856 11.5253C4.3744 12.8003 5.1319 13.882 6.13356 14.6428L5.2644 16.3812C4.8744 17.1628 4.91523 18.0712 5.3744 18.8137C5.83356 19.5562 6.62773 19.9995 7.50106 19.9995H17.5011C17.9611 19.9995 18.3344 19.627 18.3344 19.1662C18.3344 18.7053 17.9602 18.3328 17.5002 18.3328ZM3.36106 2.68865C3.30023 2.45699 3.3394 2.21949 3.4719 2.01949C3.60356 1.81949 3.84606 1.64782 4.14856 1.64782C4.52356 1.64782 4.88273 1.94532 4.99523 2.37449L7.25856 10.6587C7.5544 11.7428 8.54606 12.4995 9.67023 12.4995H15.7444C16.1919 12.4995 16.5919 12.8012 16.6552 13.1862C16.6961 13.4395 16.6302 13.6828 16.4686 13.872C16.3102 14.0587 16.0786 14.1662 15.8336 14.1662H9.6594C7.7819 14.1662 6.12856 12.9003 5.63773 11.0895L3.36106 2.68865Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_3337_13503">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SupportStepIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.97"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35547 0.972081C10.8755 0.835992 12.9003 1.76047 14.4297 3.74552C15.2233 4.909 15.6465 6.19806 15.6992 7.61271C16.353 7.68712 16.7762 8.03868 16.9688 8.66739C16.9948 10.2038 16.9948 11.7403 16.9688 13.2768C16.828 13.8472 16.4699 14.1923 15.8945 14.3119C15.3602 14.3184 14.8263 14.338 14.293 14.3705C13.1159 15.884 11.5664 16.7499 9.64453 16.9682C8.96758 16.9877 8.29051 16.9942 7.61328 16.9877C7.61328 16.532 7.61328 16.0763 7.61328 15.6205C8.2384 15.627 8.8634 15.6205 9.48828 15.601C10.8731 15.4458 12.0254 14.8533 12.9453 13.8236C12.9467 12.494 12.9727 11.1659 13.0234 9.83927C13.0841 9.56165 13.1883 9.30122 13.3359 9.05802C13.6354 8.61532 13.9349 8.17259 14.2344 7.72989C14.2683 5.92923 13.5717 4.47743 12.1445 3.37443C10.5122 2.24918 8.76746 2.04085 6.91016 2.74943C4.93742 3.67903 3.85668 5.23505 3.66797 7.41739C3.66613 7.52657 3.68566 7.63071 3.72656 7.72989C4.12199 8.28189 4.48656 8.85482 4.82031 9.44864C4.8907 9.63907 4.94277 9.83439 4.97656 10.0346C5.00262 11.2976 5.00262 12.5606 4.97656 13.8236C4.92149 14.0322 4.79777 14.182 4.60547 14.2729C3.7616 14.3367 2.91527 14.3497 2.06641 14.3119C1.49104 14.1923 1.13297 13.8472 0.992188 13.2768C0.966146 11.7403 0.966146 10.2038 0.992188 8.66739C1.18472 8.03868 1.6079 7.68712 2.26172 7.61271C2.38225 5.09403 3.50203 3.17349 5.62109 1.85099C6.47567 1.36425 7.38711 1.07128 8.35547 0.972081ZM2.33984 8.97989C2.53688 8.96712 2.73219 8.98013 2.92578 9.01896C3.16512 9.35587 3.37996 9.70743 3.57031 10.0736C3.62738 11.0352 3.64691 11.9987 3.62891 12.9643C3.19922 12.9643 2.76953 12.9643 2.33984 12.9643C2.33984 11.6361 2.33984 10.308 2.33984 8.97989ZM15.0352 8.97989C15.2305 8.97989 15.4258 8.97989 15.6211 8.97989C15.6211 10.308 15.6211 11.6361 15.6211 12.9643C15.1914 12.9643 14.7617 12.9643 14.332 12.9643C14.3255 12.0397 14.332 11.1152 14.3516 10.1908C14.3884 10.0544 14.4404 9.92419 14.5078 9.80021C14.7039 9.53782 14.8797 9.26439 15.0352 8.97989Z"
        fill="#001B38"
      />
      <path
        opacity="0.952"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90234 3.27734C10.14 3.28453 11.1946 3.72074 12.0664 4.58594C11.825 4.83391 11.5776 5.0748 11.3242 5.30859C10.2324 4.31555 8.99543 4.0877 7.61328 4.625C7.24676 4.79516 6.92125 5.02301 6.63672 5.30859C6.38336 5.0748 6.13598 4.83391 5.89453 4.58594C6.73039 3.76434 7.73301 3.32812 8.90234 3.27734Z"
        fill="black"
      />
    </svg>
  );
}

export function CheckoutStepIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.97"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06641 0.995606C6.56902 0.989094 10.0716 0.995606 13.5742 1.01514C14.6446 1.20013 15.3152 1.81862 15.5859 2.87061C15.5989 5.09717 15.612 7.32373 15.625 9.55029C17.859 10.4231 18.9658 12.0637 18.9453 14.4722C18.6627 16.8121 17.3802 18.2769 15.0977 18.8667C14.7807 18.9186 14.4617 18.9511 14.1406 18.9644C10.4505 18.9694 6.75906 18.9629 3.06641 18.9448C1.88802 18.756 1.20443 18.0724 1.01563 16.894C0.989583 12.2847 0.989583 7.67529 1.01563 3.06592C1.21142 1.88707 1.89501 1.19697 3.06641 0.995606ZM3.18359 2.28467C6.60809 2.27816 10.0326 2.28467 13.457 2.3042C14.0024 2.43306 14.2954 2.77811 14.3359 3.33936C14.3555 5.34451 14.362 7.34975 14.3555 9.35498C12.4895 9.31451 11.0507 10.0697 10.0391 11.6206C9.27664 12.9789 9.14645 14.3982 9.64844 15.8784C9.91442 16.5604 10.3115 17.1529 10.8398 17.6558C8.28777 17.6818 5.73567 17.6818 3.18359 17.6558C2.68229 17.5711 2.38933 17.2781 2.30469 16.7769C2.27865 12.2456 2.27865 7.71436 2.30469 3.18311C2.39635 2.68133 2.68932 2.38185 3.18359 2.28467ZM14.1211 10.605C15.9193 10.7304 17.0716 11.6549 17.5781 13.3784C17.8539 14.916 17.3657 16.1465 16.1133 17.0698C14.7204 17.905 13.3532 17.866 12.0117 16.9526C10.7651 15.8931 10.368 14.5715 10.8203 12.9878C11.4298 11.5084 12.5301 10.7141 14.1211 10.605Z"
        fill="#001B38"
      />
      <path
        opacity="0.964"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.78509 5.1753C6.85544 5.16878 8.92575 5.1753 10.996 5.19483C11.2934 5.29389 11.4497 5.50221 11.4648 5.81983C11.4337 6.14323 11.2645 6.35155 10.957 6.44483C8.91271 6.47088 6.86841 6.47088 4.82415 6.44483C4.42228 6.28573 4.27255 5.99276 4.37493 5.56592C4.4572 5.37284 4.59392 5.24264 4.78509 5.1753Z"
        fill="#001B38"
      />
      <path
        opacity="0.962"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82383 8.49561C6.30824 8.48909 7.79262 8.49561 9.27695 8.51514C9.76371 8.69885 9.90043 9.03088 9.68711 9.51124C9.59867 9.62581 9.48797 9.71045 9.35508 9.76514C7.83164 9.7912 6.3082 9.7912 4.78476 9.76514C4.34312 9.55112 4.23246 9.21909 4.45273 8.76905C4.55152 8.63682 4.67523 8.54569 4.82383 8.49561Z"
        fill="#001B38"
      />
      <path
        opacity="0.957"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3708 12.6367C15.9914 12.7582 16.1803 13.1097 15.9372 13.6914C15.3318 14.2969 14.7263 14.9023 14.1208 15.5078C13.8604 15.638 13.6 15.638 13.3396 15.5078C13.0076 15.1758 12.6755 14.8438 12.3435 14.5117C12.1684 14.1564 12.227 13.8504 12.5193 13.5938C12.7337 13.4799 12.955 13.4669 13.1833 13.5547C13.3715 13.7102 13.5537 13.873 13.7302 14.043C14.1659 13.6269 14.5956 13.2037 15.0193 12.7734C15.1349 12.7108 15.2521 12.6652 15.3708 12.6367Z"
        fill="#001B38"
      />
    </svg>
  );
}

export function FareTypeStepIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3337_14540)">
        <path
          d="M12.75 9V9.75H10.875L9.44175 11.5658C9.2955 11.8335 9.015 12 8.7105 12H7.87575L8.751 9.75H7.23825L6.07875 8.37525C5.856 7.98525 6.13725 7.5 6.5865 7.5C6.74175 7.5 6.89025 7.5615 6.99975 7.671L7.83225 8.25H8.763L7.875 6H8.697C9.00225 6 9.28275 6.1665 9.42825 6.43425L10.875 8.25H11.8125C12.414 8.25 12.75 8.586 12.75 9ZM15.75 9C15.75 9.82725 16.4228 10.5 17.25 10.5H18V13.5C18 14.7405 16.9905 15.75 15.75 15.75H2.25C1.0095 15.75 0 14.7405 0 13.5V4.5C0 3.2595 1.0095 2.25 2.25 2.25H15.75C16.9905 2.25 18 3.2595 18 4.5V7.5H17.25C16.4228 7.5 15.75 8.17275 15.75 9ZM14.25 9C14.25 7.605 15.207 6.42825 16.5 6.09525V4.5C16.5 4.086 16.164 3.75 15.75 3.75H4.5V5.25H3V3.75H2.25C1.836 3.75 1.5 4.086 1.5 4.5V13.5C1.5 13.914 1.836 14.25 2.25 14.25H3V12.75H4.5V14.25H15.75C16.164 14.25 16.5 13.914 16.5 13.5V11.9047C15.207 11.5717 14.25 10.395 14.25 9ZM3 8.25H4.5V6.75H3V8.25ZM3 11.25H4.5V9.75H3V11.25Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_3337_14540">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BillingInfoIcon() {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 19.9167L11.9475 17.8242L9.7275 19.345L7.50583 17.8233L5.28417 19.3433L3.05833 17.8217L0 19.9167V2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0L12.5 0C13.163 0 13.7989 0.263392 14.2678 0.732233C14.7366 1.20107 15 1.83696 15 2.5V19.9167ZM3.05833 15.8017L5.28333 17.3233L7.50583 15.8017L9.7275 17.3233L11.9483 15.8008L13.3333 16.75V2.5C13.3333 2.27899 13.2455 2.06702 13.0893 1.91074C12.933 1.75446 12.721 1.66667 12.5 1.66667H2.5C2.27899 1.66667 2.06702 1.75446 1.91074 1.91074C1.75446 2.06702 1.66667 2.27899 1.66667 2.5V16.75L3.05833 15.8017Z"
        fill="#001B38"
      />
      <path d="M11.6663 6.66602H3.33301V8.33268H11.6663V6.66602Z" fill="#001B38" />
      <path d="M9.99967 10H3.33301V11.6667H9.99967V10Z" fill="#001B38" />
    </svg>
  );
}

export function OrderPaymentIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.993"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47266 3.10596C8.14457 3.09945 11.8164 3.10596 15.4883 3.12549C16.6218 3.33453 17.4226 3.96604 17.8906 5.02002C17.9796 5.24783 18.0447 5.48221 18.0859 5.72315C18.112 8.5617 18.112 11.4002 18.0859 14.2388C17.8769 15.3723 17.2454 16.1731 16.1914 16.6411C15.9636 16.7301 15.7292 16.7952 15.4883 16.8364C11.8164 16.8625 8.14453 16.8625 4.47266 16.8364C3.33909 16.6274 2.53831 15.9959 2.07031 14.9419C1.98136 14.7141 1.91625 14.4797 1.875 14.2388C1.84896 11.4002 1.84896 8.5617 1.875 5.72315C2.1907 4.30002 3.05658 3.42761 4.47266 3.10596ZM4.98047 4.35596C8.44477 4.33744 11.9083 4.35697 15.3711 4.41455C16.2828 4.68139 16.7646 5.28686 16.8164 6.23096C12.2591 6.23096 7.70184 6.23096 3.14453 6.23096C3.184 5.33857 3.62671 4.74615 4.47266 4.45361C4.64707 4.41947 4.81633 4.38693 4.98047 4.35596ZM3.10547 7.48096C7.68879 7.48096 12.2722 7.48096 16.8555 7.48096C16.8555 7.89764 16.8555 8.31428 16.8555 8.73096C12.2722 8.73096 7.68879 8.73096 3.10547 8.73096C3.10547 8.31428 3.10547 7.89764 3.10547 7.48096ZM3.10547 9.98096C7.68879 9.98096 12.2722 9.98096 16.8555 9.98096C16.8725 11.3492 16.853 12.7163 16.7969 14.0825C16.5451 15.0115 15.9396 15.5128 14.9805 15.5864C11.6472 15.6125 8.31379 15.6125 4.98047 15.5864C4.02102 15.5124 3.41556 15.0111 3.16406 14.0825C3.10792 12.7163 3.08839 11.3492 3.10547 9.98096Z"
        fill="#001B38"
      />
      <path
        opacity="0.986"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74594 11.231C6.15227 11.2244 7.55852 11.231 8.96469 11.2505C9.31555 11.4252 9.43926 11.7052 9.33578 12.0903C9.26418 12.2661 9.14047 12.3898 8.96469 12.4614C7.55844 12.4875 6.15219 12.4875 4.74594 12.4614C4.47301 12.3518 4.34281 12.15 4.35531 11.856C4.34758 11.5585 4.47777 11.3501 4.74594 11.231Z"
        fill="black"
      />
      <path
        opacity="0.982"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74594 13.106C5.73563 13.0994 6.7252 13.106 7.71469 13.1255C8.06555 13.3002 8.18926 13.5802 8.08578 13.9653C8.01418 14.1411 7.89047 14.2648 7.71469 14.3364C6.72512 14.3625 5.73551 14.3625 4.74594 14.3364C4.47301 14.2268 4.34281 14.025 4.35531 13.731C4.34758 13.4335 4.47777 13.2251 4.74594 13.106Z"
        fill="black"
      />
    </svg>
  );
}
