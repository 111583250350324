import { Interweave } from "interweave";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import DocumentFrame from "./DocumentFrame";
import docStyle from "./DocumentView.module.css";
import { getStyleSheet, getSelectorRules, getStylesFromRules } from "./doc-utils";

const sheet = getStyleSheet(`.${docStyle.doc}`);
const targetSelectors = [
  { name: `.${docStyle.doc}` },
  { name: `.${docStyle.doc} div`, tagName: "DIV" },
  { name: `.${docStyle.doc} p`, tagName: "P" },
  { name: `.${docStyle.doc} h1`, tagName: "H1" },
  { name: `.${docStyle.doc} h2`, tagName: "H2" },
  { name: `.${docStyle.doc} h3`, tagName: "H3" },
  { name: `.${docStyle.doc} h4`, tagName: "H4" },
  { name: `.${docStyle.doc} h5`, tagName: "H5" },
  { name: `.${docStyle.doc} h6`, tagName: "H6" },
  { name: `.${docStyle.doc} ul`, tagName: "UL" },
  { name: `.${docStyle.doc} ol`, tagName: "OL" },
  { name: `.${docStyle.doc} li`, tagName: "LI" },
  { name: `.${docStyle.doc} a`, tagName: "A" },
  { name: `.${docStyle.doc} pre`, tagName: "PRE" },
  { name: `.${docStyle.doc} blockquote`, tagName: "BLOCKQUOTE" },
  { name: `.${docStyle.doc} strong`, tagName: "STRONG" },
  { name: `.${docStyle.doc} b`, tagName: "B" },
  { name: `.${docStyle.doc} hr`, tagName: "HR" },
  { name: `.${docStyle.doc} em`, tagName: "EM" },
  { name: `.${docStyle.doc} i`, tagName: "I" },
  { name: `.${docStyle.doc} u`, tagName: "U" },
  { name: `.${docStyle.doc} sub`, tagName: "SUB" },
  { name: `.${docStyle.doc} sup`, tagName: "SUP" },
];

const defaultFontTags = ["A", "DIV", "P", "OL", "LI", "BLOCKQUOTE"];

targetSelectors.forEach((selector) => {
  const rules = getSelectorRules(sheet, selector);
  const styles = getStylesFromRules(rules, selector);
  const trStyles = {};
  let isUndefinedFontSize = true;
  Object.keys(styles).forEach((k) => {
    const camelCaseKey = k
      .split("-")
      .map((w, i) => (i > 0 ? w[0].toUpperCase() : w[0]) + w.substring(1))
      .join("");
    if (k === "font-size") isUndefinedFontSize = false;
    trStyles[camelCaseKey] = styles[k];
  });

  if (isUndefinedFontSize && defaultFontTags.includes(selector.tagName))
    trStyles["fontSize"] = "16px";

  const stylesString = Object.keys(styles)
    .map((k) => `${k}: ${styles[k]};`)
    .join(" ");
  selector.cssText = stylesString;
  selector.cssStyle = trStyles;
});
console.log(targetSelectors);

export function transformPDFTemplate(node, children, targetSelectors) {
  let cssStyle = targetSelectors.find((s) => s.tagName === node.tagName)?.cssStyle || {};
  const tagList = [
    "div",
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "ol",
    "li",
    "a",
    "pre",
    "blockquote",
    "strong",
    "b",
    "hr",
    "em",
    "i",
    "u",
    "sub",
    "sup",
  ];

  const existingStyle = node.getAttribute("style");
  if (existingStyle) {
    const styleObject = {};
    const props = [];

    existingStyle
      .split(";")
      .filter((v) => v)
      .forEach((pair) => {
        const [name, value] = pair.split(":");
        props.push([name.trim(), value.trim()]);
      });

    props.forEach(([k, v]) => {
      const camelCaseKey = k
        .split("-")
        .map((w, i) => (i > 0 ? w[0].toUpperCase() : w[0]) + w.substring(1))
        .join("");
      styleObject[camelCaseKey] = v;
    });

    if (props.length > 0) {
      cssStyle = {
        ...cssStyle,
        ...styleObject,
      };
    }
  }

  const Tag = node.tagName.toLowerCase();
  if (tagList.includes(Tag)) {
    if (Tag === "hr") return <hr style={{ ...cssStyle }} />;

    const props = { style: { ...cssStyle } };
    if (Tag === "a") {
      props.href = node.getAttribute("href");
      props.target = "_blank";
      props.rel = "noreferrer";
    }
    if (Tag === "pre") props.style.maxWidth = "100%";
    return React.createElement(Tag, props, children);
  }
}

function DocumentView(props) {
  const author = window.location.href;
  const docTemplateRef = useRef(null);
  const docName = props.title.replace(/\s+/g, "_");
  const [docState, setDocState] = useState({ isDownloading: false });
  const [originalTitle] = useState(document.title);

  useEffect(() => {
    const onBeforePrint = () => (document.title = docName + ".pdf");
    const onAfterPrint = () => (document.title = originalTitle);
    window.addEventListener("beforeprint", onBeforePrint);
    window.addEventListener("afterprint", onAfterPrint);
    return () => {
      window.removeEventListener("beforeprint", onBeforePrint);
      window.removeEventListener("afterprint", onAfterPrint);
    };
  });

  const generatePDF = async (cb) => {
    const doc = new jsPDF({
      format: "a4",
      unit: "pt",
    });

    console.log(doc.getFontList());

    doc.setDisplayMode("fullheight", "continuous");
    const copiedElement = docTemplateRef.current.cloneNode(true);
    copiedElement.style.backgroundColor = "#fff";
    copiedElement.style.padding = "0.23in 0.33in";
    copiedElement.style.boxShadow = "none";
    copiedElement.style.borderRadius = "0";
    copiedElement.style.borderTopRightRadius = "0";
    copiedElement.style.borderTopLeftRadius = "0";
    copiedElement.style.borderBottomRightRadius = "0";
    copiedElement.style.borderBottomLeftRadius = "0";
    copiedElement.style.fontFamily = "sans-serif";

    const allElements = copiedElement.querySelectorAll("*");
    for (const el of allElements) {
      el.style.fontFamily = "sans-serif";
    }

    doc.html(copiedElement, {
      width: 210,
      windowWidth: 788,
      html2canvas: {
        scale: 0.76,
        logging: false,
        windowWidth: 788,
        letterRendering: true,
      },
      x: 0,
      y: 0,
      autoPaging: "text",
      margin: [24, 0],
      async callback(document) {
        document.setDocumentProperties({
          title: props.title,
          author: author,
          creator: author,
        });

        return cb(document);
      },
    });
  };

  const onPrintPDF = (e) => {
    const printWindow = window.open("", "_blank");
    const stylesheets = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'));
    let htmlContent = `
    <html>
      <head>
        <title>Print PDF</title>
  `;
    stylesheets.forEach((stylesheet) => {
      htmlContent += stylesheet.outerHTML;
    });
    htmlContent += `
      </head>
      <body>
      ${props.template}
      </body>
    </html>
  `;
    printWindow.document.write(htmlContent);
    printWindow.document.close();
    printWindow.focus(); // Focus on the new window
    printWindow.print(); // Trigger the print dialog
    printWindow.onafterprint = () => {
      printWindow.close();
    };
  };

  const onSavePDF = () => {
    setDocState((prev) => ({ ...prev, isDownloading: true }));
    generatePDF((doc) => {
      doc
        .save(docName + ".pdf", { returnPromise: true })
        .then((savedDoc) => {
          setDocState((prev) => ({ ...prev, isDownloading: false }));
        })
        .catch(console.log);
    });
  };

  const docProps = {
    onPrint: onPrintPDF,
    onSave: onSavePDF,
    docState: docState,
  };

  return (
    <DocumentFrame {...docProps} {...props}>
      <div
        ref={docTemplateRef}
        className={docStyle.doc}
        style={{ ...targetSelectors[0].cssStyle }}>
        <Interweave
          content={props.template}
          transform={(node, children) => transformPDFTemplate(node, children, targetSelectors)}
        />
      </div>
    </DocumentFrame>
  );
}

export default DocumentView;
