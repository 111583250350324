import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./SeatSelectionView.module.css";
import { selectAllPassengers } from "src/store/booking";
import { PASSENGER, SEAT_TYPE } from "src/constants";
import { CATALOGUE_IDS } from "src/constants/services";
import { selectCatalogue, selectCatalogueItem } from "src/store/catalogue";
import { orderActions } from "src/store/order";
import { getDisplayPriceValue } from "src/utils/utils";
import { SeatOption } from "./SeatOption";

export function SeatSelectionView({ flightData, totalFlights, availableTypes }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();
  const currentServices = useSelector((state) => state.order.seatType);

  const passengers = useSelector(selectAllPassengers);
  const minGroupTravelPolicy = 2;
  const isGroupTravel =
    passengers.length -
      passengers.filter((p) => p.ageGroup === PASSENGER.infant).length >=
    minGroupTravelPolicy;

  const randomSeat = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.seats.random)
  );
  const windowSeat = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.seats.window)
  );
  const aisleSeat = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.seats.aisle)
  );
  const legroomSeat = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.seats.extra_legroom)
  );
  const togetherSeat = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.seats.sitting_together)
  );

  /** PRICES */
  const randomSeatPrice = randomSeat.unit_price;
  const windowSeatPrice = windowSeat.data[flightData.airline]?.unit_price;
  const aisleSeatPrice = aisleSeat.data[flightData.airline]?.unit_price;
  const legroomSeatPrice = legroomSeat.data[flightData.airline]?.unit_price;
  const togetherSeatPrice = togetherSeat.data[flightData.airline]?.unit_price;
  /** PRICES END */

  const onSelectSeatType = (isChecked, serviceId) => {
    if (isChecked) {
      dispatch(
        orderActions.changeSeatType({
          segment: flightData.segment,
          serviceId,
          airline: flightData.airline,
        })
      );
    }
  };

  return (
    <Fragment>
      <div
        className={`${classes.flightInfo} ${
          isMobile ? classes.mobile : classes.desktop
        }`}>
        <p>
          {stringRes["booking.seats.seating"]
            .replace("##FLIGHT_NUM##", flightData.segment + 1)
            .replace("##TOTAl_FLIGHT_NUM##", totalFlights)}
        </p>
        <span className={classes.icon}>
          <img
            src={flightData.icon}
            onDragStart={(e) => e.preventDefault()}
            alt={flightData.airline}
          />
        </span>
        <span>{`${flightData.srcCity} ${flightData.srcIata} - ${flightData.destCity} ${flightData.destIata}`}</span>
        {!isMobile &&
          currentServices[flightData.segment].serviceId ===
            CATALOGUE_IDS.seats.extra_legroom && (
            <span className={classes.tag}>{stringRes["booking.seats.tag.legroom"]}</span>
          )}
      </div>
      <div className={classes.hr}></div>
      <div
        className={`${classes.seatSelection} ${
          isMobile ? classes.mobile : classes.desktop
        }`}>
        <h1>{stringRes["booking.seats.seatMap.none.title"]}</h1>
        <p>{stringRes["booking.seats.choose.text"]}</p>
        <ul className={classes.seatOptions}>
          <SeatOption
            checked={
              currentServices[flightData.segment].serviceId === CATALOGUE_IDS.seats.random
            }
            onSelect={onSelectSeatType}
            serviceId={CATALOGUE_IDS.seats.random}
            name={stringRes["booking.seats.option.random"]}
            price={getDisplayPriceValue(randomSeatPrice)}
          />
          {isGroupTravel ? (
            <SeatOption
              checked={
                currentServices[flightData.segment].serviceId ===
                CATALOGUE_IDS.seats.sitting_together
              }
              onSelect={onSelectSeatType}
              serviceId={CATALOGUE_IDS.seats.sitting_together}
              name={stringRes["booking.seats.option.together"]}
              price={getDisplayPriceValue(togetherSeatPrice)}
            />
          ) : (
            <Fragment>
              {availableTypes.includes(SEAT_TYPE.window) && (
                <SeatOption
                  checked={
                    currentServices[flightData.segment].serviceId ===
                    CATALOGUE_IDS.seats.window
                  }
                  onSelect={onSelectSeatType}
                  serviceId={CATALOGUE_IDS.seats.window}
                  name={stringRes["booking.seats.option.window"]}
                  price={getDisplayPriceValue(windowSeatPrice)}
                />
              )}
              {availableTypes.includes(SEAT_TYPE.aisle) && (
                <SeatOption
                  checked={
                    currentServices[flightData.segment].serviceId ===
                    CATALOGUE_IDS.seats.aisle
                  }
                  onSelect={onSelectSeatType}
                  serviceId={CATALOGUE_IDS.seats.aisle}
                  name={stringRes["booking.seats.option.aisle"]}
                  price={getDisplayPriceValue(aisleSeatPrice)}
                />
              )}
              {availableTypes.includes(SEAT_TYPE.extra_legroom) && (
                <SeatOption
                  checked={
                    currentServices[flightData.segment].serviceId ===
                    CATALOGUE_IDS.seats.extra_legroom
                  }
                  onSelect={onSelectSeatType}
                  serviceId={CATALOGUE_IDS.seats.extra_legroom}
                  name={stringRes["booking.seats.option.legroom"]}
                  price={getDisplayPriceValue(legroomSeatPrice)}
                />
              )}
            </Fragment>
          )}
        </ul>
      </div>
    </Fragment>
  );
}
