import { useCallback, useEffect, useRef, useState } from "react";
import { customLog } from "src/utils/utils";

const API_URL = `wss://${window.SERVER_DATA.REACT_APP_API_URL}`;

export const useWebSocket = (
  onOpen,
  onMessage,
  onClose,
  onError,
  retry = false,
  path = ""
) => {
  const [isConnected, setIsConnected] = useState(false);
  const [servicePath, setServicePath] = useState(path);
  const websocket = useRef(null);

  useEffect(() => {
    const setupWebSocket = (path, reconnect) => {
      websocket.current = new WebSocket(`${API_URL}/${servicePath}`);

      websocket.current.onopen = () => {
        customLog("%c opening websocket 🟢: " + path, "color: #41dcc1;");
        setIsConnected(true);
        if (onOpen) onOpen();
      };

      websocket.current.onclose = (event) => {
        customLog(
          "%c closing websocket 🔴: " + path + `, code=${event.code}`,
          "color: #41dcc1;"
        );
        if (event.code !== 1000 && reconnect) {
          setupWebSocket(path, reconnect);
        } else {
          setIsConnected(false);
        }
        if (onClose) onClose();
      };

      websocket.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        customLog(path + "%c 📩 Receiving websocket data: 👇👇👇\n\n", "color: #41dcc1;");
        customLog(message);
        customLog("*** *** ***");
        onMessage(message);
      };

      websocket.current.onerror = (event) => {
        customLog(path + `%c Websocket connection died ❌`, "color: #41dcc1;");
        if (onError) onError();
      };
    };

    if (servicePath) {
      setupWebSocket(servicePath, retry);
    }

    return () => {
      console.warn("Closing websocket" + servicePath);
      if (websocket.current) websocket.current.close();
    };
  }, [servicePath, onOpen, onMessage, onClose, onError, retry]);

  const send = useCallback(
    (data) => {
      const jsonData = JSON.stringify(data, null, 2);
      if (websocket.current) {
        customLog(
          servicePath + "%c ✉️ Sending websocket data: 👇👇👇\n\n" + jsonData,
          "color: #41dcc1;"
        );
        websocket.current.send(jsonData);
      }
    },
    [servicePath]
  );

  return { isConnected, send, setServicePath };
};
