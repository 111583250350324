import classes from "./BaggageItem.module.css";

export function BaggagePersonal() {
  return (
    <svg
      className={classes.baggage}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M16.0833 7.33333H15.4417C15.3063 6.67499 14.9481 6.08345 14.4274 5.65842C13.9067 5.23339 13.2555 5.00085 12.5833 5L11.4167 5C10.7445 5.00085 10.0933 5.23339 9.5726 5.65842C9.05194 6.08345 8.69372 6.67499 8.55833 7.33333H7.91667C7.1434 7.33426 6.40208 7.64185 5.85529 8.18863C5.30851 8.73541 5.00093 9.47674 5 10.25V16.0833C5.00093 16.8566 5.30851 17.5979 5.85529 18.1447C6.40208 18.6915 7.1434 18.9991 7.91667 19H16.0833C16.8566 18.9991 17.5979 18.6915 18.1447 18.1447C18.6915 17.5979 18.9991 16.8566 19 16.0833V10.25C18.9991 9.47674 18.6915 8.73541 18.1447 8.18863C17.5979 7.64185 16.8566 7.33426 16.0833 7.33333ZM11.4167 6.16667H12.5833C12.944 6.16816 13.2954 6.28105 13.5894 6.48988C13.8834 6.69872 14.1058 6.9933 14.226 7.33333H9.774C9.89422 6.9933 10.1166 6.69872 10.4106 6.48988C10.7046 6.28105 11.056 6.16816 11.4167 6.16667ZM7.91667 8.5H16.0833C16.5475 8.5 16.9926 8.68437 17.3208 9.01256C17.649 9.34075 17.8333 9.78587 17.8333 10.25V12H6.16667V10.25C6.16667 9.78587 6.35104 9.34075 6.67923 9.01256C7.00742 8.68437 7.45254 8.5 7.91667 8.5ZM16.0833 17.8333H7.91667C7.45254 17.8333 7.00742 17.649 6.67923 17.3208C6.35104 16.9926 6.16667 16.5475 6.16667 16.0833V13.1667H11.4167V13.75C11.4167 13.9047 11.4781 14.0531 11.5875 14.1625C11.6969 14.2719 11.8453 14.3333 12 14.3333C12.1547 14.3333 12.3031 14.2719 12.4125 14.1625C12.5219 14.0531 12.5833 13.9047 12.5833 13.75V13.1667H17.8333V16.0833C17.8333 16.5475 17.649 16.9926 17.3208 17.3208C16.9926 17.649 16.5475 17.8333 16.0833 17.8333Z"
        fill="#04B1A7"
      />
    </svg>
  );
}

export function BaggageCarryOn({ isIncluded }) {
  if (isIncluded) {
    return (
      <svg
        className={classes.baggage}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M16 4H8V5H9V10H8C7.46957 10 6.96086 10.2107 6.58579 10.5858C6.21071 10.9609 6 11.4696 6 12V17C6 17.5304 6.21071 18.0391 6.58579 18.4142C6.96086 18.7893 7.46957 19 8 19V19.5C8 19.6326 8.05268 19.7598 8.14645 19.8536C8.24021 19.9473 8.36739 20 8.5 20C8.63261 20 8.75979 19.9473 8.85355 19.8536C8.94732 19.7598 9 19.6326 9 19.5V19H15V19.5C15 19.6326 15.0527 19.7598 15.1464 19.8536C15.2402 19.9473 15.3674 20 15.5 20C15.6326 20 15.7598 19.9473 15.8536 19.8536C15.9473 19.7598 16 19.6326 16 19.5V19C16.5304 19 17.0391 18.7893 17.4142 18.4142C17.7893 18.0391 18 17.5304 18 17V12C18 11.4696 17.7893 10.9609 17.4142 10.5858C17.0391 10.2107 16.5304 10 16 10H15V5H16V4ZM10 10V5H14V10H10ZM8 18C7.86639 18.0088 7.73244 17.989 7.6071 17.9419C7.48177 17.8948 7.36795 17.8214 7.27327 17.7267C7.17859 17.632 7.10523 17.5182 7.0581 17.3929C7.01097 17.2676 6.99116 17.1336 7 17V12C6.99116 11.8664 7.01097 11.7324 7.0581 11.6071C7.10523 11.4818 7.17859 11.368 7.27327 11.2733C7.36795 11.1786 7.48177 11.1052 7.6071 11.0581C7.73244 11.011 7.86639 10.9912 8 11H16C16.1336 10.9912 16.2676 11.011 16.3929 11.0581C16.5182 11.1052 16.632 11.1786 16.7267 11.2733C16.8214 11.368 16.8948 11.4818 16.9419 11.6071C16.989 11.7324 17.0088 11.8664 17 12V17C17.0088 17.1336 16.989 17.2676 16.9419 17.3929C16.8948 17.5182 16.8214 17.632 16.7267 17.7267C16.632 17.8214 16.5182 17.8948 16.3929 17.9419C16.2676 17.989 16.1336 18.0088 16 18H8Z"
          fill="#04B1A7"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={classes.baggage}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 9.29V5H14V10H10.71L10 9.29ZM9 8.29L5.71 5L5 5.71L18.7 19.41L19.41 18.7L18 17.27C18.005 17.1801 18.005 17.0899 18 17V12C18 11.4696 17.7893 10.9609 17.4142 10.5858C17.0391 10.2107 16.5304 10 16 10H15V5H16V4H8V5H9V8.29ZM17 16.29V12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11H11.71L17 16.29ZM8 18H14.59L16 19.41V19.5C16 19.6326 15.9473 19.7598 15.8536 19.8536C15.7598 19.9473 15.6326 20 15.5 20C15.3674 20 15.2402 19.9473 15.1464 19.8536C15.0527 19.7598 15 19.6326 15 19.5V19H9V19.5C9 19.6326 8.94732 19.7598 8.85355 19.8536C8.75979 19.9473 8.63261 20 8.5 20C8.36739 20 8.24021 19.9473 8.14645 19.8536C8.05268 19.7598 8 19.6326 8 19.5V19C7.46957 19 6.96086 18.7893 6.58579 18.4142C6.21071 18.0391 6 17.5304 6 17V12C5.99948 11.6663 6.08247 11.3377 6.24141 11.0443C6.40036 10.7509 6.6302 10.5019 6.91 10.32L7.65 11.06C7.4586 11.1315 7.2937 11.2599 7.17749 11.428C7.06128 11.5961 6.99934 11.7957 7 12V17C7 17.2652 7.10536 17.5196 7.29289 17.7071C7.48043 17.8946 7.73478 18 8 18Z"
          fill="#FF5252"
        />
      </svg>
    );
  }
}

export function BaggageChecked({ isIncluded }) {
  if (isIncluded) {
    return (
      <svg
        className={classes.baggage}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M16 7H14V5H15V4H9V5H10V7H8C7.46957 7 6.96086 7.21071 6.58579 7.58579C6.21071 7.96086 6 8.46957 6 9V17C6 17.5304 6.21071 18.0391 6.58579 18.4142C6.96086 18.7893 7.46957 19 8 19V19.5C8 19.6326 8.05268 19.7598 8.14645 19.8536C8.24021 19.9473 8.36739 20 8.5 20C8.63261 20 8.75979 19.9473 8.85355 19.8536C8.94732 19.7598 9 19.6326 9 19.5V19H15V19.5C15 19.6326 15.0527 19.7598 15.1464 19.8536C15.2402 19.9473 15.3674 20 15.5 20C15.6326 20 15.7598 19.9473 15.8536 19.8536C15.9473 19.7598 16 19.6326 16 19.5V19C16.5304 19 17.0391 18.7893 17.4142 18.4142C17.7893 18.0391 18 17.5304 18 17V9C18 8.46957 17.7893 7.96086 17.4142 7.58579C17.0391 7.21071 16.5304 7 16 7ZM11 5H13V7H11V5ZM17 17C17 17.2652 16.8946 17.5196 16.7071 17.7071C16.5196 17.8946 16.2652 18 16 18H8C7.73478 18 7.48043 17.8946 7.29289 17.7071C7.10536 17.5196 7 17.2652 7 17V9C7 8.73478 7.10536 8.48043 7.29289 8.29289C7.48043 8.10536 7.73478 8 8 8H16C16.2652 8 16.5196 8.10536 16.7071 8.29289C16.8946 8.48043 17 8.73478 17 9V17Z"
          fill="#04B1A7"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={classes.baggage}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00012 5H10.0001V7H8.71012L6.71012 5L6.00012 5.71L19.4301 19.14L20.1401 18.44L18.0001 16.29V9C18.0001 8.46957 17.7894 7.96086 17.4143 7.58579C17.0393 7.21071 16.5306 7 16.0001 7H14.0001V5H15.0001V4H9.00012V5ZM17.0001 15.29V9C17.0001 8.73478 16.8948 8.48043 16.7072 8.29289C16.5197 8.10536 16.2653 8 16.0001 8H9.71012L17.0001 15.29ZM13.0001 6V7H11.0001V5H13.0001V6ZM15.5901 18H8.00012C7.73491 18 7.48055 17.8946 7.29302 17.7071C7.10548 17.5196 7.00012 17.2652 7.00012 17V9.41L6.07012 8.48C6.02166 8.64901 5.99808 8.82419 6.00012 9V17C6.00012 17.5304 6.21084 18.0391 6.58591 18.4142C6.96098 18.7893 7.46969 19 8.00012 19V19.5C8.00012 19.6326 8.0528 19.7598 8.14657 19.8536C8.24034 19.9473 8.36751 20 8.50012 20C8.63273 20 8.75991 19.9473 8.85368 19.8536C8.94744 19.7598 9.00012 19.6326 9.00012 19.5V19H15.0001V19.5C15.0001 19.6326 15.0528 19.7598 15.1466 19.8536C15.2403 19.9473 15.3675 20 15.5001 20C15.6327 20 15.7599 19.9473 15.8537 19.8536C15.9474 19.7598 16.0001 19.6326 16.0001 19.5V19C16.1759 19.002 16.3511 18.9785 16.5201 18.93L15.5901 18Z"
          fill="#FF5252"
        />
      </svg>
    );
  }
}
