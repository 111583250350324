import classes from "./ToggleSwitch.module.css";

function ToggleSwitch({ id, isChecked, onChange, className }) {
  const onChangeHandler = (event) => {
    const checkValue = event.target.checked;
    onChange(id, checkValue);
  };

  const onKeyUpHandler = (e) => {
    if (e.key === "Enter") {
      const inpEl = document.getElementById(id);
      onChange(id, !inpEl.checked);
    }
  };

  return (
    <label htmlFor={id} className={`${classes.toggle} ${className || ""}`}>
      <input
        id={id}
        checked={isChecked}
        type="checkbox"
        onChange={onChangeHandler}
        onKeyUp={onKeyUpHandler}
      />
      <span className={classes.slider}></span>
    </label>
  );
}

export default ToggleSwitch;
