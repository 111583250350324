import { Fragment, useContext } from "react";
import SearchForm from "../../search/SearchForm/SearchForm";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { ModalElementContext } from "src/context/modal-element-context";
import { searchDataActions } from "src/store/search";
import { useDispatch } from "react-redux";
import { useLocaleContext } from "src/context/locale-context";

function MobileModifySF() {
  const { onClose } = useContext(ModalElementContext);
  const { currentLocale } = useLocaleContext();
  const lang = currentLocale.split("_")[0].toUpperCase();

  const dispatch = useDispatch();

  return (
    <Fragment>
      <CloseWindowButton
        onClick={() => {
          dispatch(searchDataActions.fillUserInputPassengerOptions());
          dispatch(searchDataActions.fillUserInputTrips(lang));
          onClose();
        }}
      />
      <SearchForm />
    </Fragment>
  );
}

export default MobileModifySF;
