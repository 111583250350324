import dest_porto from 'src/assets/img/dest_porto.png';
import dest_barcelona from 'src/assets/img/dest_barcelona.png';
import dest_krakow from 'src/assets/img/dest_krakow.png';
import dest_milan from 'src/assets/img/dest_milan.png';
import dest_lisbon from 'src/assets/img/dest_lisbon.png';
import dest_warsaw from 'src/assets/img/dest_warsaw.png';

import dest_berlin from 'src/assets/img/dest_berlin.png';
import dest_riga from 'src/assets/img/dest_riga.png';
import dest_seville from 'src/assets/img/dest_seville.png';
import dest_tokyo from 'src/assets/img/dest_tokyo.jpg';

export const componentData = {
  title: "Direct flights from Riga till 50 Eur",
  description:
    "Other Travellers are loving these destinations. Search flights, hotels and car hire and join them on adventure.",
  showMoreBtn: "See more offers",
  filters: [
    { text: "Until 50 EUR", link: "" },
    { text: "City breaks", link: "" },
    { text: "Only direct", link: "" },
    { text: "For family", link: "" },
  ],
  destinations: [
    {
      price: 40,
      city: "Porto",
      alt: "porto",
      src: dest_porto,
    },
    {
      price: 45,
      city: "Barcelona",
      alt: "barcelona",
      src: dest_barcelona,
    },
    {
      price: 20,
      city: "Krakow",
      alt: "krakow",
      src: dest_krakow,
    },
    {
      price: 25,
      city: "Milan",
      alt: "milan",
      src: dest_milan,
    },
    {
      price: 25,
      city: "Lisbon",
      alt: "lisbon",
      src: dest_lisbon,
    },
    {
      price: 30,
      city: "Warsaw",
      alt: "warsaw",
      src: dest_warsaw,
    },
    {
      price: 30,
      city: "Riga",
      alt: "riga",
      src: dest_riga,
    },
    {
      price: 45,
      city: "Berlin",
      alt: "berlin",
      src: dest_berlin,
    },
    {
      price: 35,
      city: "Seville",
      alt: "seville",
      src: dest_seville,
    },
    {
      price: 45,
      city: "Tokyo",
      alt: "tokyo",
      src: dest_tokyo,
    }
  ],
};
