import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Outlet,
  useNavigate,
  useNavigationType,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BOOKING_STEP } from "src/constants";
import { CATALOGUE_IDS } from "src/constants/services";
import { selectTravelPackage } from "src/store/booking";
import { selectCatalogue, selectCatalogueItem } from "src/store/catalogue";
import { getDateDifferenceInDays } from "src/utils/date-utils";

const BookingFlowContext = createContext({
  searchQuery: "",
  setSearchQuery: () => {},
  goToNextBookingPage: (pageId, state = {}) => {},
  goToPrevBookingPage: (pageId, state = {}) => {},
  hasNext: (pageId) => {},
  hasPrevious: (pageId) => {},
  getAllEnabledPages: () => {},
  isPageEnabled: (pageId) => {},
  getFirstPageId: () => {},
  bookingStartPage: "",
  isFirstLoad: true,
  markAsLoaded: () => {},
  getPageId: (path) => {},
});

function getFeatureFlags(isInsuranceEligible) {
  //TODO: make an API call here
  const responseData = [
    { order: 1, page: { id: "fareTypeSelection", enabled: false } },
    { order: 2, page: { id: "passengerDetailsForm", enabled: true } },
    { order: 3, page: { id: "guaranteeSelection", enabled: true } },
    { order: 4, page: { id: "insuranceSelection", enabled: true } },
    { order: 5, page: { id: "extraServicesSelection", enabled: true } },
    { order: 6, page: { id: "seatSelection", enabled: true } },
    { order: 7, page: { id: "supportSelection", enabled: true } },
  ];

  const sortedResponse = responseData.slice().sort((a, b) => a.order - b.order);
  const mappedData = sortedResponse.map((item) => {
    return { pageId: item.page.id, enabled: item.page.enabled };
  });
  mappedData.push({ pageId: "checkoutPage", enabled: true }); // checkout is a mandatory last page

  const insuranceIdx = mappedData.findIndex(
    (item) => item.pageId === BOOKING_STEP.insuranceSelection.id
  );
  if (insuranceIdx !== -1 && mappedData[insuranceIdx].enabled === true) {
    mappedData[insuranceIdx].enabled = isInsuranceEligible;
  }

  return mappedData;
}

export const isInsuranceEligible = (travelPackage, policyData) => {
  const isOneway = travelPackage.segments_direction.length < 2;
  const travelDays =
    getDateDifferenceInDays(
      travelPackage.segments[0].dep.date,
      travelPackage.segments[travelPackage.segments.length - 1].arr.date,
      "DD.MM.YYYY"
    ) + 1;
  if (
    (isOneway &&
      travelDays >= policyData.ow_min_days &&
      travelDays <= policyData.ow_max_days) ||
    (!isOneway && travelDays >= policyData.rt_min_days && travelDays <= policyData.rt_max_days)
  ) {
    return true;
  }
  return false;
};

export const BookingFlowController = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [availableFeatures, setEnabledFeatures] = useState([]);
  const [bookingStartPage, setBookingStartPage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const { bookingStep } = useParams();

  const travelPackage = useSelector(selectTravelPackage);
  const insuranceBasicData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC)
  );

  const getCurrentPageOrder = (pageId) => {
    return availableFeatures.findIndex((item) => item.pageId === pageId);
  };

  const getPageId = useCallback((path) => {
    const pageObj = Object.values(BOOKING_STEP).find((item) => item.path === path);
    return pageObj.id;
  }, []);

  const getFirstEnabledPageOrder = () => {
    return availableFeatures.findIndex((item) => item.enabled === true);
  };

  const getLastEnabledPageOrder = () => {
    return availableFeatures.lastIndexOf((item) => item.enabled === true);
  };

  const getNextEnabledPageId = (pageId) => {
    const pageIndex = getCurrentPageOrder(pageId);
    let nextPageItem;
    if (pageIndex !== -1 && pageIndex !== getLastEnabledPageOrder()) {
      nextPageItem = availableFeatures.find(
        (item, i) => i > pageIndex && item.enabled === true
      );
    }
    return nextPageItem ? nextPageItem.pageId : null;
  };

  const getPrevEnabledPageId = (pageId) => {
    const pageIndex = getCurrentPageOrder(pageId);
    let prevPageItem;
    if (pageIndex !== -1 && pageIndex !== getFirstEnabledPageOrder()) {
      prevPageItem = availableFeatures
        .slice(0, pageIndex)
        .reverse()
        .find((item) => item.enabled === true);
    }
    return prevPageItem ? prevPageItem.pageId : null;
  };

  const goToNextPage = (pageId, state = {}) => {
    const nextPageId = getNextEnabledPageId(pageId);
    if (nextPageId) {
      const nextPagePath = BOOKING_STEP[nextPageId].path;
      const q = searchParams.toString();
      navigate(`${nextPagePath}${q ? "?" + q : ""}`, {
        state: { ...state, source: "booking-flow" },
        replace: pageId !== getFirstPageId(),
      });
    }
  };

  const goToPrevPage = (pageId, state = {}) => {
    const prevPageId = getPrevEnabledPageId(pageId);
    if (prevPageId) {
      const prevPagePath = BOOKING_STEP[prevPageId].path;
      const q = searchParams.toString();
      navigate(`${prevPagePath}${q ? "?" + q : ""}`, {
        state: { ...state, source: "booking-flow" },
        replace: true,
      });
    } else {
      let prevPagePath = `/${BOOKING_STEP.searchResults.parentPath}/${BOOKING_STEP.searchResults.path}`;
      prevPagePath += `?${searchQuery}`;
      navigate(prevPagePath, {
        state: { ...state, source: "booking-flow" },
      });
    }
  };

  const hasNextPage = (pageId) => {
    const nextPageId = getNextEnabledPageId(pageId);
    return !!nextPageId;
  };

  const hasPrevPage = (pageId) => {
    const prevPageId = getPrevEnabledPageId(pageId);
    return !!prevPageId;
  };

  const getAllEnabledPages = useCallback(() => {
    const allPagesIds = availableFeatures
      .filter((page) => page.enabled)
      .map((page) => page.pageId);

    return allPagesIds;
  }, [availableFeatures]);

  const isPageEnabled = (pageId) => {
    const pageIndex = availableFeatures.findIndex(
      (item) => item.enabled === true && item.pageId === pageId
    );
    return pageIndex > -1;
  };

  const getFirstPageId = () => {
    return availableFeatures[getFirstEnabledPageOrder()].pageId;
  };

  const markAsLoaded = useCallback(() => {
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    console.log("zzz: booking flow controller");
    const isTravelInsuranceEligible = isInsuranceEligible(
      travelPackage,
      insuranceBasicData.data.policy
    );
    const featureFlags = getFeatureFlags(isTravelInsuranceEligible);
    setEnabledFeatures(featureFlags);

    const firstPageId = featureFlags.find((item) => item.enabled === true)?.pageId;
    const bookingStartPage =
      firstPageId !== undefined &&
      `/${BOOKING_STEP[firstPageId]?.parentPath}/${BOOKING_STEP[firstPageId]?.path}`;
    setBookingStartPage(bookingStartPage);

    const bookingNum = searchParams.get("booking");
    if (!bookingStep) {
      navigate(bookingStartPage + (bookingNum ? "?booking=" + bookingNum : ""), {
        replace: true,
      });
    } else if (`/booking/${bookingStep}` !== bookingStartPage) {
      if (navigationType === "POP" || isFirstLoad) {
        console.warn("zzz: booking flow controller - navigate");
        navigate(bookingStartPage + (bookingNum ? "?booking=" + bookingNum : ""), {
          replace: true,
        });
      }
    }
  }, []);

  return (
    <BookingFlowContext.Provider
      value={{
        setSearchQuery,
        searchQuery,
        goToNextBookingPage: goToNextPage,
        goToPrevBookingPage: goToPrevPage,
        hasNext: hasNextPage,
        hasPrevious: hasPrevPage,
        getAllEnabledPages,
        isPageEnabled,
        getFirstPageId,
        isFirstLoad,
        markAsLoaded,
        bookingStartPage,
        getPageId,
      }}>
      <Outlet />
    </BookingFlowContext.Provider>
  );
};

export const useBookingFlow = () => useContext(BookingFlowContext);
