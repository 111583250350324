import Spinner from "src/components/UI/Spinner/Spinner";
import classes from "./ResultsSummary.module.css";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { Markup } from "interweave";

export function ResultsSummary({ isLoading, filteredResults, totalResults }) {
  const { stringRes } = useLocaleContext();
  const isReceivingData = useSelector((state) => state.filterSort.isReceivingData);

  const hasFinishedUpdatingWithResults =
    filteredResults > 0 && totalResults > 0 && !isLoading && !isReceivingData;

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.loadingText}>Finding the cheapest flights ...</div>
      ) : isReceivingData ? (
        <Fragment>
          <Spinner />
          <span>
            {stringRes["res.panel.filter.foundSoFar"].replace(
              "##RESULT_NUM##",
              totalResults
            )}
          </span>
        </Fragment>
      ) : null}
      {hasFinishedUpdatingWithResults && (
        <div className={classes.tripsSummary}>
          <Markup
            content={stringRes["res.panel.filter.showingTrips"].replace(
              "##FILTERED_RESULT_NUM##",
              filteredResults
            )}
          />
        </div>
      )}
    </div>
  );
}
