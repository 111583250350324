function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1732_5514)">
        <path
          opacity="0.964"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4316 -0.0136719C10.6686 -0.0136719 10.9055 -0.0136719 11.1425 -0.0136719C12.2239 0.224645 12.8665 0.890009 13.0703 1.98242C13.1507 3.10264 12.6904 3.90928 11.6894 4.40234C10.6993 4.76202 9.82886 4.57516 9.07808 3.8418C7.82623 4.61114 6.58209 5.39044 5.34566 6.17969C5.52796 6.71746 5.52796 7.2552 5.34566 7.79297C6.58209 8.58222 7.82623 9.36152 9.07808 10.1309C9.82787 9.39777 10.6983 9.21093 11.6894 9.57031C12.6904 10.0634 13.1507 10.87 13.0703 11.9902C12.8665 13.0826 12.2239 13.748 11.1425 13.9863C10.9055 13.9863 10.6686 13.9863 10.4316 13.9863C9.05965 13.6389 8.41707 12.7548 8.50386 11.334C8.53011 11.1788 8.57113 11.0284 8.62691 10.8828C7.38919 10.1022 6.14505 9.33198 4.89449 8.57227C4.03906 9.34902 3.0866 9.4903 2.03706 8.99609C1.0796 8.36681 0.719577 7.48727 0.956987 6.35742C1.38172 5.17177 2.22482 4.62036 3.48628 4.70312C4.029 4.79076 4.49841 5.02318 4.89449 5.40039C6.14505 4.64067 7.38919 3.87048 8.62691 3.08984C8.28913 2.01614 8.55344 1.12291 9.41988 0.410156C9.7279 0.192142 10.0651 0.0508659 10.4316 -0.0136719ZM10.623 0.833984C11.5831 0.846139 12.1209 1.32921 12.2363 2.2832C12.1798 2.97514 11.8243 3.43544 11.1699 3.66406C10.4033 3.81169 9.83361 3.54279 9.46089 2.85742C9.1991 2.10098 9.39508 1.49486 10.0488 1.03906C10.2322 0.93774 10.4236 0.869381 10.623 0.833984ZM3.04878 5.53711C3.99028 5.57011 4.51895 6.05317 4.63472 6.98633C4.5435 7.86138 4.06045 8.34447 3.1855 8.43555C2.30999 8.34389 1.82692 7.86081 1.73628 6.98633C1.81233 6.17214 2.24983 5.68909 3.04878 5.53711ZM10.7597 10.2402C11.4626 10.2885 11.932 10.644 12.1679 11.3066C12.3156 12.0732 12.0467 12.6429 11.3613 13.0156C10.6956 13.2582 10.1259 13.1169 9.6523 12.5918C9.22596 11.9747 9.23509 11.364 9.67964 10.7598C9.9764 10.4496 10.3364 10.2764 10.7597 10.2402Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_1732_5514">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShareIcon;
