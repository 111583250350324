import { Fragment } from "react";
import classes from "./MobilePriceSummary.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { useSelector } from "react-redux";
import { getCartOverview } from "src/store/cartOverview";

export function MobilePriceSummary() {
  const { stringRes, currencySymbol } = useLocaleContext();
  const orderBreakdown = useSelector(getCartOverview);
  return (
    <Fragment>
      <h1>{stringRes["booking.mob.bottom.paySecurely"]}</h1>
      <p>{stringRes["booking.mob.bottom.feesIncluded"]}</p>
      <div className={classes.total}>
        <span
          className={
            classes.name
          }>{`${stringRes["booking.order.breakdown.total"]} (${currencySymbol})`}</span>
        <span className={classes.price}>{`${orderBreakdown.total} ${currencySymbol}`}</span>
      </div>
    </Fragment>
  );
}
