import { useDeviceContext } from "src/context/device-context";
import classes from "./FlightItinerary.module.css";
import {
  WARN_TYPE,
  getTotalDuration,
} from "src/components/features/results/ResultsList/elements/FlightChainInfo/FlightChainInfo";
import { getMainCarrierAirlineIcon } from "src/utils/results-utils";
import { useLocaleContext } from "src/context/locale-context";
import { FlightConnectionLine } from "../../results/ResultsList/elements/ConnectionLine/FlightConnectionLine";
import { FlightConnectionLineMobile } from "../../results/ResultsList/elements/ConnectionLine/FlightConnetctionLineMobile";
import { getDateDifferenceInDays } from "src/utils/date-utils";
import { Fragment } from "react";

function FlightItinerary({ flightChain, diffLocation, isDeparture, isOneway }) {
  const { isMobile } = useDeviceContext();
  const { stringRes, currentLocale } = useLocaleContext();

  const lastFlight = flightChain[flightChain.length - 1];
  const stopNum = flightChain.length - 1;
  const warn = diffLocation
    ? isDeparture
      ? WARN_TYPE.arrival
      : WARN_TYPE.departure
    : WARN_TYPE.none;

  const firstAirlineCode = flightChain[0].supplier.code;
  const isSameAirline = flightChain.every((f) => f.supplier.code === firstAirlineCode);
  const nextAirlineIdx = isSameAirline
    ? 0
    : flightChain.findIndex((f) => f.supplier.code !== firstAirlineCode);

  const daysOffset = getDateDifferenceInDays(
    flightChain[0].dep.date,
    lastFlight.arr.date,
    "DD.MM.YYYY"
  );

  return (
    <div
      className={`${isMobile ? classes.mobile : classes.desktop} ${
        isOneway ? classes.onetrip : ""
      }`}>
      <div className={classes.overlayed}>
        {isSameAirline ? (
          <img
            alt=""
            onDragStart={(e) => e.preventDefault()}
            className={classes.airlineIcon}
            src={getMainCarrierAirlineIcon(flightChain[0])}
          />
        ) : (
          <Fragment>
            <div>
              <img
                className={classes.airlineIcon}
                alt=""
                onDragStart={(e) => e.preventDefault()}
                src={getMainCarrierAirlineIcon(flightChain[nextAirlineIdx])}
              />
            </div>
            <div>
              <img
                alt=""
                onDragStart={(e) => e.preventDefault()}
                className={classes.airlineIcon}
                src={getMainCarrierAirlineIcon(flightChain[0])}
              />
            </div>
          </Fragment>
        )}
      </div>

      <div className={classes.carrierData}>
        <div className={classes.carrierDataItem}>
          <span>{flightChain[0].dep.time}</span>
          <span className={warn === WARN_TYPE.departure ? classes.warn : ""}>
            {flightChain[0].dep.airport.code}{" "}
          </span>
        </div>

        {!isMobile ? (
          <div className={classes.carrierDataItem}>
            <FlightConnectionLine
              className={classes.connections}
              stopNum={stopNum}
              width={40}
            />
            <span></span>
          </div>
        ) : (
          <div className={classes.carrierDataItem}>
            <div className={classes.timeInfo}>
              <div>{getTotalDuration(flightChain, currentLocale)}</div>
              <FlightConnectionLineMobile
                className={classes.connections}
                hasStops={flightChain.length > 1}
              />
              <div className={stopNum > 0 ? "" : classes.direct}>
                {stopNum > 0 ? (
                  <>
                    {stopNum}{" "}
                    {stopNum > 1
                      ? stringRes["res.result.stop.many"]
                      : stringRes["res.result.stop.one"]}
                    {stopNum === 1 &&
                      ` ${flightChain
                        .slice(1)
                        .map((f) => f.dep.airport.code)
                        .join("•")}`}
                  </>
                ) : (
                  stringRes["res.result.stop.none"]
                )}
              </div>
            </div>
          </div>
        )}

        <div className={classes.carrierDataItem}>
          <span>
            <span className={daysOffset > 0 ? classes.hasDateOffset : ""}>
              {lastFlight.arr.time}
            </span>
            <sup className={classes.daysOffset}>{daysOffset > 0 ? `+${daysOffset}` : ""}</sup>
          </span>
          <span className={warn === WARN_TYPE.arrival ? classes.warn : ""}>
            {lastFlight.arr.airport.code}{" "}
          </span>
        </div>
      </div>

      {!isMobile && (
        <div className={classes.timeInfo}>
          <div>{getTotalDuration(flightChain, currentLocale)}</div>
          <div className={stopNum > 0 ? "" : classes.direct}>
            {stopNum > 0 ? (
              <>
                {stopNum}{" "}
                {stopNum > 1
                  ? stringRes["res.result.stop.many"]
                  : stringRes["res.result.stop.one"]}
                {stopNum === 1 &&
                  ` ${flightChain
                    .slice(1)
                    .map((f) => f.dep.airport.code)
                    .join(", ")}`}
              </>
            ) : (
              stringRes["res.result.stop.none"]
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FlightItinerary;
