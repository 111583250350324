import classes from "../BaggageSelection.module.css";


export function PopularTag({ text }) {
  return (
    <div className={classes.popular}>
      <div className={classes.tagText}>{text}</div>
    </div>
  );
}
