import { Fragment } from "react";
import airhelpLg from "src/assets/img/booking/airhelp_extras_large.png";
import checkinLg from "src/assets/img/booking/checkin_extras_large.png";
import smsLg from "src/assets/img/booking/sms_extras_large.png";
import Button from "src/components/UI/Button/Button";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { XIconDesktop } from "src/components/UI/Icon/XIcon";
import DesktopModal from "src/components/shared/modal/Modal/DesktopModal";
import Modal from "src/components/shared/modal/Modal/Modal";
import { CATALOGUE_IDS } from "src/constants/services";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { ModalElementContext } from "src/context/modal-element-context";
import classes from "./ServiceDecription.module.css";

export const EXTRAS_IMGS = {
  [CATALOGUE_IDS["ONLINE_CHECK-IN"]]: checkinLg,
  [CATALOGUE_IDS.SMS_MOBILE_ROUTE]: smsLg,
  [CATALOGUE_IDS.AIRHELP_PLUS]: airhelpLg,
};

function ServiceDescription({ onClose, modalId, state, template }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const innerContent = (
    <Fragment>
      <div
        style={{ marginBottom: "2rem", height: "100%", overflow: "auto", flexGrow: 1 }}
        dangerouslySetInnerHTML={{ __html: template }}></div>
      <Button
        secondary
        name={stringRes["booking.service.details.button.close"]}
        onClick={onClose}
      />
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <CloseWindowButton onClick={onClose} />
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal
        classBody={classes.body}
        modalClass={classes.modal}
        modalId={modalId}
        isClosing={state.isClosing}
        onBackdropClick={onClose}>
        <button type="button" className={classes.close} onClick={onClose}>
          <XIconDesktop color="var(--ui-5)" />
        </button>
        <div className={`${classes.desktop} ${classes.container}`}>{innerContent}</div>
      </DesktopModal>
    );
  }
}

export default ServiceDescription;
