import { Fragment, useContext } from "react";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { ModalElementContext } from "src/context/modal-element-context";
import classes from "./ResultsSortingMobile.module.css";
import Button from "src/components/UI/Button/Button";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { SORT } from "src/constants";
import { SORT_TYPE_MAP } from "src/constants/string-res-map";
import { filterSortActions } from "src/store/filter-sort";
import { useLocaleContext } from "src/context/locale-context";
import { useSearchParams } from "react-router-dom";
import { customLog, toTitleCase } from "src/utils/utils";

function MobileSortType({ sortType, cityCode }) {
  const dispatch = useDispatch();
  const { stringRes, currencySymbol } = useLocaleContext();
  const sortValues = useSelector((state) => state.filterSort.sort);
  const currentSort = sortValues.type;
  const vals = sortValues[sortType];

  const onChangeSortType = (isChecked) => {
    if (isChecked) {
      dispatch(filterSortActions.setSortType(sortType));
      // dispatch(filterSortActions.setIsSorting(true));
    }
  };

  return (
    <RadioButton
      checked={currentSort === sortType}
      id={sortType}
      name="sort-type"
      className={classes.sortType}
      onChange={onChangeSortType}
    >
      <div className={classes.text}>
        <span className={classes.label}>
          {`${stringRes[SORT_TYPE_MAP[sortType]]}` + (cityCode ? ` (${cityCode})` : "")}
        </span>
        <span className={classes.info}>{`${vals.price}${currencySymbol} • ${Math.trunc(
          vals.duration / 60
        )}h ${vals.duration % 60}m`}</span>
      </div>
    </RadioButton>
  );
}

function ResultsSortingMobile() {
  const { stringRes } = useLocaleContext();
  const { onClose } = useContext(ModalElementContext);
  const searchParams = useSearchParams();

  const sortDepCityCode = searchParams[0].get("s_iata")
    ? searchParams[0].get("s_iata").toUpperCase()
    : "N/A";
  const sortArrCityCode = searchParams[0].get("d_iata")
    ? searchParams[0].get("d_iata").toUpperCase()
    : "N/A";

  return (
    <Fragment>
      <CloseWindowButton onClick={onClose} />
      <div className={`${classes.sort} ${classes.container}`}>
        <h1>{stringRes["res.sort.title"]}</h1>
        <div className={classes.sortTypes}>
          <MobileSortType sortType={SORT.best} />
          <MobileSortType sortType={SORT.cheapest} />
          <MobileSortType sortType={SORT.fastest} />
          <MobileSortType cityCode={sortDepCityCode} sortType={SORT.earlyDeparture} />
          <MobileSortType cityCode={sortArrCityCode} sortType={SORT.earlyArrival} />
        </div>
        <Button
          name={stringRes["res.button.close"]}
          onClick={onClose}
          secondary
          cornerSm
          fontclass={classes.fontclass}
        />
      </div>
    </Fragment>
  );
}

export default ResultsSortingMobile;
