import { InfoIcon } from "src/components/UI/Icon/InfoIcon";
import { useDeviceContext } from "src/context/device-context";
import classes from "./FormInfoElement.module.css";
import DangerIcon from "../Icon/DangerIcon";

export function FormInfoElement({ children, type = "info" }) {
  const { isMobile } = useDeviceContext();
  return (
    <div
      className={`${classes.information} ${isMobile ? classes.mobile : classes.desktop} ${
        type === "warning" && classes.warning
      }`}>
      {type === "info" && <InfoIcon size={16} />}
      {type === "warning" && <DangerIcon size={20} />}
      <span>{children}</span>
    </div>
  );
}
