import classes from "./TimeFilter.module.css";
import { useDispatch, useSelector } from "react-redux";
import { filterSortActions, selectTimesFilters } from "src/store/filter-sort";
import Button from "src/components/UI/Button/Button";
import { Fragment, useContext, useEffect, useState } from "react";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { ModalElementContext } from "src/context/modal-element-context";
import Slider from "src/components/UI/Slider/Slider";
import { getTimeFromMinutes } from "src/utils/date-utils";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { useLocation, useSearchParams } from "react-router-dom";
import { TRIP_TYPE } from "src/constants";

const TAB = { departure: 1, return: 2 };

function TimeFilterContent(props) {
  const { stringRes } = useLocaleContext();
  const searchParams = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(TAB.departure);
  const isOnewayTrip = searchParams[0].get("type") === TRIP_TYPE.oneway;

  return (
    <Fragment>
      <h1>{stringRes["res.filter.title.times"]}</h1>

      <div
        className={`${classes.tabTitle} ${
          classes[selectedTab === TAB.departure ? "retInactive" : "depInactive"]
        }`}
      >
        <h2 onClick={() => setSelectedTab(TAB.departure)}>
          {stringRes["res.filter.title.times.departureTrip"]}
        </h2>
        <h2 onClick={() => setSelectedTab(TAB.return)}>
          {stringRes["res.filter.title.times.returnTrip"]}
        </h2>
      </div>

      <div className={classes.line}>
        <span
          className={classes[selectedTab === TAB.departure ? "active" : "inactive"]}
        ></span>
        <span
          className={classes[selectedTab === TAB.return ? "active" : "inactive"]}
        ></span>
      </div>

      {selectedTab === TAB.departure && (
        <div className={classes.sliderGroup}>
          <TravelTimeSlider
            isStartTime
            isSliderReleased={props.isSliderReleased}
            range={props.depStart}
            setRange={props.setDepStart}
          />
          <TravelTimeSlider
            isSliderReleased={props.isSliderReleased}
            range={props.depEnd}
            setRange={props.setDepEnd}
          />
        </div>
      )}

      {selectedTab === TAB.return && (
        <div className={classes.sliderGroup}>
          <TravelTimeSlider
            isStartTime
            isSliderReleased={props.isSliderReleased}
            range={props.retStart}
            setRange={props.setRetStart}
            disabled={isOnewayTrip}
          />
          <TravelTimeSlider
            isSliderReleased={props.isSliderReleased}
            range={props.retEnd}
            setRange={props.setRetEnd}
            disabled={isOnewayTrip}
          />
        </div>
      )}
    </Fragment>
  );
}

function TravelTimeSlider({ isStartTime, range, setRange, isSliderReleased, disabled }) {
  const { stringRes } = useLocaleContext();
  const onTimeRangeChange = (values) => {
    if (values[0] === 0 && values[1] >= 1439) {
      setRange("");
    } else if (values[1] >= 1439) {
      setRange([values[0], 1439]);
    } else {
      setRange(values);
    }
  };

  return (
    <div className={classes.slider}>
      <h2>
        {stringRes["res.filter.title.times." + (isStartTime ? "departure" : "arrival")]}
      </h2>
      <p className={`${classes.range} ${disabled ? classes.disabled : ""}`}>
        {range
          ? `${stringRes["res.filter.times.from"]} 
            ${getTimeFromMinutes(range[0])} ${stringRes["res.filter.times.to"]} 
            ${getTimeFromMinutes(range[1])}`
          : stringRes["res.filter.times.allday"]}
      </p>
      <Slider
        current={range ? range : [0, 1439]}
        min={0}
        max={1439}
        step={15}
        disabled={disabled}
        onAfterChange={() => isSliderReleased(true)}
        onBeforeChange={() => isSliderReleased(false)}
        onChange={onTimeRangeChange}
        minLabel={"00:00"}
        maxLabel={"23:59"}
        isRange
      />
    </div>
  );
}

export function TimeFilter() {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const { onClose } = useContext(ModalElementContext);

  const timesFilters = useSelector(selectTimesFilters);

  const [depStart, setDepStart] = useState(timesFilters.depStart.value);
  const [depEnd, setDepEnd] = useState(timesFilters.depEnd.value);
  const [retStart, setRetStart] = useState(timesFilters.retStart.value);
  const [retEnd, setRetEnd] = useState(timesFilters.retEnd.value);

  const [isSliderReleased, setSliderReleased] = useState(false);

  useEffect(() => {
    if (!timesFilters.depStart.isOn) {
      setDepStart(timesFilters.depStart.value);
    }
    if (!timesFilters.depEnd.isOn) {
      setDepEnd(timesFilters.depEnd.value);
    }
    if (!timesFilters.retStart.isOn) {
      setRetStart(timesFilters.retStart.value);
    }
    if (!timesFilters.retEnd.isOn) {
      setRetEnd(timesFilters.retEnd.value);
    }
  }, [
    timesFilters.depStart.isOn,
    timesFilters.depEnd.isOn,
    timesFilters.retStart.isOn,
    timesFilters.retEnd.isOn,
  ]);

  const batchDispatch = () => {
    dispatch(
      filterSortActions.applyFilter({ type: "departureStartTime", value: depStart })
    );
    dispatch(filterSortActions.applyFilter({ type: "departureEndTime", value: depEnd }));
    dispatch(filterSortActions.applyFilter({ type: "returnStartTime", value: retStart }));
    dispatch(filterSortActions.applyFilter({ type: "returnEndTime", value: retEnd }));
  };

  const onConfirmFilter = () => {
    batchDispatch();
    onClose();
  };

  useEffect(() => {
    if (!isMobile && isSliderReleased) {
      batchDispatch();
    }
  }, [isSliderReleased]);

  const props = {
    isSliderReleased: setSliderReleased,
    depStart: depStart,
    setDepStart: setDepStart,
    depEnd: depEnd,
    setDepEnd: setDepEnd,
    retStart: retStart,
    setRetStart: setRetStart,
    retEnd: retEnd,
    setRetEnd: setRetEnd,
  };

  return (
    <Fragment>
      {isMobile && <CloseWindowButton onClick={onClose} />}
      <div
        className={`${classes.container} ${classes.times} ${
          isMobile ? classes.mobile : classes.desktop
        }`}
      >
        <TimeFilterContent {...props} />
        {isMobile && (
          <Fragment>
            <Button
              name={stringRes["res.filter.button.cancel"]}
              onClick={onClose}
              secondary
              cornerSm
              fontclass={classes.fontclass}
            />
            <Button
              name={stringRes["res.filter.button.done"]}
              onClick={onConfirmFilter}
              primary
              cornerSm
              fontclass={classes.fontclass}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
