import classes from "./FlightInfo.module.css";
import ReactDOMServer from "react-dom/server";

export function ThumbstackButton({ anchorId, onClick, isActive, tooltipText }) {
  return (
    <button
      data-tooltip-id={anchorId}
      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
        <span className={classes.tooltipText}>{tooltipText}</span>
      )}
      type="button"
      className={classes.thumbstack}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        {isActive ? (
          <g clipPath="url(#clip0_1538_6301)">
            <path
              d="M3.76748 8.94998L0.853484 11.8535L0.146484 11.1465L3.06048 8.24197L3.76748 8.94998ZM6.91748 10.3325C7.3756 9.87247 7.70605 9.3011 7.87631 8.67461C8.04656 8.04811 8.05076 7.38808 7.88848 6.75948L7.73248 6.12148L9.54248 4.30148L9.82748 4.58698C10.0379 4.80455 10.3182 4.94112 10.6193 4.97275C10.9203 5.00438 11.2229 4.92906 11.474 4.75998C11.6221 4.6546 11.7454 4.5182 11.8354 4.36029C11.9253 4.20237 11.9798 4.02673 11.9949 3.84562C12.01 3.6645 11.9855 3.48226 11.923 3.3116C11.8605 3.14094 11.7615 2.98597 11.633 2.85748L9.17198 0.393975C8.96138 0.176663 8.6811 0.0402771 8.38013 0.00865431C8.07917 -0.0229685 7.77666 0.0521835 7.52548 0.220975C7.37728 0.326305 7.25385 0.462697 7.16378 0.620639C7.07371 0.77858 7.01918 0.954269 7.004 1.13545C6.98881 1.31663 7.01333 1.49895 7.07585 1.66968C7.13837 1.84041 7.23738 1.99545 7.36598 2.12398L7.68948 2.44698L5.87898 4.26698L5.24498 4.11147C4.61583 3.94845 3.95505 3.95221 3.32779 4.1224C2.70053 4.29258 2.12846 4.62331 1.66798 5.08197L1.49148 5.25898L6.74148 10.509L6.91748 10.3325Z"
              fill="var(--primary-1)"
            />
          </g>
        ) : (
          <g clipPath="url(#clip0_1538_6289)">
            <path
              d="M4.53398 8.17347L6.98399 10.6235L7.33749 10.27C7.82615 9.77963 8.17877 9.17054 8.36068 8.50261C8.54259 7.83467 8.54752 7.13089 8.37499 6.46047L8.25199 5.96047L9.29499 4.91747L9.40349 5.02597C9.65613 5.28492 9.99176 5.44683 10.3517 5.48339C10.7116 5.51995 11.0729 5.42883 11.3725 5.22597C11.55 5.09946 11.6979 4.93575 11.8057 4.74627C11.9136 4.55678 11.9788 4.34606 11.9969 4.12879C12.015 3.91152 11.9855 3.69292 11.9104 3.48821C11.8354 3.2835 11.7167 3.09761 11.5625 2.94347L9.09999 0.476974C8.84734 0.218024 8.51172 0.0561159 8.15179 0.0195566C7.79186 -0.0170028 7.43054 0.0741131 7.13099 0.276974C6.95343 0.40349 6.80558 0.567193 6.69774 0.75668C6.58991 0.946167 6.52468 1.15688 6.50659 1.37416C6.4885 1.59143 6.518 1.81003 6.59302 2.01474C6.66804 2.21945 6.78679 2.40534 6.94099 2.55947L7.08749 2.70597L6.04248 3.74997L5.54998 3.62897C4.87843 3.45508 4.17315 3.45917 3.50365 3.64084C2.83415 3.8225 2.22354 4.17548 1.73198 4.66497L1.37848 5.01847L3.82848 7.46597L0.146484 11.1465L0.853484 11.8535L4.53398 8.17347ZM5.30348 4.59997L6.35349 4.85697L8.50349 2.70697L7.64649 1.85347C7.59471 1.80182 7.5549 1.73945 7.52986 1.67074C7.50481 1.60204 7.49515 1.52867 7.50153 1.45582C7.50792 1.38298 7.53021 1.31241 7.56683 1.24912C7.60346 1.18582 7.65352 1.13132 7.71349 1.08947C7.82017 1.02489 7.94587 0.999068 8.06938 1.01637C8.19289 1.03368 8.30666 1.09305 8.39149 1.18447L10.8535 3.64647C10.9053 3.69812 10.9451 3.7605 10.9701 3.8292C10.9952 3.89791 11.0048 3.97127 10.9984 4.04412C10.992 4.11697 10.9698 4.18753 10.9331 4.25083C10.8965 4.31413 10.8465 4.36862 10.7865 4.41047C10.6798 4.47506 10.5541 4.50088 10.4306 4.48357C10.3071 4.46627 10.1933 4.4069 10.1085 4.31547L9.29299 3.49997L7.14299 5.64997L7.40199 6.70547C7.51108 7.12563 7.52734 7.56451 7.44963 7.99159C7.37192 8.41866 7.2021 8.82368 6.95199 9.17847L2.82298 5.04997C3.17882 4.79905 3.58513 4.62879 4.01355 4.55107C4.44196 4.47335 4.88218 4.49004 5.30348 4.59997Z"
              fill="var(--primary-2)"
            />
          </g>
        )}
      </svg>
    </button>
  );
}
