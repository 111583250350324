import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classes from "./ResultsCheckboxGroupFilter.module.css";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { filterSortActions } from "src/store/filter-sort";
import { ModalElementContext } from "src/context/modal-element-context";
import Button from "src/components/UI/Button/Button";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";

function CheckboxGroupFilterItem({
  id,
  label,
  checked,
  onSelect,
  onReset,
  onSelectSingle,
  name,
}) {
  const { stringRes } = useLocaleContext();

  return (
    <li>
      <Checkbox
        id={id}
        isChecked={checked}
        label={label}
        onChange={onSelect}
        name={name}
        className={classes.checkbox}
        isSameAsMobile
        checkMarkColor="#fff"
      />
      <span
        className={onReset ? classes.isOnly : ""}
        onClick={onReset ? onReset : onSelectSingle}>
        {stringRes["res.filter.airlines.only"]}
      </span>
    </li>
  );
}

function ResultsCheckboxGroupFilterContent({
  checkedItems,
  groupName,
  title,
  availableItems,
  onSelect,
  onSelectSingle,
  onReset,
}) {
  return (
    <Fragment>
      <h1>{title}</h1>
      <ul className={classes.options}>
        {availableItems
          .slice()
          .sort()
          .map((a) => (
            <CheckboxGroupFilterItem
              key={a}
              id={a}
              label={a}
              checked={checkedItems.includes(a)}
              onSelect={onSelect}
              onReset={
                checkedItems.length === 1 && checkedItems[0] === a ? onReset : null
              }
              onSelectSingle={() => onSelectSingle(a)}
              name={groupName}
            />
          ))}
      </ul>
    </Fragment>
  );
}

export function ResultsCheckboxGroupFilter({
  filterType,
  availableItems,
  title,
  filterData,
}) {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();
  const { onClose } = useContext(ModalElementContext);
  const { isMobile } = useDeviceContext();

  const [state, setState] = useState(
    filterData.value === "any" ? availableItems : filterData.value
  );

  const onConfirmValue = () => {
    const value = state.length === availableItems.length ? "any" : state;
    dispatch(filterSortActions.applyFilter({ type: filterType, value: value }));
    onClose();
  };

  useEffect(() => {
    const newState = state.filter((item) => availableItems.includes(item));
    if (!filterData.isOn || newState.length === 0) {
      setState(availableItems);
    } else {
      setState(newState);
    }
  }, [availableItems]);

  useEffect(() => {
    if (!filterData.isOn) {
      setState(availableItems);
    }
  }, [filterData.isOn]);

  useEffect(() => {
    if (!isMobile) {
      const value = state.length === availableItems.length ? "any" : state;
      dispatch(filterSortActions.applyFilter({ type: filterType, value: value }));
    }
  }, [state]);

  const onSelectHandler = (item, isChecked) => {
    if (isChecked && !state.includes(item)) {
      setState((prev) => [...prev, item]);
    } else if (!isChecked && state.includes(item)) {
      setState((prev) => {
        let i = prev.findIndex((a) => a === item);
        return prev.slice(0, i).concat(prev.slice(i + 1));
      });
    }
  };

  const onSelectSingleHanlder = (item) => {
    setState([item]);
  };

  const onResetToDefault = () => {
    setState(availableItems);
    if (!isMobile) {
      dispatch(filterSortActions.clearFilter({ type: filterType }));
    }
  };

  return (
    <Fragment>
      {isMobile && <CloseWindowButton onClick={onClose} />}
      <div
        className={`${classes.container} ${classes.group} ${
          isMobile ? classes.mobile : classes.desktop
        }`}>
        <ResultsCheckboxGroupFilterContent
          checkedItems={state}
          groupName={"group-" + filterType}
          title={title}
          availableItems={availableItems}
          onSelect={onSelectHandler}
          onSelectSingle={onSelectSingleHanlder}
          onReset={onResetToDefault}
        />
        {isMobile && (
          <Fragment>
            <Button
              name={stringRes["res.filter.button.cancel"]}
              onClick={onClose}
              secondary
              cornerSm
              fontclass={classes.fontclass}
            />
            <Button
              name={stringRes["res.filter.button.done"]}
              onClick={onConfirmValue}
              primary
              cornerSm
              fontclass={classes.fontclass}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
