import { nanoid } from "@reduxjs/toolkit";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlightCardTooltip } from "src/components/UI/Tooltip/Tooltip";
import { AIRLINE_CABIN_TYPE } from "src/constants/string-res-map";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { filterSortActions } from "src/store/filter-sort";
import { getLocalizedFlightDuration } from "src/utils/date-utils";
import { getMainCarrierAirlineIcon } from "src/utils/results-utils";
import { CarrierTimeDataDesktop } from "../CarrierTimeData/CarrierTimeDataDesktop";
import { CarrierTimeDataMobile } from "../CarrierTimeData/CarrierTimeDataMobile";
import classes from "./FlightInfo.module.css";

export const getTotalDuration = (flightChain, locale = "en_GB") => {
  const total = flightChain[0].route_duration;
  return getLocalizedFlightDuration(total, locale);
};

export const WARN_TYPE = {
  none: 0,
  departure: 1,
  arrival: 2,
};

function FlightIcon({ flightChain }) {
  const { isMobile } = useDeviceContext();
  const anchorId = nanoid();

  const isSameAirline = flightChain.every(
    (f) => f.supplier.code === flightChain[0].supplier.code
  );
  const nextAirlineIdx = isSameAirline
    ? 0
    : flightChain.findIndex((f) => f.supplier.code !== flightChain[0].supplier.code);

  const firstAirlineTitle = flightChain[0].supplier.title;
  const nextAirlineTitle = flightChain[nextAirlineIdx].supplier.title;

  return (
    <div className={classes.iconFrame}>
      {!isMobile && (
        <FlightCardTooltip
          tooltipClass={classes.tooltip}
          anchorId={"anchor-id" + anchorId}>
          <span className={classes.tooltipText}>
            <span>
              {firstAirlineTitle + (isSameAirline ? "" : ", " + nextAirlineTitle)}
            </span>
          </span>
        </FlightCardTooltip>
      )}

      <div id={"anchor-id" + anchorId} className={classes.overlayed}>
        {isSameAirline ? (
          <img
            onDragStart={(e) => e.preventDefault()}
            alt=""
            className={classes.airlineIcon}
            src={getMainCarrierAirlineIcon(flightChain[0])}
          />
        ) : (
          <Fragment>
            <div>
              <img
                onDragStart={(e) => e.preventDefault()}
                alt=""
                className={classes.airlineIcon}
                src={getMainCarrierAirlineIcon(flightChain[nextAirlineIdx])}
              />
            </div>
            <div>
              <img
                onDragStart={(e) => e.preventDefault()}
                alt=""
                className={classes.airlineIcon}
                src={getMainCarrierAirlineIcon(flightChain[0])}
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export function FlightChainInfo({
  className,
  flightChain,
  diffLocation,
  isDeparture,
  isOneway,
}) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const dispatch = useDispatch();

  const pinnedSegment = useSelector(
    (state) => state.filterSort.pinned[isDeparture ? "departure" : "arrival"]
  );
  const [isPinned, setIsPinned] = useState(false);

  const onPinFlightChain = () => {
    setIsPinned((prev) => {
      const newPinState = !prev;

      let pinnedFlightNums = [];
      if (newPinState) {
        pinnedFlightNums = flightChain.map((f) => f.flight_number); //REVIEW: number + carrier?
      }
      if (isDeparture) {
        dispatch(filterSortActions.updatePinnedDepartureSegment(pinnedFlightNums));
      } else {
        dispatch(filterSortActions.updatePinnedArrivalSegment(pinnedFlightNums));
      }

      return newPinState;
    });
  };

  useEffect(() => {
    const flightNums = flightChain.map((f) => f.flight_number);
    setIsPinned(
      flightNums.every((n) => pinnedSegment.includes(n)) &&
        flightNums.length === pinnedSegment.length
    );
  }, [pinnedSegment]);

  const hasOperatedBy = flightChain[0].supplier.code !== flightChain[0].carrier.code;

  /**
   * Note: if 1st supplier doesn't match the 1st carrier, show 1st supplier
   * as 1st airline w/ its logo, and put the 1st carrier as operated by XYZ;
   * ideally shoule be done for all carriers, but not enough space on the
   * flight card to display ¯\_(ツ)_/¯
   */
  function getAirlineCabinInfoText(flightGroup) {
    let airlineNames, operatedBy;

    operatedBy = hasOperatedBy
      ? `${stringRes["res.result.operatedBy"]} ${flightGroup[0].carrier.title}`
      : "";

    let allAirlines = flightGroup.map((f) => f.supplier.title);
    let uniqueAirlines = [...new Set(allAirlines)];
    airlineNames = uniqueAirlines
      .slice(0, 2) // no more than two items shown
      .join(" - ");

    airlineNames += " • " + stringRes[AIRLINE_CABIN_TYPE[flightGroup[0].class.name]];
    airlineNames += hasOperatedBy ? " • " + operatedBy : "";
    return airlineNames;
  }

  const props = {
    isPinned,
    onPin: onPinFlightChain,
    flightChain,
    isOneway,
    isDeparture,
    warn: diffLocation
      ? isDeparture
        ? WARN_TYPE.arrival
        : WARN_TYPE.departure
      : WARN_TYPE.none,
  };

  return (
    <div
      className={`${className ?? ""} ${isMobile ? classes.mobile : classes.desktop} ${
        isOneway ? classes.onetrip : ""
      }`}>
      <FlightIcon flightChain={flightChain} />
      {isMobile ? (
        <CarrierTimeDataMobile {...props} />
      ) : (
        <CarrierTimeDataDesktop {...props} />
      )}
      {!isMobile && (
        <Fragment>
          <div className={classes.airlineCabinInfo}>
            {getAirlineCabinInfoText(flightChain)}
          </div>
        </Fragment>
      )}
    </div>
  );
}
