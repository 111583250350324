import Button from "src/components/UI/Button/Button";
import classes from "./TopDestinations.module.css";
import { useState } from "react";
import { componentData } from "./TopDestsinationsData";
import { NavLink } from "react-router-dom";
import { customLog } from "src/utils/utils";

function Destination({ destination }) {
  return (
    <NavLink to="/">
      <img alt={destination.alt} src={destination.src} />
      <div className={classes.destination}>
        <h2>{destination.city}</h2>
        <p>flights from {destination.price} €</p>
      </div>
    </NavLink>
  );
}

function DestinationGroup({ totalVisible, destinations }) {
  return (
    <ul className={classes.grid}>
      {destinations.slice(0, totalVisible).map((dest) => (
        <li key={dest.city}>
          <Destination destination={dest} />
        </li>
      ))}
    </ul>
  );
}

const DEFAULT_ITEM_NUM = 6;

function TopDestinations(props) {
  const [currentFilter, setSelectedFilter] = useState(0);
  const [visibleItemNum, setVisibleItemNum] = useState(DEFAULT_ITEM_NUM);
  const hasMoreOffers = componentData.destinations.length > visibleItemNum;

  const onClickFilterHandler = (event, selectedFilter) => {
    customLog("touched");
    setSelectedFilter(selectedFilter);
    // get filtered data
  };

  const onSeeMoreOffersHandler = () => {
    customLog("see more offers");
    setVisibleItemNum((prev) => prev + 2);
    // load more data
  };

  const onCollapseOffersHandler = () => {
    setVisibleItemNum(DEFAULT_ITEM_NUM);
  };

  return (
    <section className={classes.container}>
      <h1>{componentData.title}</h1>
      <p>{componentData.description}</p>
      <div className={`${classes.container} ${classes.filterGroup}`}>
        {componentData.filters.map((item, i) => (
          <Button
            key={item.text}
            name={item.text}
            fontclass={classes.filterButtonFont}
            onClick={(event) => onClickFilterHandler(event, i)}
            {...(i === currentFilter ? { ternary: true } : { secondary: true })}
          />
        ))}
      </div>
      <DestinationGroup
        totalVisible={visibleItemNum}
        destinations={componentData.destinations}
      />
      <Button
        name={hasMoreOffers ? componentData.showMoreBtn : "See less offers"}
        opts={{ paddingLeft: "1.25rem", paddingRight: "1.25rem" }}
        onClick={
          hasMoreOffers ? onSeeMoreOffersHandler : onCollapseOffersHandler
        }
        secondary
      />
    </section>
  );
}

export default TopDestinations;
