import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BAG_WEIGHT_CATEGORY, BOOKING_STEP, PASSENGER } from "src/constants";
import {
  CATALOGUE_IDS,
  CATALOGUE_IDS_STR_MAP,
  ORDER_SUMMARY_GROUP,
} from "src/constants/services";
import { useAuth } from "src/context/auth-context";
import { useBookingFlow } from "src/context/booking-flow-context";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { selectAllPassengers, selectTravelPackage } from "src/store/booking";
import { getCartOverview } from "src/store/cartOverview";
import {
  BAGGAGE,
  getBundledCabinBaggageDimensions,
  getBundledCabinBaggageUpgradeDimensions,
  getBundledCabinBaggageUpgradeWeight,
  getBundledCabinBaggageWeight,
  getBundledPersonalItemDimensions,
  getBundledPersonalItemWeight,
  getPersonalItemDimensions,
  getPersonalItemWeight,
  getRegisteredBaggageDimensions,
  getRegisteredBaggageWeight,
  hasBundledCabinBaggageFastTrack,
  hasBundledCabinBaggageUpgradeFastTrack,
} from "src/utils/baggage-utils";
import { customLog, toTitleCase } from "src/utils/utils";
import { isConnectionProtectionEligible } from "../StepContent/PassengerStepContent";
import classes from "./CheckoutSummary.module.css";

const SEATS_ORDER_ITEMS = [
  { key: "seatTypeWindow", strKey: "booking.checkout.summary.windowSeats" },
  { key: "seatTypeAisle", strKey: "booking.checkout.summary.aisleSeats" },
  { key: "seatTypeExtraLegroom", strKey: "booking.checkout.summary.legroomSeats" },
  { key: "seatTypeTogether", strKey: "booking.checkout.summary.togetherSeats" },
  { key: "seatTypeRandom", strKey: "booking.checkout.summary.randomSeats" },
];

const EXTRAS_ORDER_ITEMS = [
  {
    key: "connectionProtection",
    itemStrKey: "booking.checkout.summary.connectionProtection",
    noItemStrKey: "booking.checkout.summary.noConnectionProtection",
  },
  {
    key: "changeProtection",
    itemStrKey: "booking.checkout.summary.moneyback",
    noItemStrKey: "booking.checkout.summary.noMoneyback",
  },
  {
    key: "bookingSMSService",
    itemStrKey: "booking.checkout.summary.bookingSMS",
    noItemStrKey: "booking.checkout.summary.noBookingSMS",
  },
  {
    key: "onlineCheckin",
    itemStrKey: "booking.checkout.summary.flightCheckin",
    noItemStrKey: "booking.checkout.summary.noFlightCheckin",
  },
  {
    key: "flightsSMSService",
    itemStrKey: "booking.checkout.summary.flightsSMS",
    noItemStrKey: "booking.checkout.summary.noFlightsSMS",
  },
  {
    key: "airHelpService",
    itemStrKey: "booking.checkout.summary.airhelp",
    noItemStrKey: "booking.checkout.summary.noAirhelp",
  },
];

// "serviceFee",
// "priceLock",

function getBaggageSizeDisplayData(
  travelPackage,
  baggageType,
  isBundle,
  isDouble,
  weightType
) {
  let size, weight;
  if (baggageType === BAGGAGE.personal && isBundle) {
    size = getBundledPersonalItemDimensions(travelPackage);
    weight = getBundledPersonalItemWeight(travelPackage);
  } else if (baggageType === BAGGAGE.personal) {
    size = getPersonalItemDimensions(travelPackage);
    weight = getPersonalItemWeight(travelPackage);
  } else if (
    baggageType === BAGGAGE.cabin &&
    isBundle &&
    weightType === BAG_WEIGHT_CATEGORY.larger
  ) {
    size = getBundledCabinBaggageUpgradeDimensions(travelPackage);
    weight = getBundledCabinBaggageUpgradeWeight(travelPackage);
  } else if (baggageType === BAGGAGE.cabin && isBundle) {
    size = getBundledCabinBaggageDimensions(travelPackage);
    weight = getBundledCabinBaggageWeight(travelPackage);
  } else if (baggageType === BAGGAGE.checked && isDouble) {
    size = getRegisteredBaggageDimensions(travelPackage, isDouble, weightType);
    weight = getRegisteredBaggageWeight(travelPackage, isDouble, weightType);
    weight = "2x " + weight;
  } else if (baggageType === BAGGAGE.checked) {
    size = getRegisteredBaggageDimensions(travelPackage, isDouble, weightType);
    weight = getRegisteredBaggageWeight(travelPackage, isDouble, weightType);
  }

  return `${size}, ${weight}`;
}

function SummaryGroup({ id, title, items, onEdit }) {
  const { stringRes } = useLocaleContext();

  return (
    <li className={classes.group}>
      <div className={classes.heading}>
        <h2>{title}</h2>
        {onEdit && (
          <button onClick={onEdit}>
            {stringRes["booking.checkout.summary.category.edit"]}
          </button>
        )}
      </div>
      <ul className={classes.items}>
        {items.map((item, i) => (
          <li key={id + i}>
            <span className={classes.service}>{item.name}</span>
            {item.notes &&
              item.notes.map((note, y) => (
                <span key={id + i + y} className={classes.note}>
                  {note}
                </span>
              ))}
          </li>
        ))}
      </ul>
    </li>
  );
}

function CheckoutSummary({ ref, readOnly, withBookingNumber }) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { isPageEnabled } = useBookingFlow();
  const { user } = useAuth();
  const navigate = useNavigate();

  const passengers = useSelector(selectAllPassengers);
  const travelPackage = useSelector(selectTravelPackage);
  const orderBreakdown = useSelector(getCartOverview);

  const selectedInsurance = useSelector((state) => state.order.travelInsurance.serviceId);
  const selectedSupport = useSelector((state) => state.order.supportService.serviceId);

  const fillSummaryData = () => {
    const sortedGroups = [
      ORDER_SUMMARY_GROUP.personalDetails,
      ORDER_SUMMARY_GROUP.onBoardService,
      ORDER_SUMMARY_GROUP.travelInsurance,
      ORDER_SUMMARY_GROUP.servicePackage,
      ORDER_SUMMARY_GROUP.extrasServices,
    ];

    const transformedOrder = {};
    orderBreakdown.items.forEach((item) => {
      if (!Object.values(CATALOGUE_IDS.seats).includes(item.key)) {
        transformedOrder[item.key] = { ...item };
      } else {
        transformedOrder[item.key] = transformedOrder[item.key]
          ? [...transformedOrder[item.key], { ...item }]
          : [{ ...item }];
      }
    });

    const data = sortedGroups.map((group) => {
      return {
        id: group.key,
        title: stringRes[group.strKey],
        items: [],
      };
    });

    const GENDER = {
      female: stringRes["booking.form.passenger.gender.female"],
      male: stringRes["booking.form.passenger.gender.male"],
    };

    const personalDetails = passengers.map((pax) => {
      return { name: `${pax.name} ${pax.surname}`, notes: [pax.dob, GENDER[pax.gender]] };
    });

    const cabinBundlesFastTrack = hasBundledCabinBaggageFastTrack(travelPackage)
      ? transformedOrder.cabinBundle?.units
      : 0;
    const cabinBundleUpgradeFastTracks = hasBundledCabinBaggageUpgradeFastTrack(travelPackage)
      ? transformedOrder.cabinBundleUpgrade?.units
      : 0;
    const fastTrackCount = cabinBundleUpgradeFastTracks + cabinBundlesFastTrack;

    const onboardServices = [
      {
        name: `${passengers.length}x ${stringRes["booking.checkout.summary.personalItem"]}`,
        notes: [getBaggageSizeDisplayData(travelPackage, BAGGAGE.personal)],
      },
      {
        name: `${transformedOrder.cabinBundle?.units}x ${stringRes["booking.checkout.summary.cabinBaggage"]}`,
        notes: transformedOrder.cabinBundle?.isActiveOrderItem
          ? [getBaggageSizeDisplayData(travelPackage, BAGGAGE.cabin, true)]
          : null,
      },
      {
        name: `${transformedOrder.cabinBundleUpgrade?.units}x ${stringRes["booking.checkout.summary.cabinBundleUpgrade"]}`,
        notes: transformedOrder.cabinBundleUpgrade?.isActiveOrderItem
          ? [
              getBaggageSizeDisplayData(
                travelPackage,
                BAGGAGE.cabin,
                true,
                null,
                BAG_WEIGHT_CATEGORY.larger
              ),
            ]
          : null,
      },
      {
        name: `${fastTrackCount}x ${stringRes["booking.checkout.summary.fastTrack"]}`,
      },
    ];

    const checkedBaggageItems = [
      transformedOrder.checkedBaggageSmall,
      transformedOrder.checkedBaggageLarge,
      transformedOrder.checkedBaggageDouble,
    ];
    if (checkedBaggageItems.every((item) => item?.units === 0)) {
      onboardServices.push({
        name: `0x ${stringRes["booking.checkout.summary.registeredBaggage"]}`,
      });
    } else {
      checkedBaggageItems.forEach((item, i) => {
        if (item?.units > 0) {
          const baggageData = getBaggageSizeDisplayData(
            travelPackage,
            BAGGAGE.checked,
            false,
            i === 2,
            i === 0 ? BAG_WEIGHT_CATEGORY.default : i === 1 ? BAG_WEIGHT_CATEGORY.larger : null
          );

          onboardServices.push({
            name: `${item.units}x ${stringRes["booking.checkout.summary.registeredBaggage"]}`,
            notes: item.isActiveOrderItem ? [baggageData] : null,
          });
        }
      });
    }

    if (transformedOrder.checkedBaggageProtection?.units > 0) {
      onboardServices.push({
        name: `${transformedOrder.checkedBaggageProtection?.units}x ${stringRes["booking.checkout.summary.baggageProtection"]}`,
      });
    }

    customLog(transformedOrder);
    SEATS_ORDER_ITEMS.forEach((seatType) => {
      if (transformedOrder[seatType.key]) {
        transformedOrder[seatType.key].forEach((segmentSeat) => {
          if (segmentSeat.units > 0) {
            onboardServices.push({
              name: `${
                passengers.length -
                passengers.filter((p) => p.ageGroup === PASSENGER.infant).length
              }x ${stringRes[seatType.strKey]}`,
              notes: [segmentSeat.routeFull],
            });
          }
        });
      }
    });

    const isInsurancePageEnabled = isPageEnabled(BOOKING_STEP.insuranceSelection.id);
    const insurance = [
      {
        name: stringRes[
          transformedOrder.travelInsurance?.isActiveOrderItem
            ? CATALOGUE_IDS_STR_MAP[selectedInsurance]
            : "booking.checkout.summary.noInsurance"
        ],
      },
    ];
    const servicePckg = [
      {
        name: stringRes[
          transformedOrder.supportService?.isActiveOrderItem
            ? CATALOGUE_IDS_STR_MAP[selectedSupport]
            : "booking.checkout.summary.supportBasic"
        ],
      },
    ];
    const isConnectionProtectEligible = isConnectionProtectionEligible(travelPackage);
    const extras = EXTRAS_ORDER_ITEMS.filter(
      (extraItem) =>
        extraItem.key !== CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE ||
        isConnectionProtectEligible
    ).map((extraItem) => {
      const orderItem = transformedOrder[extraItem.key];
      return {
        name: stringRes[
          orderItem?.isActiveOrderItem ? extraItem.itemStrKey : extraItem.noItemStrKey
        ],
      };
    });

    data[0].items = personalDetails;
    data[1].items = onboardServices;
    data[2].items = isInsurancePageEnabled ? insurance : [];
    data[3].items = servicePckg;
    data[4].items = extras;

    return data.filter((d) => d.items.length > 0);
  };

  const [summaryData] = useState(fillSummaryData);
  const [searchParams] = useSearchParams();

  const userName =
    user && user.displayName ? user.displayName : toTitleCase(passengers[0].name);

  const onEditGroup = (groupId) => {
    console.log("clickedd edit " + groupId);
    let navLink = null;
    const query = searchParams ? "?" + searchParams.toString() : "";
    switch (groupId) {
      case ORDER_SUMMARY_GROUP.personalDetails.key:
        navLink = BOOKING_STEP.passengerDetailsForm.path + query;
        break;
      case ORDER_SUMMARY_GROUP.onBoardService.key:
        navLink = BOOKING_STEP.passengerDetailsForm.path + query;
        break;
      case ORDER_SUMMARY_GROUP.travelInsurance.key:
        navLink = BOOKING_STEP.insuranceSelection.path + query;
        break;
      case ORDER_SUMMARY_GROUP.servicePackage.key:
        navLink = BOOKING_STEP.supportSelection.path + query;
        break;
      case ORDER_SUMMARY_GROUP.extrasServices.key:
        navLink = BOOKING_STEP.extraServicesSelection.path + query;
        break;
      default:
        return null;
    }
    if (navLink) {
      navigate("../" + navLink);
    }
  };

  return (
    <div
      ref={ref}
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
      <h1>
        {isMobile
          ? stringRes["booking.checkout.summary.title.mob"]
          : stringRes["booking.checkout.summary.title"] + (userName ? `, ${userName}!` : `!`)}
      </h1>

      {!isMobile && (
        <>
          <p className={classes.description}>
            {stringRes["booking.checkout.summary.description"]}
          </p>
          {withBookingNumber && (
            <p style={{ fontFamily: "var(--font-semibold)" }} className={classes.description}>
              Booking number: {travelPackage.booking_number}
            </p>
          )}
        </>
      )}
      <div className={classes.hr}></div>
      <ul className={classes.summary}>
        {summaryData.map((group) => (
          <SummaryGroup
            key={group.id}
            id={group.id}
            title={group.title}
            items={group.items}
            onEdit={readOnly ? null : () => onEditGroup(group.id)}
          />
        ))}
      </ul>
    </div>
  );
}

export default CheckoutSummary;
