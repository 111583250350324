import { useState } from "react";

function useModalState(initialState) {
  const [state, setState] = useState(
    initialState || {
      isClosing: false,
      doShow: false,
    }
  );

  const onClose = (callback) => {
    setState((prev) => {
      return { ...prev, isClosing: true };
    });
    setTimeout(() => {
      setState((prev) => {
        return { ...prev, doShow: false };
      });
      if (callback) callback();
    }, 200);
  };

  const onOpen = () => {
    setState({ isClosing: false, doShow: true });
  };

  return [state, onOpen, onClose, setState];
}

export default useModalState;
