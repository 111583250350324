import { useEffect, useRef } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./StepContent.module.css";
import { useLocaleContext } from "src/context/locale-context";
import BookingStepTitle from "../StepTitle/BookingStepTitle";
import BookingSidePanel from "../SidePanel/BookingSidePanel";
import Button from "src/components/UI/Button/Button";
import { StepDirectionArrow } from "src/components/UI/Arrow/Arrow";
import { SecurityNote } from "../MobileBottomElement/MobileBottomElement";
import { SupportSelection } from "../SupportSelection/SupportSelection";
import { useBookingFlow } from "src/context/booking-flow-context";

function SupportStepContent({ pageId }) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { goToNextBookingPage, goToPrevBookingPage, hasPrevious } = useBookingFlow();

  const serviceRef = useRef();

  useEffect(() => {
    const page = document.getElementById("booking-page");
    if (isMobile) {
      page.style.backgroundColor = "white";
    }
    return () => {
      page.style.backgroundColor = "initial";
    };
  }, [isMobile]);

  const onGoToPrevStep = () => {
    goToPrevBookingPage(pageId);
  };

  const onGoToNextStep = () => {
    goToNextBookingPage(pageId);
  };

  if (!isMobile) {
    return (
      <section className={`${classes.container} ${classes.desktop}`}>
        <BookingStepTitle step={pageId} />
        <div className={classes.main}>
          <SupportSelection onContinue={onGoToNextStep} ref={serviceRef} />
        </div>
        <BookingSidePanel />
        <div className={classes.nextButton}>
          {hasPrevious(pageId) && (
            <Button
              secondary
              name={
                <span className={classes.withBackArr}>
                  <span>{stringRes["booking.back.button"]}</span>
                  <StepDirectionArrow back />
                </span>
              }
              onClick={onGoToPrevStep}
            />
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className={`${classes.container} ${classes.mobile} ${classes.support}`}>
        <BookingStepTitle step={pageId} />
        <SupportSelection onContinue={onGoToNextStep} ref={serviceRef} />
        <SecurityNote />
      </section>
    );
  }
}

export default SupportStepContent;
