import classes from "./DurationFilter.module.css";
import { useDispatch, useSelector } from "react-redux";
import { filterSortActions, selectFilters } from "src/store/filter-sort";
import Button from "src/components/UI/Button/Button";
import { Fragment, useContext, useEffect, useState } from "react";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { ModalElementContext } from "src/context/modal-element-context";
import Slider from "src/components/UI/Slider/Slider";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";

function DurationFilterContent({
  travelTime,
  travelTimeMax,
  onTravelTimeChange,
  stopover,
  stopoverMin,
  stopoverMax,
  isSliderReleased,
  onStopoverChange,
}) {
  const { stringRes } = useLocaleContext();

  return (
    <Fragment>
      <h1>{stringRes["res.filter.title.duration"]}</h1>

      <div className={classes.sliderGroup}>
        <div className={classes.slider}>
          <h2>{stringRes["res.filter.title.duration.maxTravel"]}</h2>
          <p className={classes.range}>
            {travelTime
              ? `${travelTime} ${stringRes["res.filter.duration.hours"]}`
              : stringRes["res.filter.duration.any"]}
          </p>
          <Slider
            current={travelTime ? travelTime : travelTimeMax}
            min={1}
            max={travelTimeMax}
            step={1}
            onAfterChange={() => isSliderReleased(true)}
            onBeforeChange={() => isSliderReleased(false)}
            onChange={onTravelTimeChange}
            maxLabel={stringRes["res.filter.duration.all"]}
          />
        </div>

        <div className={classes.slider}>
          <h2>{stringRes["res.filter.title.duration.stopover"]}</h2>
          <p className={classes.range}>
            {stopover
              ? `${stopover[0]}-${stopover[1]} ${stringRes["res.filter.duration.hours"]}`
              : stringRes["res.filter.duration.any"]}
          </p>
          <Slider
            current={stopover ? stopover : [stopoverMin, stopoverMax]}
            min={stopoverMin}
            max={stopoverMax}
            step={1}
            onAfterChange={() => isSliderReleased(true)}
            onBeforeChange={() => isSliderReleased(false)}
            onChange={onStopoverChange}
            minLabel={`${stopoverMin} ${stringRes["res.filter.duration.hours"]}`}
            maxLabel={`${stopoverMax} ${stringRes["res.filter.duration.hours"]}`}
            isRange
          />
        </div>
      </div>
    </Fragment>
  );
}

export function DurationFilter() {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();
  const { onClose } = useContext(ModalElementContext);
  const { isMobile } = useDeviceContext();

  const travelTimeSettings = useSelector((state) => selectFilters(state, "duration"));
  const stopoverSettings = useSelector((state) => selectFilters(state, "stopover"));

  const [travelTime, setTravelTime] = useState(travelTimeSettings.value);
  const [stopover, setStopover] = useState(stopoverSettings.value);
  const [isSliderReleased, setSliderReleased] = useState(false);

  useEffect(() => {
    if (!travelTimeSettings.isOn) {
      setTravelTime(travelTimeSettings.value);
    }
    if (!stopoverSettings.isOn) {
      setStopover(stopoverSettings.value);
    }
  }, [travelTimeSettings.isOn, stopoverSettings.isOn]);

  useEffect(() => {
    if (!isMobile && isSliderReleased) {
      dispatch(filterSortActions.applyFilter({ type: "stopover", value: stopover }));

    }
  }, [stopover, isSliderReleased]);

  useEffect(() => {
    if (!isMobile && isSliderReleased) {
      dispatch(filterSortActions.applyFilter({ type: "duration", value: travelTime }));
    }
  }, [travelTime, isSliderReleased]);

  const onStopoverRangeChange = (values) => {
    if (values[0] === stopoverSettings.min && values[1] === stopoverSettings.max) {
      setStopover("");
    } else {
      setStopover(values);
    }
  };

  const onTravelTimeValueChange = (value) => {
    if (value === travelTimeSettings.max) {
      setTravelTime("");
    } else {
      setTravelTime(value);
    }
  };

  const onConfirmFilter = () => {
    dispatch(filterSortActions.applyFilter({ type: "stopover", value: stopover }));
    dispatch(filterSortActions.applyFilter({ type: "duration", value: travelTime }));
    onClose();
  };

  const props = {
    travelTime: travelTime,
    travelTimeMax: travelTimeSettings.max,
    onTravelTimeChange: onTravelTimeValueChange,
    stopover: stopover,
    stopoverMin: stopoverSettings.min,
    stopoverMax: stopoverSettings.max,
    onStopoverChange: onStopoverRangeChange,
    isSliderReleased: setSliderReleased,
  };

  return (
    <Fragment>
      {isMobile && <CloseWindowButton onClick={onClose} />}
      <div
        className={`${classes.container} ${classes.duration} ${
          isMobile ? classes.mobile : classes.desktop
        }`}
      >
        <DurationFilterContent {...props} />
        {isMobile && (
          <Fragment>
            <Button
              name={stringRes["res.filter.button.cancel"]}
              onClick={onClose}
              secondary
              cornerSm
              fontclass={classes.fontclass}
            />
            <Button
              name={stringRes["res.filter.button.done"]}
              onClick={onConfirmFilter}
              primary
              cornerSm
              fontclass={classes.fontclass}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
