import classes from "./LoadingIndicatorBar.module.css";

export function LoadingIndicatorBar({ isLoading, height = 4 }) {
  return (
    <div
      style={{ height: `${height}px` }}
      className={`${classes.loadingBar} ${isLoading ? classes.loading : classes.loaded}`}
    ></div>
  );
}
