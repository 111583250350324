import { useEffect } from "react";
import { Navigate, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import Button from "src/components/UI/Button/Button";
import { useAuth } from "src/context/auth-context";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { clearBookingSessionData } from "src/utils/storage-utils";

export function BookingCancelled() {
  const { stringRes } = useLocaleContext();
  const { isAuthenticated } = useAuth();
  const { isMobile } = useDeviceContext();
  const location = useLocation();
  const navigate = useNavigate();
  const navType = useNavigationType();

  useEffect(clearBookingSessionData, []);

  if (!location.state || location.state.source !== "booking" || navType === "POP") {
    return <Navigate to="/notfound" />;
  }

  return (
    <main id="booking-cancelled-page" className={isMobile ? "mobile" : "desktop"}>
      <div className="order-container">
        <h1>{stringRes["booking.cancelled.title"]}</h1>
        <p>{stringRes["booking.cancelled.description"]}</p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "1rem",
            marginTop: "2rem",
          }}>
          {isAuthenticated ? (
            <Button
              wide
              primary
              onMouseUp={() => navigate("/dashboard/trips")}
              name={stringRes["button.general.dashboard"]}
            />
          ) : (
            <Button
              primary
              wide
              onClick={() => navigate("/booking")}
              name={stringRes["button.general.booking.restart"]}
            />
          )}
        </div>
      </div>
    </main>
  );
}
