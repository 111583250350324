import { Fragment, forwardRef, useState } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./PassengerForm.module.css";
import { FormInputDropdown } from "../../../shared/input/FormInput/FormInput";
import { useLocaleContext } from "src/context/locale-context";
import { PASSENGER } from "src/constants";
import {
  BaggageSelection,
} from "../BaggageSelection/BaggageSelection";
import { BaggageSelectionNotAvailable } from "../BaggageSelection/elements/BaggageSelectionNotAvailable";
import { useSelector } from "react-redux";
import Button from "src/components/UI/Button/Button";
import { FormInfoElement } from "../../../UI/FormInfoElement/FormInfoElement";
import { motion } from "framer-motion";
import { selectBaggage } from "src/store/order";
import { Markup } from "interweave";
import { PassengerDataForm } from "./elements/PassengerDataForm";

function InfantPassengerForm({ paxData, passengerId, ageGroup }) {
  return (
    <Fragment>
      <PassengerDataForm paxData={paxData} passengerId={passengerId} ageGroup={ageGroup} />
      <BaggageSelectionNotAvailable />
    </Fragment>
  );
}

function AdultPassengerForm({ paxData, passengerId, ageGroup, baggageData }) {
  return (
    <Fragment>
      <PassengerDataForm paxData={paxData} ageGroup={ageGroup} passengerId={passengerId} />
      <BaggageSelection
        baggageData={baggageData}
        paxData={paxData}
        passengerId={passengerId}
      />
    </Fragment>
  );
}

const PassengerForm = forwardRef(function PassengerForm(
  { passenger, passengerNum, onRemove, onChangeAgeGroup, isInit, enableInfantType },
  ref
) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const DEF_PAX_AGE_GROUPS = {
    [PASSENGER.adult]: stringRes["booking.order.breakdown.adult"],
    [PASSENGER.child]: stringRes["booking.passenger.group.age.child"],
  };

  const baggageData = useSelector((state) => selectBaggage(state, passenger.id));
  const [state, setState] = useState({ ageGroup: { isActive: false } });

  const namesInfo = <Markup content={stringRes["booking.form.passenger.info.names"]} />;
  const formTitle = (
    <span className={classes.paxtitle}>
      {passengerNum > 1 ? passengerNum + ". " : ""}
      {passengerNum > 1
        ? stringRes["booking.form.passenger.secondary.title"]
        : stringRes["booking.form.passenger.title"]}
    </span>
  );

  const scrollFormIntoView = (isFormAppearing) => {
    if (isFormAppearing && !isInit && ref[passenger.id]) {
      const formY = ref[passenger.id].getBoundingClientRect().top + window.scrollY - 60;
      window.scrollTo({ top: formY, behavior: "smooth" });
    }
  };

  const props = {
    paxData: passenger,
    passengerId: passenger.id,
    ageGroup: passenger.ageGroup,
    baggageData,
  };

  const motionProps = isMobile
    ? {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
      }
    : {
        initial: { opacity: 0 },
        animate: { scale: 1, opacity: 1 },
        exit: { scale: 0.9, opacity: 0 },
        transition: { type: "tween" },
      };

  return (
    <motion.div
      ref={(r) => {
        ref[passenger.id] = r;
      }}
      {...motionProps}
      onAnimationStart={(property) => scrollFormIntoView(property.opacity > 0)}
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
      <div className={classes.formHeader}>
        <h1>{formTitle}</h1>
        <div className={classes.ageGroupSelect}>
          <FormInputDropdown
            onSelect={onChangeAgeGroup}
            options={
              passengerNum === 1
                ? { [PASSENGER.adult]: DEF_PAX_AGE_GROUPS.adult }
                : enableInfantType
                ? {
                    ...DEF_PAX_AGE_GROUPS,
                    [PASSENGER.infant]: stringRes["booking.passenger.group.age.infant"],
                  }
                : DEF_PAX_AGE_GROUPS
            }
            value={passenger.ageGroup}
            disabled={passengerNum === 1}
            isValid
            isActive={state.ageGroup.isActive}
            onFocus={() => setState({ ageGroup: { isActive: true } })}
            onBlur={() => setState({ ageGroup: { isActive: false } })}
          />
        </div>
      </div>

      <FormInfoElement>{namesInfo}</FormInfoElement>
      {passenger.ageGroup !== PASSENGER.infant ? (
        <AdultPassengerForm {...props} />
      ) : (
        <InfantPassengerForm {...props} />
      )}
      {onRemove && (
        <div className={classes.removePassenger}>
          <Button
            name={stringRes["booking.form.passenger.remove.button"]}
            onClick={onRemove}
            wide={isMobile}
          />
        </div>
      )}
    </motion.div>
  );
});

export default PassengerForm;
