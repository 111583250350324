import ReactSlider from "react-slider";
import classes from "./Slider.module.css";
import { useDeviceContext } from "src/context/device-context";

function Slider({
  current,
  min,
  max,
  step,
  isRange,
  disabled,
  minLabel,
  maxLabel,
  onChange,
  onAfterChange,
  onBeforeChange,
}) {
  const { isMobile } = useDeviceContext();

  const onSliderValueChange = (v) => {
    // console.log(v);
    onChange(v);
  };

  return (
    <div
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}
    >
      <ReactSlider
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        value={current}
        onAfterChange={onAfterChange}
        onBeforeChange={onBeforeChange}
        onChange={onSliderValueChange}
        minDistance={step}
        className={`${classes.slider} ${disabled ? classes.disabled : ""}`.trimEnd()}
        trackClassName={isRange ? "slider-track-range" : "slider-track"}
        thumbClassName="slider-thumb"
      />
      <div className={classes.labels}>
        <span>{minLabel ? minLabel : ""}</span>
        <span>{maxLabel ? maxLabel : ""}</span>
      </div>
    </div>
  );
}

export default Slider;
