import { useLocaleContext } from "src/context/locale-context";

export function ClearAllFiltersButton({ onClearAll }) {
  const { stringRes } = useLocaleContext();

  return (
    <button type="button" onClick={onClearAll}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <g clipPath="url(#clip0_90_2229)">
          <path
            d="M6.00488 0C2.69117 0 0.00488281 2.68629 0.00488281 6C0.00488281 9.31371 2.69117 12 6.00488 12C9.31859 12 12.0049 9.31371 12.0049 6C12.0013 2.68777 9.31712 0.00358594 6.00488 0ZM8.00488 7.29349C8.20805 7.4805 8.22118 7.79681 8.03417 7.99999C7.84716 8.20317 7.53085 8.2163 7.32767 8.02929C7.3175 8.01994 7.30775 8.01016 7.29838 7.99999L6.00488 6.70699L4.71188 7.99999C4.51325 8.19183 4.19673 8.18632 4.00489 7.98769C3.81777 7.79393 3.81777 7.48676 4.00489 7.293L5.29789 6L4.00489 4.707C3.81305 4.50837 3.81856 4.19184 4.0172 4.00001C4.21095 3.81288 4.51813 3.81288 4.71188 4.00001L6.00488 5.29301L7.29838 4.00001C7.48538 3.79683 7.8017 3.7837 8.00488 3.97071C8.20805 4.15772 8.22118 4.47403 8.03417 4.67721C8.02482 4.68738 8.01505 4.69713 8.00488 4.70651L6.71187 6L8.00488 7.29349Z"
            fill="#001B38"
          />
        </g>
        <defs>
          <clipPath id="clip0_90_2229">
            <rect width="12" height="12" fill="white" transform="translate(0.00488281)" />
          </clipPath>
        </defs>
      </svg>
      <span>{stringRes["res.filter.active.clear"]}</span>
    </button>
  );
}
