export function ShieldIcon({ color = "#001B38" }) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.075 2.10938L8.36563 0.534375C8.14063 0.478125 7.88751 0.478125 7.66251 0.534375L1.92501 2.10938C1.33438 2.27813 0.912506 2.8125 0.912506 3.43125V7.59375C0.912506 11.9531 3.50001 15.8063 7.49376 17.4094C7.66251 17.4656 7.83126 17.5219 8.00001 17.5219C8.16876 17.5219 8.33751 17.4938 8.50626 17.4094C12.5 15.8063 15.0875 11.925 15.0875 7.56563V3.43125C15.0875 2.8125 14.6656 2.27813 14.075 2.10938ZM13.8219 7.56563C13.8219 11.3344 11.4875 14.8219 8.05626 16.2281C8.02813 16.2281 8.00001 16.2281 7.97188 16.2281C4.45626 14.8219 2.17813 11.4188 2.17813 7.59375V3.43125C2.17813 3.40313 2.20626 3.34688 2.23438 3.34688L7.97188 1.77188H8.00001C8.00001 1.77188 8.00001 1.77188 8.02813 1.77188L13.7656 3.34688C13.7938 3.34688 13.8219 3.40313 13.8219 3.43125V7.56563Z"
        fill={color}
      />
      <path
        d="M11.1781 6.35569L7.29689 9.61819L5.55314 8.15569C5.27189 7.93069 4.87814 7.95882 4.65314 8.24007C4.42814 8.52132 4.45627 8.91507 4.73752 9.14007L6.87502 10.9401C6.98752 11.0526 7.12814 11.0807 7.26877 11.0807C7.40939 11.0807 7.55002 11.0244 7.66252 10.9401L11.9938 7.31194C12.275 7.08694 12.3031 6.69319 12.0781 6.41194C11.8531 6.15882 11.4313 6.13069 11.1781 6.35569Z"
        fill={color}
      />
    </svg>
  );
}
