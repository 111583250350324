import classes from "./UpdateBookingModal.module.css";
import Button from "src/components/UI/Button/Button";
import { Fragment } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "src/components/shared/modal/Modal/Modal";
import DesktopModal from "src/components/shared/modal/Modal/DesktopModal";

function UpdateBookingModal({ onClose, onUpdate, state }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const innerContent = (
    <Fragment>
      <div className={classes.image}></div>
      <h1>{stringRes["booking.modal.price.refresh.title"]}</h1>
      <p>{stringRes["booking.modal.price.refresh.description"]}</p>
      <Button
        primary
        name={stringRes["booking.modal.price.refresh.button"]}
        onClick={onUpdate}
      />
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>{innerContent}</div>
      </DesktopModal>
    );
  }
}

export default UpdateBookingModal;
