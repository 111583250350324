import { Fragment, useEffect, useState } from "react";
import ResultsList from "./ResultsList/ResultsList";
import { useDispatch, useSelector } from "react-redux";
import { SORT, TRIP_TYPE } from "src/constants";
import { selectTripType } from "src/store/search";
import {
  filterSortActions,
  selectActiveFilters,
  selectIsAnyFilterOn,
  selectSort,
} from "src/store/filter-sort";
import {
  getCheapestPrice,
  getFilterMatches,
  getFilterValues,
  getSortValues,
  sortByBestScore,
  sortByDuration,
  sortByEarliestArrival,
  sortByEarliestDeparture,
  sortByPrice,
} from "src/utils/filter-utils";

import { LoadingResultsList } from "./ResultsList/LoadingResultsList";
import { customLog } from "src/utils/utils";
import { FilteredResultsNotFound } from "./ResultsNotFound/ResultsNotFound";

function extractFilterSortValues(results) {
  const filterValueObj = getFilterValues(results);
  const sortValueObj = getSortValues(results);
  return { filterValueObj, sortValueObj };
}

function ResultsMainPanel({
  results,
  filteredResults,
  setFilteredResults,
  isNewRequest,
  onSelectResult,
  onShowDetails,
}) {
  const dispatch = useDispatch();
  const [cachedResults, setCachedResults] = useState(results);
  const isAnyFilterOn = useSelector(selectIsAnyFilterOn);

  const isSorting = useSelector((state) => state.filterSort.isSorting);
  const sortType = useSelector(selectSort);

  const activeFilters = useSelector(selectActiveFilters);
  const tripType = useSelector(selectTripType);

  const pinnedDeparture = useSelector((state) => state.filterSort.pinned.departure);
  const pinnedArrival = useSelector((state) => state.filterSort.pinned.arrival);

  const refilter = (minDuration) => {
    customLog("something changed in active filters");
    dispatch(filterSortActions.setIsSorting(true));
    const startTimestamp = Date.now();

    customLog("refilter/sort");
    let newResults = cachedResults.slice();
    if (pinnedDeparture.length > 0) {
      newResults = newResults.filter((tripPackage) => {
        const firstChainIndices = tripPackage.segments_direction[0];
        const depFlightNums = firstChainIndices.map(
          (idx) => tripPackage.segments[idx].flight_number
        );
        return (
          depFlightNums.every((n) => pinnedDeparture.includes(n)) &&
          depFlightNums.length === pinnedDeparture.length
        );
      });
    }

    if (pinnedArrival.length > 0) {
      newResults = newResults.filter((tripPackage) => {
        const lastChainIndices =
          tripPackage.segments_direction[tripPackage.segments_direction.length - 1];
        const arrFlightNums = lastChainIndices.map(
          (idx) => tripPackage.segments[idx].flight_number
        );
        return (
          arrFlightNums.every((n) => pinnedArrival.includes(n)) &&
          arrFlightNums.length === pinnedArrival.length
        );
      });
    }

    const bagsFilterKeys = ["cabinBaggage", "checkedBaggage"];
    const otherFilters = Object.keys(activeFilters).filter((k) => !bagsFilterKeys.includes(k));
    const otherMatchesRequired = otherFilters.length;

    newResults = newResults.filter((tripPackage) => {
      let { matches, doesBaggageMatch } = getFilterMatches(
        tripPackage,
        activeFilters,
        tripType === TRIP_TYPE.roundtrip
      );
      return matches === otherMatchesRequired && doesBaggageMatch;
    });
    customLog("don filtering");

    let sortValues;
    if (newResults.length > 0) {
      if (sortType === SORT.cheapest) {
        newResults.sort(sortByPrice);
      } else if (sortType === SORT.fastest) {
        newResults.sort(sortByDuration);
      } else if (sortType === SORT.earlyArrival) {
        newResults.sort(sortByEarliestArrival);
      } else if (sortType === SORT.earlyDeparture) {
        newResults.sort(sortByEarliestDeparture);
      } else if (sortType === SORT.best) {
        newResults.sort(sortByBestScore);
        if (newResults.length > 2) {
          const { cheapestIdx } = getCheapestPrice(newResults, 1);
          const cheapestValue = newResults[cheapestIdx];
          newResults.splice(cheapestIdx, 1);
          newResults.splice(1, 0, cheapestValue);
        }
      }
      customLog("don sorting");
      sortValues = getSortValues(newResults);
      customLog(sortValues);
      customLog("don collecting sort values");
    }

    const timeElapsed = Date.now() - startTimestamp;
    const timerId = setTimeout(() => {
      if (sortValues) {
        Object.entries(sortValues).forEach((e) => {
          const val = e[1];
          const { time, score, ref, ...finalVal } = val;
          dispatch(filterSortActions.setSortValue({ name: e[0], value: finalVal }));
        });
      }
      window.scrollTo({ top: 0 });
      setFilteredResults(newResults);
      dispatch(filterSortActions.setIsSorting(false));
    }, Math.max(0, minDuration - timeElapsed));

    return timerId;
  };

  useEffect(() => {
    let minDuration = 400;
    const timerId = refilter(minDuration);
    return () => clearTimeout(timerId);
  }, [cachedResults, sortType, activeFilters, pinnedArrival, pinnedDeparture]);

  useEffect(() => {
    setCachedResults(results);
    const { filterValueObj: fVals, sortValueObj } = extractFilterSortValues(results);
    dispatch(filterSortActions.setMinFilterValue({ type: "price", value: fVals.minPrice }));
    dispatch(filterSortActions.setMaxFilterValue({ type: "price", value: fVals.maxPrice }));
    dispatch(
      filterSortActions.setMaxFilterValue({ type: "duration", value: fVals.maxDuration })
    );
    dispatch(
      filterSortActions.setMinFilterValue({ type: "stopover", value: fVals.minStopover })
    );
    dispatch(
      filterSortActions.setMaxFilterValue({ type: "stopover", value: fVals.maxStopover })
    );
    dispatch(filterSortActions.setAirlineOptions({ options: fVals.airlines }));
    dispatch(filterSortActions.setAirportOptions({ options: fVals.airports }));

    Object.entries(sortValueObj).forEach((e) => {
      const val = e[1];
      const { time, score, ref, ...finalVal } = val;
      dispatch(filterSortActions.setSortValue({ name: e[0], value: finalVal }));
    });
  }, [dispatch, results]);

  const showZeroFiltered = !isSorting && filteredResults.length === 0 && isAnyFilterOn;

  const displayElement = () => {
    if (isNewRequest || (filteredResults.length === 0 && isSorting)) {
      return <LoadingResultsList />;
    } else if (showZeroFiltered) {
      return <FilteredResultsNotFound />;
    } else {
      return (
        <ResultsList
          filteredResults={filteredResults}
          onSelectResult={onSelectResult}
          onShowDetails={onShowDetails}
        />
      );
    }
  };

  return <Fragment>{displayElement()}</Fragment>;
}

export default ResultsMainPanel;
