import classes from "./InsuranceSelection.module.css";
import { Fragment, forwardRef, useEffect, useState } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { CATALOGUE_IDS, PRICE_POLICY, SERVICE_TYPE } from "src/constants/services";
import { BOOKING_STEP } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "src/store/order";
import {
  selectCatalogue,
  selectCatalogueItem,
  selectServiceUnitPrice,
} from "src/store/catalogue";
import { selectisFormIncomplete } from "src/store/booking";
import insuranceUmbrellaIcon from "src/assets/img/insuranceStepIcon.png";
import insuranceUmbrellaPlusIcon from "src/assets/img/insurancePlusIcon.png";
import { FormInfoElement } from "../../../UI/FormInfoElement/FormInfoElement";
import { Checkmark, FatCheckmark } from "src/components/UI/Icon/Checkmark";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import insuranceNoneImg from "src/assets/img/insuranceNone.png";
import insuranceBasicImg from "src/assets/img/insuranceBasic.png";
import insurancePlusImg from "src/assets/img/insurancePlus.png";
import Card from "src/components/UI/Card/Card";
import AddButtonOval from "src/components/UI/AddButtonOval/AddButtonOval";
import { nanoid } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import { Markup } from "interweave";
import { XIconDesktop, XIconMobile } from "src/components/UI/Icon/XIcon";
import { localeApi } from "src/services/api";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";

const INSURANCE_IMGS = {
  [CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS]: insurancePlusImg,
  [CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC]: insuranceBasicImg,
  [SERVICE_TYPE.REFUSED]: insuranceNoneImg,
};

const INSURANCE_MOB_IMGS = {
  [CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS]: insuranceUmbrellaPlusIcon,
  [CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC]: insuranceUmbrellaIcon,
};

function InsuranceHeader({ serviceId, selected, name, price, onSelect }) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const checked = selected === serviceId;

  const onSelectHandler = (isChecked) => {
    onSelect(isChecked, serviceId);
  };

  const onToggle = () => {
    if (checked) {
      onSelect(true, SERVICE_TYPE.REFUSED);
    } else {
      onSelect(true, serviceId);
    }
  };

  if (isMobile) {
    return (
      <div className={classes.header}>
        <div className={classes.top}>
          <span className={classes.title}>{name}</span>
          <img alt="" src={INSURANCE_MOB_IMGS[serviceId]} className={classes.icon} />
        </div>
        <div className={classes.hr}></div>
        <div className={classes.bottom}>
          <span className={classes.price}>
            <span>
              {`${price} ${currencySymbol} / ${stringRes["booking.service.price.day"]}`}
            </span>
            <span>{stringRes[PRICE_POLICY.per_person]}</span>
          </span>
          <AddButtonOval onClick={onToggle} isAdded={checked} />
        </div>
      </div>
    );
  } else {
    return (
      <RadioButton
        checked={checked}
        id={"insurance-" + serviceId}
        name={"insurance-selection"}
        className={`${classes.insuranceType} ${checked ? classes.active : ""}`}
        onChange={onSelectHandler}>
        <div className={classes.basicInfo}>
          <img alt="" className={classes.icon} src={INSURANCE_IMGS[serviceId]} />
          <span className={classes.title}>{name}</span>
          {serviceId !== SERVICE_TYPE.REFUSED && (
            <Fragment>
              <span className={classes.price}>
                <span className={classes.amount}>{`+ ${price} ${currencySymbol} `}</span>
                <span>{stringRes[PRICE_POLICY.per_day]}</span>
              </span>
              <span>{stringRes[PRICE_POLICY.per_person]}</span>
            </Fragment>
          )}
        </div>
      </RadioButton>
    );
  }
}

function TravelInsuranceFeatures({ id, features }) {
  const { isMobile } = useDeviceContext();
  if (features?.length === 0)
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        <DotPulseLoader opacity={0.4} />
      </div>
    );

  return (
    <ul className={classes.features}>
      {features.map((ft) => (
        <li key={id + nanoid()} className={classes.feature}>
          {ft.check_box ? (
            <Fragment>
              {isMobile ? <FatCheckmark /> : <Checkmark color={"#434F60"} />}
            </Fragment>
          ) : (
            <Fragment>
              {isMobile ? (
                <XIconMobile color={"#FF0101"} />
              ) : (
                <XIconDesktop color={"#434F60"} />
              )}
            </Fragment>
          )}
          <span>{ft.comment}</span>
        </li>
      ))}
    </ul>
  );
}

function TravelInsuranceOptionMobile(props) {
  const ftId = props.name.replace(" ", "_");
  const { stringRes } = useLocaleContext();

  return (
    <Card className={classes.insuranceOption} cornerSm>
      <InsuranceHeader {...props} />
      {props.features?.length > 0 ? (
        <>
          <TravelInsuranceFeatures id={ftId} features={props.features} />
          <Link target="_blank" to="/info/guarantee-rules">
            {stringRes["link.text.showDetails"]}
          </Link>
          <div className={classes.note}>
            <Markup content={stringRes["booking.insurance.mob.note"]} />
          </div>
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DotPulseLoader opacity={0.4} />
        </div>
      )}
    </Card>
  );
}

const InsuranceSelection = forwardRef(function InsuranceSelection(props, ref) {
  const { stringRes, currentLocale } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();
  const [features, setFeatures] = useState({ basic: [], plus: [] });

  const isFormIncomplete = useSelector((state) =>
    selectisFormIncomplete(state, BOOKING_STEP.insuranceSelection.id)
  );
  const currentService = useSelector((state) => state.order.travelInsurance.serviceId);
  const showWarning = isFormIncomplete && !currentService;

  const noInsurancePrice = 0;
  const withBasicInsurancePrice = useSelector((state) =>
    selectServiceUnitPrice(selectCatalogue(state), CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC)
  );
  const withPlusInsurancePrice = useSelector((state) =>
    selectServiceUnitPrice(selectCatalogue(state), CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS)
  );

  const basicData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC)
  );
  const plusData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS)
  );

  useEffect(() => {
    if (isMobile && !currentService) {
      onSelectInsuranceOption(true, SERVICE_TYPE.REFUSED);
    }

    localeApi
      .getAdditionalStringResources(currentLocale.substring(0, 2))
      .then((response) => {
        console.log(response.data);
        const strRes = response.data;

        const localizedDataBasic = [];
        basicData.data.package.forEach((ft) => {
          const feature = { ...ft, comment: strRes[ft.name] };
          localizedDataBasic.push(feature);
        });

        const localizedDataPlus = [];
        plusData.data.package.forEach((ft) => {
          const feature = { ...ft, comment: strRes[ft.name] };
          localizedDataPlus.push(feature);
        });

        setFeatures({ basic: localizedDataBasic, plus: localizedDataPlus });
      })
      .catch((err) => {
        console.log(err);
        setFeatures({ basic: basicData.data.package, plus: plusData.data.package });
      });
  }, []);

  const onSelectInsuranceOption = (isChecked, serviceId) => {
    if (isChecked) {
      dispatch(orderActions.changeTravelInsuranceType(serviceId));
    }
  };

  const sharedProps = {
    selected: currentService,
    onSelect: onSelectInsuranceOption,
  };

  if (!isMobile) {
    return (
      <div ref={ref} className={`${classes.container} ${classes.desktop}`}>
        <div className={classes.top}>
          <h1>{stringRes["booking.insurance.title"]}</h1>
          <p className={classes.description}>
            <Markup content={stringRes["booking.insurance.description"]} />
          </p>
          <img src={insuranceUmbrellaIcon} alt="insurance" />
        </div>
        <FormInfoElement>
          <Markup content={stringRes["booking.insurance.note"]} />
        </FormInfoElement>
        {showWarning && (
          <p className={classes.warnText}>
            {`${stringRes["booking.insurance.option.warn"]}:`}
          </p>
        )}

        <div className={`${classes.insuranceOpts} ${showWarning ? classes.warn : ""}`}>
          <InsuranceHeader
            {...sharedProps}
            serviceId={CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS}
            name={stringRes["booking.insurance.option.plus"]}
            price={withPlusInsurancePrice}
          />
          <TravelInsuranceFeatures id="insurance-plus-ft" features={features.plus} />

          <InsuranceHeader
            {...sharedProps}
            serviceId={CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC}
            name={stringRes["booking.insurance.option.basic"]}
            price={withBasicInsurancePrice}
          />
          <TravelInsuranceFeatures id="insurance-basic-ft" features={features.basic} />

          <InsuranceHeader
            {...sharedProps}
            serviceId={SERVICE_TYPE.REFUSED}
            name={stringRes["booking.insurance.option.none"]}
            price={noInsurancePrice}
          />
        </div>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div ref={ref} className={`${classes.container} ${classes.mobile}`}>
          <TravelInsuranceOptionMobile
            {...sharedProps}
            serviceId={CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC}
            name={stringRes["booking.insurance.option.basic"]}
            price={withBasicInsurancePrice}
            features={features.basic}
          />
          <TravelInsuranceOptionMobile
            serviceId={CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_PLUS}
            name={stringRes["booking.insurance.option.plus"]}
            price={withPlusInsurancePrice}
            features={features.plus}
            {...sharedProps}
          />
        </div>
      </Fragment>
    );
  }
});

export default InsuranceSelection;
