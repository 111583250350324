import { ModalElementContext } from "src/context/modal-element-context";
import classes from "./MobileInfoSnackbar.module.css";
import Button from "src/components/UI/Button/Button";
import Snackbar from "src/components/shared/modal/Snackbar/Snackbar";
import { useLocaleContext } from "src/context/locale-context";

function MobileInfoSnackbar({ message, isClosing, onClose }) {
  const { stringRes } = useLocaleContext();
  return (
    <ModalElementContext.Provider value={{ onClose: onClose }}>
      <Snackbar isClosing={isClosing}>
        <div className={classes.snackbar}>
          <div className={classes.message}>{message}</div>
          <Button name={stringRes["res.button.close"]} cornerSm wide onClick={onClose} />
        </div>
      </Snackbar>
    </ModalElementContext.Provider>
  );
}

export default MobileInfoSnackbar;
