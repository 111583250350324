import classes from "../../ResultsList.module.css";
import Button from "src/components/UI/Button/Button";
import { FlightChainInfo } from "../FlightChainInfo/FlightChainInfo";
import HeartIcon from "src/components/UI/Icon/HeartIcon";
import { SortTagItem } from "../../ResultsList";
import ShareIcon from "src/components/UI/Icon/ShareIcon";
import { useLocaleContext } from "src/context/locale-context";
import {
  getFirstFlightChain,
  getLastFlightChain,
  getOldPricePerPerson,
  getPricePerPerson,
  getServiceFee,
  getTotalPricePerPerson,
  hasServiceFee,
  isDepartureArrivalDifferent,
  showSearchSeatsLeft,
} from "src/utils/results-utils";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { parseIntFromString } from "src/utils/utils";
import BaggageIndicatorsList from "./BaggageIndicatorsList";
import useModalState from "src/hooks/useModalState";
import { MobileBaggageDetails } from "../../../BaggageDetails/BaggageDetails";

export function MobileResultItem({
  item,
  isOneway,
  onSelectResult,
  onFavoriteHandler,
  onShareHandler,
  isCheapest,
  isBest,
}) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const searchParams = useSearchParams();
  const firstChain = getFirstFlightChain(item);
  const lastChain = getLastFlightChain(item);
  const priceWithoutFee = getPricePerPerson(item);
  const oldPriceWithoutFee = getOldPricePerPerson(item);

  const isSorting = useSelector((state) => state.filterSort.isSorting);

  const adultNum = parseIntFromString(searchParams[0].get("p_adult"), 1);
  const childNum = parseIntFromString(searchParams[0].get("p_child"), 0);
  const infantNum = parseIntFromString(searchParams[0].get("p_infant"), 0);
  const passengerNum = adultNum + childNum + infantNum;

  const [baggageInfoState, openBaggage, closeBaggage] = useModalState();

  const isDiffLocation = isDepartureArrivalDifferent(firstChain, lastChain);

  let classnames = [classes.resultsItem, classes.loaded, classes.mobile];
  if (isCheapest && isBest) {
    classnames.push(classes.outlined);
  }
  if (isSorting) {
    classnames.push(classes.isSorting);
  }

  return (
    <div className={classnames.join(" ")}>
      <div className={`${classes.top} ${isOneway ? classes.onetrip : classes.multitrip}`}>
        <div
          className={`${classes.sortTags} ${isBest || isCheapest ? "" : classes.none}`}>
          {isBest && <SortTagItem name={stringRes["res.result.tag.best"]} isBest />}
          {isCheapest && (
            <SortTagItem name={stringRes["res.result.tag.cheapest"]} isCheapest />
          )}
        </div>
        <FlightChainInfo
          isDeparture
          isOneway={isOneway}
          flightChain={firstChain}
          diffLocation={!isOneway && isDiffLocation}
          className={classes.srcFlightInfo}
        />
        {!isOneway && (
          <FlightChainInfo
            diffLocation={!isOneway && isDiffLocation}
            flightChain={lastChain}
            className={classes.destFlightInfo}
          />
        )}
      </div>

      <div className={classes.middle}>
        <div onClick={() => openBaggage()} className={classes.baggageIndicators}>
          {baggageInfoState.doShow && (
            <MobileBaggageDetails
              onClose={() => closeBaggage()}
              state={baggageInfoState}
              baggage={item.baggage}
            />
          )}
          <BaggageIndicatorsList flightPackage={item} />
        </div>
        {!!oldPriceWithoutFee && hasServiceFee(item) && (
          <div className={classes.priceBreakdown}>
            <span>{`${stringRes["res.result.fee.service"]} ${getServiceFee(
              item
            )} ${currencySymbol}`}</span>
            <span>{`${stringRes["res.result.price.final"]} ${getTotalPricePerPerson(
              item
            )} ${currencySymbol}`}</span>
          </div>
        )}
      </div>

      <div className={classes.bottom}>
        <div className={classes.extraButtons}>
          <button className={classes.heart} onClick={onFavoriteHandler}>
            <HeartIcon />
          </button>
          <button className={classes.share} onClick={onShareHandler}>
            <ShareIcon />
          </button>
        </div>
        <div className={classes.seatsPrice}>
          <div>{`${priceWithoutFee} ${currencySymbol} `}</div>
          {showSearchSeatsLeft(item.seats, passengerNum) && (
            <div className={classes.seatsLeft}>
              <b>{`${item.seats} ${stringRes["res.result.seatsLeft"].split(" ")[0]} `}</b>
              {`${stringRes["res.result.seatsLeft"].split(" ").slice(1).join(" ")}`}
            </div>
          )}
        </div>
        <div className={classes.selectButton}>
          <Button
            onClick={onSelectResult}
            ternary
            name={stringRes["res.result.button.select"]}
          />
        </div>
      </div>
    </div>
  );
}
