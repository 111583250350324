import classes from "./GetFreshPrices.module.css";
import Button from "src/components/UI/Button/Button";
import { Fragment, useState } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "src/components/shared/modal/Modal/Modal";
import DesktopModal from "src/components/shared/modal/Modal/DesktopModal";
import illustration from "src/assets/img/illustrations/illustr5.png";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";

function GetFreshPricesModal({ onRefresh, state }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onRefreshHandler = () => {
    setIsButtonDisabled(true);
    onRefresh();
  };

  const innerContent = (
    <Fragment>
      <div className={classes.image}>
        <img src={illustration} alt="travellers with a suitcase" />
      </div>
      <h1>{stringRes["res.modal.refresh.title"]}</h1>
      <p>{`${stringRes["res.modal.refresh.description"]}.`}</p>
      <Button
        disabled={isButtonDisabled}
        primary
        name={isButtonDisabled ? <DotPulseLoader /> : stringRes["res.modal.refresh.button"]}
        onClick={onRefreshHandler}
      />
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: () => {} }}>
        <Modal isClosing={state.isClosing}>
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal isClosing={state.isClosing} onBackdropClick={() => {}}>
        <div className={`${classes.desktop} ${classes.container}`}>{innerContent}</div>
      </DesktopModal>
    );
  }
}

export default GetFreshPricesModal;
