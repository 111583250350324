import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BOOKING_STEP } from "src/constants";
import BookingStepPassengers from "./booking/BookingStepPassengers";
import BookingStepMoneyBack from "./booking/BookingStepMoneyBack";
import BookingStepInsurance from "./booking/BookingStepInsurance";
import BookingStepSeats from "./booking/BookingStepSeats";
import BookingStepExtras from "./booking/BookingStepExtras";
import BookingStepSupport from "./booking/BookingStepSupport";
import BookingStepCheckout from "./booking/BookingStepCheckout";
import { customLog } from "src/utils/utils";

const PAGES_MAP = {
  [BOOKING_STEP.fareTypeSelection.path]: <div>fare</div>,
  [BOOKING_STEP.passengerDetailsForm.path]: BookingStepPassengers,
  [BOOKING_STEP.guaranteeSelection.path]: BookingStepMoneyBack,
  [BOOKING_STEP.insuranceSelection.path]: BookingStepInsurance,
  [BOOKING_STEP.extraServicesSelection.path]: BookingStepExtras,
  [BOOKING_STEP.seatSelection.path]: BookingStepSeats,
  [BOOKING_STEP.supportSelection.path]: BookingStepSupport,
  [BOOKING_STEP.checkoutPage.path]: BookingStepCheckout,
};

export const BookingFlowPage = () => {
  const { bookingStep } = useParams();
  const navigate = useNavigate();
  customLog(bookingStep);

  const BookingStepPage = PAGES_MAP[bookingStep];
  if (!BookingStepPage) {
    navigate("/notfound");
  }

  const routeDef = Object.values(BOOKING_STEP).find((v) => v.path === bookingStep);
  return <BookingStepPage pageId={routeDef.id} />;
};
