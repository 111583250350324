import classes from "./SeatOption.module.css";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { SEAT_ICON_MAP } from "./SeatsSelection";
import { useDeviceContext } from 'src/context/device-context';
import { useLocaleContext } from 'src/context/locale-context';

export function SeatOption({ checked, name, serviceId, price, onSelect }) {
  const { isMobile } = useDeviceContext();
  const { stringRes, currencySymbol } = useLocaleContext();
  const priceText =
    price > 0
      ? `${price} ${currencySymbol}`
      : stringRes["booking.seats.option.price.free"];

  return (
    <RadioButton
      checked={checked}
      id={"seating-type-" + serviceId}
      name="seating-selection"
      className={`${classes.option} ${isMobile ? classes.mobile : classes.desktop} ${
        checked ? classes.active : ""
      }`}
      onChange={(isChecked) => onSelect(isChecked, serviceId)}
    >
      <div className={classes.optionTitle}>
        <span>{SEAT_ICON_MAP[serviceId]}</span>
        <span>{name}</span>
        <span className={classes.price}>{priceText}</span>
      </div>
    </RadioButton>
  );
}
