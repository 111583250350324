import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import classes from "../PaymentOptions.module.css";
import { useLocaleContext } from "src/context/locale-context";

function OfflinePayment() {
  const { stringRes } = useLocaleContext();

  return (
    <div className={classes.cash}>
      <ul>
        <li>{stringRes["payment.options.print.invoice"]}</li>
        <li>{stringRes["payment.offline.visit.office.description"]}</li>
      </ul>
      <FormInfoElement>
        <p>{`${stringRes["prepayment.invoice.info"]} ${stringRes["payment.offline.form.info.description"]}`}</p>
      </FormInfoElement>
    </div>
  );
}

export default OfflinePayment;
