import { json, redirect } from "react-router-dom";
import { ERROR_STATUS_MESSAGE } from "src/assets/lang/errorMessages";
import { TRIP_TYPE } from "src/constants";
import { airportsApi, resultsApi } from "src/services/api";
import store from "src/store";
import { filterSortActions } from "src/store/filter-sort";
import { searchDataModel } from "src/store/models";
import { searchDataActions } from "src/store/search";
import { isTodayOrLater } from "src/utils/date-utils";
import { parseQueryParams } from "src/utils/query-utils";
import { getPricePerPerson } from "src/utils/results-utils";
import {
  getSelectedLocale,
  isDirectFlightsRequired,
  isSearchRefreshRequired,
  unmarkDirectFlightsRequired,
  unmarkSearchRefreshRequired,
} from "src/utils/storage-utils";
import {
  customLog,
  validateCabinClass,
  validatePassengerCount,
  validateTripType,
  validateTrips,
} from "src/utils/utils";

export function reconcileDuplicates(currentResult, nextResult) {
  let newMergeKeys = [];
  let mergedResults = [];
  if (currentResult.length === 0) {
    newMergeKeys = nextResult.map((flightPackage) => flightPackage.mergeKey);
    mergedResults = nextResult;
  } else {
    mergedResults = currentResult.slice();
    for (let i = 0; i < nextResult.length; i++) {
      const newItem = nextResult[i];
      const matchIdx = currentResult.findIndex(
        (oldItem) => oldItem.mergeKey === newItem.mergeKey
      );
      if (
        matchIdx !== -1 &&
        getPricePerPerson(newItem) < getPricePerPerson(currentResult[matchIdx])
      ) {
        mergedResults.splice(matchIdx, 1, newItem);
        newMergeKeys.push(newItem.mergeKey);
      } else if (matchIdx === -1) {
        mergedResults.push(newItem);
        newMergeKeys.push(newItem.mergeKey);
      }
    }
  }

  return { mergedResults, newMergeKeys };
}

async function getFlightResultKey(query) {
  let errResponse = { error: true };
  for (let retryCount = 0; retryCount < 5; retryCount++) {
    const response = await resultsApi.getSearchResultsKey(query).catch((err) => {
      console.log(`Search results correlation ID request error occured: ${retryCount}:`, err);
      errResponse.message = "Something went wrong";
      if (err.response) {
        errResponse.status = err.response.status;
        errResponse.data = err.response.data;
        errResponse.message = err.message;
      }
      console.log(errResponse);
      return errResponse;
    });

    if (!response.error) {
      console.log(response.data);
      return { key: response.data };
    }
  }
  return errResponse;
}

export async function loader({ request }) {
  store.dispatch(filterSortActions.setIsReceivingData(true));
  customLog(request);
  const url = new URL(request.url);
  if (!url.search) {
    return redirect("/");
  }

  // parse params from url
  const searchParams = url.searchParams;
  const dataFromUrl = parseQueryParams(searchParams);

  // validate fields
  if (!validateCabinClass(dataFromUrl.cabinClass)) {
    dataFromUrl.cabinClass = "";
  }
  if (!validatePassengerCount(dataFromUrl.passengers)) {
    dataFromUrl.passengers = { ...searchDataModel.passengers };
  }

  let hasReturnDate = dataFromUrl.trips[0]?.endDate;
  if (
    (dataFromUrl.tripType === TRIP_TYPE.roundtrip && !hasReturnDate) ||
    !validateTrips(dataFromUrl.trips) ||
    !validateTripType(dataFromUrl.tripType) ||
    !isTodayOrLater(dataFromUrl.trips[0].startDate) ||
    (hasReturnDate && !isTodayOrLater(dataFromUrl.trips[0].endDate))
  ) {
    return redirect("/");
  }

  const currentLang = getSelectedLocale().split("_")[0].toUpperCase();
  // location validate city & iata code contents
  for (let i = 0; i < dataFromUrl.trips.length; i++) {
    const trip = dataFromUrl.trips[i];
    let cityNameSrc, cityNameDest;
    let cityIataSrc, cityIataDest;

    try {
      // get src city name
      let srcQ = trip.srcLocation.isCity
        ? `city=${trip.srcLocation.cityIata}`
        : `airport=${trip.srcLocation.iata}`;
      const resSrc = await airportsApi.getAirports(srcQ, currentLang).catch((err) => {
        customLog(err);
        throw json({
          data: err,
          statusText: ERROR_STATUS_MESSAGE[currentLang.toLowerCase()].service,
        });
      });

      if (resSrc.data && resSrc.data.length > 0) {
        resSrc.data[0].city = Object.fromEntries(
          resSrc.data[0].city.flatMap((c) => Object.entries(c))
        );
        cityNameSrc = resSrc.data[0].city["EN"];
        cityIataSrc = resSrc.data[0].cityIata;
      }

      // get dest city name
      let destQ = trip.destLocation.isCity
        ? `city=${trip.destLocation.cityIata}`
        : `airport=${trip.destLocation.iata}`;
      const resDest = await airportsApi.getAirports(destQ, currentLang).catch((err) => {
        customLog(err);
        throw json({
          data: err,
          statusText: ERROR_STATUS_MESSAGE[currentLang.toLowerCase()].service,
        });
      });

      if (resDest.data && resDest.data.length > 0) {
        resDest.data[0].city = Object.fromEntries(
          resDest.data[0].city.flatMap((c) => Object.entries(c))
        );
        cityNameDest = resDest.data[0].city["EN"];
        cityIataDest = resDest.data[0].cityIata;
      }

      dataFromUrl.trips[i].destLocation.city = cityNameDest;
      dataFromUrl.trips[i].destLocation.cityIata = cityIataDest;
      dataFromUrl.trips[i].destLocation.names = resDest.data[0].city;
      dataFromUrl.trips[i].srcLocation.city = cityNameSrc;
      dataFromUrl.trips[i].srcLocation.cityIata = cityIataSrc;
      dataFromUrl.trips[i].srcLocation.names = resSrc.data[0].city;
    } catch (error) {
      customLog(error);
      return redirect("/");
    }
  }

  // fill redux store with this data if valid
  store.dispatch(searchDataActions.copyState({ data: dataFromUrl }));
  store.dispatch(searchDataActions.fillUserInputPassengerOptions());
  store.dispatch(searchDataActions.fillUserInputTrips(currentLang));
  store.dispatch(filterSortActions.updatePinnedArrivalSegment([]));
  store.dispatch(filterSortActions.updatePinnedDepartureSegment([]));

  customLog(url.search);
  customLog(url.pathname);
  // Remove _ct parameters
  for (let i = 0; i < 6; i++) {
    let suffix = i === 0 ? "" : i + 1;
    url.searchParams.delete(`s_ct${suffix}`);
    url.searchParams.delete(`d_ct${suffix}`);
  }

  const isRefresh = isSearchRefreshRequired();
  const isDirect = isDirectFlightsRequired();
  const query =
    url.search + (isRefresh ? "&refresh=true" : "") + (isDirect ? "&direct=true" : "");
  unmarkSearchRefreshRequired();
  unmarkDirectFlightsRequired();
  return {
    requestData: await getFlightResultKey(query),
  };
}
