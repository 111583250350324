import { useEffect, useState } from "react";
import classes from "../PassengerForm.module.css";
import {
  FormInputDOB,
  FormInputDropdown,
  FormInputLabel,
  FormInputText,
  FormCountryInputDropdown,
} from "src/components/shared/input/FormInput/FormInput";
import { isValidDOB } from "src/utils/date-utils";
import { useLocaleContext } from "src/context/locale-context";
import { LATIN_LANG_PATTERN_NAME } from "src/constants/validation";
import { AGE_GROUP_MIN } from "src/constants/validation";
import { AGE_GROUP_MAX } from "src/constants/validation";
import { BOOKING_STEP } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectValidationAttempts, selectisFormIncomplete } from "src/store/booking";
import { updatePassenger } from "src/store/actions";
import { removeDiacritics } from "src/utils/alphabet-utils";
import { countries, getCountryCode } from "countries-list";
import { sortDictionaryAtoZ } from "src/utils/utils";
import { getDefaultCountryCode } from "src/utils/utils";

const FORM_PLACEHOLDER = {
  lv_LV: { name: "Janis", surname: "Priede" },
  lt_LT: { name: "Jonas", surname: "Lukas" },
  et_EE: { name: "Joonas", surname: "Jaak" },
  en_GB: { name: "John", surname: "Lucas" },
  ru_RU: { name: "Ivan", surname: "Ivanov" },
};
const FIELD_INIT_STATE = { isValid: false, isActive: false, hasBeenTouched: false };
const NATIONALITIES = {};

export function PassengerDataForm({ paxData, passengerId, ageGroup }) {
  const { stringRes, currentLocale } = useLocaleContext();
  const dispatch = useDispatch();
  const MAX_AGE = AGE_GROUP_MAX[ageGroup];
  const MIN_AGE = AGE_GROUP_MIN[ageGroup];

  Object.values(countries).forEach(
    (country) => (NATIONALITIES[getCountryCode(country.name)] = country.name)
  );

  const defaultCountryCode = getDefaultCountryCode();
  const firstGroup = {};
  const otherGroup = {};

  Object.entries(NATIONALITIES).forEach((entry) => {
    if (entry[0] === defaultCountryCode) {
      firstGroup[entry[0]] = entry[1];
    } else {
      otherGroup[entry[0]] = entry[1];
    }
  });

  const GENDER = {
    female: stringRes["booking.form.passenger.gender.female"],
    male: stringRes["booking.form.passenger.gender.male"],
  };

  const [values, setValues] = useState({
    name: paxData?.name,
    surname: paxData?.surname,
    nationality: paxData?.nationality,
    gender: paxData?.gender,
    dob: paxData?.dob, //in YYYY-MM-DD format
  });

  const [state, setState] = useState(() => {
    const newState = {};
    ["name", "surname", "nationality", "gender", "dob"].forEach((key) => {
      newState[key] = {
        ...FIELD_INIT_STATE,
        isValid: !!values[key] && (key !== "dob" || isValidDOB(values.dob, MAX_AGE, MIN_AGE)),
      };
    });
    return newState;
  });

  const validationAttempts = useSelector((state) =>
    selectValidationAttempts(state, BOOKING_STEP.passengerDetailsForm.id)
  );
  const isFormIncomplete = useSelector((state) =>
    selectisFormIncomplete(state, BOOKING_STEP.passengerDetailsForm.id)
  );

  useEffect(() => {
    if (isFormIncomplete) {
      setState((prev) => ({
        ...prev,
        name: { ...prev["name"], hasBeenTouched: true },
        surname: { ...prev["surname"], hasBeenTouched: true },
        nationality: { ...prev["nationality"], hasBeenTouched: true },
        gender: { ...prev["gender"], hasBeenTouched: true },
        dob: { ...prev["dob"], hasBeenTouched: true },
      }));
    }
  }, [isFormIncomplete, validationAttempts]);

  const onFocusField = (field) => {
    setState((prev) => ({
      ...prev,
      [field]: { ...prev[field], isActive: true, hasBeenTouched: true },
    }));
  };

  const onBlurField = (field) => {
    setState((prev) => ({ ...prev, [field]: { ...prev[field], isActive: false } }));
    if (values[field] !== paxData[field]) {
      dispatch(updatePassenger(passengerId, ageGroup, values));
    }
  };

  const getTransformedValue = (value) => {
    let transformedValue = value;
    // remove diacritics and replace with latin equivalent
    if (value) {
      transformedValue = removeDiacritics(transformedValue);
    }
    return transformedValue.toUpperCase();
  };

  const onNameChange = (value) => {
    const trValue = getTransformedValue(value);
    setValues((prev) => ({
      ...prev,
      name: LATIN_LANG_PATTERN_NAME.test(trValue) || !trValue ? trValue : prev.name,
    }));
    let isValidName = !!trValue;
    setState((prev) => ({ ...prev, name: { ...prev["name"], isValid: isValidName } }));
  };

  const onSurnameChange = (value) => {
    const trValue = getTransformedValue(value);
    setValues((prev) => ({
      ...prev,
      surname: LATIN_LANG_PATTERN_NAME.test(trValue) || !trValue ? trValue : prev.surname,
    }));
    let isValidSurname = !!trValue;
    setState((prev) => ({
      ...prev,
      surname: { ...prev["surname"], isValid: isValidSurname },
    }));
  };

  const onNationalityChange = (value) => {
    setValues((prev) => ({ ...prev, nationality: value }));
    setState((prev) => ({
      ...prev,
      nationality: { ...prev["nationality"], isValid: true },
    }));
  };

  const onGenderChange = (value) => {
    setValues((prev) => ({ ...prev, gender: value }));
    setState((prev) => ({
      ...prev,
      gender: { ...prev["gender"], isValid: true },
    }));
  };

  const onDOBChange = (dateStr) => {
    setValues((prev) => ({ ...prev, dob: dateStr }));
    let isDateValueValid = !!dateStr && isValidDOB(dateStr, MAX_AGE, MIN_AGE);
    setState((prev) => ({
      ...prev,
      dob: { ...prev["dob"], isValid: isDateValueValid },
    }));
  };

  return (
    <form id={"pax-form-" + passengerId} className={classes.form}>
      <div className={classes.row}>
        <FormInputLabel text={stringRes["booking.form.passenger.name"]}>
          <FormInputText
            onChange={(e) => onNameChange(e.target.value)}
            placeholder={`${stringRes["input.form.field.eg"]} ${FORM_PLACEHOLDER[currentLocale].name}`}
            value={values.name}
            isValid={!state.name.hasBeenTouched || state.name.isValid}
            isActive={state.name.isActive}
            onFocus={() => onFocusField("name")}
            onBlur={() => onBlurField("name")}
          />
        </FormInputLabel>

        <FormInputLabel text={stringRes["booking.form.passenger.surname"]}>
          <FormInputText
            onChange={(e) => onSurnameChange(e.target.value)}
            value={values.surname}
            placeholder={`${stringRes["input.form.field.eg"]} ${FORM_PLACEHOLDER[currentLocale].surname}`}
            isValid={!state.surname.hasBeenTouched || state.surname.isValid}
            isActive={state.surname.isActive}
            onFocus={() => onFocusField("surname")}
            onBlur={() => onBlurField("surname")}
          />
        </FormInputLabel>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <FormInputLabel text={stringRes["booking.form.passenger.nationality"]}>
            <FormCountryInputDropdown
              value={values.nationality}
              optionGroups={[firstGroup, sortDictionaryAtoZ(otherGroup)]}
              isValid={!state.nationality.hasBeenTouched || state.nationality.isValid}
              isActive={state.nationality.isActive}
              onSelect={onNationalityChange}
              onFocus={() => onFocusField("nationality")}
              onBlur={() => onBlurField("nationality")}
              placeholder={stringRes["booking.form.passenger.field.select"]}
            />
          </FormInputLabel>

          <FormInputLabel text={stringRes["booking.form.passenger.gender"]}>
            <FormInputDropdown
              onSelect={onGenderChange}
              options={GENDER}
              value={values.gender}
              isValid={!state.gender.hasBeenTouched || state.gender.isValid}
              isActive={state.gender.isActive}
              onFocus={() => onFocusField("gender")}
              onBlur={() => onBlurField("gender")}
              placeholder={stringRes["booking.form.passenger.field.select"]}
            />
          </FormInputLabel>
        </div>

        <FormInputLabel text={stringRes["booking.form.passenger.dob"]}>
          <FormInputDOB
            isValid={!state.dob.hasBeenTouched || state.dob.isValid}
            isActive={state.dob.isActive}
            onChange={onDOBChange}
            ageGroup={ageGroup}
            value={values.dob}
            onFocus={() => onFocusField("dob")}
            onBlur={() => onBlurField("dob")}
          />
        </FormInputLabel>
      </div>
    </form>
  );
}
