import Card from "src/components/UI/Card/Card";
import { Link } from "react-router-dom";
import classes from "./UserMenu.module.css";
import { useRef, useCallback } from "react";
import useClickAway from "src/hooks/useClickAway";
import { useAuth } from "src/context/auth-context";
import { useLocaleContext } from "src/context/locale-context";

const cardOpts = {
  paddingTop: "1.875rem",
  paddingBottom: "1.875rem",
  position: "absolute",
  top: "3.125rem",
  zIndex: "850",
};

function UserMenu(props) {
  const { user } = useAuth();
  const menuRef = useRef();
  const { stringRes } = useLocaleContext();

  const onCloseHandler = useCallback(() => {
    props.onClose();
  }, [props]);

  useClickAway(menuRef, onCloseHandler);

  const menuItems = [
    { name: stringRes["menu.user.trips"], to: "/dashboard/trips" },
    { name: stringRes["menu.user.passengers"], to: "/dashboard/passengers" },
    { name: stringRes["menu.user.invoices"], to: "/dashboard/invoices" },
    { name: stringRes["menu.user.priceAlerts"], to: "/dashboard/alerts" },
    { name: stringRes["menu.user.referral"], to: "/dashboard/referral" },
    { name: stringRes["menu.user.preferences"], to: "/dashboard/preferences" },
  ];

  return (
    <Card ref={menuRef} opts={cardOpts} shadowLg>
      <ul className={`${classes.container} ${classes.menu}`}>
        {menuItems.map((item) => (
          <li key={item.name}>
            <Link to={item.to}>{item.name}</Link>
          </li>
        ))}
        <li className={classes.divider} />
        <li>
          <button className={classes.signout} onClick={props.onLogout}>
            {stringRes["menu.user.signout"]}
          </button>
        </li>
        <li
          className={`${classes.container} ${classes.user}`}
          style={{ order: menuItems.length }}>
          <span className={classes.miniIcon}>
            <img
              style={{ borderRadius: "100%", maxHeight: 17 }}
              alt="user"
              src={user.photoURL}
            />
          </span>
          <span className={classes.name} title={user.email}>
            {user.email}
          </span>
        </li>
      </ul>
    </Card>
  );
}

export default UserMenu;
