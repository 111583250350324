import airhelpSm from "src/assets/img/booking/airhelp_extras.png";
import checkinSm from "src/assets/img/booking/checkin_extras.png";
import smsSm from "src/assets/img/booking/sms_extras.png";
import AddButtonOval from "src/components/UI/AddButtonOval/AddButtonOval";
import Card from "src/components/UI/Card/Card";
import { CATALOGUE_IDS } from "src/constants/services";
import { useLocaleContext } from "src/context/locale-context";
import classes from "../ExtraServicesSelection.module.css";
import { ServiceFeatures } from "./ExtraServiceOptionDesktop";

export const EXTRAS_MOB_IMGS = {
  [CATALOGUE_IDS["ONLINE_CHECK-IN"]]: checkinSm,
  [CATALOGUE_IDS.SMS_MOBILE_ROUTE]: smsSm,
  [CATALOGUE_IDS.AIRHELP_PLUS]: airhelpSm,
};

function ServiceDescription({ description }) {
  return (
    <div className={classes.description}>
      <p>{description}</p>
    </div>
  );
}

function ServiceFooterMobile({ isSelected, price, onSelect, priceBasis }) {
  const { currencySymbol } = useLocaleContext();

  return (
    <div className={classes.footer}>
      <div className={classes.hr}></div>
      <div className={classes.bottom}>
        <span className={classes.price}>
          <span>{`${price} ${currencySymbol}`}</span>
          <span>{priceBasis}</span>
        </span>
        <AddButtonOval onClick={onSelect} isAdded={isSelected} />
      </div>
    </div>
  );
}

function ServiceTitleMobile({ name, serviceId }) {
  return (
    <div className={classes.header}>
      <span className={classes.title}>{name}</span>
      <img src={EXTRAS_MOB_IMGS[serviceId]} alt="" className={classes.icon} />
    </div>
  );
}

export function ExtraServiceOptionMobile(props) {
  const { stringRes } = useLocaleContext();

  return (
    <Card className={classes.serviceOption} cornerSm>
      <ServiceTitleMobile {...props} />
      {props.disableListView ? (
        <>
          <ServiceDescription id={props.id} description={props.description} />
          <button
            value={props.serviceId}
            onClick={props.onShowDetails}
            className={classes.info}>
            {stringRes["link.text.showDetails"]}
          </button>
        </>
      ) : (
        <>
          <ServiceFeatures id={props.id} features={props.features} />
          <button
            value={props.serviceId}
            onClick={props.onShowDetails}
            className={classes.info}>
            {stringRes["link.text.showDetails"]}
          </button>
        </>
      )}
      <ServiceFooterMobile {...props} />
    </Card>
  );
}
