import classes from "./PriceFilter.module.css";
import { useDispatch, useSelector } from "react-redux";
import { filterSortActions, selectFilters } from "src/store/filter-sort";
import Button from "src/components/UI/Button/Button";
import { Fragment, useContext, useEffect, useState } from "react";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import { ModalElementContext } from "src/context/modal-element-context";
import Slider from "src/components/UI/Slider/Slider";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";

export function PriceFilter() {
  const dispatch = useDispatch();
  const { stringRes, currencySymbol } = useLocaleContext();
  const { onClose } = useContext(ModalElementContext);
  const { isMobile } = useDeviceContext();
  const filterData = useSelector((state) => selectFilters(state, "price"));

  const min = filterData.min;
  const max = filterData.max;

  const [state, setState] = useState(filterData.value);
  const [isSliderReleased, setSliderReleased] = useState(false);

  useEffect(() => {
    if (!filterData.isOn) {
      setState(filterData.value);
    }
  }, [filterData.isOn]);

  useEffect(() => {
    if (!isMobile && isSliderReleased) {
      dispatch(filterSortActions.applyFilter({ type: "price", value: state }));
    }
  }, [state, isSliderReleased]);

  const onSliderRangeChange = (values) => {
    if (values[0] === min && values[1] === max) {
      setState("");
    } else {
      setState(values);
    }
  };

  const onConfirmValue = () => {
    dispatch(filterSortActions.applyFilter({ type: "price", value: state }));
    onClose();
  };

  return (
    <Fragment>
      {isMobile && <CloseWindowButton onClick={onClose} />}
      <div
        className={`${classes.container} ${classes.price} ${
          isMobile ? classes.mobile : classes.desktop
        }`}
      >
        <h1>{stringRes["res.filter.title.price"]}</h1>
        <div className={classes.slider}>
          <p className={classes.range}>
            {state
              ? `${state[0]}-${state[1]} ${currencySymbol}`
              : stringRes["res.filter.price.norange"]}
          </p>
          <Slider
            current={state ? state : [min, max]}
            min={min}
            max={max}
            step={1}
            onAfterChange={() => setSliderReleased(true)}
            onBeforeChange={() => setSliderReleased(false)}
            onChange={onSliderRangeChange}
            minLabel={`${min} ${currencySymbol}`}
            maxLabel={`${max} ${currencySymbol}`}
            isRange
          />
        </div>
        {isMobile && (
          <Fragment>
            <Button
              name={stringRes["res.filter.button.cancel"]}
              onClick={onClose}
              secondary
              cornerSm
              fontclass={classes.fontclass}
            />
            <Button
              name={stringRes["res.filter.button.done"]}
              onClick={onConfirmValue}
              primary
              cornerSm
              fontclass={classes.fontclass}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
