import { nanoid } from "@reduxjs/toolkit";
import { CABIN_CLASS_LETTER_MAP, PASSENGER, TRIP_TYPE } from "src/constants";
import { addDaysToDate, getDateDifferenceInDays, reformatDate } from "./date-utils";
import { getAllFlightChains, isRoundTrip } from "./results-utils";
import { customLog, parseIntFromString } from "./utils";

export function buildBookingUrl(
  city,
  startDate,
  endDate,
  adults,
  children,
  infants,
  locale,
  airportCode
) {
  const checkinDate = startDate;
  const durationDays = getDateDifferenceInDays(startDate, endDate);
  const checkoutDate = durationDays > 0 ? endDate : addDaysToDate(startDate, 3);

  // Encode the city and airport code for the URL if provided
  const encodedCity = encodeURIComponent(city);
  const encodedAirportCode = airportCode
    ? `+${encodeURIComponent(airportCode)}&dest_type=airport`
    : "";
  // Calculate group_children as the sum of children and infants
  const groupChildren = children + infants;

  // Build the URL with the specified parameters, including the locale
  let bookingUrl =
    `https://www.booking.com/searchresults.html?` +
    `ss=${encodedCity}${encodedAirportCode}&` +
    `checkin=${checkinDate}&` +
    `checkout=${checkoutDate}&` +
    `group_adults=${adults}&` +
    `group_children=${groupChildren}&` +
    `lang=${locale?.replace("_", "-")}`;

  // Add age parameters for children (age=2) and infants (age=0)
  for (let i = 0; i < children; i++) {
    bookingUrl += `&age=2`;
  }

  for (let i = 0; i < infants; i++) {
    bookingUrl += `&age=0`;
  }

  return bookingUrl;
}

export const getTripsFromQueryParams = (hasReturn, params) => {
  const trips = [];
  for (let i = 0; i < 6; i++) {
    let suffix = i === 0 ? "" : i + 1;

    // Check if all required parameters exist in params
    if (
      !params.has(`src${suffix}`) ||
      !params.has(`s_iata${suffix}`) ||
      !params.has(`s_ct${suffix}`) ||
      !params.has(`dest${suffix}`) ||
      !params.has(`d_iata${suffix}`) ||
      !params.has(`d_ct${suffix}`) ||
      !params.has(`start${suffix}`) ||
      (hasReturn && !params.has(`end${suffix}`))
    ) {
      return trips;
    }

    const srcCity = decodeURIComponent(params.get(`src${suffix}`)) || "";
    const srcIata = params.get(`s_iata${suffix}`) || "";
    const srcCityIata = params.get(`s_ct${suffix}`) || "";
    const srcIsCity = srcCityIata === srcIata;

    const destCity = decodeURIComponent(params.get(`dest${suffix}`)) || "";
    const destIata = params.get(`d_iata${suffix}`) || "";
    const destCityIata = params.get(`d_ct${suffix}`) || "";
    const destIsCity = destIata === destCityIata;

    const startDate = params.get(`start${suffix}`) || "";
    const endDate = params.get(`end${suffix}`) || "";

    const trip = {
      id: nanoid(),
      srcLocation: {
        city: srcCity,
        iata: srcIata.toUpperCase(),
        cityIata: srcCityIata.toUpperCase(),
        isCity: srcIsCity,
      },
      destLocation: {
        city: destCity,
        iata: destIata.toUpperCase(),
        cityIata: destCityIata.toUpperCase(),
        isCity: destIsCity,
      },
      startDate: startDate,
      endDate: endDate,
    };

    trips.push(trip);
  }
  return trips;
};

export const parseQueryParams = (params) => {
  const tripType = params.get("type");
  const parsedObj = {
    tripType: tripType || "",
    cabinClass: params.get("cabin") || "",
    passengers: {
      adult: parseIntFromString(params.get("p_adult"), 1),
      child: parseIntFromString(params.get("p_child"), 0),
      infant: parseIntFromString(params.get("p_infant"), 0),
    },
    trips: getTripsFromQueryParams(tripType === TRIP_TYPE.roundtrip, params),
  };

  return parsedObj;
};

export const buildSearchQueryPerTrip = (isReturn, trip, num = "") => {
  const params = [];
  params.push(`src${num}=${encodeURIComponent(trip.srcLocation.city)}`);
  params.push(`s_iata${num}=${trip.srcLocation.iata}`);
  params.push(`s_ct${num}=${trip.srcLocation.cityIata}`);

  params.push(`dest${num}=${encodeURIComponent(trip.destLocation.city)}`);
  params.push(`d_iata${num}=${trip.destLocation.iata}`);
  params.push(`d_ct${num}=${trip.destLocation.cityIata}`);

  params.push(`start${num}=` + encodeURIComponent(trip.startDate));
  if (isReturn) {
    params.push(`end${num}=` + encodeURIComponent(trip.endDate));
  }
  return params;
};

export const buildSearchQuery = (searchData) => {
  let params = [];
  const tripParams = buildSearchQueryPerTrip(
    searchData.tripType === TRIP_TYPE.roundtrip,
    searchData.trips[0]
  );
  params = params.concat(tripParams);

  if (searchData.tripType === TRIP_TYPE.multicity) {
    for (let i = 1; i < searchData.trips.length; i++) {
      const tripParams = buildSearchQueryPerTrip(false, searchData.trips[i], i + 1);
      params = params.concat(tripParams);
    }
  }

  params.push(`p_adult=${searchData.passengers.adult}`);
  if (searchData.passengers.child) {
    params.push(`p_child=${searchData.passengers.child}`);
  }
  if (searchData.passengers.infant) {
    params.push(`p_infant=${searchData.passengers.infant}`);
  }

  params.push(`type=${searchData.tripType}`);
  params.push(`cabin=${searchData.cabinClass || "all"}`);

  customLog(params.join("&").toLowerCase());
  return params.join("&").toLowerCase();
};

export const buildSearchModelFromTravelPackage = (travelPackage, passengers) => {
  const isRoundTripType = isRoundTrip(travelPackage);
  const chains = getAllFlightChains(travelPackage);
  const _first = chains[0];
  const _last = chains.at(-1);
  const paxGroups = Object.groupBy(passengers, ({ ageGroup }) => ageGroup);

  const searchData = { passengers: {} };
  searchData.tripType = isRoundTripType ? TRIP_TYPE.roundtrip : TRIP_TYPE.oneway;
  searchData.cabinClass = CABIN_CLASS_LETTER_MAP[_first[0].class.name] || "all";
  searchData.passengers.adult = paxGroups[PASSENGER.adult].length;
  searchData.passengers.child = paxGroups[PASSENGER.child]?.length || 0;
  searchData.passengers.infant = paxGroups[PASSENGER.infant]?.length || 0;
  searchData.trips = [
    {
      id: nanoid(),
      srcLocation: {
        city: _first[0].dep.city.title?.split(",")[0].trim(),
        iata: _first[0].dep.airport.code,
        cityIata: _first[0].dep.city.code,
        isCity: _first[0].dep.airport.code === _first[0].dep.city.code,
      },
      destLocation: {
        city: _first.at(-1).arr.city.title?.split(",")[0].trim(),
        iata: _first.at(-1).arr.airport.code,
        cityIata: _first.at(-1).arr.city.code,
        isCity: _first.at(-1).arr.airport.code === _first.at(-1).arr.city.code,
      },
      startDate: reformatDate(_first[0].dep.date, "DD.MM.YYYY", "YYYY-MM-DD"),
      endDate:
        searchData.tripType === TRIP_TYPE.roundtrip
          ? reformatDate(_last[0].dep.date, "DD.MM.YYYY", "YYYY-MM-DD")
          : "",
    },
  ];
  return searchData;
};
