import classes from "./FlightCard.module.css";
import Card from "src/components/UI/Card/Card";
import { useLocaleContext } from "src/context/locale-context";
import { getMainCarrierAirlineIcon } from "src/utils/results-utils";
import { AIRLINE_CABIN_TYPE } from "src/constants/string-res-map";
import { useDeviceContext } from "src/context/device-context";
import { getFlightDateFormatted, getLocalizedFlightDuration } from "src/utils/date-utils";
import { useTenantContext } from "src/context/tenant-context";

export function FlightCard({ flight, isFirst, hasNext }) {
  const { stringRes, currentLocale } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const { dateFormat } = useTenantContext();

  return (
    <Card
      cornerSm
      className={`${classes.flight} ${isMobile ? classes.mobile : classes.desktop} ${
        isFirst ? classes.first : hasNext ? classes.mid : classes.last
      }`}>
      <div className={`${classes.itinerary} ${classes.departure}`}>
        <div className={classes.src}>
          <span>{flight.dep.time}</span>
          <span>{getFlightDateFormatted(flight.dep.date, dateFormat)}</span>
        </div>
        <div className={classes.connection}>
          <div className={classes.line}></div>
          <div className={classes.dot}></div>
          <div className={classes.line}></div>
        </div>
        <div className={classes.src}>
          <span>{`${flight.dep.city.title} • ${flight.dep.airport.code}`}</span>
          <span>{flight.dep.airport.title}</span>
        </div>
      </div>

      <div className={`${classes.itinerary} ${classes.info}`}>
        <div className={classes.dur}>
          <span>
            {getLocalizedFlightDuration(flight.duration?.flight?.total_min, currentLocale)}
          </span>
        </div>
        <div className={classes.connection}>
          <div className={classes.line}></div>
        </div>
        <div className={classes.airline}>
          <span className={classes.icon}>
            <img
              onDragStart={(e) => e.preventDefault()}
              alt=""
              src={getMainCarrierAirlineIcon(flight)}
            />
          </span>
          <span>{flight.supplier.title}</span>
          <span>
            {`${flight.carrier.code}${flight.flight_number} • `}
            {flight.supplier.code !== flight.carrier.code
              ? `${stringRes["res.result.operatedBy"]} ${flight.carrier.title} • `
              : ""}
            {stringRes[AIRLINE_CABIN_TYPE[flight.class.name]]}
          </span>
        </div>
      </div>

      <div className={`${classes.itinerary} ${classes.arrival}`}>
        <div className={classes.dest}>
          <span>{flight.arr.time}</span>
          <span>{getFlightDateFormatted(flight.arr.date, dateFormat)}</span>
        </div>
        <div className={`${classes.connection} ${hasNext ? "" : classes.end}`}>
          <div className={classes.line}></div>
          <div className={classes.dot}></div>
          <div className={classes.line}></div>
        </div>
        <div className={classes.dest}>
          <span>{`${flight.arr.city.title} • ${flight.arr.airport.code}`}</span>
          <span>{flight.arr.airport.title}</span>
        </div>
      </div>
    </Card>
  );
}
