import classes from "./MobileBottomElement.module.css";
import { useLocaleContext } from "src/context/locale-context";
import Button from "src/components/UI/Button/Button";
import secureLock from "src/assets/img/secureLock.png";
import { MobilePriceSummary } from "../MobilePriceSummary/MobilePriceSummary";
import { useMatch } from "react-router-dom";
import { BOOKING_STEP } from "src/constants";

const GRAY_BG_COLOR_PAGES = [
  BOOKING_STEP.insuranceSelection.path,
  BOOKING_STEP.extraServicesSelection.path,
];

export function SecurityNote() {
  const { stringRes } = useLocaleContext();

  return (
    <div className={classes.securityInfo}>
      <h2>
        <img src={secureLock} alt="secure" />
        {stringRes["booking.mob.secure.title"]}
      </h2>
      <p>{stringRes["booking.mob.secure.description"]}</p>
    </div>
  );
}

export function MobileBottomElement({ withPrice, onGoToNextStep, isDisabled, buttonText }) {
  const { stringRes } = useLocaleContext();
  const { bookingStep } = useMatch("/booking/:bookingStep").params;

  return (
    <div
      style={{
        backgroundColor: GRAY_BG_COLOR_PAGES.includes(bookingStep)
          ? "var(--UI-background)"
          : "#fff",
      }}
      className={classes.next}>
      {withPrice && <MobilePriceSummary />}
      <Button
        name={buttonText ?? stringRes["booking.continue.button"]}
        wide
        disabled={isDisabled}
        onClick={onGoToNextStep}
      />
      <SecurityNote />
    </div>
  );
}
