import { useEffect, useRef } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./StepContent.module.css";
import { BOOKING_STEP } from "src/constants";
import { useLocaleContext } from "src/context/locale-context";
import BookingStepTitle from "../StepTitle/BookingStepTitle";
import BookingSidePanel from "../SidePanel/BookingSidePanel";
import { useDispatch, useSelector } from "react-redux";
import { bookingActions } from "src/store/booking";
import { MobileBottomElement } from "../MobileBottomElement/MobileBottomElement";
import InsuranceSelection from "../InsuranceSelection/InsuranceSelection";
import btaLogo from "src/assets/img/btaInsuranceIcon.png";
import { useBookingFlow } from "src/context/booking-flow-context";
import { BookingNextButton } from "src/components/features/booking/StepContent/BookingNextButton";
import { BookingBackButton } from "src/components/features/booking/StepContent/BookingBackButton";

function InsuranceStepContent({ pageId }) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { goToNextBookingPage, goToPrevBookingPage, hasPrevious, hasNext } =
    useBookingFlow();
  const dispatch = useDispatch();

  const serviceRef = useRef();
  const insuranceService = useSelector((state) => state.order.travelInsurance.serviceId);

  useEffect(() => {
    const page = document.getElementById("booking-page");
    if (isMobile) {
      page.style.backgroundColor = "transparent";
    }
    return () => {
      page.style.backgroundColor = "initial";
    };
  }, [isMobile]);

  const onGoToPrevStep = () => {
    dispatch(bookingActions.resetFormState(BOOKING_STEP.insuranceSelection.id));
    goToPrevBookingPage(pageId);
  };

  const onGoToNextStep = () => {
    let warningElement, isValidInsuranceService;
    if (isMobile || insuranceService) {
      isValidInsuranceService = true;
    } else {
      warningElement = serviceRef.current;
    }

    if (isValidInsuranceService) {
      dispatch(bookingActions.resetFormState(BOOKING_STEP.insuranceSelection.id));
      goToNextBookingPage(pageId);
    } else {
      dispatch(
        bookingActions.updateFormWarningStatus({
          step: BOOKING_STEP.insuranceSelection.id,
          isIncomplete: true,
        })
      );
      dispatch(
        bookingActions.incrementValidationAttempts(BOOKING_STEP.insuranceSelection.id)
      );
      warningElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!isMobile) {
    return (
      <section className={`${classes.container} ${classes.desktop}`}>
        <BookingStepTitle step={pageId} />
        <div className={classes.main}>
          <InsuranceSelection ref={serviceRef} />
        </div>
        <BookingSidePanel />
        <div className={classes.nextButton}>
          {hasPrevious(pageId) ? (
            <BookingBackButton
              onClick={onGoToPrevStep}
              className={classes.withBackArr}
              buttonText={stringRes["booking.back.button"]}
            />
          ) : (
            <div></div>
          )}
          {hasNext(pageId) ? (
            <BookingNextButton
              onClick={onGoToNextStep}
              className={classes.withArr}
              buttonText={stringRes["booking.continue.button"]}
            />
          ) : (
            <div></div>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className={`${classes.container} ${classes.mobile} ${classes.insurance}`}>
        <div className={classes.insuranceGroup}>
          <img alt="BTA" src={btaLogo} />
        </div>
        <BookingStepTitle step={pageId} />
        <InsuranceSelection ref={serviceRef} />
        <MobileBottomElement onGoToNextStep={onGoToNextStep} />
      </section>
    );
  }
}

export default InsuranceStepContent;
