import classes from "./ResumeBookingModal.module.css";
import Button from "src/components/UI/Button/Button";
import { Fragment } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { ModalElementContext } from "src/context/modal-element-context";
import Modal from "src/components/shared/modal/Modal/Modal";
import DesktopModal from "src/components/shared/modal/Modal/DesktopModal";

function ResumeBookingModal({ onResume, onClose, state }) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const innerContent = (
    <Fragment>
      <div className={classes.image}></div>
      <h1>{stringRes["booking.modal.resume.title"]}</h1>
      <p>{stringRes["booking.modal.resume.description"]}</p>
      <div className={classes.buttons}>
        <Button
          secondary
          name={stringRes["booking.modal.resume.skip.button"]}
          onClick={onClose}
        />
        <Button
          primary
          name={
            stringRes[
              isMobile ? "booking.modal.mob.resume.button" : "booking.modal.resume.button"
            ]
          }
          onClick={onResume}
        />
      </div>
    </Fragment>
  );

  if (isMobile) {
    return (
      <ModalElementContext.Provider value={{ onClose: onClose }}>
        <Modal isClosing={state.isClosing}>
          <div className={`${classes.mobile} ${classes.container}`}>{innerContent}</div>
        </Modal>
      </ModalElementContext.Provider>
    );
  } else {
    return (
      <DesktopModal isClosing={state.isClosing} onBackdropClick={onClose}>
        <div className={`${classes.desktop} ${classes.container}`}>{innerContent}</div>
      </DesktopModal>
    );
  }
}

export default ResumeBookingModal;
