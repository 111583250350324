import classes from "./MobileResultsFilter.module.css";
import BellIcon from "src/components/UI/Icon/BellIcon";

export function MobilePriceAlertsButton({ onClick }) {
  return (
    <button className={`${classes.buttonBarItem} ${classes.bell}`} onClick={onClick}>
      <BellIcon />
    </button>
  );
}
