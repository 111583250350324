import { Fragment, forwardRef, useEffect } from "react";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./MoneyBackGuarantee.module.css";
import { useDeviceContext } from "src/context/device-context";
import { Link } from "react-router-dom";
import { PROJECT_NAME } from "src/constants";
import { CATALOGUE_IDS, PRICE_POLICY, SERVICE_TYPE } from "src/constants/services";
import { BOOKING_STEP } from "src/constants";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { ShieldIcon } from "src/components/UI/Icon/ShieldIcon";
import { splitStringByNewline } from "src/utils/string-utils";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "src/store/order";
import { selectCatalogue, selectServicePrice } from "src/store/catalogue";
import { selectisFormIncomplete } from "src/store/booking";
import moneybackStepIcon from "src/assets/img/moneybackStepIcon.png";
import AddButtonOval from "src/components/UI/AddButtonOval/AddButtonOval";
import { Markup } from "interweave";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import useStaticContent from "src/hooks/useStaticContent";
import { STATIC_ELEMENT } from "src/constants/static";

function GuaranteeOptionDesktop({ serviceId, price, checked, onSelect }) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const isYes = serviceId === CATALOGUE_IDS.MBG_SERVICE;

  const optionTitle = stringRes[`booking.change.guarantee.option.${isYes ? "yes" : "no"}`];

  const optionDesc = isYes
    ? [
        stringRes["booking.change.guarantee.option.yes.feature1"].replace(
          "##PROJECT_NAME##",
          PROJECT_NAME
        ),
        stringRes["booking.change.guarantee.option.yes.feature2"],
        stringRes["booking.change.guarantee.option.yes.feature3"],
      ]
    : [stringRes["booking.change.guarantee.option.no.desc"]];

  return (
    <RadioButton
      checked={checked}
      id={"guarantee-type-" + serviceId}
      name="change-guarantee"
      className={`${classes.guaranteeType} ${checked ? classes.active : ""}`}
      onChange={(isChecked) => onSelect(isChecked, serviceId)}>
      <div className={classes.optionTitle}>
        <span>{optionTitle}</span>
        <span className={classes.price}>{`${price} ${currencySymbol}`}</span>
      </div>
      {isYes && (
        <div className={classes.optionDescription}>
          {optionDesc.map((desc, i) => (
            <span key={i} className={classes.feature}>
              <ShieldIcon />
              <span>
                <Markup content={desc} />
              </span>
            </span>
          ))}
        </div>
      )}
    </RadioButton>
  );
}

function GuaranteeOptionMobile({ serviceId, price, isAdded, onToggle }) {
  const { stringRes, currencySymbol } = useLocaleContext();

  return (
    <Fragment>
      <div className={classes.optionTop}>
        <div className={classes.optionPrice}>
          <span className={classes.price}>{`${price} ${currencySymbol}`}</span>
          <span className={classes.info}>{stringRes[PRICE_POLICY.per_order]}</span>
        </div>
        <AddButtonOval onClick={() => onToggle(!isAdded)} isAdded={isAdded} />
      </div>
      <div className={classes.optionDescription}>
        {splitStringByNewline(stringRes["booking.mob.change.guarantee.option.features"]).map(
          (desc, i) => (
            <span key={i} className={classes.feature}>
              <ShieldIcon color={"#12B169"} />
              <span>
                <Markup content={desc} />
              </span>
            </span>
          )
        )}
      </div>
    </Fragment>
  );
}

const MoneyBackGuarantee = forwardRef(function MoneyBackGuarantee(props, ref) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  const { openModal } = useModalContext();
  const { html: cancellation_guarantee } = useStaticContent(STATIC_ELEMENT.guarantee);

  const onShowGuaranteeDetails = (e) => {
    e.stopPropagation();
    openModal(MODAL_TYPE.serviceDetails, { template: cancellation_guarantee });
  };

  const isFormIncomplete = useSelector((state) =>
    selectisFormIncomplete(state, BOOKING_STEP.guaranteeSelection.id)
  );
  const currentService = useSelector((state) => state.order.changeProtection.serviceId);
  const showWarning = isFormIncomplete && !currentService;

  const noProtectionPrice = 0;
  const withProtectionPrice = useSelector((state) =>
    selectServicePrice(selectCatalogue(state), CATALOGUE_IDS.MBG_SERVICE)
  );

  useEffect(() => {
    if (isMobile && !currentService) {
      onToggleAddButton(SERVICE_TYPE.REFUSED);
    }
  }, []);

  const onSelectGuaranteeOption = (isChecked, serviceId) => {
    if (isChecked) {
      dispatch(orderActions.changeChangeProtection(serviceId));
    }
  };

  const onToggleAddButton = (serviceId) => {
    dispatch(orderActions.changeChangeProtection(serviceId));
  };

  if (!isMobile) {
    return (
      <div ref={ref} className={`${classes.container} ${classes.desktop}`}>
        <div className={classes.top}>
          <h1>
            <span>{stringRes["booking.change.guarantee.title"]}</span>
            <span className={classes.tag}>
              <span className={classes.tagText}>
                {stringRes["booking.change.guarantee.tag"]}
              </span>
            </span>
          </h1>
          <p className={classes.description}>
            <span>
              <Markup content={stringRes["booking.change.guarantee.description"]} />

              <br />
              <span>{`${stringRes["booking.change.guarantee.desc.learnMore"]} `}</span>
              <button className={classes.guaranteeLink} onClick={onShowGuaranteeDetails}>
                <Markup
                  content={stringRes["booking.change.guarantee.desc.guaranteeName"].replace(
                    "##PROJECT_NAME##",
                    PROJECT_NAME
                  )}
                />
              </button>
            </span>
          </p>
          <img src={moneybackStepIcon} alt="cancelled ticket" />
        </div>

        {showWarning && (
          <p className={classes.warnText}>
            {`${stringRes["booking.change.guarantee.option.warn"]}:`}
          </p>
        )}
        <div className={`${classes.guaranteeOpts} ${showWarning ? classes.warn : ""}`}>
          <GuaranteeOptionDesktop
            checked={currentService === SERVICE_TYPE.REFUSED}
            onSelect={onSelectGuaranteeOption}
            serviceId={SERVICE_TYPE.REFUSED}
            price={noProtectionPrice}
          />
          <GuaranteeOptionDesktop
            checked={currentService === CATALOGUE_IDS.MBG_SERVICE}
            onSelect={onSelectGuaranteeOption}
            serviceId={CATALOGUE_IDS.MBG_SERVICE}
            price={withProtectionPrice}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div ref={ref} className={`${classes.container} ${classes.mobile}`}>
        <div className={classes.hr}></div>
        <span className={classes.tag}>
          <span className={classes.tagText}>{stringRes["booking.change.guarantee.tag"]}</span>
        </span>
        <p className={classes.description}>
          <Markup content={stringRes["booking.mob.change.guarantee.description"]} />{" "}
        </p>
        <GuaranteeOptionMobile
          isAdded={currentService === CATALOGUE_IDS.MBG_SERVICE}
          onToggle={(doAddService) =>
            onToggleAddButton(doAddService ? CATALOGUE_IDS.MBG_SERVICE : SERVICE_TYPE.REFUSED)
          }
          serviceId={CATALOGUE_IDS.MBG_SERVICE}
          price={withProtectionPrice}
        />
        <button onClick={onShowGuaranteeDetails} className={classes.conditions}>
          {stringRes["booking.mob.change.guarantee.conditions"]}
        </button>
      </div>
    );
  }
});

export default MoneyBackGuarantee;
