import CheckoutSummary from "src/components/features/booking/CheckoutSummary/CheckoutSummary";
import PaymentOptions from "src/components/features/booking/PaymentOptions/PaymentOptions";

import { useSelector } from "react-redux";
import Button from "src/components/UI/Button/Button";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import { PassengerIcon } from "src/components/UI/Icon/PassengerIcon";
import { BillingInfoIcon, OrderPaymentIcon } from "src/components/UI/Icon/StepsIcons";
import BillingVATDetails from "src/components/features/booking/BillingVATDetails/BillingVATDetails";
import mobNavStyle from "src/components/features/booking/MobileBookingHeader/MobileBookingHeader.module.css";
import { MobileBottomElement } from "src/components/features/booking/MobileBottomElement/MobileBottomElement";
import OrderDetails from "src/components/features/booking/OrderDetails/OrderDetails";
import BookingSidePanel from "src/components/features/booking/SidePanel/BookingSidePanel";
import classes from "src/components/features/booking/StepContent/StepContent.module.css";
import {
  BookingStepTitleMobile,
  MOB_PAGES_WHEADER_IMG,
  STEP_DESC_MOB,
  STEP_IMG_MOB,
  STEP_NAMES_MOB,
} from "src/components/features/booking/StepTitle/BookingStepTitle";
import { BOOKING_STEP } from "src/constants";
import { WEEKDAY_SHORT_STR_MAP } from "src/constants/string-res-map";
import { useLocaleContext } from "src/context/locale-context";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";
import { useTenantContext } from "src/context/tenant-context";
import { selectAllPassengers } from "src/store/booking";
import { getFlightDateFormatted, getWeekdayFromDate } from "src/utils/date-utils";
import { getFirstFlightChain, getLastFlightChain } from "src/utils/results-utils";

export function DesktopPaymentByLinkContent(props) {
  const { stringRes } = useLocaleContext();

  return (
    <section
      style={{ gridAutoFlow: "column" }}
      className={`${classes.container} ${classes.desktop} ${classes.checkout}`}>
      <div className={classes.mainGroup}>
        <div className={classes.main}>
          <CheckoutSummary readOnly withBookingNumber />
        </div>
        <div className={classes.subheading}>
          <BillingInfoIcon />
          {stringRes["booking.checkout.billing.title"]}
        </div>
        <div className={classes.main}>
          <BillingVATDetails ref={props.bref} />
        </div>
        <div className={classes.subheading}>
          <OrderPaymentIcon />
          {stringRes["booking.checkout.payment.title"]}
        </div>
        <div className={classes.main}>
          <PaymentOptions ref={props.pref} />
        </div>
      </div>

      <BookingSidePanel row={1} />
      <div
        style={{
          margin: "2.5rem 0 4rem",
          width: "100%",
          height: "3.4375rem",
          display: "flex",
          justifyContent: "flex-end",
        }}>
        <Button
          disabled={!props.paymentData.type || !props.isTOSAgreed}
          opts={{ width: "fit-content", minWidth: "9.8125rem", fontSize: "18px" }}
          name={
            props.loading ? <DotPulseLoader color="#fff" opacity={0.4} /> : props.payButtonText
          }
          onClick={props.onInitiatePayment}
        />
      </div>
    </section>
  );
}

export function MobilePaymentByLinkContent(props) {
  const { stringRes } = useLocaleContext();

  let step = BOOKING_STEP.checkoutPage.id;
  let stepTitle = stringRes[STEP_NAMES_MOB[step]];
  let textColor, descColor;
  textColor = "#12B169";

  return (
    <section className={`${classes.container} ${classes.mobile} ${classes.checkout}`}>
      <div className={classes.row}>
        <BookingStepTitleMobile
          name={stepTitle}
          description={stringRes[STEP_DESC_MOB[step]]}
          icon={STEP_IMG_MOB[step]}
          bgColor={MOB_PAGES_WHEADER_IMG.includes(step) ? "transparent" : "#fff"}
          textColor={textColor}
          descColor={descColor}
        />
        <OrderDetails />
        <CheckoutSummary readOnly withBookingNumber />
      </div>
      <div className={classes.row}>
        <BillingVATDetails ref={props.bref} />
      </div>
      <div className={classes.row}>
        <PaymentOptions ref={props.pref} />
      </div>
      <MobileBottomElement
        withPrice
        isDisabled={!props.paymentData.type || !props.isTOSAgreed}
        onGoToNextStep={props.onInitiatePayment}
        buttonText={
          props.loading ? <DotPulseLoader color="#fff" opacity={0.4} /> : props.payButtonText
        }
      />
    </section>
  );
}

export function PaymentByLinkHeader({ travelPackage }) {
  const { stringRes } = useLocaleContext();
  const { shortDateFormat } = useTenantContext();

  const passengers = useSelector(selectAllPassengers);
  const isReturn = travelPackage.segments_direction.length === 2;
  const firstChain = getFirstFlightChain(travelPackage);
  const lastChain = getLastFlightChain(travelPackage);

  const weekdayNumStart = getWeekdayFromDate(firstChain[0].dep.date, "DD.MM.YYYY");
  const weekdayNumEnd = getWeekdayFromDate(lastChain[0].dep.date, "DD.MM.YYYY");

  const dateStart = getFlightDateFormatted(firstChain[0].dep.date, shortDateFormat);
  const dateEnd = getFlightDateFormatted(lastChain[0].dep.date, shortDateFormat);

  const dates =
    `${stringRes[WEEKDAY_SHORT_STR_MAP[weekdayNumStart]]}, ${dateStart} ` +
    (isReturn ? `- ${stringRes[WEEKDAY_SHORT_STR_MAP[weekdayNumEnd]]}, ${dateEnd}` : "");

  const { openModal } = useModalContext();
  const onOpenDetails = () => {
    openModal(MODAL_TYPE.flightDetails, { item: travelPackage, hideContinue: true });
  };

  return (
    <div className={mobNavStyle.container}>
      <div className={mobNavStyle.prev}></div>
      <div className={mobNavStyle.summary}>
        <div className={mobNavStyle.cities}>{`${firstChain[0].dep.city.title} - ${
          isReturn
            ? firstChain[firstChain.length - 1].arr.city.title
            : lastChain[lastChain.length - 1].arr.city.title
        }`}</div>
        <div className={mobNavStyle.dates}>
          {dates}
          <span>
            <PassengerIcon className={mobNavStyle.paxIcon} />
            {passengers.length}
          </span>
        </div>
      </div>
      <div className={mobNavStyle.details}>
        <Button name={stringRes["button.general.details"]} secondary onClick={onOpenDetails} />
      </div>
    </div>
  );
}
