import { useLoaderData, useNavigate } from "react-router-dom";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import Button from "src/components/UI/Button/Button";
import { useAuth } from "src/context/auth-context";

export function OrderComplete() {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { decodedOrderData, bookingID, bookingNumber } = useLoaderData();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const goToPage = (button, page) => {
    if (button === 1) {
      window.open(page, "_blank");
    } else if (button === 0) {
      navigate(page);
    }
  };

  return (
    <main id="order-complete-page" className={isMobile ? "mobile" : "desktop"}>
      <div className="order-container">
        <h1>{stringRes["order.payment.success.title"]}</h1>
        <h2>
          {stringRes["order.payment.order.num"] + " #"}
          <span>{bookingNumber}</span>
        </h2>
        <p>{`${stringRes["order.payment.order.status"]}: ${decodedOrderData.payment_status}`}</p>
        {stringRes["order.payment.success.note"].split("\n").map((note) => (
          <p key={note} style={{ padding: "0.125rem 0" }}>
            {note}
          </p>
        ))}

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            columnGap: "1rem",
            rowGap: "1rem",
            marginTop: "2rem",
          }}>
          <Button
            wide
            secondary
            onMouseUp={(e) => goToPage(e.button, "/")}
            name={stringRes["button.general.homepage"]}
          />

          {isAuthenticated && (
            <Button
              wide
              primary
              onMouseUp={(e) => goToPage(e.button, "/dashboard/trips/" + bookingID)}
              name={stringRes["button.general.order.details"]}
            />
          )}
        </div>
      </div>
    </main>
  );
}
