function UserIcon({ size = 15, color = "#001B38" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1176_593)">
        <path
          opacity="0.981"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.8408 0.453955C9.32857 0.309308 11.3745 1.17845 12.9785 3.06138C14.4677 5.02925 14.8779 7.207 14.209 9.59458C13.4181 11.9284 11.8507 13.4763 9.50681 14.2381C7.08797 14.8833 4.90046 14.4341 2.94431 12.8905C0.892903 11.0431 0.10677 8.75306 0.585913 6.02036C1.2512 3.32382 2.90647 1.55135 5.55173 0.702979C5.98008 0.591006 6.40975 0.507999 6.8408 0.453955ZM7.45603 1.39146C9.84042 1.47921 11.6324 2.54366 12.832 4.58481C13.6688 6.2371 13.7762 7.93632 13.1543 9.68247C12.9973 10.1039 12.7874 10.4945 12.5244 10.8543C10.3862 8.86732 7.91552 8.30581 5.11228 9.16978C4.09884 9.53944 3.21018 10.101 2.44626 10.8543C1.42948 9.29889 1.16093 7.61918 1.6406 5.81528C2.30954 3.70102 3.70114 2.30942 5.8154 1.64048C6.35734 1.49543 6.90422 1.41243 7.45603 1.39146Z"
          fill={color}
        />
        <path
          opacity="0.988"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.07524 2.32913C8.52543 2.22361 9.54596 2.82908 10.1368 4.14553C10.5625 5.59327 10.1572 6.74074 8.92094 7.58792C8.26695 7.95082 7.57358 8.05336 6.84086 7.89553C5.63954 7.55847 4.92177 6.77721 4.68754 5.55178C4.56778 4.10086 5.16836 3.08036 6.4893 2.49026C6.68582 2.42657 6.88115 2.37286 7.07524 2.32913Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default UserIcon;
