import classes from "./SupportSelection.module.css";
import { Fragment, forwardRef, useEffect, useState } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "src/store/order";
import { selectCatalogue, selectCatalogueItem } from "src/store/catalogue";
import supportBasicImg from "src/assets/img/booking/supportBasic.png";
import supportPlusImg from "src/assets/img/booking/supportPlus.png";
import supportPremiumImg from "src/assets/img/booking/supportPremium.png";
import { Checkmark, FatCheckmark } from "src/components/UI/Icon/Checkmark";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import Button from "src/components/UI/Button/Button";
import { getDisplayPriceValue } from "src/utils/utils";
import { XIconUnavailable } from "src/components/UI/Icon/XIcon";
import { motion } from "framer-motion";
import { Markup } from "interweave";
import { localeApi } from "src/services/api";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";

const SUPPORT_IMG_MAP = {
  [CATALOGUE_IDS.SUPPORT.basic]: supportBasicImg,
  [CATALOGUE_IDS.SUPPORT.standart]: supportPlusImg,
  [CATALOGUE_IDS.SUPPORT.premium]: supportPremiumImg,
};

function PopularTag({ text }) {
  return (
    <div className={classes.tag}>
      <div className={classes.tagText}>{text}</div>
    </div>
  );
}

export const SupportSelection = forwardRef(({ onContinue }, ref) => {
  const { stringRes, currentLocale } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  const basicData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.SUPPORT.basic)
  );
  const standartData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.SUPPORT.standart)
  );
  const premiumData = useSelector((state) =>
    selectCatalogueItem(selectCatalogue(state), CATALOGUE_IDS.SUPPORT.premium)
  );

  const [featuresData, setFeatures] = useState({ basic: [], plus: [], premium: [] });
  const currentService = useSelector((state) => state.order.supportService.serviceId);

  const onSelectSupportOption = (serviceId) => {
    dispatch(orderActions.changeSupportServiceType(serviceId));
  };

  useEffect(() => {
    if (currentService === SERVICE_TYPE.NOT_SELECTED) {
      onSelectSupportOption(CATALOGUE_IDS.SUPPORT.standart);
    }

    localeApi
      .getAdditionalStringResources(currentLocale.substring(0, 2))
      .then((response) => {
        console.log(response.data);
        const strRes = response.data;

        const localizedDataBasic = [];
        basicData.data.package.forEach((ft) => {
          const feature = { ...ft, comment: strRes[ft.name] };
          localizedDataBasic.push(feature);
        });

        const localizedDataStd = [];
        standartData.data.package.forEach((ft) => {
          const feature = { ...ft, comment: strRes[ft.name] };
          localizedDataStd.push(feature);
        });

        const localizedDataPremium = [];
        premiumData.data.package.forEach((ft) => {
          const feature = { ...ft, comment: strRes[ft.name] };
          localizedDataPremium.push(feature);
        });

        setFeatures({
          basic: localizedDataBasic,
          plus: localizedDataStd,
          premium: localizedDataPremium,
        });
      })
      .catch((err) => {
        console.log(err);
        setFeatures({
          basic: basicData.data.package,
          plus: standartData.data.package,
          premium: premiumData.data.package,
        });
      });
  }, []);

  const basicTypeProps = {
    serviceId: CATALOGUE_IDS.SUPPORT.basic,
    name: stringRes[
      isMobile
        ? "booking.support.mob.option.basic.title"
        : "booking.support.option.basic.title"
    ],
    price: basicData.sum_total,
    features: featuresData.basic,
    buttonName: stringRes["booking.support.option.basic.button"],
    isPopular: basicData.data.policy.recommended,
  };

  const plusTypeProps = {
    serviceId: CATALOGUE_IDS.SUPPORT.standart,
    name: stringRes[
      isMobile ? "booking.support.mob.option.plus.title" : "booking.support.option.plus.title"
    ],
    price: standartData.sum_total,
    features: featuresData.plus,
    buttonName:
      stringRes[
        isMobile
          ? "booking.support.mob.option.plus.button"
          : "booking.support.option.plus.button"
      ],
    isPopular: standartData.data.policy.recommended,
  };

  const premiumTypeProps = {
    serviceId: CATALOGUE_IDS.SUPPORT.premium,
    name: stringRes[
      isMobile
        ? "booking.support.mob.option.premium.title"
        : "booking.support.option.premium.title"
    ],
    price: premiumData.sum_total,
    features: featuresData.premium,
    buttonName: stringRes["booking.support.option.premium.button"],
    isPopular: premiumData.data.policy.recommended,
  };

  const sharedProps = {
    selected: currentService,
    onSelect: onSelectSupportOption,
    onContinue: onContinue,
  };

  if (isMobile) {
    return (
      <div ref={ref} className={`${classes.container} ${classes.mobile}`}>
        <div className={classes.hr}></div>
        <div className={classes.supportOpts}>
          {featuresData.basic.length === 0 ||
          featuresData.plus.length === 0 ||
          featuresData.premium.length === 0 ? (
            <div style={{ gridColumn: "1 / -1", justifySelf: "center" }}>
              <DotPulseLoader opacity={0.4} />
            </div>
          ) : (
            <>
              <SupportTypeOption {...basicTypeProps} {...sharedProps} />
              <SupportTypeOption {...plusTypeProps} {...sharedProps} />
              <SupportTypeOption {...premiumTypeProps} {...sharedProps} />
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div ref={ref} className={`${classes.container} ${classes.desktop}`}>
        <h1>{stringRes["booking.support.title"]}</h1>
        <p className={classes.description}>
          <Markup content={stringRes["booking.support.description"]} />
        </p>
        <div className={classes.supportOpts}>
          {featuresData.basic.length === 0 ||
          featuresData.plus.length === 0 ||
          featuresData.premium.length === 0 ? (
            <div style={{ gridColumn: "1 / -1" }}>
              <DotPulseLoader opacity={0.4} />
            </div>
          ) : (
            <>
              <SupportTypeOption {...basicTypeProps} {...sharedProps} />
              <SupportTypeOption {...plusTypeProps} {...sharedProps} />
              <SupportTypeOption {...premiumTypeProps} {...sharedProps} />{" "}
              <div className={classes.recommended}>
                {stringRes["booking.support.option.recommended"]}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
});

function SupportTypeOption({
  serviceId,
  selected,
  name,
  price,
  buttonName,
  features,
  onSelect,
  onContinue,
  isPopular,
}) {
  const { isMobile } = useDeviceContext();
  const isSelected = selected === serviceId;

  const onSelectHandler = () => {
    onSelect(serviceId);
  };

  const onContinueHandler = () => {
    onSelect(serviceId);
    onContinue();
  };

  const childProps = {
    onSelect: onSelectHandler,
    onContinue: onContinueHandler,
    isSelected,
    serviceId,
    name,
    price,
    buttonName,
    features,
    isPopular,
  };

  if (isMobile) {
    return <SupportTypeOptionMobile {...childProps} />;
  } else {
    return <SupportTypeOptionDesktop {...childProps} />;
  }
}

function SupportTypeOptionDesktop({
  serviceId,
  isSelected,
  name,
  price,
  buttonName,
  features,
  onSelect,
  onContinue,
  isPopular,
}) {
  const { stringRes, currencySymbol } = useLocaleContext();

  const buttonOpts = {
    [isPopular ? "primary" : "secondary"]: true,
    name: buttonName,
    wide: true,
    onClick: onContinue,
  };

  return (
    <div
      className={`${classes.option} ${isSelected ? classes.active : ""} ${
        isPopular ? classes.popular : ""
      }`}
      onClick={onSelect}>
      {isPopular && <PopularTag text={stringRes["booking.support.option.popular"]} />}
      <img alt="" className={classes.icon} src={SUPPORT_IMG_MAP[serviceId]} />
      <span className={classes.name}>{name}</span>
      <span className={classes.price}>
        {price > 0
          ? `${getDisplayPriceValue(price)} ${currencySymbol}`
          : stringRes["booking.support.option.included"]}
      </span>
      <ul className={classes.features}>
        {features.map((ft, i) => (
          <li className={classes.feature} key={i}>
            <span>
              {ft.check_box ? <Checkmark color={"#00A991"} /> : <XIconUnavailable size={12} />}
            </span>
            <span>{ft.comment}</span>
          </li>
        ))}
      </ul>
      <Button {...buttonOpts} />
    </div>
  );
}

function SupportTypeOptionMobile({
  serviceId,
  isSelected,
  name,
  price,
  buttonName,
  features,
  onSelect,
  onContinue,
  isPopular,
}) {
  const { stringRes, currencySymbol } = useLocaleContext();

  const buttonOpts = {
    name: buttonName,
    wide: true,
    onClick: onContinue,
  };

  return (
    <Fragment>
      <RadioButton
        className={`${classes.option} ${isSelected ? classes.active : ""} ${
          isPopular ? classes.popular : ""
        }`}
        onChange={onSelect}
        id={"support-option-" + serviceId}
        name="support-type-selection"
        checked={isSelected}>
        {isPopular && (
          <PopularTag text={stringRes["booking.support.option.tag.recommended"]} />
        )}
        <div className={classes.details}>
          <img alt="" className={classes.icon} src={SUPPORT_IMG_MAP[serviceId]} />
          <span className={classes.name}>{name}</span>
          <span className={classes.price}>
            {price > 0
              ? `${getDisplayPriceValue(price)} ${currencySymbol}`
              : stringRes["booking.support.option.included"]}
          </span>
        </div>
      </RadioButton>
      {isSelected && (
        <Fragment>
          <ul className={classes.features}>
            {features.map((ft, i) => (
              <li className={classes.feature} key={i}>
                <motion.span
                  initial={{ opacity: 0.25, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.5,
                    scale: {
                      type: "spring",
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.005,
                    },
                  }}>
                  {ft.check_box ? (
                    <FatCheckmark color={"#00A991"} />
                  ) : (
                    <XIconUnavailable size={10} />
                  )}
                </motion.span>
                <motion.span
                  initial={{ opacity: 0.75 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}>
                  {ft.comment}
                </motion.span>
              </li>
            ))}
          </ul>
          <Button {...buttonOpts} />
        </Fragment>
      )}
    </Fragment>
  );
}
