export function EyeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3337_16203)">
        <path
          d="M19.3923 7.84956C18.0998 5.74456 15.1598 2.21289 9.9998 2.21289C4.8398 2.21289 1.8998 5.74456 0.607299 7.84956C0.207739 8.49581 -0.00390625 9.24059 -0.00390625 10.0004C-0.00390625 10.7602 0.207739 11.505 0.607299 12.1512C1.8998 14.2562 4.8398 17.7879 9.9998 17.7879C15.1598 17.7879 18.0998 14.2562 19.3923 12.1512C19.7919 11.505 20.0035 10.7602 20.0035 10.0004C20.0035 9.24059 19.7919 8.49581 19.3923 7.84956ZM17.9715 11.2787C16.8615 13.0837 14.349 16.1212 9.9998 16.1212C5.65063 16.1212 3.13813 13.0837 2.02813 11.2787C1.79074 10.8946 1.66501 10.452 1.66501 10.0004C1.66501 9.54883 1.79074 9.10619 2.02813 8.72206C3.13813 6.91706 5.65063 3.87956 9.9998 3.87956C14.349 3.87956 16.8615 6.91372 17.9715 8.72206C18.2089 9.10619 18.3346 9.54883 18.3346 10.0004C18.3346 10.452 18.2089 10.8946 17.9715 11.2787Z"
          fill="#001B38"
        />
        <path
          d="M9.99968 5.83301C9.17559 5.83301 8.37001 6.07738 7.6848 6.53522C6.9996 6.99306 6.46554 7.6438 6.15018 8.40516C5.83481 9.16652 5.7523 10.0043 5.91307 10.8126C6.07384 11.6208 6.47068 12.3632 7.0534 12.946C7.63612 13.5287 8.37855 13.9255 9.1868 14.0863C9.99505 14.2471 10.8328 14.1645 11.5942 13.8492C12.3555 13.5338 13.0063 12.9998 13.4641 12.3146C13.922 11.6293 14.1663 10.8238 14.1663 9.99967C14.165 8.89501 13.7256 7.83597 12.9445 7.05486C12.1634 6.27374 11.1043 5.83433 9.99968 5.83301ZM9.99968 12.4997C9.50522 12.4997 9.02187 12.3531 8.61075 12.0783C8.19963 11.8036 7.8792 11.4132 7.68998 10.9564C7.50076 10.4996 7.45125 9.9969 7.54771 9.51195C7.64418 9.027 7.88228 8.58154 8.23191 8.23191C8.58154 7.88228 9.027 7.64417 9.51195 7.54771C9.9969 7.45125 10.4996 7.50076 10.9564 7.68998C11.4132 7.87919 11.8036 8.19963 12.0784 8.61075C12.3531 9.02187 12.4997 9.50522 12.4997 9.99967C12.4997 10.6627 12.2363 11.2986 11.7674 11.7674C11.2986 12.2363 10.6627 12.4997 9.99968 12.4997Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_3337_16203">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
