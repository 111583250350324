import classes from "./BookingStepTitle.module.css";
import {
  CheckoutStepIcon,
  ExtrasStepIcon,
  FareTypeStepIcon,
  InsuranceStepIcon,
  MoneyBackStepIcon,
  PassengerStepIcon,
  SeatsStepIcon,
  SupportStepIcon,
} from "src/components/UI/Icon/StepsIcons";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import {
  CheckoutMobStepIcon,
  ExtrasMobStepIcon,
  InsuranceMobStepIcon,
  MoneyBackMobStepIcon,
  PassengerStepMobIcon,
  SeatsMobStepIcon,
  SupportMobStepIcon,
} from "src/components/UI/Icon/StepsIconsMob";
import { CATALOGUE_IDS } from "src/constants/services";
import { BOOKING_STEP } from "src/constants";
import { useSelector } from "react-redux";
import { selectCatalogue, selectServiceUnitPrice } from "src/store/catalogue";

function BookingStepTitleDesktop({ name, icon }) {
  return (
    <div className={classes.bookingStep}>
      {icon}
      <span className="step-name">{name}</span>
    </div>
  );
}

export function BookingStepTitleMobile({
  name,
  description,
  icon,
  bgColor,
  textColor,
  descColor,
}) {
  const bgProp = { style: bgColor ? { backgroundColor: bgColor } : {} };
  const titleProp = { style: textColor ? { color: textColor } : {} };
  const descProp = { style: descColor ? { color: descColor } : {} };

  return (
    <div className={classes.bookingStepMob} {...bgProp}>
      <h1 className="step-name" {...titleProp}>
        {name}
      </h1>
      <p {...descProp}>{description}</p>
      <span className={classes.icon}>{icon}</span>
    </div>
  );
}

const STEP_ICONS = {
  [BOOKING_STEP.fareTypeSelection.id]: <FareTypeStepIcon />,
  [BOOKING_STEP.passengerDetailsForm.id]: <PassengerStepIcon />,
  [BOOKING_STEP.guaranteeSelection.id]: <MoneyBackStepIcon />,
  [BOOKING_STEP.insuranceSelection.id]: <InsuranceStepIcon />,
  [BOOKING_STEP.extraServicesSelection.id]: <ExtrasStepIcon />,
  [BOOKING_STEP.seatSelection.id]: <SeatsStepIcon />,
  [BOOKING_STEP.supportSelection.id]: <SupportStepIcon />,
  [BOOKING_STEP.checkoutPage.id]: <CheckoutStepIcon />,
};
const STEP_NAMES = {
  [BOOKING_STEP.fareTypeSelection.id]: "booking.title.step...",
  [BOOKING_STEP.passengerDetailsForm.id]: "booking.title.step.passengers",
  [BOOKING_STEP.guaranteeSelection.id]: "booking.title.step.guarantee",
  [BOOKING_STEP.insuranceSelection.id]: "booking.title.step.insurance",
  [BOOKING_STEP.extraServicesSelection.id]: "booking.title.step.extras",
  [BOOKING_STEP.seatSelection.id]: "booking.title.step.seats",
  [BOOKING_STEP.supportSelection.id]: "booking.title.step.support",
  [BOOKING_STEP.checkoutPage.id]: "booking.title.step.checkout",
};

export const STEP_IMG_MOB = {
  [BOOKING_STEP.fareTypeSelection.id]: null,
  [BOOKING_STEP.passengerDetailsForm.id]: <PassengerStepMobIcon />,
  [BOOKING_STEP.guaranteeSelection.id]: <MoneyBackMobStepIcon />,
  [BOOKING_STEP.insuranceSelection.id]: <InsuranceMobStepIcon />,
  [BOOKING_STEP.extraServicesSelection.id]: <ExtrasMobStepIcon />,
  [BOOKING_STEP.seatSelection.id]: <SeatsMobStepIcon />,
  [BOOKING_STEP.supportSelection.id]: <SupportMobStepIcon />,
  [BOOKING_STEP.checkoutPage.id]: <CheckoutMobStepIcon />,
};
export const STEP_NAMES_MOB = {
  [BOOKING_STEP.fareTypeSelection.id]: "booking.title.step.mob..",
  [BOOKING_STEP.passengerDetailsForm.id]: "booking.title.step.mob.passengers",
  [BOOKING_STEP.guaranteeSelection.id]: "booking.title.step.mob.guarantee",
  [BOOKING_STEP.insuranceSelection.id]: "booking.title.step.mob.insurance",
  [BOOKING_STEP.extraServicesSelection.id]: "booking.title.step.mob.extras",
  [BOOKING_STEP.seatSelection.id]: "booking.title.step.mob.seats",
  [BOOKING_STEP.supportSelection.id]: "booking.title.step.mob.support",
  [BOOKING_STEP.checkoutPage.id]: "booking.title.step.mob.checkout",
};
export const STEP_DESC_MOB = {
  [BOOKING_STEP.fareTypeSelection.id]: "booking.description.step.mob..",
  [BOOKING_STEP.passengerDetailsForm.id]: "booking.description.step.mob.passengers",
  [BOOKING_STEP.guaranteeSelection.id]: "booking.description.step.mob.guarantee",
  [BOOKING_STEP.insuranceSelection.id]: null,
  [BOOKING_STEP.extraServicesSelection.id]: "booking.description.step.mob.extras",
  [BOOKING_STEP.seatSelection.id]: "booking.description.step.mob.seats",
  [BOOKING_STEP.supportSelection.id]: "booking.description.step.mob.support",
  [BOOKING_STEP.checkoutPage.id]: "booking.description.step.mob.checkout",
};

export const MOB_PAGES_WHEADER_IMG = [
  BOOKING_STEP.insuranceSelection.id,
  BOOKING_STEP.extraServicesSelection.id,
];

function BookingStepTitle({ step }) {
  const { currencySymbol, stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  const insurancePrice = useSelector((state) =>
    selectServiceUnitPrice(selectCatalogue(state), CATALOGUE_IDS.TRAVEL_INSURANCE.TRAVEL_BASIC)
  );

  if (isMobile) {
    let stepTitle = stringRes[STEP_NAMES_MOB[step]];
    let textColor, descColor;

    if (step === BOOKING_STEP.insuranceSelection.id) {
      stepTitle = stepTitle.replace(
        "##INSURANCE_PRICE##",
        `${insurancePrice} ${currencySymbol}`
      );
    }

    if (step === BOOKING_STEP.checkoutPage.id) {
      textColor = "#12B169";
    } else if (MOB_PAGES_WHEADER_IMG.includes(step)) {
      textColor = "#fff";
      descColor = textColor;
    }

    return (
      <BookingStepTitleMobile
        name={stepTitle}
        description={stringRes[STEP_DESC_MOB[step]]}
        icon={STEP_IMG_MOB[step]}
        bgColor={MOB_PAGES_WHEADER_IMG.includes(step) ? "transparent" : "#fff"}
        textColor={textColor}
        descColor={descColor}
      />
    );
  } else {
    return (
      <BookingStepTitleDesktop
        name={stringRes[STEP_NAMES[step]]}
        icon={STEP_ICONS[step]}
      />
    );
  }
}

export default BookingStepTitle;
