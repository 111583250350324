import classes from "./FlightDetails.module.css";
import { useLocaleContext } from "src/context/locale-context";
import {
  NoCarryonIcon,
  NoCheckedBagIcon,
  PersonalItemIcon,
  NoPriorityBoardingIcon,
  SeatSelectionIcon,
  TicketIcon,
  CarryonIcon,
  CheckedBagIcon,
} from "src/components/UI/Icon/FareDetailsIcons";
import {
  BAGGAGE,
  getPersonalItemDimensions,
  getPersonalItemWeight,
  hasFreeBaggageIncluded,
} from "src/utils/baggage-utils";

export function FareItems({ item }) {
  const { stringRes } = useLocaleContext();

  return (
    <div className={classes.fareDetails}>
      <h4>{stringRes["res.details.flight.fare.details"]}</h4>
      <ul>
        <li>
          <PersonalItemIcon color={"#04b1a7"} />
          <span>
            {`1 ${stringRes["res.details.flight.fare.personalItem"]}, `}
            {`${getPersonalItemWeight(item)}, ${getPersonalItemDimensions(item)}`}
          </span>
        </li>

        {hasFreeBaggageIncluded(item.baggage, BAGGAGE.cabin) ? (
          <li>
            <CarryonIcon color={"#04b1a7"} />
            <span>
              {`${item.baggage[BAGGAGE.cabin][0].piece} ${
                stringRes["res.details.flight.fare.carryon"]
              }`}
            </span>
          </li>
        ) : (
          <li>
            <NoCarryonIcon />
            <span>{stringRes["res.details.flight.fare.carryon.none"]}</span>
          </li>
        )}

        {hasFreeBaggageIncluded(item.baggage, BAGGAGE.checked) ? (
          <li>
            <CheckedBagIcon color={"#04b1a7"} />
            <span>{`${item.baggage[BAGGAGE.checked][0].piece} ${
              stringRes["res.details.flight.fare.checked"]
            }`}</span>
          </li>
        ) : (
          <li>
            <NoCheckedBagIcon />
            <span>{stringRes["res.details.flight.fare.checked.none"]}</span>
          </li>
        )}

        <li>
          <NoPriorityBoardingIcon />
          <span>{stringRes["res.details.flight.fare.priority.none"]}</span>
        </li>
        <li>
          <SeatSelectionIcon />
          <span>{stringRes["res.details.flight.fare.seat.none"]}</span>
        </li>
        <li>
          <TicketIcon />
          <span>{stringRes["res.details.flight.fare.changes.none"]}</span>
        </li>
        <li>
          <TicketIcon />
          <span>{stringRes["res.details.flight.fare.refund.none"]}</span>
        </li>
      </ul>
    </div>
  );
}
