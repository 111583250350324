export function FacebookIconLogin() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_885_14595)">
        <path
          d="M19.8 0H4.2C1.8804 0 0 1.8804 0 4.2V19.8C0 22.1196 1.8804 24 4.2 24H19.8C22.1196 24 24 22.1196 24 19.8V4.2C24 1.8804 22.1196 0 19.8 0Z"
          fill="#385997"
        />
        <path
          d="M20.4 11.0996H16.65V8.24961C16.65 7.55211 17.4525 7.49961 17.7 7.49961H20.25V3.59961H17.7C14.8763 3.59961 12.75 5.59836 12.75 8.24961V11.0996H9.60001V14.9996H12.75V23.9996H16.65V14.9996H19.8L20.4 11.0996Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_885_14595">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function GoogleIconLogin() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17217 11.9996C5.17217 11.2202 5.30154 10.4728 5.53273 9.77191L1.48835 6.68359C0.700104 8.28391 0.256104 10.0873 0.256104 11.9996C0.256104 13.9104 0.699728 15.7125 1.48667 17.3118L5.52879 14.2175C5.29985 13.5198 5.17217 12.7753 5.17217 11.9996Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2732 4.90912C13.9665 4.90912 15.496 5.50912 16.6977 6.49088L20.1934 3C18.0632 1.14544 15.3321 0 12.2732 0C7.52422 0 3.44272 2.71575 1.4884 6.684L5.53259 9.77231C6.46447 6.94369 9.12078 4.90912 12.2732 4.90912Z"
        fill="#EA4335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2732 19.0917C9.12097 19.0917 6.46465 17.0571 5.53278 14.2285L1.4884 17.3163C3.44272 21.2851 7.52422 24.0008 12.2732 24.0008C15.2042 24.0008 18.0027 22.96 20.1028 21.01L16.264 18.0423C15.1808 18.7246 13.8167 19.0917 12.2732 19.0917Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7439 12.0001C23.7439 11.291 23.6346 10.5273 23.4707 9.81836H12.2732V14.4547H18.7187C18.3964 16.0355 17.5193 17.2507 16.2639 18.0415L20.1028 21.0093C22.3089 18.9618 23.7439 15.9115 23.7439 12.0001Z"
        fill="#4285F4"
      />
    </svg>
  );
}

export function AppleIconLogin({ color = "#262F3C" }) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6856 11.918C14.6593 9.27979 16.837 8.01445 16.9343 7.95053C15.7105 6.1611 13.8038 5.91557 13.1252 5.88763C11.5038 5.72295 9.95991 6.84267 9.13738 6.84267C8.31654 6.84267 7.04569 5.91176 5.70119 5.93546C3.9325 5.96171 2.30225 6.96416 1.39208 8.54743C-0.444758 11.7339 0.922184 16.4561 2.71203 19.0423C3.58706 20.3063 4.63058 21.7287 5.99964 21.6767C7.31916 21.6246 7.817 20.8232 9.41212 20.8232C11.0072 20.8232 11.4551 21.6767 12.8509 21.65C14.2699 21.6242 15.1694 20.3618 16.0373 19.0926C17.0418 17.6241 17.4554 16.2021 17.48 16.1302C17.4491 16.1158 14.7139 15.068 14.6856 11.918Z"
        fill={color}
      />
      <path
        d="M12.063 4.17684C12.7891 3.29546 13.281 2.07076 13.1472 0.851562C12.0995 0.893896 10.8311 1.54794 10.0789 2.42848C9.40494 3.20995 8.81566 4.45412 8.97356 5.65131C10.1424 5.7419 11.3345 5.05653 12.063 4.17684Z"
        fill={color}
      />
    </svg>
  );
}

export function EmailIconLogin() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3337_16115)">
        <path
          d="M21.8873 5.96771C21.8431 5.90848 21.7877 5.85852 21.7242 5.82071C21.6607 5.7829 21.5904 5.75797 21.5173 5.74735C21.4441 5.73673 21.3696 5.74062 21.298 5.75881C21.2264 5.777 21.159 5.80912 21.0998 5.85334L12.0267 12.6258C11.9942 12.6502 11.9548 12.6633 11.9142 12.6633C11.8736 12.6633 11.8342 12.6502 11.8017 12.6258L2.9067 5.82334C2.84797 5.77853 2.781 5.74572 2.70959 5.72679C2.63819 5.70786 2.56375 5.70318 2.49054 5.71302C2.41733 5.72286 2.34677 5.74702 2.28289 5.78412C2.21902 5.82123 2.16308 5.87055 2.11826 5.92928C2.07345 5.988 2.04064 6.05498 2.02171 6.12638C2.00278 6.19779 1.9981 6.27222 2.00794 6.34544C2.01778 6.41865 2.04194 6.48921 2.07904 6.55308C2.11615 6.61696 2.16547 6.6729 2.2242 6.71772L9.21795 12.0671L2.30107 17.2796C2.24149 17.324 2.19124 17.3798 2.15319 17.4436C2.11515 17.5075 2.09006 17.5782 2.07935 17.6518C2.06864 17.7254 2.07253 17.8003 2.09079 17.8724C2.10904 17.9444 2.14132 18.0122 2.18576 18.0718C2.23021 18.1314 2.28595 18.1816 2.34982 18.2197C2.41368 18.2577 2.48441 18.2828 2.55797 18.2935C2.63153 18.3042 2.70648 18.3003 2.77854 18.2821C2.8506 18.2638 2.91836 18.2315 2.97795 18.1871L10.1461 12.7758L11.1173 13.5258C11.3438 13.6992 11.6206 13.7941 11.9058 13.7961C12.1909 13.7981 12.469 13.7072 12.6979 13.5371L13.7179 12.7758L20.9704 18.1871C21.0297 18.2313 21.097 18.2634 21.1686 18.2816C21.2403 18.2997 21.3148 18.3036 21.3879 18.293C21.461 18.2824 21.5313 18.2575 21.5948 18.2197C21.6583 18.1819 21.7138 18.1319 21.7579 18.0727C21.8021 18.0135 21.8342 17.9462 21.8524 17.8745C21.8706 17.8029 21.8745 17.7284 21.8639 17.6553C21.8532 17.5822 21.8283 17.5118 21.7905 17.4483C21.7527 17.3848 21.7028 17.3294 21.6436 17.2852L14.6573 12.0652L21.7729 6.74959C21.891 6.66047 21.9691 6.52845 21.9905 6.38212C22.012 6.23578 21.9749 6.08691 21.8873 5.96771Z"
          fill="#001b38"
        />
        <path
          d="M21.1875 3.5625H2.8125C2.06658 3.5625 1.35121 3.85882 0.823762 4.38626C0.296316 4.91371 0 5.62908 0 6.375L0 17.625C0 18.3709 0.296316 19.0863 0.823762 19.6137C1.35121 20.1412 2.06658 20.4375 2.8125 20.4375H21.1875C21.9334 20.4375 22.6488 20.1412 23.1762 19.6137C23.7037 19.0863 24 18.3709 24 17.625V6.375C24 5.62908 23.7037 4.91371 23.1762 4.38626C22.6488 3.85882 21.9334 3.5625 21.1875 3.5625ZM22.875 17.625C22.875 18.0726 22.6972 18.5018 22.3807 18.8182C22.0643 19.1347 21.6351 19.3125 21.1875 19.3125H2.8125C2.36495 19.3125 1.93572 19.1347 1.61926 18.8182C1.30279 18.5018 1.125 18.0726 1.125 17.625V6.375C1.125 5.92745 1.30279 5.49823 1.61926 5.18176C1.93572 4.86529 2.36495 4.6875 2.8125 4.6875H21.1875C21.6351 4.6875 22.0643 4.86529 22.3807 5.18176C22.6972 5.49823 22.875 5.92745 22.875 6.375V17.625Z"
          fill="#001b38"
        />
      </g>
      <defs>
        <clipPath id="clip0_3337_16115">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
