export function FlightConnectionIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0002" cy="10" r="10" fill="#04B1A7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0002 7.59025C17.0002 7.70743 17.0002 7.82462 17.0002 7.94181C16.9002 8.2598 16.6854 8.45185 16.3557 8.51798C15.4052 8.52448 14.4547 8.53101 13.5042 8.53751C12.6757 9.86892 11.8424 11.197 11.0042 12.5219C10.6654 12.5382 10.3269 12.5349 9.98853 12.5121C10.3994 11.185 10.8161 9.86017 11.2385 8.53751C10.3271 8.52448 9.41562 8.52448 8.50415 8.53751C8.25675 8.86304 8.00936 9.18855 7.76196 9.51407C7.50814 9.52384 7.25423 9.5271 7.00024 9.52384C7.00024 9.4978 7.00024 9.47175 7.00024 9.44571C7.16859 8.88987 7.33135 8.32999 7.48853 7.76603C7.33135 7.20206 7.16859 6.64218 7.00024 6.08634C7.00024 6.07331 7.00024 6.0603 7.00024 6.04728C7.24956 6.0346 7.49696 6.02159 7.74243 6.00821C8.00322 6.33421 8.25713 6.66624 8.50415 7.00431C9.4189 7.0303 10.3369 7.0303 11.2581 7.00431C11.2556 6.99245 11.2491 6.98595 11.2385 6.98478C10.8147 5.66767 10.3981 4.34604 9.98853 3.01993C10.3269 2.99715 10.6654 2.9939 11.0042 3.01017C11.8424 4.335 12.6757 5.66314 13.5042 6.99454C14.4547 7.00105 15.4052 7.00757 16.3557 7.01407C16.6854 7.08021 16.9002 7.27226 17.0002 7.59025Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0002 11.5902C14.0002 11.7074 14.0002 11.8246 14.0002 11.9418C13.9002 12.2598 13.6854 12.4518 13.3557 12.518C12.4052 12.5245 11.4547 12.531 10.5042 12.5375C9.67571 13.8689 8.84237 15.197 8.00415 16.5219C7.66542 16.5382 7.32688 16.5349 6.98853 16.5121C7.39942 15.185 7.8161 13.8602 8.23853 12.5375C7.32706 12.5245 6.41562 12.5245 5.50415 12.5375C5.25675 12.863 5.00936 13.1885 4.76196 13.5141C4.50814 13.5238 4.25423 13.5271 4.00024 13.5238C4.00024 13.4978 4.00024 13.4717 4.00024 13.4457C4.16859 12.8899 4.33135 12.33 4.48853 11.766C4.33135 11.2021 4.16859 10.6422 4.00024 10.0863C4.00024 10.0733 4.00024 10.0603 4.00024 10.0473C4.24956 10.0346 4.49696 10.0216 4.74243 10.0082C5.00322 10.3342 5.25713 10.6662 5.50415 11.0043C6.4189 11.0303 7.33687 11.0303 8.25806 11.0043C8.25563 10.9925 8.24913 10.9859 8.23853 10.9848C7.81472 9.66767 7.39806 8.34604 6.98853 7.01993C7.32688 6.99715 7.66542 6.9939 8.00415 7.01017C8.84237 8.335 9.67571 9.66314 10.5042 10.9945C11.4547 11.001 12.4052 11.0076 13.3557 11.0141C13.6854 11.0802 13.9002 11.2723 14.0002 11.5902Z"
        fill="white"
      />
    </svg>
  );
}

export function SelfTransferIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0002" cy="10" r="10" fill="#FF5252" />
      <g clipPath="url(#clip0_3337_12292)">
        <path
          opacity="0.979"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.85571 3.98828C10.0198 3.98828 10.1838 3.98828 10.3479 3.98828C11.0668 4.18841 11.4145 4.66498 11.3909 5.41797C11.256 6.1309 10.8263 6.51372 10.1018 6.56641C9.30847 6.49183 8.87488 6.05823 8.80103 5.26562C8.85798 4.59548 9.20954 4.1697 9.85571 3.98828Z"
          fill="white"
        />
        <path
          opacity="0.98"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8713 15.9883C11.7541 15.9883 11.6369 15.9883 11.5197 15.9883C11.3233 15.9128 11.1944 15.7722 11.133 15.5664C11.0505 14.8238 10.9646 14.0816 10.8752 13.3398C10.7247 12.7751 10.5255 12.2282 10.2776 11.6992C9.87924 12.8981 9.49252 14.1013 9.1174 15.3086C8.94818 15.6803 8.66304 15.8249 8.26193 15.7422C7.8316 15.6122 7.67145 15.3271 7.78146 14.8867C8.20024 13.5913 8.61431 12.2944 9.02365 10.9961C9.03928 10.1836 9.03928 9.37111 9.02365 8.5586C8.84599 8.67365 8.72098 8.82991 8.64865 9.02735C8.52066 9.62826 8.39567 10.2298 8.27365 10.832C8.24147 10.9746 8.16335 11.084 8.03927 11.1602C7.66488 12.4473 7.28597 13.7325 6.90256 15.0156C7.1213 15.2734 7.1213 15.5313 6.90256 15.7891C6.50688 16.0335 6.21 15.9476 6.01193 15.5313C5.67734 15.4261 5.3414 15.3245 5.00412 15.2266C4.7936 15.1217 4.71938 14.9538 4.78146 14.7227C5.00021 13.9727 5.21896 13.2227 5.43771 12.4727C5.51981 12.2655 5.67216 12.1757 5.89474 12.2031C6.37456 12.3279 6.84722 12.4725 7.31271 12.6367C7.45674 12.154 7.59345 11.6696 7.72287 11.1836C7.45709 11.1716 7.30863 11.0349 7.27756 10.7734C7.40822 10.0107 7.54495 9.249 7.68771 8.48829C7.71689 8.35183 7.77157 8.22684 7.85177 8.11329C8.28624 7.71783 8.72763 7.33111 9.17599 6.95313C9.2176 6.9194 9.26447 6.89597 9.31662 6.88282C9.94163 6.86719 10.5666 6.86719 11.1916 6.88282C11.3161 6.90794 11.4216 6.96654 11.508 7.0586C12.0765 8.21908 12.639 9.38313 13.1955 10.5508C13.2545 10.8275 13.1568 11.0268 12.9026 11.1484C12.6719 11.2976 12.4727 11.2625 12.3049 11.043C12.0378 10.4854 11.7644 9.93072 11.4846 9.37891C11.469 9.90234 11.469 10.4258 11.4846 10.9492C11.751 11.483 11.962 12.0377 12.1174 12.6133C12.2509 13.4896 12.3642 14.3685 12.4572 15.25C12.4374 15.6382 12.2421 15.8843 11.8713 15.9883ZM6.43381 15.1211C6.75823 15.1131 6.8715 15.2616 6.77365 15.5664C6.59974 15.7292 6.43568 15.7214 6.28146 15.543C6.20749 15.3553 6.25826 15.2147 6.43381 15.1211Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3337_12292">
          <rect width="12" height="12" fill="white" transform="translate(3.00024 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DifferentTransferAirportIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0002" cy="10" r="10" fill="#0172CB" />
      <g clipPath="url(#clip0_3337_12370)">
        <path
          d="M10.0002 4C8.67461 4.00146 7.40368 4.52871 6.46632 5.46607C5.52895 6.40344 5.0017 7.67436 5.00024 9C5.00024 11.641 9.20024 15.2665 9.67724 15.6715L10.0002 15.9445L10.3232 15.6715C10.8002 15.2665 15.0002 11.641 15.0002 9C14.9988 7.67436 14.4715 6.40344 13.5342 5.46607C12.5968 4.52871 11.3259 4.00146 10.0002 4ZM10.0002 11.5C9.50579 11.5 9.02244 11.3534 8.61132 11.0787C8.2002 10.804 7.87976 10.4135 7.69055 9.95671C7.50133 9.49989 7.45182 8.99723 7.54828 8.51227C7.64474 8.02732 7.88285 7.58186 8.23248 7.23223C8.58211 6.8826 9.02757 6.6445 9.51252 6.54804C9.99747 6.45157 10.5001 6.50108 10.957 6.6903C11.4138 6.87952 11.8042 7.19995 12.0789 7.61107C12.3536 8.0222 12.5002 8.50555 12.5002 9C12.4995 9.6628 12.2358 10.2982 11.7671 10.7669C11.2985 11.2356 10.663 11.4992 10.0002 11.5Z"
          fill="white"
        />
        <path
          d="M10.0002 10.5C10.8287 10.5 11.5002 9.82843 11.5002 9C11.5002 8.17157 10.8287 7.5 10.0002 7.5C9.17182 7.5 8.50024 8.17157 8.50024 9C8.50024 9.82843 9.17182 10.5 10.0002 10.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3337_12370">
          <rect width="12" height="12" fill="white" transform="translate(4.00024 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DifferentDepartureLocationIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0002" cy="10" r="10" fill="#FAA916" fillOpacity="0.63" />
      <circle cx="10.0002" cy="10" r="7.5" fill="#FAA916" fillOpacity="0.63" />
      <path
        d="M9.51224 7.58C9.02024 7.58 8.60024 7.172 8.60024 6.668C8.60024 6.164 9.02024 5.768 9.51224 5.768C10.0162 5.768 10.4242 6.164 10.4242 6.668C10.4242 7.172 10.0162 7.58 9.51224 7.58ZM10.2802 14H8.75624V8.204H10.2802V14Z"
        fill="white"
      />
    </svg>
  );
}
