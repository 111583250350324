import { Checkmark } from "src/components/UI/Icon/Checkmark";
import { useLocaleContext } from "src/context/locale-context";
import classes from "../BaggageSelection.module.css";

export function InfantFareBenefits() {
  const { stringRes } = useLocaleContext();
  return (
    <div className={classes.fareFeatures}>
      <p>{stringRes["booking.form.passenger.infant.fare"]}</p>
      <ul>
        <li className={classes.feature}>
          <Checkmark />
          <span>{stringRes["booking.form.passenger.infant.fare.feature1"]}</span>
        </li>
        <li className={classes.feature}>
          <Checkmark />
          <span>{stringRes["booking.form.passenger.infant.fare.feature2"]}</span>
        </li>
      </ul>
    </div>
  );
}
