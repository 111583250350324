import { useState, useRef, useEffect } from "react";

const useResizeHeightObserver = () => {
  const [height, setPanelHeight] = useState();
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const currPanelHeight = entries[0].target.offsetHeight;
      setPanelHeight(currPanelHeight);
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { ref, height };
};

export default useResizeHeightObserver;
