import classes from "./OrderDetails.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import SeatsIcon from "src/components/UI/Icon/SeatsIcon";
import { useSelector } from "react-redux";
import ShareIcon from "src/components/UI/Icon/ShareIcon";
import FlightItinerary from "../FlightItinerary/FlightItinerary";
import {
  getFirstFlightChain,
  getFlightDate,
  getLastFlightChain,
  isDepartureArrivalDifferent,
  showSearchSeatsLeft,
} from "src/utils/results-utils";
import { selectAllPassengers, selectTravelPackage } from "src/store/booking";
import {
  getDayFromDate,
  getMonthFromDate,
  getWeekdayFromDate,
} from "src/utils/date-utils";
import OrderBreakdown from "../OrderBreakdown/OrderBreakdown";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";

const WEEKDAY_STR_MAP = [
  "calendar.weekday.mon.fullname",
  "calendar.weekday.tue.fullname",
  "calendar.weekday.wed.fullname",
  "calendar.weekday.thu.fullname",
  "calendar.weekday.fri.fullname",
  "calendar.weekday.sat.fullname",
  "calendar.weekday.sun.fullname",
];

const MONTH_STR_MAP = [
  "calendar.month.jan.short",
  "calendar.month.feb.short",
  "calendar.month.mar.short",
  "calendar.month.apr.short",
  "calendar.month.may.short",
  "calendar.month.jun.short",
  "calendar.month.jul.short",
  "calendar.month.aug.short",
  "calendar.month.sep.short",
  "calendar.month.oct.short",
  "calendar.month.nov.short",
  "calendar.month.dec.short",
];

function OrderDetails() {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const { openModal } = useModalContext();

  const currentPaxs = useSelector(selectAllPassengers);
  const travelPackage = useSelector(selectTravelPackage);
  const isOneway = travelPackage.segments_direction.length === 1;
  const firstChain = getFirstFlightChain(travelPackage);
  const lastChain = getLastFlightChain(travelPackage);
  const isDiffLocation = isDepartureArrivalDifferent(firstChain, lastChain);

  const startDate = getFlightDate(firstChain[0]);
  const startDateStr = `${stringRes[WEEKDAY_STR_MAP[getWeekdayFromDate(startDate)]]}, ${
    stringRes[MONTH_STR_MAP[getMonthFromDate(startDate)]]
  } ${getDayFromDate(startDate)}`;

  const endDate = getFlightDate(lastChain[0]);
  const endDateStr = `${stringRes[WEEKDAY_STR_MAP[getWeekdayFromDate(endDate)]]}, ${
    stringRes[MONTH_STR_MAP[getMonthFromDate(endDate)]]
  } ${getDayFromDate(endDate)}`;

  const onShowFlightDetails = () => {
    openModal(MODAL_TYPE.flightDetails, { hideContinue: true, item: travelPackage });
  };

  const onShareItinerary = () => {
    // openModal(MODAL_TYPE.shareOptions, {
    //   onSelectOption: (option) => console.log(`${option} share type selected`),
    //   onCopy: (link) => console.log(`link copied: ${link}`),
    // });
  };

  if (!isMobile) {
    return (
      <div className={`${classes.container} ${classes.desktop}`}>
        <div className={classes.title}>
          <h1>{stringRes["booking.order.title"]}</h1>
          <button className={classes.share} onClick={onShareItinerary}>
            <ShareIcon />
          </button>
        </div>
        <div className={classes.summary}>
          <div className={classes.itinerary}>
            <p>{startDateStr}</p>
            <FlightItinerary
              flightChain={firstChain}
              diffLocation={!isOneway && isDiffLocation}
              isDeparture
              isOneway={isOneway}
            />
          </div>
          {!isOneway && (
            <div className={classes.itinerary}>
              <p>{endDateStr}</p>
              <FlightItinerary
                flightChain={lastChain}
                diffLocation={isDiffLocation}
                isOneway={isOneway}
              />
            </div>
          )}
          <button onClick={onShowFlightDetails} className={classes.detailsButton}>
            {stringRes["link.text.showDetails"]}
          </button>
        </div>
        <div className={classes.hr}></div>
        <OrderBreakdown />
        {showSearchSeatsLeft(travelPackage.seats, currentPaxs.length) && (
          <div className={classes.ticketsInfo}>
            <SeatsIcon />
            {travelPackage.seats === 1
              ? stringRes["booking.order.ticketsLeft.ticket.singular"]
              : stringRes["booking.order.ticketsLeft.ticket.plural"].replace(
                  "##TICKET_QTY##",
                  travelPackage.seats
                )}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={`${classes.container} ${classes.mobile}`}>
        <div className={classes.title}>
          <h1>{stringRes["booking.mob.order.title"]}</h1>
        </div>
        <div className={classes.hr}></div>
        <div className={classes.summary}>
          <div className={classes.itinerary}>
            <FlightItinerary
              flightChain={firstChain}
              diffLocation={!isOneway && isDiffLocation}
              isDeparture
              isOneway={isOneway}
            />
          </div>
          {!isOneway && (
            <div className={classes.itinerary}>
              <FlightItinerary
                flightChain={lastChain}
                diffLocation={isDiffLocation}
                isOneway={isOneway}
              />
            </div>
          )}
          <button onClick={onShowFlightDetails} className={classes.detailsButton}>
            {stringRes["booking.mob.order.showDetails"]}
          </button>
          <div className={classes.hr}></div>
        </div>
      </div>
    );
  }
}

export default OrderDetails;
