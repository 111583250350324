import { useSelector } from "react-redux";
import { ResultsCheckboxGroupFilter } from "../ResultsCheckboxGroupFilter";
import { selectFilters } from "src/store/filter-sort";
import { useLocaleContext } from "src/context/locale-context";

function AirportsFilter() {
  const { stringRes } = useLocaleContext();
  const airportFilter = useSelector(state => selectFilters(state, "airport"));
  const availableAirports = airportFilter.options;

  return (
    <ResultsCheckboxGroupFilter
      filterType="airport"
      availableItems={availableAirports}
      title={stringRes["res.filter.title.airports"]}
      filterData={airportFilter}
    />
  );
}

export default AirportsFilter;