import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import { useDeviceContext } from "src/context/device-context";

const COLORS = [
  { graphic: "#FBB900", btn: "var(--primary-1, #1665e3)" },
  { graphic: "var(--primary-1, #1665e3)", btn: "#FBB900" },
];

function InfoPageLayout() {
  const { isMobile } = useDeviceContext();
  const [color] = useState(COLORS[Math.floor(Math.random() * COLORS.length)]);

  useEffect(() => {
    document.body.classList.add("info-body");
    return () => document.body.classList.remove("info-body");
  }, []);

  return (
    <>
      <main id="info-pages-container" className={isMobile ? "mobile" : "desktop"}>
        <PaddedView>
          <Outlet />
        </PaddedView>
      </main>
      <div id="info-pages-bottom-graphic" className={isMobile ? "mobile" : "desktop"}>
        <div className="graphic-container">
          <svg viewBox="0 0 1440 520" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.5"
              d="M-490 11.5744V520H2569V82.6622C2338.03 26.6788 1861 -52.9504 1208.38 82.6622C678.68 192.734 426.061 34.3208 123.876 11.5744C-153.032 -9.2704 -380.37 6.03158 -490 11.5744Z"
              fill={color.graphic}
            />
          </svg>
        </div>
        <div className="graphic-container">
          <svg viewBox="0 0 1440 522" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2135 1.95453V522.002H-490V139.865C-291.801 83.9284 221.043 1.02922 632.46 99.7764C1027.03 194.48 1352.93 124.116 1600.68 55.9606C1797 1.95453 2040.92 -3.58333 2135 1.95453Z"
              fill={color.graphic}
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default InfoPageLayout;
