export function FlightConnectionLineMobile({ hasStops, className }) {
  const plane = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M3.98125 11.7033C4.07812 11.5908 7.33125 7.10567 7.33125 7.10567C7.3375 7.09943 7.34375 7.09318 7.34688 7.09006C7.52187 6.91827 7.67187 6.92764 7.93437 6.91515C7.93437 6.91515 10.0906 6.88704 10.8094 6.84643C11.5281 6.80583 12 6.43103 12 5.99375C12 5.55648 11.5312 5.1848 10.8125 5.1442C10.0938 5.10359 7.9375 5.07548 7.9375 5.07548C7.675 5.06299 7.525 5.07236 7.35 4.90057C7.34375 4.89433 7.3375 4.88808 7.33437 4.88496C7.33437 4.88496 4.08125 0.402915 3.98438 0.287351C3.8875 0.171786 3.74062 0 3.41562 0C3.03437 0 2.9875 0.112441 3.05313 0.331078C3.05313 0.331078 4.2875 4.71005 4.32812 4.87246C4.36875 5.03488 4.30937 5.11921 4.09375 5.1317C3.87813 5.1442 2.64375 5.15669 2.5 5.15981C2.35625 5.16294 2.35312 5.14107 2.26875 5.06923L0.99375 4.06976C0.93125 4.01666 0.853126 3.98542 0.765625 3.98542C0.765625 3.98542 0.575 3.98542 0.378125 3.98542C0.18125 3.98542 0.150001 4.07288 0.225 4.24154C0.300001 4.4102 0.768749 5.25976 0.768749 5.25976C0.778125 5.28162 0.828125 5.40344 0.828125 5.45966C0.828125 5.59084 0.831249 5.70953 0.634375 5.73451C0.278124 5.77512 0 5.88756 0 6C0 6.11244 0.278124 6.22488 0.634375 6.26549C0.831249 6.28735 0.828125 6.40916 0.828125 6.54034C0.828125 6.59656 0.778125 6.71838 0.768749 6.74024C0.768749 6.74024 0.300001 7.5898 0.225 7.75846C0.150001 7.92712 0.18125 8.01458 0.378125 8.01458C0.575 8.01458 0.765625 8.01458 0.765625 8.01458C0.853126 8.01458 0.934375 7.98334 0.99375 7.93024L2.26875 6.93077C2.35312 6.85893 2.35937 6.84019 2.5 6.84019C2.64062 6.84019 3.87813 6.8558 4.09375 6.8683C4.30937 6.88079 4.36875 6.96512 4.32812 7.12754C4.2875 7.28995 3.05313 11.6689 3.05313 11.6689C2.9875 11.8907 3.03437 12 3.41562 12C3.7375 11.9906 3.88437 11.8188 3.98125 11.7033Z"
        fill="#BAC7D5"
      />
    </svg>
  );

  const line = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="2"
      viewBox="0 0 100 2"
      fill="none"
      preserveAspectRatio="none"
    >
      <path d="M1 1H60H119" stroke="#BAC7D5" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );

  const stopNode = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
    >
      <circle cx="3" cy="3" r="2.5" fill="white" stroke="#FF5171" />
    </svg>
  );

  return (
    <span className={className}>
      {line}
      {plane}
      {hasStops && stopNode}
    </span>
  );
}
