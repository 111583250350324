import ToggleSwitch from "src/components/UI/ToggleSwitch/ToggleSwitch";
import classes from "./BillingVATDetails.module.css";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useLocaleContext } from "src/context/locale-context";
import { AnimatePresence, motion } from "framer-motion";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { countries, getCountryCode } from "countries-list";
import { useSelector, useDispatch } from "react-redux";
import {
  bookingActions,
  selectBillingData,
  selectValidationAttempts,
  selectisFormIncomplete,
} from "src/store/booking";
import { BILLING_TYPE, BOOKING_STEP } from "src/constants";
import { useDeviceContext } from "src/context/device-context";
import { CompanyVATDetails } from "./CompanyVATDetails";
import { PersonalVATDetails } from "./PersonalVATDetails";
import { getDefaultCountryCode, sortDictionaryAtoZ } from "src/utils/utils";

const FORM_PLACEHOLDER = {
  lv_LV: {
    person: {
      name: "piem., Jānis",
      surname: "piem., Priede",
      address: "piem., Meža iela 15-4",
    },
    company: {
      companyName: "piem., Mana Kompānija, SIA",
      companyID: "piem., 00000000000",
      VATNumber: "piem., LV00000000000",
      address: "piem., Meža iela 15-4",
    },
  },
  lt_LT: {
    person: {
      name: "pvz., Jonas",
      surname: "pvz., Lukas",
      address: "pvz., Mežos g. 15-4",
    },
    company: {
      companyName: "pvz., Mano Kompanija, UAB",
      companyID: "pvz., 00000000000",
      VATNumber: "pvz., LT00000000000",
      address: "pvz., Mežos g. 15-4",
    },
  },
  et_EE: {
    person: {
      name: "esim., Joonas",
      surname: "esim., Jaak",
      address: "esim., Tammiku 15-4",
    },
    company: {
      companyName: "esim., Meie Kogu, OU",
      companyID: "esim., 00000000000",
      VATNumber: "esim., EE00000000000",
      address: "esim., Tammiku 15-4",
    },
  },
  en_GB: {
    person: {
      name: "e.g., John",
      surname: "e.g., Smith",
      address: "e.g., Street st. 15-20",
    },
    company: {
      companyName: "e.g., My Company Name Ltd",
      companyID: "e.g., 000000000000",
      VATNumber: "e.g., GB000000000000",
      address: "e.g.  Street st. 15-20",
    },
  },
  ru_RU: {
    person: {
      name: "напр., Иван",
      surname: "напр., Иванов",
      address: "напр., ул. Лесная 15-4",
    },
    company: {
      companyName: "напр., Моя Компания, OOO",
      companyID: "напр., 00000000000",
      VATNumber: "напр., XX00000000000",
      address: "напр., ул. Лесная 15-4",
    },
  },
};

const FIELD_INIT_STATE = { isValid: false, isActive: false, hasBeenTouched: false };

export function getCountryGroups() {
  const COUNTRIES = {};
  Object.values(countries).forEach(
    (country) => (COUNTRIES[getCountryCode(country.name)] = country.name)
  );

  const firstGroup = {};
  const otherGroup = {};

  Object.entries(COUNTRIES).forEach((entry) => {
    if (entry[0] === getDefaultCountryCode()) {
      firstGroup[entry[0]] = entry[1];
    } else {
      otherGroup[entry[0]] = entry[1];
    }
  });

  return [firstGroup, sortDictionaryAtoZ(otherGroup)];
}

const BillingVATDetails = forwardRef(function BillingVATDetails(props, ref) {
  const { stringRes, currentLocale } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  const storedBillingData = useSelector(selectBillingData);

  const [isInit, setIsInit] = useState(true);
  const [values, setValues] = useState({
    person: {
      name: storedBillingData.person.name,
      surname: storedBillingData.person.surname,
      address: storedBillingData.person.address,
      country: storedBillingData.person.country,
    },
    company: {
      companyName: storedBillingData.company.companyName,
      companyID: storedBillingData.company.companyID,
      VATNumber: storedBillingData.company.VATNumber,
      address: storedBillingData.company.address,
      country: storedBillingData.company.country,
    },
  });

  const [state, setState] = useState({
    person: {
      name: { ...FIELD_INIT_STATE },
      surname: { ...FIELD_INIT_STATE },
      address: { ...FIELD_INIT_STATE },
      country: { ...FIELD_INIT_STATE },
    },
    company: {
      companyName: { ...FIELD_INIT_STATE },
      companyID: { ...FIELD_INIT_STATE },
      VATNumber: { ...FIELD_INIT_STATE },
      address: { ...FIELD_INIT_STATE },
      country: { ...FIELD_INIT_STATE },
    },
  });

  const validationAttempts = useSelector((state) =>
    selectValidationAttempts(state, BOOKING_STEP.checkoutPage.id)
  );
  const isFormIncomplete = useSelector((state) =>
    selectisFormIncomplete(state, BOOKING_STEP.checkoutPage.id)
  );

  useEffect(() => {
    if (isFormIncomplete) {
      const type = storedBillingData.isCompany ? "company" : "person";
      setState((prev) => {
        return {
          ...prev,
          [type]: Object.fromEntries(
            Object.keys(prev[type]).map((k) => [k, { ...prev[type][k], hasBeenTouched: true }])
          ),
        };
      });
    }
  }, [isFormIncomplete, validationAttempts]);

  const formRef = useRef();

  const onToggleVATData = () => {
    dispatch(bookingActions.toggleBillingRequired());
  };

  const onVATTypeChange = (type) => {
    const fieldType = type === BILLING_TYPE.company ? "company" : "person";
    setState((prev) => {
      return {
        ...prev,
        [fieldType]: Object.fromEntries(
          Object.keys(prev[fieldType]).map((k) => [k, { ...FIELD_INIT_STATE }])
        ),
      };
    });
    dispatch(bookingActions.changeBillingType(type));
  };

  const scrollFormIntoView = (isFormAppearing) => {
    if (isFormAppearing && !isInit) {
      formRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const isValidField = (field) => {
    return !field.hasBeenTouched || field.isValid;
  };

  const setIsValidField = (field, isValid, isCompany) => {
    const type = isCompany ? "company" : "person";
    setState((prev) => {
      return {
        ...prev,
        [type]: {
          ...prev[type],
          [field]: { ...prev[type][field], isValid: isValid },
        },
      };
    });
  };

  const onChangeCompanyField = (fieldName, value) => {
    setValues((prev) => {
      return { ...prev, company: { ...prev.company, [fieldName]: value } };
    });
    setIsValidField(fieldName, !!value, true);
  };

  const onChangePersonalField = (fieldName, value) => {
    setValues((prev) => {
      return { ...prev, person: { ...prev.person, [fieldName]: value } };
    });
    setIsValidField(fieldName, !!value);
  };

  const onFocusField = (field, isCompany) => {
    const type = isCompany ? "company" : "person";
    setState((prev) => {
      return {
        ...prev,
        [type]: {
          ...prev[type],
          [field]: {
            isValid: !!values[type][field],
            isActive: true,
            hasBeenTouched: true,
          },
        },
      };
    });
  };

  const onBlurField = (field, isCompany) => {
    const type = isCompany ? "company" : "person";
    setState((prev) => {
      return {
        ...prev,
        [type]: {
          ...prev[type],
          [field]: { ...prev[type][field], isActive: false },
        },
      };
    });

    if (storedBillingData.isCompany) {
      if (values.company[field] !== storedBillingData.company[field]) {
        dispatch(bookingActions.updateCompanyBillingData(values.company));
      }
    } else {
      if (values.person[field] !== storedBillingData.person[field]) {
        dispatch(bookingActions.updatePersonalBillingData(values.person));
      }
    }
  };

  const childProps = {
    values,
    isValidField,
    onChangeCompanyField,
    onChangePersonalField,
    onFocusField,
    onBlurField,
    state,
  };

  return (
    <div
      ref={ref}
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
      {isMobile && <h2>{stringRes["booking.checkout.billing.title.mob"]}</h2>}
      <div className={classes.top}>
        <h3>{stringRes["booking.checkout.billing.vat.option"]}</h3>
        <ToggleSwitch
          id="vat-invoice-opt-in"
          isChecked={storedBillingData.isVATRequired}
          onChange={onToggleVATData}
        />
      </div>
      <AnimatePresence>
        {storedBillingData.isVATRequired && (
          <motion.div
            ref={formRef}
            key="invoice-form-component"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onAnimationStart={(property) => scrollFormIntoView(property.opacity > 0)}
            transition={{ duration: 0.25, ease: "circIn" }}>
            <form name="vat-invoice-request-form" className={classes.vat}>
              <fieldset id="vat-invoice-type-select" className={classes.vatTypes}>
                <RadioButton
                  className={classes.vatType}
                  id="vat-invoice-type-company"
                  name="vat-invoice-type"
                  onChange={() => onVATTypeChange(BILLING_TYPE.company)}
                  checked={storedBillingData.isCompany}>
                  <span>{stringRes["booking.checkout.billing.vat.company"]}</span>
                </RadioButton>
                <RadioButton
                  className={classes.vatType}
                  id="vat-invoice-type-person"
                  name="vat-invoice-type"
                  onChange={() => onVATTypeChange(BILLING_TYPE.person)}
                  checked={!storedBillingData.isCompany}>
                  <span>{stringRes["booking.checkout.billing.vat.person"]}</span>
                </RadioButton>
              </fieldset>
              {storedBillingData.isCompany ? (
                <CompanyVATDetails
                  {...childProps}
                  placeholder={FORM_PLACEHOLDER[currentLocale].company}
                />
              ) : (
                <PersonalVATDetails
                  {...childProps}
                  placeholder={FORM_PLACEHOLDER[currentLocale].person}
                />
              )}
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default BillingVATDetails;
