import { useEffect, useRef } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./StepContent.module.css";
import { useLocaleContext } from "src/context/locale-context";
import BookingStepTitle from "../StepTitle/BookingStepTitle";
import BookingSidePanel from "../SidePanel/BookingSidePanel";
import { MobileBottomElement } from "../MobileBottomElement/MobileBottomElement";
import ExtraServicesSelection from "../ExtraServicesSelection/ExtraServicesSelection";
import { BookingNextButton } from "src/components/features/booking/StepContent/BookingNextButton";
import { BookingBackButton } from "src/components/features/booking/StepContent/BookingBackButton";
import { useBookingFlow } from "src/context/booking-flow-context";

function ExtrasStepContent({ pageId }) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { goToNextBookingPage, goToPrevBookingPage, hasNext, hasPrevious } =
    useBookingFlow();

  const serviceRef = useRef();

  useEffect(() => {
    const page = document.getElementById("booking-page");
    if (isMobile) {
      page.style.backgroundColor = "transparent";
    }
    return () => {
      page.style.backgroundColor = "initial";
    };
  }, [isMobile]);

  const onGoToPrevStep = () => {
    goToPrevBookingPage(pageId);
  };

  const onGoToNextStep = () => {
    goToNextBookingPage(pageId);
  };

  if (!isMobile) {
    return (
      <section className={`${classes.container} ${classes.desktop}`}>
        <BookingStepTitle step={pageId} />
        <div className={classes.main}>
          <ExtraServicesSelection ref={serviceRef} />
        </div>
        <BookingSidePanel />
        <div className={classes.nextButton}>
          {hasPrevious(pageId) ? (
            <BookingBackButton
              onClick={onGoToPrevStep}
              className={classes.withBackArr}
              buttonText={stringRes["booking.back.button"]}
            />
          ) : (
            <div></div>
          )}
          {hasNext(pageId) ? (
            <BookingNextButton
              onClick={onGoToNextStep}
              className={classes.withArr}
              buttonText={stringRes["booking.continue.button"]}
            />
          ) : (
            <div></div>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className={`${classes.container} ${classes.mobile} ${classes.extras}`}>
        <BookingStepTitle step={pageId} />
        <ExtraServicesSelection ref={serviceRef} />
        <MobileBottomElement onGoToNextStep={onGoToNextStep} />
      </section>
    );
  }
}

export default ExtrasStepContent;
