import whatsappImg from "src/assets/img/share_vendors/whatsapp.png";
import fbMessengerImg from "src/assets/img/share_vendors/messenger.png";
import iosMessagesImg from "src/assets/img/share_vendors/messages.png";
import telegramImg from "src/assets/img/share_vendors/telegram.png";
import viberImg from "src/assets/img/share_vendors/viber.png";

export function WhatsAppIcon() {
  return <img src={whatsappImg} alt="whatsapp" />;
}

export function MessengerIcon() {
  return <img src={fbMessengerImg} alt="facebook messenger" />;
}

export function IOSMessagesIcon() {
  return <img src={iosMessagesImg} alt="IOS messages" />;
}

export function TelegramIcon() {
  return <img src={telegramImg} alt="telegram" />;
}

export function ViberIcon() {
  return <img src={viberImg} alt="viber" />;
}

export function EmailIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3337_19722)">
        <rect width="20" height="20" fill="white" />
        <path
          d="M19.9617 4.61816L12.9467 11.6332C12.1645 12.4134 11.1048 12.8515 10 12.8515C8.89521 12.8515 7.83552 12.4134 7.05333 11.6332L0.0383333 4.61816C0.0266667 4.74983 0 4.869 0 4.99983V14.9998C0.00132321 16.1045 0.440735 17.1635 1.22185 17.9446C2.00296 18.7258 3.062 19.1652 4.16667 19.1665H15.8333C16.938 19.1652 17.997 18.7258 18.7782 17.9446C19.5593 17.1635 19.9987 16.1045 20 14.9998V4.99983C20 4.869 19.9733 4.74983 19.9617 4.61816Z"
          fill="#001B38"
        />
        <path
          d="M11.7685 10.4547L19.3801 2.84217C19.0114 2.23077 18.4913 1.72471 17.8701 1.37278C17.2489 1.02086 16.5474 0.834958 15.8335 0.833008H4.16678C3.4528 0.834958 2.75137 1.02086 2.13014 1.37278C1.50891 1.72471 0.988849 2.23077 0.620117 2.84217L8.23178 10.4547C8.70143 10.9224 9.33727 11.1851 10.0001 11.1851C10.663 11.1851 11.2988 10.9224 11.7685 10.4547Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_3337_19722">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
