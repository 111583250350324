import { Fragment } from "react";
import {
  BAGGAGE,
  getBaggageWeight,
  hasFreeBaggageIncluded,
} from "src/utils/baggage-utils";
import {
  BaggageCarryOn,
  BaggageChecked,
  BaggagePersonal,
} from "src/components/UI/BaggageItem/BaggageItem";
import classes from "./BaggageIndicatorsList.module.css";

export function BaggageElement({ type, hasFree, weight }) {
  let icon;
  if (type === BAGGAGE.personal) {
    icon = <BaggagePersonal />;
  } else if (type === BAGGAGE.cabin) {
    icon = <BaggageCarryOn isIncluded={hasFree} />;
  } else if (type === BAGGAGE.checked) {
    icon = <BaggageChecked isIncluded={hasFree} />;
  } else {
    return null;
  }

  return (
    <div className={classes.baggageIcon}>
      {icon}
      {hasFree && <div className={classes.weight}>{weight}</div>}
    </div>
  );
}

function BaggageIndicatorsList({ flightPackage }) {
  return (
    <Fragment>
      {Object.values(BAGGAGE)
        .filter((v) => v !== BAGGAGE.cabinBundle)
        .map((v) => {
          const hasFree = hasFreeBaggageIncluded(flightPackage.baggage, v);
          let baggage = flightPackage.baggage[v]?.[0];
          const weight = baggage ? getBaggageWeight(baggage) : "";
          return <BaggageElement key={v} type={v} hasFree={hasFree} weight={weight} />;
        })}
    </Fragment>
  );
}

export default BaggageIndicatorsList;
