import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  linkWithCredential,
  OAuthProvider,
  sendEmailVerification,
  EmailAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, fbProvider, googleProvider } from "src/services/firebase";
import { LOGIN_VENDOR } from "src/constants";
import { customLog } from "src/utils/utils";
import { Outlet } from "react-router-dom";

const AuthContext = createContext({
  login: (vendor, email, password, onSuccess, onError) => {},
  logout: () => {},
  signup: (email, password, onSuccess, onError) => {},
  triggerPasswordReset: (email, onSuccess, onError) => {},
  user: null,
  loaded: false,
  isAuthenticated: false,
});

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [pendingCred, setPendingCred] = useState(null);

  async function loginWithEmail(email, password, onSuccess, onError) {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      onSuccessHandler(result, EmailAuthProvider.credential(email, password), onSuccess);
    } catch (error) {
      customLog(error);
      if (onError) onError(error);
    }
  }

  async function linkPendingCredToUser(user) {
    try {
      if (pendingCred) {
        let userdata = await linkWithCredential(user, pendingCred);
        customLog(userdata);
        setPendingCred(null);
      }
    } catch (error) {
      customLog(error);
    }
  }

  function onSuccessHandler(result, credential, onSuccess) {
    if (!result.user.emailVerified) {
      sendEmailVerification(result.user, {
        url: window.location.href,
      });
    } else {
      linkPendingCredToUser(result.user);
    }
    const token = credential.accessToken;
    if (onSuccess) onSuccess();
  }

  function onErrorHandler(error, onError) {
    customLog(error);
    const errorCode = error.code;
    if (error.code === "auth/account-exists-with-different-credential") {
      const credential = OAuthProvider.credentialFromError(error);
      setPendingCred(credential);
    }
    if (onError) onError(errorCode);
  }

  async function loginWithGoogle(onSuccess, onError) {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      onSuccessHandler(result, GoogleAuthProvider.credentialFromResult(result), onSuccess);
    } catch (error) {
      onErrorHandler(error, onError);
    }
  }

  async function loginWithFacebook(onSuccess, onError) {
    try {
      const result = await signInWithPopup(auth, fbProvider);
      onSuccessHandler(result, FacebookAuthProvider.credentialFromResult(result), onSuccess);
    } catch (error) {
      onErrorHandler(error, onError);
    }
  }

  function login(vendor, email, password, onSuccess, onError) {
    switch (vendor) {
      case LOGIN_VENDOR.google:
        return loginWithGoogle(onSuccess, onError);
      case LOGIN_VENDOR.facebook:
        return loginWithFacebook(onSuccess, onError);
      case LOGIN_VENDOR.apple:
        return null;
      default:
        return loginWithEmail(email, password, onSuccess, onError);
    }
  }

  async function signup(email, password, onSuccess, onError) {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      if (!result.user.emailVerified) {
        await sendEmailVerification(result.user, {
          url: window.location.href,
        });
      }
      customLog(result.user);
      if (onSuccess) onSuccess();
    } catch (error) {
      onErrorHandler(error, onError);
    }
  }

  async function passwordReset(email, onSuccess, onError) {
    try {
      const result = await sendPasswordResetEmail(auth, email, {
        url: window.location.href,
      });
      customLog(result);
      if (onSuccess) onSuccess();
    } catch (error) {
      customLog(error);
      if (onError) onError();
    }
  }

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);
      setLoaded(true);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        triggerPasswordReset: passwordReset,
        login,
        signup,
        loaded,
        logout,
        isAuthenticated: !!user,
      }}>
      <Outlet />
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
