export function WarningIcon({ size = 12 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6.5" r="5.5" fill="#D21C1C" stroke="#D21C1C" />
      <mask
        id="path-2-outside-1_3309_16578"
        maskUnits="userSpaceOnUse"
        x="3.72266"
        y="2"
        width="4"
        height="9"
        fill="black"
      >
        <rect fill="white" x="3.72266" y="2" width="4" height="9" />
        <path d="M6.42122 7.264H5.52522L5.35722 3.664H6.58122L6.42122 7.264ZM5.97322 9.08C5.62122 9.08 5.33322 8.784 5.33322 8.44C5.33322 8.088 5.62122 7.792 5.97322 7.792C6.32522 7.792 6.62122 8.088 6.62122 8.44C6.62122 8.784 6.32522 9.08 5.97322 9.08Z" />
      </mask>
      <path
        d="M6.42122 7.264H5.52522L5.35722 3.664H6.58122L6.42122 7.264ZM5.97322 9.08C5.62122 9.08 5.33322 8.784 5.33322 8.44C5.33322 8.088 5.62122 7.792 5.97322 7.792C6.32522 7.792 6.62122 8.088 6.62122 8.44C6.62122 8.784 6.32522 9.08 5.97322 9.08Z"
        fill="white"
      />
      <path
        d="M6.42122 7.264V8.264H7.37776L7.42023 7.3084L6.42122 7.264ZM5.52522 7.264L4.52631 7.31062L4.5708 8.264H5.52522V7.264ZM5.35722 3.664V2.664H4.30946L4.35831 3.71062L5.35722 3.664ZM6.58122 3.664L7.58023 3.7084L7.62665 2.664H6.58122V3.664ZM6.42122 6.264H5.52522V8.264H6.42122V6.264ZM6.52413 7.21738L6.35613 3.61738L4.35831 3.71062L4.52631 7.31062L6.52413 7.21738ZM5.35722 4.664H6.58122V2.664H5.35722V4.664ZM5.58221 3.6196L5.42221 7.2196L7.42023 7.3084L7.58023 3.7084L5.58221 3.6196ZM5.97322 8.08C6.08351 8.08 6.17383 8.12691 6.23135 8.18493C6.28768 8.24175 6.33322 8.32989 6.33322 8.44H4.33322C4.33322 9.32817 5.06086 10.08 5.97322 10.08V8.08ZM6.33322 8.44C6.33322 8.6241 6.18955 8.792 5.97322 8.792V6.792C5.05289 6.792 4.33322 7.5519 4.33322 8.44H6.33322ZM5.97322 8.792C5.86448 8.792 5.77738 8.74637 5.72211 8.69111C5.66684 8.63584 5.62122 8.54874 5.62122 8.44H7.62122C7.62122 7.53572 6.8775 6.792 5.97322 6.792V8.792ZM5.62122 8.44C5.62122 8.32128 5.67152 8.23204 5.72513 8.17889C5.77929 8.12519 5.86492 8.08 5.97322 8.08V10.08C6.86946 10.08 7.62122 9.34429 7.62122 8.44H5.62122Z"
        fill="#D21C1C"
        mask="url(#path-2-outside-1_3309_16578)"
      />
    </svg>
  );
}
