import ActiveFilters from "./ActiveFilters/ActiveFilters";
import { useSelector } from "react-redux";
import { selectIsAnyFilterOn } from "src/store/filter-sort";
import { ExpandingFilterList } from "./ExpandingFilterList/ExpandingFilterList";
import classes from "./ResultsLeftPanel.module.css";
import { Fragment, useEffect, useState } from "react";
import useResizeHeightObserver from "src/hooks/useResizeHeightObserver";
import { useLocaleContext } from "./../../../context/locale-context";
import Spinner from "src/components/UI/Spinner/Spinner";

function DesktopPriceAlertsSection() {
  return <div style={{ display: "none" }}>price alerts</div>;
}

function B2BBlock() {
  return (
    <div className={classes.b2b} style={{ display: "none" }}>
      b2b block
    </div>
  );
}

function ResultsLeftPanel({ isLoading, filteredResults, totalResults }) {
  const { stringRes } = useLocaleContext();
  const isAnyFilterOn = useSelector(selectIsAnyFilterOn);
  const isReceivingData = useSelector((state) => state.filterSort.isReceivingData);

  const { ref: panelRef, height: panelHeight } = useResizeHeightObserver();
  const [scrollYPos, setScrollYPos] = useState({ curr: 0, prev: 0 });
  const [panelTopPos, setPanelTop] = useState(0);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const scrollYOffset = window.scrollY;
      setScrollYPos((prev) => {
        return { prev: prev.curr, curr: scrollYOffset };
      });
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const scrollDiff = scrollYPos.prev - scrollYPos.curr;
    const extraPadding = 2 * 16;
    const resultsHeaderBottomPos = document
      .getElementsByClassName("results-header")[0]
      .getBoundingClientRect().bottom;
    const visibleWindowHeight = window.innerHeight;
    const topPositionAtStop = panelHeight - visibleWindowHeight;

    const topToBottomDistance = panelRef.current.getBoundingClientRect().bottom;
    const topToTopDistance = panelRef.current.getBoundingClientRect().top;

    if (panelHeight <= visibleWindowHeight - resultsHeaderBottomPos) {
      setPanelTop(resultsHeaderBottomPos + extraPadding);
    } else if (scrollDiff <= 0 && visibleWindowHeight - topToBottomDistance > 17) {
      if (!isBottom) {
        setIsBottom(true);
        setPanelTop(-topPositionAtStop);
      }
    } else if (scrollDiff > 0 && topToTopDistance >= resultsHeaderBottomPos) {
      setPanelTop(resultsHeaderBottomPos + extraPadding);
    } else {
      setPanelTop((prevTopPos) => prevTopPos + scrollDiff);
    }

    if (scrollDiff > 0) {
      setIsBottom(false);
    }
  }, [scrollYPos.curr, window.innerHeight]);

  const hasFinishedUpdatingWithResults =
    filteredResults > 0 && totalResults > 0 && !isLoading && !isReceivingData;

  return (
    <section
      ref={panelRef}
      style={{ alignSelf: "start", top: `${panelTopPos / 16}rem` }}
      className="left-panel">
      {isLoading ? (
        <ExpandingFilterList />
      ) : (
        <Fragment>
          <DesktopPriceAlertsSection />
          {hasFinishedUpdatingWithResults && (
            <div className={classes.resultNum}>
              <b>{filteredResults}</b>
              {` ${stringRes["res.panel.filter.of"]} ${totalResults} ${stringRes["res.panel.filter.flights"]}`}
            </div>
          )}
          {isReceivingData && (
            <div className={classes.tempResults}>
              <Spinner />
              <span>
                {stringRes["res.panel.filter.foundSoFar"].replace(
                  "##RESULT_NUM##",
                  totalResults
                )}
              </span>
            </div>
          )}
          {isAnyFilterOn && <ActiveFilters />}
          <ExpandingFilterList />
          <B2BBlock />
        </Fragment>
      )}
    </section>
  );
}

export default ResultsLeftPanel;
