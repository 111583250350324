function SeatsIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_35_3524)">
        <path
          opacity="0.978"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20312 -0.015625C5.42188 -0.015625 5.64062 -0.015625 5.85938 -0.015625C6.77819 0.269145 7.21047 0.894144 7.15625 1.85938C6.96684 2.73625 6.42519 3.21541 5.53125 3.29688C4.51634 3.19863 3.95906 2.64134 3.85938 1.625C3.92081 0.755509 4.36872 0.208636 5.20312 -0.015625Z"
          fill="var(--primary-1)"
        />
        <path
          opacity="0.979"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.39063 4.07812C1.95313 4.07812 2.51563 4.07812 3.07813 4.07812C3.07292 6.63022 3.07813 9.18231 3.09375 11.7344C3.28997 12.8785 3.95144 13.5921 5.07813 13.875C6.86891 13.921 8.66057 13.9366 10.4531 13.9219C10.4531 14.474 10.4531 15.026 10.4531 15.5781C8.651 15.5833 6.84894 15.5781 5.04688 15.5625C3.28347 15.2887 2.12201 14.3043 1.5625 12.6094C1.5074 12.4038 1.46573 12.1954 1.4375 11.9844C1.39194 9.34941 1.37632 6.714 1.39063 4.07812Z"
          fill="var(--primary-1)"
        />
        <path
          opacity="0.984"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4218 15.984C13.3906 15.984 13.3593 15.984 13.3281 15.984C12.3969 15.0372 11.4594 14.0945 10.5156 13.1559C9.10934 13.1455 7.70309 13.1351 6.29684 13.1246C5.26081 13.0627 4.51603 12.5679 4.06247 11.6403C3.99131 11.458 3.93922 11.2705 3.90622 11.0778C3.87078 10.1515 3.85515 9.22443 3.85934 8.29652C3.85503 7.37896 3.87065 6.46227 3.90622 5.54652C4.22278 4.48418 4.93631 3.9998 6.04684 4.0934C6.40003 4.15546 6.71253 4.30127 6.98434 4.5309C7.5219 5.13655 8.074 5.7303 8.64059 6.31215C9.64978 7.12955 10.806 7.56184 12.1093 7.60902C12.1093 8.21318 12.1093 8.81737 12.1093 9.42152C10.4176 9.30746 8.91237 8.72415 7.59372 7.67152C7.57809 8.66105 7.57287 9.65065 7.57809 10.6403C8.52606 10.6351 9.47397 10.6403 10.4218 10.6559C11.8072 12.0413 13.1927 13.4267 14.5781 14.8121C14.1864 15.1986 13.8009 15.5893 13.4218 15.984Z"
          fill="var(--primary-1)"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_3524">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SeatsIcon;
