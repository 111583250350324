export function ClockIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.27735 7.75598L10.8513 10.1166C11.078 10.3233 11.0927 10.6746 10.886 10.9013C10.7856 11.0084 10.6472 11.0718 10.5005 11.0781C10.3538 11.0843 10.2105 11.0328 10.1013 10.9346L7.34602 8.40998C7.2892 8.35787 7.24385 8.29451 7.21286 8.22392C7.18187 8.15334 7.16592 8.07707 7.16602 7.99998V4.94464C7.16602 4.79727 7.22456 4.65594 7.32877 4.55173C7.43297 4.44752 7.57431 4.38898 7.72168 4.38898C7.86905 4.38898 8.01039 4.44752 8.1146 4.55173C8.21881 4.65594 8.27735 4.79727 8.27735 4.94464V7.75598Z"
        fill="#5A6872"
      />
      <path
        d="M8.00065 1.33331C4.31865 1.33331 1.33398 4.31798 1.33398 7.99998C1.33398 11.682 4.31865 14.6666 8.00065 14.6666C11.6827 14.6666 14.6673 11.682 14.6673 7.99998C14.6633 4.31998 11.6807 1.33731 8.00065 1.33331ZM8.00065 13.5553C6.90191 13.5553 5.82784 13.2295 4.91427 12.6191C4.0007 12.0086 3.28866 11.141 2.86819 10.1259C2.44772 9.11081 2.33771 7.99382 2.55206 6.91619C2.76642 5.83856 3.29551 4.84869 4.07244 4.07177C4.84936 3.29484 5.83923 2.76574 6.91686 2.55139C7.99449 2.33704 9.11148 2.44705 10.1266 2.86752C11.1417 3.28799 12.0093 4.00003 12.6197 4.9136C13.2302 5.82717 13.556 6.90124 13.556 7.99998C13.5544 9.47286 12.9686 10.885 11.9271 11.9264C10.8856 12.9679 9.47353 13.5537 8.00065 13.5553Z"
        fill="#5A6872"
      />
    </svg>
  );
}
