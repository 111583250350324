import { AnimatePresence, motion } from "framer-motion";
import { useLocaleContext } from "src/context/locale-context";
import { PAYMENT_TYPE } from "../PaymentOptions";
import classes from "../PaymentOptions.module.css";

export function SelectedType({ type, content, onChange }) {
  const { stringRes } = useLocaleContext();

  return (
    <motion.div className={classes.selectedType}>
      <div style={{ marginBottom: content ? "1.75rem" : "0" }} className={classes.header}>
        <div>
          <span className={classes.icon}>{PAYMENT_TYPE[type].icon}</span>
          <span>{stringRes[PAYMENT_TYPE[type].strKey]}</span>
        </div>
        <button onClick={onChange} className={classes.changeBtn}>
          {stringRes["booking.checkout.payment.option.change"]}
        </button>
      </div>
      <AnimatePresence>{content}</AnimatePresence>
    </motion.div>
  );
}
