import { useCallback, useEffect, useRef, useState } from "react";
import { PAYMENT_TYPE } from "src/components/features/booking/PaymentOptions/PaymentOptions";
import { useDeviceContext } from "src/context/device-context";

import { addListener } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  ERROR_MESSAGES,
  ERROR_STATUS_MESSAGE,
  TRANSALTED_STRINGS,
} from "src/assets/lang/errorMessages";
import { DesktopPaymentLinkHeader } from "src/components/features/booking/DesktopBookingHeader/DesktopBookingHeader";
import { validateStep } from "src/components/features/booking/StepContent/CheckoutStepContent";
import {
  DesktopPaymentByLinkContent,
  MobilePaymentByLinkContent,
  PaymentByLinkHeader,
} from "src/components/features/booking/StepContent/PaymentByLinkContent";
import { BOOKING_STEP } from "src/constants";
import { BOOKING } from "src/constants/booking";
import { FULL_CATALOGUE } from "src/constants/services";
import { useGlobalLoading } from "src/context/loading-context";
import { useLocaleContext } from "src/context/locale-context";
import { useWebSocket } from "src/hooks/useWebSocket";
import { paymentApi } from "src/services/api";
import {
  bookingActions,
  selectBillingData,
  selectContactData,
  selectPaymentData,
} from "src/store/booking";
import { catalogueActions } from "src/store/catalogue";
import { getOrderPayload } from "src/utils/booking-utils";
import { bookingListeners } from "src/utils/listeners";
import { getSelectedLocale } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { ErrorComponent } from "./ErrorBoundary";

function PageWrapper({ children, travelPackage }) {
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(catalogueActions.loadCatalogue(...FULL_CATALOGUE));
      dispatch(catalogueActions.loadServiceData(null));
      dispatch(catalogueActions.loadAncillaryData(null));
    };
  }, [dispatch]);

  return (
    <main
      id="booking-page"
      style={isMobile ? { marginTop: "-1rem" } : { gridTemplateRows: "5.125rem 1fr" }}
      className={isMobile ? "mobile" : "desktop"}>
      {isMobile && <PaymentByLinkHeader travelPackage={travelPackage} />}
      {!isMobile && <DesktopPaymentLinkHeader bookingNumber={travelPackage.booking_number} />}
      <section className="booking-content">{children}</section>
    </main>
  );
}

function BookingPaymentByLink() {
  const { isMobile } = useDeviceContext();
  const bref = useRef(null);
  const pref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { stringRes, currencySymbol, currentLocale } = useLocaleContext();
  const { correlationID, travelPackage } = useLoaderData();
  const [connectionError, setConnectionError] = useState(null);

  const storedBillingData = useSelector(selectBillingData);
  const contactData = useSelector(selectContactData);
  const paymentData = useSelector(selectPaymentData);
  const isTOSAgreed = useSelector((state) => state.booking.isTermsAgreed);
  const cartTotal = useSelector((state) => state.cartOverview.total);

  const { loading, setLoading } = useGlobalLoading();
  const isImmediatePayment = [
    PAYMENT_TYPE.paymentInitiation.key,
    PAYMENT_TYPE.cardPayments.key,
  ].includes(paymentData.type);

  const onConnect = useCallback(() => {
    console.log("connected");
    setConnectionError(null);
  }, []);

  const onDisconnect = useCallback(() => {
    console.log("disconnected");
  }, []);

  const onError = useCallback(
    (error) => {
      console.log(error);
      const time = new Date();
      customLog(
        `booking websocket Error: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
      );
      const lang = getSelectedLocale()?.split("_")[0] || "en";
      setConnectionError({
        errorMessage: ERROR_MESSAGES[lang].booking,
        statusText: ERROR_STATUS_MESSAGE[lang].booking,
        buttonText: TRANSALTED_STRINGS[lang].homepage,
      });
      setLoading(false);
    },
    [setLoading]
  );

  const onMessage = useCallback(
    (nextEvent) => {
      const time = new Date();
      customLog(
        `booking websocket: adding event: ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
      );
      if (nextEvent.command === BOOKING.CANCEL_ORDER) {
        navigate("/booking/cancelled/", {
          state: { id: correlationID, source: "booking" },
        });
      }
    },
    [correlationID, navigate]
  );

  const { isConnected, send } = useWebSocket(
    onConnect,
    onMessage,
    onDisconnect,
    onError,
    true,
    `booking/websocket/${correlationID}`
  );

  const sendWrapper = useCallback(
    (data) => {
      send({ ...data, visibleToUser: true });
    },
    [send]
  );

  useEffect(() => {
    setLoading(!isConnected, 0.5);
  }, [setLoading, isConnected]);

  useEffect(() => {
    const listenerOpts = [
      bookingListeners.getBillingInfoListenerOpts,
      bookingListeners.getTermsAcceptanceListenerOpts,
      bookingListeners.getPaymentTypeListenerOpts,
      bookingListeners.getOffersOptInListenerOpts,
    ];

    const unsubscribeList = listenerOpts.flatMap((getOptsFunc) => {
      const funcOpts = getOptsFunc(sendWrapper);
      if (Array.isArray(funcOpts)) {
        return funcOpts.map((getOpts) => dispatch(addListener(getOpts)));
      } else {
        return dispatch(addListener(funcOpts));
      }
    });

    return () => unsubscribeList.forEach((unsubsribe) => unsubsribe());
  }, [dispatch, sendWrapper]);

  const payButtonText = isImmediatePayment
    ? `${stringRes["booking.checkout.payment.pay"]} ${cartTotal} ${currencySymbol}`
    : stringRes["booking.checkout.order.place"];

  const onInitiatePayment = async () => {
    setLoading(true, 0.5);
    const [success, warningElement] = validateStep(
      storedBillingData,
      paymentData,
      isTOSAgreed,
      bref,
      pref
    );
    if (success) {
      dispatch(bookingActions.resetFormState(BOOKING_STEP.checkoutPage.id));
      sendWrapper({
        command: BOOKING.COMMIT_PAYMENT_LINK_PAGE,
        payload: {
          payment: {
            ...paymentData,
            amount: cartTotal,
            timestamp: Date.now(),
            status: "pending",
          },
        },
      });

      if (isImmediatePayment) {
        console.log("starting payment...");
        const lang = currentLocale.split("_")[0];
        const payload = getOrderPayload(
          correlationID,
          travelPackage.booking_number,
          lang,
          cartTotal,
          {
            ...paymentData,
            description:
              paymentData.type === PAYMENT_TYPE.cardPayments
                ? stringRes["payment.type.card.description.display"]
                : stringRes["payment.type.bank.description.display"],
          },
          storedBillingData,
          contactData.email
        );

        customLog(JSON.stringify(payload));
        paymentApi
          .processPaymentOrder(payload)
          .then((response) => {
            const paymentUrl = response.data;
            customLog(response.data);
            window.location.href = paymentUrl;
          })
          .catch((err) => {
            customLog(err);
            setLoading(false, null, 0.25);
            navigate("/order/" + correlationID + "/error", {
              state: { source: "bookingCheckout" },
            });
          });
      } else {
        setLoading(false, null, 0.25);
        navigate(
          "/order/" +
            correlationID +
            "/confirmation?type=" +
            paymentData.type +
            "&booking-number=" +
            travelPackage.booking_number,
          {
            state: { source: "bookingCheckout", paymentType: paymentData.type },
          }
        );
      }
    } else {
      setLoading(false, null, 0.25);
      dispatch(
        bookingActions.updateFormWarningStatus({
          step: BOOKING_STEP.checkoutPage.id,
          isIncomplete: true,
        })
      );
      dispatch(bookingActions.incrementValidationAttempts(BOOKING_STEP.checkoutPage.id));
      warningElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (connectionError) {
    return (
      <ErrorComponent
        errorHeader={connectionError.statusText}
        errorMessages={connectionError.errorMessage}
        buttonText={connectionError.buttonText}
      />
    );
  }

  if (!isConnected) return null;

  const pageProps = {
    onInitiatePayment,
    payButtonText,
    storedBillingData,
    contactData,
    paymentData,
    isTOSAgreed,
    cartTotal,
    bref,
    pref,
    loading,
  };

  return (
    <PageWrapper travelPackage={travelPackage}>
      {!isMobile && <DesktopPaymentByLinkContent {...pageProps} />}
      {isMobile && <MobilePaymentByLinkContent {...pageProps} />}
    </PageWrapper>
  );
}

export default BookingPaymentByLink;
