import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DotPulseLoader from "src/components/UI/DotPulseLoader/DotPulseLoader";
import {
  BankTransferIcon,
  CardIcon,
  InternetBankIcon,
  OfflinePaymentIcon,
} from "src/components/UI/Icon/PaymentTypeIcon";
import { BOOKING_STEP } from "src/constants";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { paymentApi } from "src/services/api";
import {
  bookingActions,
  selectPaymentData,
  selectValidationAttempts,
  selectisFormIncomplete,
} from "src/store/booking";
import { customLog } from "src/utils/utils";
import classes from "./PaymentOptions.module.css";
import { PaymentTermsComponent } from "./elements/PaymentTermsComponent";
import { SelectedType } from "./elements/SelectedType";
import { UnselectedPaymentOptions } from "./elements/UnselectedPaymentOptions";
import BankTransfer from "./elements/BankTransfer";
import OfflinePayment from "./elements/OfflinePayment";
import { PaymentSubtypes } from "./elements/PaymentSubtypes";

export const PAYMENT_TYPE = {
  paymentInitiation: {
    key: "paymentInitiation",
    strKey: "booking.checkout.payment.option.bank",
    icon: <InternetBankIcon />,
  },
  bankTransfer: {
    key: "bankTransfer",
    strKey: "booking.checkout.payment.option.transfer",
    icon: <BankTransferIcon />,
  },
  offlinePayment: {
    key: "offlinePayment",
    strKey: "booking.checkout.payment.option.offline",
    icon: <OfflinePaymentIcon />,
  },
  cardPayments: {
    key: "cardPayments",
    strKey: "booking.checkout.payment.option.card",
    icon: <CardIcon />,
  },
};

function LockIcon() {
  return (
    <span className={classes.lockIcon}>
      <svg
        className="group"
        width="8"
        height="10"
        viewBox="0 0 8 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.994"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6582 -0.00976562C3.87955 -0.00976562 4.10092 -0.00976562 4.32227 -0.00976562C5.65795 0.215809 6.50104 0.984037 6.85156 2.29492C6.88498 2.456 6.90451 2.61877 6.91016 2.7832C6.91992 3.10215 6.92318 3.42115 6.91992 3.74023C7.1544 3.73697 7.38877 3.74023 7.62305 3.75C7.67188 3.77279 7.70768 3.80859 7.73047 3.85742C7.7435 5.68686 7.7435 7.51627 7.73047 9.3457C7.6325 9.67479 7.4209 9.88963 7.0957 9.99023C5.02539 9.99023 2.95508 9.99023 0.884766 9.99023C0.559565 9.88963 0.347977 9.67479 0.25 9.3457C0.236979 7.51627 0.236979 5.68686 0.25 3.85742C0.272788 3.80859 0.308594 3.77279 0.357422 3.75C0.591708 3.74023 0.826083 3.73697 1.06055 3.74023C1.05729 3.42115 1.06055 3.10215 1.07031 2.7832C1.13768 1.77239 1.59016 0.987883 2.42773 0.429688C2.8101 0.199199 3.22025 0.0527152 3.6582 -0.00976562ZM3.75586 1.25977C4.55363 1.20765 5.13631 1.53317 5.50391 2.23633C5.55873 2.36174 5.59779 2.49195 5.62109 2.62695C5.64826 2.99738 5.65803 3.36848 5.65039 3.74023C4.54361 3.74023 3.43686 3.74023 2.33008 3.74023C2.32244 3.36848 2.33221 2.99738 2.35938 2.62695C2.53328 1.87033 2.99879 1.4146 3.75586 1.25977ZM3.87305 5.41992C4.43877 5.41277 4.75127 5.68947 4.81055 6.25C4.80369 6.51438 4.69627 6.72596 4.48828 6.88477C4.51399 7.27896 4.54979 7.67285 4.5957 8.06641C4.60721 8.17846 4.56162 8.25658 4.45898 8.30078C4.14648 8.31381 3.83398 8.31381 3.52148 8.30078C3.46127 8.28543 3.41897 8.24963 3.39453 8.19336C3.41164 7.78537 3.44744 7.37848 3.50195 6.97266C3.49869 6.94336 3.49545 6.91406 3.49219 6.88477C3.18201 6.62074 3.09738 6.29521 3.23828 5.9082C3.3743 5.64502 3.58588 5.48225 3.87305 5.41992Z"
          fill="#001B38"
        />
      </svg>
    </span>
  );
}

const PaymentOptions = forwardRef(function PaymentOptions(props, ref) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  const [isInit, setIsInit] = useState(true);
  const [isError, setIsError] = useState(false);

  const paymentData = useSelector(selectPaymentData);
  const selectedType = paymentData.type;
  const [isExpanded, setIsExpanded] = useState(selectedType ? true : false);
  const [vendorOptions, setVendorOptions] = useState({
    [PAYMENT_TYPE.cardPayments.key]: [],
    [PAYMENT_TYPE.paymentInitiation.key]: [],
    [PAYMENT_TYPE.bankTransfer.key]: [],
    [PAYMENT_TYPE.offlinePayment.key]: [],
  });

  const isTermsConditionsAgreed = useSelector((state) => state.booking.isTermsAgreed);

  const isFormIncomplete = useSelector((state) =>
    selectisFormIncomplete(state, BOOKING_STEP.checkoutPage.id)
  );
  const validationAttempts = useSelector((state) =>
    selectValidationAttempts(state, BOOKING_STEP.checkoutPage.id)
  );

  const [formState, setFormState] = useState({
    paymentMethod: { isValid: !!selectedType, hasBeenTouched: false },
    tos: { isValid: isTermsConditionsAgreed, hasBeenTouched: false },
  });

  useEffect(() => {
    if (!isInit && isFormIncomplete) {
      setFormState((fState) => {
        return {
          ...fState,
          paymentMethod: { isValid: !!selectedType, hasBeenTouched: true },
          tos: { isValid: isTermsConditionsAgreed, hasBeenTouched: true },
        };
      });
    }
  }, [isFormIncomplete, validationAttempts]);

  useEffect(() => {
    dispatch(bookingActions.updateTermsAgreementStatus(false));
    dispatch(bookingActions.updateSkipOffersEmailsStatus(false));

    function getPaymentOpts() {
      paymentApi
        .getPaymentMethods()
        .then((response) => {
          const { paymentMethods } = response.data;
          console.log(paymentMethods);
          const targetCountry = window.SERVER_DATA.REACT_APP_DEFAULT_DOMAIN_LOCALE ?? "en_GB";
          const countryCode = targetCountry.split("_")[1];
          setVendorOptions((vend) => {
            let updVend = { ...vend };
            updVend[PAYMENT_TYPE.paymentInitiation.key] =
              paymentMethods.paymentInitiation.setup[countryCode].paymentMethods;
            return updVend;
          });
        })
        .catch((err) => {
          customLog("Payment method fetching error occured: " + err);
          setIsError(true);
        })
        .finally(() => setIsInit(false));
    }

    getPaymentOpts();
  }, [dispatch]);

  const noteElement = (
    <div
      style={{
        marginTop:
          isExpanded && vendorOptions[selectedType].length > 0
            ? "1.8rem"
            : isMobile
            ? "1.25rem"
            : "1rem",
      }}
      className={classes.note}>
      <LockIcon />
      <span>{stringRes["booking.checkout.payment.note"]}</span>
    </div>
  );

  if (isInit) {
    return (
      <div className={`${classes.loading} ${isMobile ? classes.mobile : classes.desktop}`}>
        {isMobile && <h2>{stringRes["booking.checkout.payment.title"]}</h2>}
        <div
          style={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <DotPulseLoader />
        </div>
      </div>
    );
  } else if (isError) {
    return (
      <div className={`${classes.loading} ${isMobile ? classes.mobile : classes.desktop}`}>
        <p style={{ color: "var(--error)" }}>{stringRes["error.general.message"]}</p>
      </div>
    );
  } else {
    return (
      <div className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
        {isMobile && <h2>{stringRes["booking.checkout.payment.title"]}</h2>}
        {!selectedType || !isExpanded ? (
          <UnselectedPaymentOptions
            ref={ref}
            setIsExpanded={setIsExpanded}
            formState={formState}
            setFormState={setFormState}
            selectedType={selectedType}
          />
        ) : (
          <SelectedType
            type={selectedType}
            content={
              selectedType === PAYMENT_TYPE.bankTransfer.key ? (
                <BankTransfer />
              ) : selectedType === PAYMENT_TYPE.offlinePayment.key ? (
                <OfflinePayment />
              ) : selectedType === PAYMENT_TYPE.paymentInitiation.key ? (
                <PaymentSubtypes options={vendorOptions[selectedType]} />
              ) : null
            }
            options={vendorOptions[selectedType]}
            onChange={() => setIsExpanded(false)}
          />
        )}
        {!isMobile && noteElement}
        {isExpanded && (
          <PaymentTermsComponent ref={ref} setFormState={setFormState} formState={formState} />
        )}
        {isMobile && noteElement}
      </div>
    );
  }
});

export default PaymentOptions;
