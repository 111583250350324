import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import classes from "../PaymentOptions.module.css";
import { useLocaleContext } from "src/context/locale-context";

function BankTransfer() {
  const { stringRes } = useLocaleContext();
  return (
    <div className={classes.transfer}>
      <ul>
        <li>{stringRes["payment.options.print.invoice"]}</li>
        <li>{stringRes["payment.options.payment.options"]}</li>
      </ul>

      <FormInfoElement>
        <p>{stringRes["prepayment.invoice.info"]}</p>
      </FormInfoElement>
    </div>
  );
}

export default BankTransfer;
