import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Navigate, isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";
import {
  ERROR_MESSAGES,
  ERROR_STATUS_MESSAGE,
  TRANSALTED_STRINGS,
} from "src/assets/lang/errorMessages";
import Button from "src/components/UI/Button/Button";
import { MAX_SCREEN_WIDTH_MOBILE } from "src/constants";
import {
  getSelectedLocale,
  markSessionReloaded,
  markSessionUnlocked,
} from "src/utils/storage-utils";

export function ErrorComponent({ errorHeader, errorMessages }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: MAX_SCREEN_WIDTH_MOBILE });
  const lang = getSelectedLocale()?.split("_")[0] || "en";

  useEffect(() => {
    markSessionUnlocked();
    markSessionReloaded();
  }, []);

  const goToHomePage = () => {
    navigate("/");
  };
  return (
    <main id="unknown-error-occured" className={`body fullpage ${isMobile ? "mob" : "dsk"}`}>
      <div
        style={{
          width: isMobile ? "18rem" : "24rem",
          height: isMobile ? "12rem" : "15rem",
          backgroundColor: "var(--ui-3)",
        }}></div>
      <h1>{errorHeader}</h1>
      {errorMessages.length > 0 && (
        <p>
          {errorMessages.map((msg) => (
            <span key={msg}>{msg}</span>
          ))}
        </p>
      )}
      <Button
        opts={{
          paddingRight: "1.5rem",
          paddingLeft: "1.5rem",
          marginTop: errorMessages.length === 0 ? "1rem" : "0",
        }}
        name={TRANSALTED_STRINGS[lang].homepage}
        onClick={goToHomePage}
      />
    </main>
  );
}

function ErrorBoundary() {
  const lang = getSelectedLocale()?.split("_")[0] || "en";
  const error = useRouteError();
  let errorHeader = "";
  let errorMessage = [];
  let buttonText = TRANSALTED_STRINGS[lang].homepage;

  console.log(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Navigate to="/notfound" />;
    }

    if (error.status === 401) {
      errorHeader = ERROR_STATUS_MESSAGE[lang].notauthorized;
      errorMessage = ERROR_MESSAGES[lang].notauthorized;
      buttonText = TRANSALTED_STRINGS[lang].back;
    }

    if (error.status === 410) {
      errorHeader = ERROR_STATUS_MESSAGE[lang].expired;
      errorMessage = ERROR_MESSAGES[lang].expired;
    }

    if (error.status === 503) {
      errorHeader = ERROR_STATUS_MESSAGE[lang].apidown;
      errorMessage = ERROR_MESSAGES[lang].apidown;
    } else {
      errorHeader =
        error.statusText || error.data?.statusText || ERROR_STATUS_MESSAGE[lang].general;
      errorMessage = error.data?.errorMessage || ERROR_MESSAGES[lang].general;
    }
  } else {
    errorHeader = ERROR_STATUS_MESSAGE[lang].general;
    errorMessage = error.data?.errorMessage || ERROR_MESSAGES[lang].general;
  }

  return (
    <ErrorComponent
      buttonText={buttonText}
      errorHeader={errorHeader}
      errorMessages={errorMessage}
    />
  );
}

export default ErrorBoundary;
