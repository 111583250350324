import classes from "../PaymentOptions.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { PAYMENT_TYPE } from "../PaymentOptions";

export function PaymentType({ id }) {
  const { stringRes } = useLocaleContext();
  const paymentName = stringRes[PAYMENT_TYPE[id].strKey];
  const paymentIcon = PAYMENT_TYPE[id].icon;

  return (
    <div className={classes.details}>
      <div className={classes.icon}>{paymentIcon}</div>
      <div className={classes.name}>{paymentName}</div>
    </div>
  );
}
