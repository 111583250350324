import { forwardRef } from "react";
import { useLocaleContext } from "src/context/locale-context";
import classes from "./ProtectionGuarantee.module.css";
import { useDeviceContext } from "src/context/device-context";
import { Link } from "react-router-dom";
import { PROJECT_NAME } from "src/constants";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { BOOKING_STEP } from "src/constants";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { ShieldIcon } from "src/components/UI/Icon/ShieldIcon";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "src/store/order";
import { selectCatalogue, selectServicePrice } from "src/store/catalogue";
import { selectisFormIncomplete } from "src/store/booking";
import { getDisplayPriceValue } from "src/utils/utils";
import { Markup } from "interweave";

function GuaranteeOption({ serviceId, price, checked, onSelect }) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const isYes = serviceId === CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE;

  const optionTitle =
    stringRes[`booking.connection.guarantee.option.${isYes ? "yes" : "no"}`];

  const optionDesc = isYes
    ? [
        stringRes["booking.connection.guarantee.option.yes.feature1"].replace(
          "##PROJECT_NAME##",
          PROJECT_NAME
        ),
        stringRes["booking.connection.guarantee.option.yes.feature2"],
        stringRes["booking.connection.guarantee.option.yes.feature3"],
      ]
    : [stringRes["booking.connection.guarantee.option.no.desc"]];

  return (
    <RadioButton
      checked={checked}
      id={"guarantee-type-" + serviceId}
      name="connection-guarantee"
      className={`${classes.guaranteeType} ${checked ? classes.active : ""}`}
      onChange={(isChecked) => onSelect(isChecked, serviceId)}>
      <div className={classes.optionTitle}>
        <span>{optionTitle}</span>
        <span className={classes.price}>{`${price} ${currencySymbol}`}</span>
      </div>
      {isYes && (
        <div className={classes.optionDescription}>
          {optionDesc.map((desc, i) => (
            <span key={i} className={classes.feature}>
              <ShieldIcon color={"#12b169"} />
              <span>
                <Markup content={desc} />
              </span>
            </span>
          ))}
        </div>
      )}
    </RadioButton>
  );
}

const ProtectionGuarantee = forwardRef(function ProtectionGuarantee(props, ref) {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();
  const isFormIncomplete = useSelector((state) =>
    selectisFormIncomplete(state, BOOKING_STEP.passengerDetailsForm.id)
  );

  const currentService = useSelector(
    (state) => state.order.connectionProtection.serviceId
  );

  const showWarning = isFormIncomplete && !currentService;

  const noProtectionPrice = 0;
  const withProtectionPrice = useSelector((state) =>
    selectServicePrice(
      selectCatalogue(state),
      CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE
    )
  );

  const onSelectGuaranteeOption = (isChecked, serviceId) => {
    if (isChecked) {
      dispatch(orderActions.changeConnectionProtection(serviceId));
    }
  };

  return (
    <div
      ref={ref}
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
      <h1>{stringRes["booking.connection.guarantee.title"]}</h1>
      {!isMobile && <h2>{stringRes["booking.connection.guarantee.subtitle"]}</h2>}
      <p className={classes.description}>
        {!isMobile ? (
          <span>
            <Markup content={stringRes["booking.connection.guarantee.description"]} />{" "}
            <Link target="_blank" tabIndex="-1" to="/info/guarantee-rules">
              <Markup
                content={stringRes[
                  "booking.connection.guarantee.desc.guaranteeName"
                ].replace("##PROJECT_NAME##", PROJECT_NAME)}
              />
            </Link>
          </span>
        ) : (
          <span>
            <Markup content={stringRes["booking.mob.connection.guarantee.description"]} />{" "}
          </span>
        )}
      </p>
      {showWarning && (
        <p className={classes.warnText}>
          {`${stringRes["booking.connection.guarantee.option.warn"]}:`}
        </p>
      )}
      <div className={`${classes.guaranteeOpts} ${showWarning ? classes.warn : ""}`}>
        <GuaranteeOption
          checked={currentService === SERVICE_TYPE.REFUSED}
          onSelect={onSelectGuaranteeOption}
          serviceId={SERVICE_TYPE.REFUSED}
          price={getDisplayPriceValue(noProtectionPrice)}
        />
        <GuaranteeOption
          checked={currentService === CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE}
          onSelect={onSelectGuaranteeOption}
          serviceId={CATALOGUE_IDS.CONNECTION_PROTECTION_SERVICE}
          price={getDisplayPriceValue(withProtectionPrice)}
        />
      </div>
      {isMobile && (
        <Link
          target="_blank"
          className={classes.conditions}
          tabIndex="-1"
          to="/info/guarantee-rules">
          {stringRes["booking.mob.connection.guarantee.conditions"]}
        </Link>
      )}
    </div>
  );
});

export default ProtectionGuarantee;
