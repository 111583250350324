import { useEffect, useMemo, useRef, useState } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./StepContent.module.css";
import { useLocaleContext } from "src/context/locale-context";
import BookingStepTitle from "../StepTitle/BookingStepTitle";
import BookingSidePanel from "../SidePanel/BookingSidePanel";
import { useSelector } from "react-redux";
import { SecurityNote } from "../MobileBottomElement/MobileBottomElement";
import SeatsSelection from "../SeatsSelection/SeatsSelection";
import { getCarrierIcon, getAllFlights } from "src/utils/results-utils";
import { selectTravelPackage } from "src/store/booking";
import Button from "src/components/UI/Button/Button";
import { useBookingFlow } from "src/context/booking-flow-context";
import { BookingNextButton } from "src/components/features/booking/StepContent/BookingNextButton";
import { BookingBackButton } from "src/components/features/booking/StepContent/BookingBackButton";
import { SEAT_TYPE } from "src/constants";
import { selectAncillaryData } from "src/store/catalogue";
import { customLog } from "src/utils/utils";

export const CHANGE_EVENT = {
  next: "next",
  prev: "prev",
};

function SeatSelectionStepContent({ pageId }) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { goToNextBookingPage, goToPrevBookingPage, hasNext, hasPrevious } =
    useBookingFlow();

  const serviceRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [changeEvent, setChangeEvent] = useState("");
  const [currentVisibleFlight, setCurrentFlight] = useState(0);

  const travelPackage = useSelector(selectTravelPackage);
  const ancillaryData = useSelector(selectAncillaryData);

  const allFlightsData = useMemo(() => {
    const allSegments = getAllFlights(travelPackage);
    return allSegments.map((fl, i) => {
      return {
        segment: i,
        icon: getCarrierIcon(fl),
        srcCity: fl.dep.city.title,
        srcIata: fl.dep.airport.code,
        destCity: fl.arr.city.title,
        destIata: fl.arr.airport.code,
        airline: fl.carrier.code,
      };
    });
  }, [travelPackage]);

  const [airlineSeatOptions, setAirlineSeatOptions] = useState([]);
  const [noSeatsToChooseFlights, setNoSeatsToChooseFlights] = useState([]);

  useEffect(() => {
    const airlinesList = [...new Set(allFlightsData.map((fl) => fl.airline))];
    const airlineOpts = {};
    customLog(ancillaryData);

    for (let i = 0; i < airlinesList.length; i++) {
      const airline = airlinesList[i];
      let seatOpts = [];
      if (ancillaryData[airline]) {
        seatOpts = seatOpts.concat(
          Object.keys(ancillaryData[airline]).map((k) => SEAT_TYPE[k])
        );
      }
      airlineOpts[airline] = seatOpts;
    }
    setAirlineSeatOptions(airlineOpts);
    setNoSeatsToChooseFlights(
      allFlightsData.filter((fl) => airlineOpts[fl.airline].length === 0)
    );
    const timerId = setTimeout(() => {
      setIsLoading(false);
    }, 600);
    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    const page = document.getElementById("booking-page");
    if (isMobile) {
      page.style.backgroundColor = "white";
    }
    return () => {
      page.style.backgroundColor = "initial";
    };
  }, [isMobile]);

  const nextFlight = () => {
    setChangeEvent(CHANGE_EVENT.next);
    setTimeout(() => setChangeEvent(""), 25);
  };

  const prevFlight = () => {
    setChangeEvent(CHANGE_EVENT.prev);
    setTimeout(() => setChangeEvent(""), 25);
  };

  const onAfterChangeHandler = (flightIdx) => {
    setCurrentFlight(flightIdx);
  };

  const onGoToPrevStep = () => {
    goToPrevBookingPage(pageId);
  };

  const onGoToNextStep = () => {
    goToNextBookingPage(pageId);
  };

  const props = {
    isLoading,
    changeEvent,
    onAfterChange: onAfterChangeHandler,
    allFlightsData,
    airlineSeatOptions,
    noSeatsToChooseFlights,
  };

  const isLastFlight = currentVisibleFlight === allFlightsData.length - 1;
  const isFirstFlight = currentVisibleFlight === 0;

  const backwardButton =
    stringRes[isFirstFlight ? "booking.back.button" : "booking.seats.previous.button"];

  const forwardButton =
    stringRes[isLastFlight ? "booking.continue.button" : "booking.seats.next.button"];

  if (!isMobile) {
    return (
      <section className={`${classes.container} ${classes.desktop} ${classes.seats}`}>
        <BookingStepTitle step={pageId} />
        <div className={classes.main}>
          <SeatsSelection {...props} ref={serviceRef} />
        </div>
        <BookingSidePanel />
        {!isLoading && (
          <div className={classes.nextButton}>
            {hasPrevious(pageId) || !isFirstFlight ? (
              <BookingBackButton
                onClick={isFirstFlight ? onGoToPrevStep : prevFlight}
                className={classes.withBackArr}
                buttonText={backwardButton}
              />
            ) : (
              <div></div>
            )}

            {hasNext(pageId) || !isLastFlight ? (
              <BookingNextButton
                onClick={isLastFlight ? onGoToNextStep : nextFlight}
                className={classes.withArr}
                buttonText={forwardButton}
              />
            ) : (
              <div></div>
            )}
          </div>
        )}
      </section>
    );
  } else {
    return (
      <section className={`${classes.container} ${classes.mobile} ${classes.seats}`}>
        <BookingStepTitle step={pageId} />
        <SeatsSelection ref={serviceRef} {...props} />
        {!isLoading && (
          <div className={classes.navButtons}>
            <Button
              wide
              secondary
              name={
                <span className={classes.withBackArr}>
                  {stringRes["booking.seats.previous.button"]}
                </span>
              }
              onClick={prevFlight}
            />
            <Button
              wide
              name={<span className={classes.withArr}>{forwardButton}</span>}
              onClick={isLastFlight ? onGoToNextStep : nextFlight}
            />
          </div>
        )}
        {!isLoading && <SecurityNote />}
      </section>
    );
  }
}

export default SeatSelectionStepContent;
