import { useDeviceContext } from "src/context/device-context";
import { DesktopResultItem } from "./DesktopResultItem";
import { MobileResultItem } from "./MobileResultItem";
import { MODAL_TYPE, useModalContext } from "src/context/modal-stack-context";

export function ResultsItem({ item, isCheapest, isBest, onSelect, onShowDetails }) {
  const { isMobile } = useDeviceContext();
  const { openModal } = useModalContext();

  const props = {
    item,
    isOneway: item.segments_direction.length === 1,
    isCheapest,
    isBest,
    onShowDetailsHandler: () => onShowDetails(item),
    onShareHandler: () => {},
      // openModal(MODAL_TYPE.shareOptions, {
      //   onSelectOption: (option) => console.log(`${option} share type selected`),
      //   onCopy: (link) => console.log(`link copied: ${link}`),
      // }),
    onFavoriteHandler: () => {},
    onSelectResult: () => onSelect(item),
  };

  return isMobile ? <MobileResultItem {...props} /> : <DesktopResultItem {...props} />;
}
