import { useEffect, useState } from "react";
import classes from "./Tooltip.module.css";
import { Tooltip } from "react-tooltip";

export function DarkTooltip({ children, anchorId }) {
  return (
    <Tooltip
      offset={15}
      className={`${classes.tooltip} ${classes.dark}`}
      opacity={1}
      anchorSelect={`#${anchorId}`}
      place="bottom">
      {children}
    </Tooltip>
  );
}

export function FormTooltip({ isOn, isWarning, children, rightPos, bottomPos, width }) {
  const [cls, setClass] = useState(classes.info);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setClass(isWarning ? classes.warn : classes.info);
    }, 100);
    return () => clearTimeout(timerId);
  }, [isWarning]);

  return (
    <span
      style={{ right: rightPos, bottom: bottomPos }}
      className={`${classes.tooltip} ${classes.form} ${
        isOn ? classes.tooltipOn : classes.tooltipOff
      }`}>
      <span style={{ maxWidth: width }} className={`${classes.body} ${cls}`}>
        {children}
      </span>
    </span>
  );
}

export function FlightCardTooltip({ tooltipClass, anchorId, children, isDynamic }) {
  if (!isDynamic) {
    return (
      <Tooltip
        className={tooltipClass}
        opacity={1}
        anchorSelect={`#${anchorId}`}
        place="top">
        {children}
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        id={anchorId}
        className={tooltipClass}
        opacity={1}
        place="top"
        render={({ content, activeAnchor }) => (
          <span>{activeAnchor?.getAttribute("data-tooltip-attr")}</span>
        )}
      />
    );
  }
}

export function BaggageDetailsTooltip({ anchorId, children }) {
  return (
    <Tooltip
      style={{ padding: "0" }}
      className={classes.baggageTooltip}
      opacity={1}
      anchorSelect={`#${anchorId}`}
      clickable
      place="left-start">
      {children}
    </Tooltip>
  );
}
