import { useEffect } from "react";
import { customLog } from "src/utils/utils";

export default function useClickAway(componentRef, onClickAway) {
  useEffect(() => {
    const clickAwayHandler = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        // customLog('EXECUTING CLICK AWAY');
        onClickAway();
      }
    };

    const timerId = setTimeout(() => {
      document.addEventListener("click", clickAwayHandler);
    }, 1);

    return () => {
      clearTimeout(timerId);
      document.removeEventListener("click", clickAwayHandler);
    };
  }, [componentRef, onClickAway]);
}