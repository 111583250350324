import { useEffect, useState } from "react";
import classes from "./SwitchDirectionButton.module.css";
import { useDeviceContext } from "src/context/device-context";
import SwitchDirectionMobile from "./SwitchDirectionMobile";
import SwitchDirectionDesktop from "./SwitchDirectionDesktop";

function SwitchDirectionButton({ onClick, buttonClass }) {
  const { isMobile } = useDeviceContext();
  const baseClass = buttonClass
    ? `${buttonClass} ${classes.switch}`
    : classes.switchButton;
  const [classname, setClassname] = useState(baseClass);

  const onButtonClickHandler = () => {
    setClassname(`${baseClass} ${classes.anim}`);
    onClick();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setClassname(baseClass);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [classname]);

  return (
    <button type="button" onClick={onButtonClickHandler} className={classname}>
      {isMobile ? <SwitchDirectionMobile /> : <SwitchDirectionDesktop />}
    </button>
  );
}

export default SwitchDirectionButton;
