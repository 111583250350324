import { countries, getCountryData } from "countries-list";
import { Fragment, forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "src/components/UI/Checkbox/Checkbox";
import { BOOKING_STEP, PROJECT_NAME } from "src/constants";
import { CATALOGUE_IDS, SERVICE_TYPE } from "src/constants/services";
import { DIGIT_PATTERN, EMAIL_PATTERN } from "src/constants/validation";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { useTenantContext } from "src/context/tenant-context";
import { bookingActions, selectContactData, selectisFormIncomplete } from "src/store/booking";
import { selectCatalogue, selectServicePrice } from "src/store/catalogue";
import { orderActions } from "src/store/order";
import { customLog, getDefaultCountryCode, getDisplayPriceValue } from "src/utils/utils";
import { FormInputLabel, FormInputText } from "../../../shared/input/FormInput/FormInput";
import classes from "./ContactInformation.module.css";

const MIN_PHONE_NUM_LEN = 8;
const COUNTRY_TEL_CODES = Object.values(countries)
  .sort((a, b) => a.name.localeCompare(b.name))
  .map((country) => ({
    name: country.name,
    phone: country.phone[0],
    cc: country.languages[0],
  }));

const ContactInformation = forwardRef(function ContactInformation(props, ref) {
  const { stringRes, currencySymbol } = useLocaleContext();
  const { phonePrefixes } = useTenantContext();
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();

  const storedContactData = useSelector(selectContactData);
  const isFormIncomplete = useSelector((state) =>
    selectisFormIncomplete(state, BOOKING_STEP.passengerDetailsForm.id)
  );

  const defCountryData = getCountryData(getDefaultCountryCode());
  let firstGroup = [];
  const otherGroup = [];

  const validatePhoneNum = (phoneNum) => {
    return DIGIT_PATTERN.test(phoneNum) && phoneNum.length >= MIN_PHONE_NUM_LEN;
  };

  COUNTRY_TEL_CODES.forEach((telCode) => {
    if (phonePrefixes.includes("" + telCode.phone)) {
      firstGroup.push(telCode);
    } else {
      otherGroup.push(telCode);
    }
  });

  firstGroup.sort((a, b) => {
    const defCode = defCountryData.phone[0];
    return a.phone === defCode ? -1 : b.phone === defCode ? 1 : 0;
  });

  const [countryCode, setCountryCode] = useState(
    storedContactData.telCode || String(defCountryData.phone[0])
  );
  const [phoneNum, setPhoneNum] = useState(storedContactData.tel);
  const [email, setEmail] = useState(storedContactData.email);

  const [state, setState] = useState({
    email: {
      isValid: EMAIL_PATTERN.test(email),
      isActive: false,
      hasBeenTouched: false,
      doWarn: false,
    },
    tel: {
      isValid: validatePhoneNum(phoneNum),
      isActive: false,
      hasBeenTouched: false,
      doWarn: false,
    },
  });

  const selectedSMSService = useSelector((state) => state.order.bookingSMSService.serviceId);
  const setSubscribedToSMS = (doSubscribe) => {
    dispatch(
      orderActions.changeBookingSMSService(
        doSubscribe ? CATALOGUE_IDS.SMS_TICKET : SERVICE_TYPE.NOT_SELECTED
      )
    );
  };

  const isSubscribedToNewsletter = useSelector(
    (state) => state.booking.contact.isSubscribedNewsletter
  );

  const SMSServicePrice = useSelector((state) =>
    selectServicePrice(selectCatalogue(state), CATALOGUE_IDS.SMS_TICKET)
  );

  useEffect(() => {
    if (isFormIncomplete) {
      setState((prev) => {
        return {
          ...prev,
          email: { ...prev["email"], hasBeenTouched: true },
          tel: { ...prev["tel"], hasBeenTouched: true },
        };
      });
    }
  }, [isFormIncomplete]);

  const onEmailChange = (value) => {
    setEmail(value);
    let isValidEmail = EMAIL_PATTERN.test(value);
    setState((prev) => {
      return {
        ...prev,
        email: {
          ...prev["email"],
          isValid: isValidEmail,
          doWarn: !isValidEmail && prev["email"].hasBeenTouched,
        },
      };
    });
  };

  const onPhoneNumberChange = (value) => {
    setPhoneNum((prev) => (DIGIT_PATTERN.test(value) || value === "" ? value : prev));
    let isValidPhoneNum = validatePhoneNum(value);
    setState((prev) => {
      return {
        ...prev,
        tel: {
          ...prev["tel"],
          isValid: isValidPhoneNum,
          doWarn: !isValidPhoneNum && prev["tel"].hasBeenTouched,
        },
      };
    });
  };

  const onFocusField = (field) => {
    setState((prev) => {
      return {
        ...prev,
        [field]: { ...prev[field], isActive: true, hasBeenTouched: true },
      };
    });
  };

  const onBlurField = (field) => {
    setState((prev) => {
      return {
        ...prev,
        [field]: {
          ...prev[field],
          isActive: false,
          doWarn: !prev[field].isValid && prev[field].hasBeenTouched,
        },
      };
    });
    if (
      storedContactData.email !== email ||
      storedContactData.tel !== phoneNum ||
      storedContactData.telCode !== countryCode
    ) {
      dispatch(
        bookingActions.updateContactData({
          email,
          tel: phoneNum,
          telCode: countryCode,
        })
      );
    }
  };

  const onSelectCountryCode = (code) => {
    customLog(code);
    setCountryCode(code);
  };

  const emailFieldInfoState = {
    hasInfo: true,
    hasWarning: state.email.doWarn,
    infoText:
      state.email.hasBeenTouched && !state.email.isValid && email
        ? stringRes["booking.email.tooltip.warning.invalid"]
        : state.email.hasBeenTouched && !state.email.isValid
        ? stringRes["booking.email.tooltip.warning.required"]
        : stringRes["booking.email.tooltip.info"],
  };

  const telFieldInfoState = {
    hasInfo: true,
    hasWarning: state.tel.doWarn,
    infoText:
      state.tel.hasBeenTouched && !state.tel.isValid && phoneNum
        ? stringRes["booking.tel.tooltip.warning.invalid"]
        : state.tel.hasBeenTouched && !state.tel.isValid
        ? stringRes["booking.tel.tooltip.warning.required"]
        : stringRes["booking.tel.tooltip.info"],
  };

  return (
    <div
      ref={ref}
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
      <h1>{stringRes["booking.passengers.contact.title"]}</h1>
      <form className={classes.form}>
        <FormInputLabel
          text={stringRes["booking.passengers.contact.label.email"]}
          {...emailFieldInfoState}>
          <FormInputText
            onChange={(e) => onEmailChange(e.target.value)}
            placeholder={"your@email.com"}
            value={email}
            isValid={!state.email.hasBeenTouched || state.email.isValid}
            isActive={state.email.isActive}
            onFocus={() => onFocusField("email")}
            onBlur={() => onBlurField("email")}
            name="email"
            autocomplete="on"
          />
        </FormInputLabel>

        <FormInputLabel
          text={stringRes["booking.passengers.contact.label.phone"]}
          {...telFieldInfoState}>
          <div className={`${classes.dropdown} ${classes.telephone}`}>
            <div
              className={`${classes.field} ${state.tel.isActive ? classes.active : ""} ${
                !state.tel.hasBeenTouched || state.tel.isValid ? "" : classes.invalid
              }`.trim()}>
              <span className={classes.display}>
                <span className={classes.code}>{`+${countryCode}`}</span>
              </span>
              <select
                onFocus={() => onFocusField("tel")}
                onBlur={() => onBlurField("tel")}
                value={countryCode}
                className={classes.codeList}
                onChange={(e) => onSelectCountryCode(e.target.value)}>
                {[firstGroup, otherGroup].map((group, i) => (
                  <Fragment key={"group" + i}>
                    {group.map((country) => (
                      <option
                        value={String(country.phone)}
                        key={country.name + i}>{`${country.name} (+${country.phone})`}</option>
                    ))}
                    {i === 0 && (
                      <option key="country-separator" className={classes.separator} value="" disabled>
                        {"".padEnd(25, "- ")}
                      </option>
                    )}
                  </Fragment>
                ))}
              </select>
              <span className={classes.vrBorder}></span>
              <input
                onChange={(e) => onPhoneNumberChange(e.target.value)}
                onFocus={() => onFocusField("tel")}
                onBlur={() => onBlurField("tel")}
                value={phoneNum}
                type="text"
              />
            </div>
          </div>
        </FormInputLabel>
      </form>
      <div className={classes.checkboxArea}>
        <Checkbox
          id="subscribe-sms"
          isChecked={selectedSMSService === CATALOGUE_IDS.SMS_TICKET}
          label={
            <b>{`${stringRes["booking.passengers.contact.sms"]} ${getDisplayPriceValue(
              SMSServicePrice
            )} ${currencySymbol}.`}</b>
          }
          onChange={(id, value) => setSubscribedToSMS(value)}
          name="contact-info-option"
          className={classes.checkbox}
          isSameAsMobile
          checkMarkColor="#fff"
        />
        <Checkbox
          id="subscribe-newsletter"
          isChecked={isSubscribedToNewsletter}
          label={stringRes["booking.passengers.contact.newsletter"].replace(
            "##PROJECT_NAME##",
            PROJECT_NAME
          )}
          onChange={(id, value) =>
            dispatch(bookingActions.changeNewsletterSubscription(value))
          }
          name="contact-info-option"
          className={classes.checkbox}
          isSameAsMobile
          checkMarkColor="#fff"
        />
      </div>
    </div>
  );
});

export default ContactInformation;
