import { createContext, useContext, useEffect, useState } from "react";
import { LOCALE_TYPE } from "src/constants";
import { localeApi } from "src/services/api";
import { customLog } from "src/utils/utils";
import { STRING_RES_KEYS_ALL } from "src/constants/string-res-map";
import { getSelectedLocale, setSelectedLocale } from "src/utils/storage-utils";
import { useDispatch } from "react-redux";
import { searchDataActions } from "src/store/search";
import { useTenantContext } from "./tenant-context";

const CURRENCY_SYMBOLS = {
  EUR: "€",
  USD: "$",
  PLN: "zł",
  GBP: "£",
  RUB: "₽",
};

const LANG_VALUES = {
  [LOCALE_TYPE.GB]: ["English", "Latvian", "Russian", "Lithuanian"],
  [LOCALE_TYPE.LV]: ["Angļu", "Latviešu", "Krievu", "Lietuviešu"],
  [LOCALE_TYPE.LT]: ["Anglų", "Latvių", "Rusų", "Lietuvių"],
  [LOCALE_TYPE.RU]: ["Английский", "Латышский", "Русский", "Литовский"],
};

const COUNTRY_VALUES = {
  [LOCALE_TYPE.GB]: ["Latvia"],
  [LOCALE_TYPE.LV]: ["Latvija"],
  [LOCALE_TYPE.LT]: ["Latvija"],
  [LOCALE_TYPE.RU]: ["Латвия"],
};

const LocaleContext = createContext({
  currentLocale: "",
  currency: "",
  currencySymbol: "",
  stringRes: {},
  setLocale: () => {},
  languages: [],
  countries: [],
  currencies: [],
});

export const LocaleContextManager = (props) => {
  const dispatch = useDispatch();
  const { currency } = useTenantContext();
  const [locale, setLocale] = useState(getSelectedLocale());
  const [state, setState] = useState({
    currentLocale: undefined,
    currency: "EUR",
    currencySymbol: "€",
    stringRes: Object.fromEntries(STRING_RES_KEYS_ALL.map((k) => [k, k])),
    languages: [
      { value: LOCALE_TYPE.GB, label: "English" },
      { value: LOCALE_TYPE.LV, label: "Latvian" },
      { value: LOCALE_TYPE.RU, label: "Russian" },
      // { value: LOCALE_TYPE.LT, label: "Lithuanian" },
    ],
    countries: [
      {
        value: "lv",
        label: "Latvia",
      },
    ],
    currencies: [{ value: "eur", label: "Euro - EUR" }],
  });

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        currency: currency,
        currencySymbol: CURRENCY_SYMBOLS[currency],
      };
    });
  }, [currency]);

  useEffect(() => {
    if (!state.currentLocale || locale !== state.currentLocale) {
      const lang = locale.split("_")[0];
      const stringRes = {};
      localeApi
        .getStringResources(lang)
        .then((data) => {
          console.log(data.data);
          const localizedData = data.data;
          STRING_RES_KEYS_ALL.forEach((reqKey) => {
            if (!localizedData[reqKey]) {
              stringRes[reqKey] = reqKey;
            } else {
              stringRes[reqKey] = localizedData[reqKey];
            }
          });
        })
        .catch((error) => {
          customLog(error);
          STRING_RES_KEYS_ALL.forEach((reqKey) => {
            stringRes[reqKey] = reqKey;
          });
        })
        .finally(() => {
          const labels = LANG_VALUES[locale];
          const countries = COUNTRY_VALUES[locale];
          const languages = [
            { value: LOCALE_TYPE.GB, label: labels[0] },
            { value: LOCALE_TYPE.LV, label: labels[1] },
            { value: LOCALE_TYPE.RU, label: labels[2] },
            // { value: LOCALE_TYPE.LT, label: labels[3] },
          ];
          setState((prev) => {
            return {
              ...prev,
              currentLocale: locale,
              stringRes: stringRes,
              languages: languages,
              countries: [{ value: "lv", label: countries[0] }],
            };
          });
          setSelectedLocale(locale);
          dispatch(searchDataActions.changeCityDisplayLanguage(lang.toUpperCase()));
        });
    }
  }, [dispatch, locale, state.currentLocale]);

  return (
    <LocaleContext.Provider value={{ ...state, setLocale: setLocale }}>
      {state.currentLocale && props.children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;

export const useLocaleContext = () => useContext(LocaleContext);
