import { Fragment, forwardRef, useContext, useEffect, useId, useRef } from "react";
import { createPortal } from "react-dom";
import classes from "./Modal.module.css";
import { ModalElementContext } from "src/context/modal-element-context";
import { customLog } from "src/utils/utils";
import { nanoid } from "@reduxjs/toolkit";

function Backdrop({ isClosing }) {
  const animClass = isClosing ? classes.fadeout : classes.fadein;
  return <div className={`${classes.backdrop} ${animClass}`} />;
}

const FullScreenOverlay = forwardRef(function FullScreenOverlay({ children, isClosing }, ref) {
  return (
    <dialog ref={ref} className={`${classes.modal} ${classes.fullscreen}`}>
      {children}
    </dialog>
  );
});

const RegularOverlay = forwardRef(function RegularOverlay(
  { children, isClosing, onBackdropClick, clickSelectors = [] },
  ref
) {
  // const animClass = isClosing ? classes.close : classes.open;
  const elId = useId();
  const uid = nanoid();

  useEffect(() => {
    const dialog = document.getElementById(elId);

    const onDetectOutsideClick = (event) => {
      customLog("modal");
      customLog(event.target);
      const rect = dialog.getBoundingClientRect();
      const isDialogClick =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;
      const isInnerContentClick = event.target.closest("#modal-content" + uid);
      const selectedItemClicked = clickSelectors.some((csel) => event.target.closest(csel));
      customLog(isInnerContentClick);
      if (!isDialogClick && !isInnerContentClick && !selectedItemClicked) {
        onBackdropClick();
      }
    };
    // dialog.showModal();
    dialog.addEventListener("click", onDetectOutsideClick);
    return () => {
      document.removeEventListener("click", onDetectOutsideClick);
    };
  }, []);

  return (
    <dialog id={elId} ref={ref} className={`${classes.modal} ${classes.halfscreen}`}>
      <div id={"modal-content" + uid}>{children}</div>
    </dialog>
  );
});

const portalElement = document.getElementById("overlays");

function Modal({
  isClosing,
  fullscreen = false,
  children,
  clickSelectors = [],
  disablePointerEvents,
}) {
  const modalRef = useRef();
  const { onClose } = useContext(ModalElementContext);

  useEffect(() => {
    document.body.classList.add(classes.body);
    document.getElementsByTagName("html")[0].style.scrollbarWidth = "none";
    if (modalRef.current?.open && isClosing) {
      modalRef.current?.close();
    } else if (!modalRef.current?.open && !isClosing) {
      modalRef.current?.showModal();
    }
    return () => {
      document.body.classList.remove(classes.body);
      document.getElementsByTagName("html")[0].style.scrollbarWidth = "unset";
    };
  }, [isClosing]);

  const overlay = fullscreen ? (
    <FullScreenOverlay ref={modalRef} isClosing={isClosing}>
      {children}
    </FullScreenOverlay>
  ) : (
    <RegularOverlay
      clickSelectors={clickSelectors}
      ref={modalRef}
      onBackdropClick={disablePointerEvents ? () => {} : onClose}
      isClosing={isClosing}>
      {children}
    </RegularOverlay>
  );

  return (
    <Fragment>
      {createPortal(<Backdrop onClick={onClose} isClosing={isClosing} />, portalElement)}
      {createPortal(overlay, portalElement)}
    </Fragment>
  );
}

export default Modal;
