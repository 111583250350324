import { useEffect, useRef } from "react";
import { useDeviceContext } from "src/context/device-context";
import classes from "./StepContent.module.css";
import { BOOKING_STEP } from "src/constants";
import { useLocaleContext } from "src/context/locale-context";
import BookingStepTitle from "../StepTitle/BookingStepTitle";
import BookingSidePanel from "../SidePanel/BookingSidePanel";
import { useDispatch, useSelector } from "react-redux";
import MoneyBackGuarantee from "../MoneyBackGuarantee/MoneyBackGuarantee";
import { bookingActions } from "src/store/booking";
import { MobileBottomElement } from "../MobileBottomElement/MobileBottomElement";
import { useBookingFlow } from "src/context/booking-flow-context";
import { BookingNextButton } from "src/components/features/booking/StepContent/BookingNextButton";
import { BookingBackButton } from "src/components/features/booking/StepContent/BookingBackButton";

function MoneyBackStepContent({ pageId }) {
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { goToNextBookingPage, goToPrevBookingPage, hasNext, hasPrevious } =
    useBookingFlow();
  const dispatch = useDispatch();

  const serviceRef = useRef();
  const protectionService = useSelector(
    (state) => state.order.changeProtection.serviceId
  );

  useEffect(() => {
    const page = document.getElementById("booking-page");
    if (isMobile) {
      page.style.backgroundColor = "white";
    }
    return () => {
      page.style.backgroundColor = "initial";
    };
  }, [isMobile]);

  const onGoToPrevStep = () => {
    dispatch(bookingActions.resetFormState(BOOKING_STEP.guaranteeSelection.id));
    goToPrevBookingPage(pageId);
  };

  const onGoToNextStep = () => {
    let warningElement, isValidProtectionService;
    if (protectionService) {
      isValidProtectionService = true;
    } else {
      warningElement = serviceRef.current;
    }

    if (isValidProtectionService) {
      dispatch(bookingActions.resetFormState(BOOKING_STEP.guaranteeSelection.id));
      goToNextBookingPage(pageId);
    } else {
      dispatch(
        bookingActions.updateFormWarningStatus({
          step: BOOKING_STEP.guaranteeSelection.id,
          isIncomplete: true,
        })
      );
      dispatch(
        bookingActions.incrementValidationAttempts(BOOKING_STEP.guaranteeSelection.id)
      );
      warningElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!isMobile) {
    return (
      <section className={`${classes.container} ${classes.desktop}`}>
        <BookingStepTitle step={pageId} />
        <div className={classes.main}>
          <MoneyBackGuarantee ref={serviceRef} />
        </div>
        <BookingSidePanel />
        <div className={classes.nextButton}>
          {hasPrevious(pageId) ? (
            <BookingBackButton
              onClick={onGoToPrevStep}
              className={classes.withBackArr}
              buttonText={stringRes["booking.back.button"]}
            />
          ) : (
            <div></div>
          )}
          {hasNext(pageId) ? (
            <BookingNextButton
              onClick={onGoToNextStep}
              className={classes.withArr}
              buttonText={stringRes["booking.continue.button"]}
            />
          ) : (
            <div></div>
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className={`${classes.container} ${classes.mobile} ${classes.moneyback}`}>
        <BookingStepTitle step={pageId} />
        <MoneyBackGuarantee ref={serviceRef} />
        <MobileBottomElement onGoToNextStep={onGoToNextStep} />
      </section>
    );
  }
}

export default MoneyBackStepContent;
