import { Fragment, forwardRef, useContext, useEffect, useId, useRef } from "react";
import { createPortal } from "react-dom";
import classes from "./Snackbar.module.css";
import { ModalElementContext } from "src/context/modal-element-context";

function Backdrop({ isClosing }) {
  const animClass = isClosing ? classes.fadeout : classes.fadein;
  return <div className={`${classes.backdrop} ${animClass}`} />;
}

const RegularElement = forwardRef(function RegularOverlay(
  { children, onBackdropClick },
  ref
) {
  const elId = useId();

  useEffect(() => {
    const dialog = document.getElementById(elId);

    const onDetectOutsideClick = (event) => {
      const rect = dialog.getBoundingClientRect();
      const isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;
      if (!isInDialog) {
        onBackdropClick();
      }
    };
    dialog.addEventListener("click", onDetectOutsideClick);
    return () => {
      document.removeEventListener("click", onDetectOutsideClick);
    };
  }, []);

  return (
    <dialog id={elId} ref={ref} className={`${classes.snackbar} ${classes.dark}`}>
      {children}
    </dialog>
  );
});

const portalElement = document.getElementById("overlays");

function Snackbar({ isClosing, children }) {
  const snackbarRef = useRef();
  const { onClose } = useContext(ModalElementContext);

  useEffect(() => {
    if (snackbarRef.current?.open && isClosing) {
      snackbarRef.current?.close();
    } else if (!snackbarRef.current?.open && !isClosing) {
      snackbarRef.current?.showModal();
    }
  }, [isClosing]);

  const element = (
    <RegularElement ref={snackbarRef} onBackdropClick={onClose} isClosing={isClosing}>
      {children}
    </RegularElement>
  );

  return (
    <Fragment>
      {createPortal(<Backdrop onClick={onClose} isClosing={isClosing} />, portalElement)}
      {createPortal(element, portalElement)}
    </Fragment>
  );
}

export default Snackbar;
