import { nanoid } from "@reduxjs/toolkit";
import { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import { FlightCardTooltip } from "src/components/UI/Tooltip/Tooltip";
import { WEEKDAY_SHORT_STR_MAP } from "src/constants/string-res-map";
import { useLocaleContext } from "src/context/locale-context";
import { useTenantContext } from "src/context/tenant-context";
import {
  getDateDifferenceInDays,
  getFlightDateFormatted,
  getWeekdayFromDate,
} from "src/utils/date-utils";
import { FlightConnectionLine } from "../ConnectionLine/FlightConnectionLine";
import { WARN_TYPE, getTotalDuration } from "../FlightChainInfo/FlightChainInfo";
import classes from "../FlightChainInfo/FlightInfo.module.css";
import { ThumbstackButton } from "../FlightChainInfo/ThumbstackButton";

function DepartureDataItem({ flightChain, warn, anchorId }) {
  const { stringRes } = useLocaleContext();

  return (
    <div className={classes.carrierDataItem}>
      <span>{flightChain[0].dep.time}</span>
      <span
        data-tooltip-id={anchorId}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          <span className={classes.tooltipText}>
            <span>
              {`${flightChain[0].dep.city.title}, ${flightChain[0].dep.airport.title} (${flightChain[0].dep.airport.code})`}
            </span>
            {warn === WARN_TYPE.departure ? (
              <span>{stringRes["res.result.info.differentAirports"]}</span>
            ) : (
              ""
            )}
          </span>
        )}
        className={warn === WARN_TYPE.departure ? classes.warn : ""}>
        {flightChain[0].dep.airport.code}{" "}
      </span>
    </div>
  );
}

function ConnectionDataItem({ flightChain, anchorId }) {
  const { stringRes } = useLocaleContext();
  const stopNum = flightChain.length - 1;
  const stopovers = flightChain
    .filter((fl) => "transfer" in fl.duration)
    .map((fl) => {
      const total = fl.duration.transfer.total_min;
      const hours = Math.trunc(total / 60);
      const minutes = total % 60;

      let str = hours > 0 ? `${hours}h ` : "";
      str += minutes > 0 ? `${minutes}m ` : "";
      str += `${stringRes["res.result.info.layover"]} - `;
      str += `${fl.arr.airport.title} (${fl.arr.airport.code})`;
      return str;
    });

  return (
    <div
      data-tooltip-id={stopNum > 0 ? anchorId : null}
      data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
        <span className={classes.tooltipText}>
          {stopovers.map((stop) => (
            <span key={stop}>{stop}</span>
          ))}
        </span>
      )}
      className={classes.carrierDataItem}>
      <FlightConnectionLine className={classes.connections} stopNum={stopNum} />
      <span></span>
    </div>
  );
}

function ArrivalDataItem({ flightChain, warn, anchorId }) {
  const { stringRes } = useLocaleContext();
  const { shortDateFormat } = useTenantContext();
  const lastFlight = flightChain[flightChain.length - 1];
  const daysOffset = getDateDifferenceInDays(
    flightChain[0].dep.date,
    lastFlight.arr.date,
    "DD.MM.YYYY"
  );

  const weekdayNumEnd = getWeekdayFromDate(lastFlight.arr.date, "DD.MM.YYYY");
  const endDate = getFlightDateFormatted(lastFlight.arr.date, shortDateFormat);

  return (
    <div className={classes.carrierDataItem}>
      <span
        data-tooltip-id={daysOffset > 0 ? anchorId : null}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          <span className={classes.tooltipText}>
            {daysOffset > 1
              ? stringRes["res.result.info.daysOffset.many"].replace(
                  "##DAYS_OFFSET##",
                  daysOffset
                )
              : stringRes["res.result.info.daysOffset.one"]}
            {` - ${stringRes[WEEKDAY_SHORT_STR_MAP[weekdayNumEnd]]}, ${endDate}`}
          </span>
        )}>
        <span className={daysOffset > 0 ? classes.hasDateOffset : ""}>
          {lastFlight.arr.time}
        </span>
        <sup className={classes.daysOffset}>{daysOffset > 0 ? `+${daysOffset}` : ""}</sup>
      </span>
      <span
        data-tooltip-id={anchorId}
        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
          <span className={classes.tooltipText}>
            <span>
              {`${lastFlight.arr.city.title}, ${lastFlight.arr.airport.title} (${lastFlight.arr.airport.code})`}
            </span>
            {warn === WARN_TYPE.arrival ? (
              <span>{stringRes["res.result.info.differentAirports"]}</span>
            ) : (
              ""
            )}
          </span>
        )}
        className={warn === WARN_TYPE.arrival ? classes.warn : ""}>
        {lastFlight.arr.airport.code}{" "}
      </span>
    </div>
  );
}

function CarrierData({ flightChain, warn, anchorId }) {
  const props = {
    flightChain,
    warn,
    anchorId,
  };

  return (
    <div className={classes.carrierData}>
      <DepartureDataItem {...props} />
      <ConnectionDataItem {...props} />
      <ArrivalDataItem {...props} />
    </div>
  );
}

function TimeInfo({ flightChain }) {
  const { stringRes, currentLocale } = useLocaleContext();
  const stopNum = flightChain.length - 1;

  return (
    <div className={classes.timeInfo}>
      <div>{getTotalDuration(flightChain, currentLocale)}</div>
      <div className={stopNum > 0 ? "" : classes.direct}>
        {stopNum > 0 ? (
          <>
            {stopNum}{" "}
            {stopNum > 1
              ? stringRes["res.result.stop.many"]
              : stringRes["res.result.stop.one"]}
            {stopNum === 1 &&
              ` ${flightChain
                .slice(1)
                .map((f) => f.dep.airport.code)
                .join(", ")}`}
          </>
        ) : (
          stringRes["res.result.stop.none"]
        )}
      </div>
    </div>
  );
}

export function CarrierTimeDataDesktop({
  flightChain,
  isDeparture,
  warn,
  isOneway,
  onPin,
  isPinned,
}) {
  const { stringRes } = useLocaleContext();
  const anchorId = nanoid();
  const props = {
    flightChain,
    warn,
    anchorId,
  };

  return (
    <Fragment>
      <FlightCardTooltip tooltipClass={classes.tooltip} anchorId={anchorId} isDynamic />
      <CarrierData {...props} />
      <TimeInfo {...props} />
      {!isOneway && (
        <ThumbstackButton
          tooltipText={
            stringRes[
              isDeparture
                ? "res.result.info.pinned.departure"
                : "res.result.info.pinned.return"
            ]
          }
          anchorId={anchorId}
          isActive={isPinned}
          onClick={onPin}
        />
      )}
    </Fragment>
  );
}
