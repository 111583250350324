import { STATIC_ELEMENT } from "src/constants/static";
import useStaticContent from "src/hooks/useStaticContent";

function TermsOfService() {
  const { html: template } = useStaticContent(STATIC_ELEMENT.terms);
  return (
    <div style={{ display: "contents" }} dangerouslySetInnerHTML={{ __html: template }}></div>
  );
}

export default TermsOfService;
