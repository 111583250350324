import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import CancellationPolicy from "./CancellationPolicy";
import GuaranteeRules from "./GuaranteeRules";

export const info = {
  TermsOfService,
  PrivacyPolicy,
  CancellationPolicy,
  GuaranteeRules,
};
