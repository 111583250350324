import { Link } from "react-router-dom";

export function splitStringByNewline(inputString) {
  return inputString.split("\n");
}

export function splitStringByWrappedWords(inputString, wrapper) {
  const escapedWrapper = wrapper.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(
    `(${escapedWrapper}[^${escapedWrapper}]+${escapedWrapper})`,
    "g"
  );
  return inputString.split(regex);
}

export function transformNewTabLinks(node, children) {
  if (node.tagName === "A") {
    return (
      <Link target="_blank" to={node.getAttribute("href")}>
        {children}
      </Link>
    );
  }
}
