import classes from "../PaymentOptions.module.css";
import { motion } from "framer-motion";

export function PaymentVendor({ vendor, isActive, onSelect }) {
  return (
    <motion.li
      whileTap={{ scale: 0.97, transformOrigin: "center" }}
      onClick={onSelect}
      className={`${classes.paymentVendor} ${isActive ? classes.active : ""}`}>
      <span className={classes.icon}>
        <img
          onDragStart={(e) => e.preventDefault()}
          width={"100%"}
          alt={vendor.name}
          src={vendor.logoUrl}
        />
      </span>
    </motion.li>
  );
}
