function BellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_1491_5996)">
        <path
          opacity="0.994"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.82444 -0.0117188C5.93383 -0.0117188 6.04319 -0.0117188 6.15257 -0.0117188C6.29952 0.0446309 6.40499 0.146193 6.46897 0.292969C6.5024 0.533306 6.51412 0.775495 6.50413 1.01953C7.88929 1.26812 8.8307 2.04937 9.32835 3.36328C9.38999 3.56299 9.43687 3.7661 9.46898 3.97266C9.49872 4.68295 9.51435 5.39388 9.51585 6.10547C9.56802 6.76209 9.78676 7.35584 10.1721 7.88672C10.3906 8.15208 10.625 8.40209 10.8752 8.63672C11.1235 9.1474 11.0181 9.56536 10.5588 9.89062C10.4713 9.94062 10.3776 9.97186 10.2776 9.98438C7.41819 10 4.55882 10 1.69944 9.98438C1.1588 9.82158 0.928333 9.4583 1.00804 8.89453C1.04781 8.74463 1.11812 8.61183 1.21897 8.49609C2.04211 7.81847 2.46397 6.94348 2.4846 5.87109C2.48624 5.21419 2.50187 4.55794 2.53147 3.90234C2.73634 2.85523 3.28712 2.04664 4.18382 1.47656C4.59093 1.2379 5.02844 1.08555 5.49632 1.01953C5.49243 0.785025 5.49632 0.55065 5.50804 0.316406C5.57101 0.163617 5.67648 0.0542423 5.82444 -0.0117188Z"
          fill="#001B38"
        />
        <path
          opacity="0.983"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.29297 11.9883C6.09766 11.9883 5.90234 11.9883 5.70703 11.9883C4.8851 11.811 4.36167 11.3149 4.13672 10.5C5.37108 10.4883 6.60544 10.4844 7.83984 10.4883C7.63901 11.3141 7.12338 11.8141 6.29297 11.9883Z"
          fill="#001B38"
        />
      </g>
      <defs>
        <clipPath id="clip0_1491_5996">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BellIcon;
