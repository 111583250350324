import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useLocaleContext } from "src/context/locale-context";
import Button from "src/components/UI/Button/Button";
import { useAuth } from "src/context/auth-context";
import { useDeviceContext } from "src/context/device-context";
import { useEffect } from "react";
import { clearBookingSessionData } from "src/utils/storage-utils";
import { useDispatch } from "react-redux";
import { resetAfterBooking } from "src/store/actions";

export function OrderError() {
  const { orderId } = useParams();
  const [queryParams] = useSearchParams();
  const { stringRes } = useLocaleContext();
  const { isAuthenticated } = useAuth();
  const { isMobile } = useDeviceContext();
  const location = useLocation();
  const navigate = useNavigate();
  const navType = useNavigationType();
  const dispatch = useDispatch();

  useEffect(() => {
    clearBookingSessionData();
    dispatch(resetAfterBooking());
  }, [dispatch]);

  if (!location.state || location.state.source !== "bookingCheckout" || navType === "POP") {
    return <Navigate to="/notfound" />;
  }

  return (
    <main id="order-completion-error-page" className={isMobile ? "mobile" : "desktop"}>
      <div className="order-container">
        <h1>{stringRes["order.payment.error.title"]}</h1>
        <h2>
          {stringRes["order.payment.order.num"] + " #"}
          <span>{queryParams.get("booking-number")}</span>
        </h2>
        <p>{stringRes["order.payment.error.note"]}</p>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "1rem",
            marginTop: "2rem",
          }}>
          {isAuthenticated ? (
            <Button
              wide
              primary
              onMouseUp={() => navigate("/dashboard/trips/" + orderId, { state: {} })}
              name={stringRes["button.general.order.details"]}
            />
          ) : (
            <Button
              primary
              wide
              onClick={() => navigate("/booking", { state: {} })}
              name={stringRes["button.general.booking.restart"]}
            />
          )}
        </div>
      </div>
    </main>
  );
}
