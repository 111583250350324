import classes from "./BookingSignIn.module.css";
import { Checkmark } from "src/components/UI/Icon/Checkmark";
import {
  AppleIconLogin,
  FacebookIconLogin,
  GoogleIconLogin,
} from "src/components/UI/LoginIcon/LoginIcon";
import Button from "src/components/UI/Button/Button";
import { useLocaleContext } from "src/context/locale-context";
import { useDeviceContext } from "src/context/device-context";
import { useEffect, useState } from "react";
import { useAuth } from "src/context/auth-context";
import { useGlobalLoading } from "src/context/loading-context";
import { AnimatePresence, motion } from "framer-motion";
import { LOGIN_VENDOR } from "src/constants";
import { ANIM_PROPS } from "src/utils/utils";

function LoginButtonContent({ vendor }) {
  const { stringRes } = useLocaleContext();
  let icon = null;
  let name = "";
  if (vendor === LOGIN_VENDOR.facebook) {
    icon = <FacebookIconLogin />;
    name = "Facebook";
  } else if (vendor === LOGIN_VENDOR.google) {
    icon = <GoogleIconLogin />;
    name = "Google";
  } else if (vendor === LOGIN_VENDOR.apple) {
    icon = <AppleIconLogin />;
  }

  return (
    <span className={classes.loginVendor}>
      {icon}
      <span>{`${stringRes["booking.passengers.signin.continueWith"]} ${name}`}</span>
    </span>
  );
}

function BookingSignIn() {
  const { stringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();
  const { login } = useAuth();
  const { setLoading } = useGlobalLoading();

  let features = [
    stringRes["booking.passengers.signin.feature1"],
    stringRes["booking.passengers.signin.feature2"],
    stringRes["booking.passengers.signin.feature3"],
  ];

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let timerId;
    if (errorMessage) {
      timerId = setTimeout(() => setErrorMessage(""), 20 * 1000);
    }
    return () => clearTimeout(timerId);
  }, [errorMessage]);

  const onSelectOption = (loginVendor) => {
    const onSuccess = () => {
      setLoading(false);
    };

    const onError = (errorCode) => {
      setLoading(false);
      if (errorCode === "auth/account-exists-with-different-credential") {
        setErrorMessage(stringRes["login.modal.user.signin.exists"]);
      } else {
        setErrorMessage(stringRes["login.modal.error.credentials"]);
      }
    };

    setLoading(true, 0.5);
    login(loginVendor, null, null, onSuccess, onError);
  };

  return (
    <motion.div
      {...ANIM_PROPS}
      transition={{ type: "just" }}
      className={`${classes.container} ${isMobile ? classes.mobile : classes.desktop}`}>
      <h1>{stringRes["booking.passengers.signin.title"]}</h1>
      <ul className={classes.loginFeatures}>
        {features.map((ft) => (
          <li key={ft} className={classes.feature}>
            <span key={ft} className={classes.checkmark}>
              <Checkmark />
            </span>
            <span className={classes.description}>{ft}</span>
          </li>
        ))}
      </ul>
      <div className={classes.buttonBar}>
        {/* <Button
          onClick={() => onSelectOption(LOGIN_VENDOR.facebook)}
          secondary
          name={<LoginButtonContent vendor={LOGIN_VENDOR.facebook} />}
        /> */}
        <Button
          onClick={() => onSelectOption(LOGIN_VENDOR.google)}
          secondary
          name={<LoginButtonContent vendor={LOGIN_VENDOR.google} />}
        />
        {/* <Button
          onClick={() => {}}
          secondary
          name={<LoginButtonContent vendor={LOGIN_VENDOR.apple} />}
        /> */}
      </div>
      <AnimatePresence>
        {errorMessage && (
          <motion.span
            {...ANIM_PROPS}
            transition={{ duration: 1 }}
            className={classes.err}>
            {errorMessage}
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default BookingSignIn;
