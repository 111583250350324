import { useSelector } from "react-redux";
import { ResultsCheckboxGroupFilter } from "../ResultsCheckboxGroupFilter";
import { selectFilters } from "src/store/filter-sort";
import { useLocaleContext } from "src/context/locale-context";

function AirlinesFilter() {
  const { stringRes } = useLocaleContext();
  const airlineFilter = useSelector(state => selectFilters(state, "airline"));
  const availableArlines = airlineFilter.options;

  return (
    <ResultsCheckboxGroupFilter
      filterType="airline"
      availableItems={availableArlines}
      title={stringRes["res.filter.title.airlines"]}
      filterData={airlineFilter}
    />
  );
}

export default AirlinesFilter;
