import LockPrice from "../LockPrice/LockPrice";
import OrderDetails from "../OrderDetails/OrderDetails";
import classes from "./BookingSidePanel.module.css";

function BookingSidePanel({ showLockPrice, row = 2 }) {
  return (
    <div style={{ gridRowStart: row }} className={classes.side}>
      <div className={classes.fixed}>
        <OrderDetails />
        {showLockPrice && <LockPrice />}
      </div>
    </div>
  );
}

export default BookingSidePanel;
