import { Fragment, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./BagsFilter.module.css";
import baggageImage from "src/assets/img/baggageImg.png";
import { filterSortActions, selectFilters } from "src/store/filter-sort";
import { CounterItem } from "src/components/UI/CounterItem/CounterItem";
import { BAGGAGE_TYPE_FILTER } from "src/constants";
import { customLog } from "src/utils/utils";
import { useLocaleContext } from "src/context/locale-context";
import { ModalElementContext } from "src/context/modal-element-context";
import { useDeviceContext } from "src/context/device-context";
import { CloseWindowButton } from "src/components/UI/CloseWindowButton/CloseWindowButton";
import Button from "src/components/UI/Button/Button";

export function BagsFilter() {
  const dispatch = useDispatch();
  const { isMobile } = useDeviceContext();
  const { stringRes } = useLocaleContext();
  const { onClose } = useContext(ModalElementContext);

  const filterCheckedBagData = useSelector((state) =>
    selectFilters(state, BAGGAGE_TYPE_FILTER.checked)
  );
  const filterCabinBagData = useSelector((state) =>
    selectFilters(state, BAGGAGE_TYPE_FILTER.cabin)
  );

  const [state, setState] = useState({
    checked: filterCheckedBagData.value,
    cabin: filterCabinBagData.value,
  });

  useEffect(() => {
    setState((prev) => {
      return { ...prev, cabin: filterCabinBagData.value };
    });
  }, [filterCabinBagData.isOn]);

  useEffect(() => {
    setState((prev) => {
      return { ...prev, checked: filterCheckedBagData.value };
    });
  }, [filterCheckedBagData.isOn]);

  useEffect(() => {
    if (!isMobile) {
      dispatch(
        filterSortActions.applyFilter({
          type: BAGGAGE_TYPE_FILTER.checked,
          value: state.checked,
        })
      );
    }
  }, [state.checked]);

  useEffect(() => {
    if (!isMobile) {
      dispatch(
        filterSortActions.applyFilter({
          type: BAGGAGE_TYPE_FILTER.cabin,
          value: state.cabin,
        })
      );
    }
  }, [state.cabin]);

  const onAddBaggage = (type) => {
    if (type === BAGGAGE_TYPE_FILTER.checked) {
      setState((prev) => {
        return { ...prev, checked: prev.checked + 1 };
      });
    } else if (type === BAGGAGE_TYPE_FILTER.cabin) {
      setState((prev) => {
        return { ...prev, cabin: prev.cabin + 1 };
      });
    }
  };

  const onRemoveBaggage = (type) => {
    if (type === BAGGAGE_TYPE_FILTER.checked) {
      setState((prev) => {
        return { ...prev, checked: prev.checked - 1 };
      });
    } else if (type === BAGGAGE_TYPE_FILTER.cabin) {
      setState((prev) => {
        return { ...prev, cabin: prev.cabin - 1 };
      });
    }
  };

  const canAddBaggage = (type) => {
    if (type === BAGGAGE_TYPE_FILTER.checked) {
      return state.checked < filterCheckedBagData.max;
    } else if (type === BAGGAGE_TYPE_FILTER.cabin) {
      return state.cabin < filterCabinBagData.max;
    }
  };

  const canRemoveBaggage = (type) => {
    customLog(type);
    if (type === BAGGAGE_TYPE_FILTER.checked) {
      return state.checked > 0;
    } else if (type === BAGGAGE_TYPE_FILTER.cabin) {
      return state.cabin > 0;
    }
  };

  const onConfirmValue = () => {
    dispatch(
      filterSortActions.applyFilter({
        type: BAGGAGE_TYPE_FILTER.cabin,
        value: state.cabin,
      })
    );
    dispatch(
      filterSortActions.applyFilter({
        type: BAGGAGE_TYPE_FILTER.checked,
        value: state.checked,
      })
    );
    onClose();
  };

  return (
    <Fragment>
      {isMobile && <CloseWindowButton onClick={onClose} />}
      <div
        className={`${classes.container} ${classes.baggage} ${
          isMobile ? classes.mobile : classes.desktop
        }`}>
        <h1>{stringRes["res.filter.title.baggage"]}</h1>
        <div className={classes.baggageItem}>
          {isMobile ? (
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 0H2V1H3V6H2C1.46957 6 0.960859 6.21071 0.585786 6.58579C0.210714 6.96086 0 7.46957 0 8V13C0 13.5304 0.210714 14.0391 0.585786 14.4142C0.960859 14.7893 1.46957 15 2 15V15.5C2 15.6326 2.05268 15.7598 2.14645 15.8536C2.24021 15.9473 2.36739 16 2.5 16C2.63261 16 2.75979 15.9473 2.85355 15.8536C2.94732 15.7598 3 15.6326 3 15.5V15H9V15.5C9 15.6326 9.05268 15.7598 9.14645 15.8536C9.24021 15.9473 9.36739 16 9.5 16C9.63261 16 9.75979 15.9473 9.85355 15.8536C9.94732 15.7598 10 15.6326 10 15.5V15C10.5304 15 11.0391 14.7893 11.4142 14.4142C11.7893 14.0391 12 13.5304 12 13V8C12 7.46957 11.7893 6.96086 11.4142 6.58579C11.0391 6.21071 10.5304 6 10 6H9V1H10V0ZM4 6V1H8V6H4ZM2 14C1.86639 14.0088 1.73244 13.989 1.6071 13.9419C1.48177 13.8948 1.36795 13.8214 1.27327 13.7267C1.17859 13.632 1.10523 13.5182 1.0581 13.3929C1.01097 13.2676 0.991161 13.1336 1 13V8C0.991161 7.86639 1.01097 7.73244 1.0581 7.6071C1.10523 7.48177 1.17859 7.36795 1.27327 7.27327C1.36795 7.17859 1.48177 7.10523 1.6071 7.0581C1.73244 7.01097 1.86639 6.99116 2 7H10C10.1336 6.99116 10.2676 7.01097 10.3929 7.0581C10.5182 7.10523 10.632 7.17859 10.7267 7.27327C10.8214 7.36795 10.8948 7.48177 10.9419 7.6071C10.989 7.73244 11.0088 7.86639 11 8V13C11.0088 13.1336 10.989 13.2676 10.9419 13.3929C10.8948 13.5182 10.8214 13.632 10.7267 13.7267C10.632 13.8214 10.5182 13.8948 10.3929 13.9419C10.2676 13.989 10.1336 14.0088 10 14H2Z"
                fill="#001B38"
              />
            </svg>
          ) : (
            <img src={baggageImage} alt="baggage icon" />
          )}

          <span>{stringRes["res.filter.baggage.cabin"]}</span>
          <CounterItem
            value={state.cabin}
            onAdd={() => onAddBaggage(BAGGAGE_TYPE_FILTER.cabin)}
            onRemove={() => onRemoveBaggage(BAGGAGE_TYPE_FILTER.cabin)}
            canAdd={canAddBaggage(BAGGAGE_TYPE_FILTER.cabin)}
            canRemove={canRemoveBaggage(BAGGAGE_TYPE_FILTER.cabin)}
            className={classes.counter}
          />
        </div>
        <div className={classes.baggageItem}>
          {isMobile ? (
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 3H8V1H9V0H3V1H4V3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5V13C0 13.5304 0.210714 14.0391 0.585786 14.4142C0.960859 14.7893 1.46957 15 2 15V15.5C2 15.6326 2.05268 15.7598 2.14645 15.8536C2.24021 15.9473 2.36739 16 2.5 16C2.63261 16 2.75979 15.9473 2.85355 15.8536C2.94732 15.7598 3 15.6326 3 15.5V15H9V15.5C9 15.6326 9.05268 15.7598 9.14645 15.8536C9.24021 15.9473 9.36739 16 9.5 16C9.63261 16 9.75979 15.9473 9.85355 15.8536C9.94732 15.7598 10 15.6326 10 15.5V15C10.5304 15 11.0391 14.7893 11.4142 14.4142C11.7893 14.0391 12 13.5304 12 13V5C12 4.46957 11.7893 3.96086 11.4142 3.58579C11.0391 3.21071 10.5304 3 10 3ZM5 1H7V3H5V1ZM11 13C11 13.2652 10.8946 13.5196 10.7071 13.7071C10.5196 13.8946 10.2652 14 10 14H2C1.73478 14 1.48043 13.8946 1.29289 13.7071C1.10536 13.5196 1 13.2652 1 13V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H10C10.2652 4 10.5196 4.10536 10.7071 4.29289C10.8946 4.48043 11 4.73478 11 5V13Z"
                fill="#001B38"
              />
            </svg>
          ) : (
            <img src={baggageImage} alt="baggage icon" />
          )}

          <span>{stringRes["res.filter.baggage.checked"]}</span>
          <CounterItem
            value={state.checked}
            onAdd={() => onAddBaggage(BAGGAGE_TYPE_FILTER.checked)}
            onRemove={() => onRemoveBaggage(BAGGAGE_TYPE_FILTER.checked)}
            canAdd={canAddBaggage(BAGGAGE_TYPE_FILTER.checked)}
            canRemove={canRemoveBaggage(BAGGAGE_TYPE_FILTER.checked)}
            className={classes.counter}
          />
        </div>
        {isMobile && (
          <Fragment>
            <Button
              name={stringRes["res.filter.button.cancel"]}
              onClick={onClose}
              secondary
              cornerSm
              fontclass={classes.fontclass}
            />
            <Button
              name={stringRes["res.filter.button.done"]}
              onClick={onConfirmValue}
              primary
              cornerSm
              fontclass={classes.fontclass}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
