import mobPaxStepIcon from "src/assets/img/mobPaxStepIcon.png";
import moneybackStepIcon from "src/assets/img/moneybackStepIcon.png";
import seatsStepIcon from "src/assets/img/seatsStepIcon2.png";
import supportStepIcon from "src/assets/img/mobSupportStepIcon.png";
import checkoutStepIcon from "src/assets/img/mobCheckoutStepIcon.png";

export function PassengerStepMobIcon({ className }) {
  return (
    <img
      style={{ width: "50px" }}
      className={className}
      src={mobPaxStepIcon}
      alt="passengers"
    />
  );
}

export function MoneyBackMobStepIcon({ className }) {
  return (
    <img
      style={{ width: "50px" }}
      className={className}
      src={moneybackStepIcon}
      alt="money back guarantee"
    />
  );
}

export function InsuranceMobStepIcon({ className }) {
  return null;
}

export function ExtrasMobStepIcon({ className }) {
  return null;
}

export function SeatsMobStepIcon({ className }) {
  return (
    <img
      style={{ width: "50px" }}
      className={className}
      src={seatsStepIcon}
      alt="plane seats"
    />
  );
}

export function SupportMobStepIcon({ className }) {
  return (
    <img
      style={{ width: "50px" }}
      className={className}
      src={supportStepIcon}
      alt="plane seats"
    />
  );
}

export function CheckoutMobStepIcon({ className }) {
  return (
    <img
      style={{ width: "50px" }}
      className={className}
      src={checkoutStepIcon}
      alt="plane seats"
    />
  );
}

