import { CATALOGUE_IDS } from "src/constants/services";

export const BREAKDOWN_ITEM_STR_KEY = {
  adultFare: "booking.order.breakdown.adult",
  childFare: "booking.order.breakdown.child",
  infantFare: "booking.order.breakdown.infant",
  cabinBundle: "booking.order.breakdown.cabinBundle",
  cabinBundleUpgrade: "booking.order.breakdown.cabinBundleUpgrade",
  checkedBaggageSmall: "booking.order.breakdown.baggage.checked",
  checkedBaggageLarge: "booking.order.breakdown.baggage.checked",
  checkedBaggageDouble: "booking.order.breakdown.baggage.checked",
  checkedBaggageProtection: "booking.order.breakdown.checkedBaggageProtection",
  serviceFee: "booking.order.breakdown.serviceFee",
  connectionProtection: "booking.order.breakdown.connectionProtection",
  changeProtection: "booking.order.breakdown.changeProtection",
  travelInsurance: "booking.order.breakdown.travelInsurance",
  seatType: "booking.order.breakdown.seatType",
  supportService: "booking.order.breakdown.supportService",
  airHelpService: "booking.order.breakdown.airHelpService",
  bookingSMSService: "booking.order.breakdown.bookingSMSService",
  onlineCheckin: "booking.order.breakdown.onlineCheckin",
  priceLock: "booking.order.breakdown.lockin",
  flightsSMSService: "booking.order.breakdown.flightsSMSService",
  [CATALOGUE_IDS.seats.window]: "booking.order.breakdown.seatTypeWindow",
  [CATALOGUE_IDS.seats.aisle]: "booking.order.breakdown.seatTypeAisle",
  [CATALOGUE_IDS.seats.extra_legroom]: "booking.order.breakdown.seatTypeLegroom",
  [CATALOGUE_IDS.seats.sitting_together]: "booking.order.breakdown.seatTypeTogether",
  [CATALOGUE_IDS.seats.random]: "booking.checkout.summary.randomSeats",
};
