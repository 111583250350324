import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FILTER_TYPE } from "src/constants";
import { SORT_TYPE_MAP } from "src/constants/string-res-map";
import { filterSortActions, selectFilters, selectSort } from "src/store/filter-sort";
import { MobileFilterItem } from "./MobileFilterItem";
import { MobileSortItem } from "./MobileSortItem";
import { useLocaleContext } from "src/context/locale-context";

export function MobileResultsFilters({ onClickOption }) {
  const dispatch = useDispatch();
  const { stringRes } = useLocaleContext();
  const currentSort = useSelector(selectSort);

  const filterItems = [
    { title: stringRes[SORT_TYPE_MAP[currentSort]], id: "results-sort", filterType: [] },
    {
      title: stringRes["res.filter.title.baggage"],
      id: "baggage-filter",
      filterType: [FILTER_TYPE.cabinBaggage, FILTER_TYPE.checkedBaggage],
    },
    {
      title: stringRes["res.filter.title.stops"],
      id: "stop-filter",
      filterType: [FILTER_TYPE.stops],
    },
    {
      title: stringRes["res.filter.title.duration"],
      id: "duration-filter",
      filterType: [FILTER_TYPE.duration, FILTER_TYPE.stopover],
    },
    {
      title: stringRes["res.filter.title.times"],
      id: "times-filter",
      filterType: [
        FILTER_TYPE.departureStartTime,
        FILTER_TYPE.departureEndTime,
        FILTER_TYPE.returnStartTime,
        FILTER_TYPE.returnEndTime,
      ],
    },
    {
      title: stringRes["res.filter.title.airlines"],
      id: "airlines-filter",
      filterType: [FILTER_TYPE.airline],
    },
    {
      title: stringRes["res.filter.title.airports"],
      id: "airports-filter",
      filterType: [FILTER_TYPE.airport],
    },
    {
      title: stringRes["res.filter.title.price"],
      id: "price-filter",
      filterType: [FILTER_TYPE.price],
    },
  ];

  const onClearFilter = (filters) => {
    for (let i = 0; i < filters.length; i++) {
      dispatch(filterSortActions.clearFilter({ type: filters[i] }));
    }
  };

  const filters = useSelector(selectFilters);

  return (
    <Fragment>
      {filterItems.map((item, i) => {
        if (i === 0) {
          return (
            <MobileSortItem
              key={item.title}
              id={item.id}
              title={item.title}
              onClick={() => onClickOption(item.id)}
            />
          );
        } else {
          const isFilterActive = item.filterType.some((type) => filters[type].isOn);
          return (
            <MobileFilterItem
              key={item.title}
              isFilterActive={isFilterActive}
              id={item.id}
              title={item.title}
              onClear={() => onClearFilter(item.filterType)}
              onClick={() => onClickOption(item.id)}
            />
          );
        }
      })}
    </Fragment>
  );
}
