import { ModalElementContext } from "src/context/modal-element-context";
import classes from "./BaggageDetails.module.css";
import Button from "src/components/UI/Button/Button";
import Modal from "src/components/shared/modal/Modal/Modal";
import { useLocaleContext } from "src/context/locale-context";
import {
  CarryonIcon,
  CheckedBagIcon,
  PersonalItemIcon,
} from "src/components/UI/Icon/FareDetailsIcons";
import { BAGGAGE, getBaggageWeight, hasFreeBaggageIncluded } from "src/utils/baggage-utils";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { filterSortActions } from "src/store/filter-sort";
import { BAGGAGE_TYPE_FILTER } from "src/constants";

const BAG_TYPE_MAP = {
  [BAGGAGE.personal]: {
    icon: <PersonalItemIcon />,
    strKey: "booking.checkout.summary.personalItem",
  },
  [BAGGAGE.cabin]: {
    icon: <CarryonIcon />,
    strKey: "booking.checkout.summary.cabinBaggage",
  },
  [BAGGAGE.checked]: {
    icon: <CheckedBagIcon />,
    strKey: "booking.checkout.summary.registeredBaggage",
  },
};

function AddBaggageButton({ onClick }) {
  const { stringRes } = useLocaleContext();

  return null;

  return (
    <motion.button onClick={onClick} className={classes.add}>
      <span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.99979 3.32812C7.26488 3.32812 7.47979 3.54303 7.47979 3.80812L7.47977 6.12172C7.47977 6.34264 7.65886 6.52173 7.87977 6.52173L10.1934 6.52174C10.4381 6.52174 10.64 6.70485 10.6697 6.94153L10.6734 7.00174C10.6734 7.26684 10.4585 7.48174 10.1934 7.48174L7.87978 7.48173C7.65886 7.48173 7.47977 7.66081 7.47977 7.88173L7.47979 10.1954C7.47979 10.4401 7.29667 10.642 7.06 10.6716L6.99979 10.6754C6.73469 10.6754 6.51979 10.4605 6.51979 10.1954L6.51977 7.88172C6.51977 7.66081 6.34069 7.48173 6.11977 7.48173L3.80617 7.48174C3.56147 7.48174 3.35953 7.29863 3.32991 7.06195L3.32617 7.00174C3.32617 6.73664 3.54108 6.52174 3.80617 6.52174L6.11978 6.52173C6.34069 6.52173 6.51977 6.34264 6.51977 6.12173L6.51979 3.80812C6.51979 3.56342 6.7029 3.36148 6.93958 3.33186L6.99979 3.32812Z"
            fill="white"
            stroke="white"
          />
        </svg>
      </span>
      {stringRes["res.baggage.details.button.add"]}
    </motion.button>
  );
}

export function MobileBaggageDetails({ baggage, onClose, state }) {
  const { stringRes } = useLocaleContext();
  const dispatch = useDispatch();

  const onAddCheckedBaggage = () => {
    dispatch(filterSortActions.applyFilter({ type: BAGGAGE_TYPE_FILTER.checked, value: 1 }));
    onClose();
  };

  const onAddCabinBaggage = () => {
    dispatch(filterSortActions.applyFilter({ type: BAGGAGE_TYPE_FILTER.cabin, value: 1 }));
    onClose();
  };

  const onAddBaggage = (type) => {
    if (type === BAGGAGE.cabin) {
      onAddCabinBaggage();
    } else if (type === BAGGAGE.checked) {
      onAddCheckedBaggage();
    }
  };

  return (
    <ModalElementContext.Provider value={{ onClose: onClose }}>
      <Modal isClosing={state.isClosing}>
        <div className={`${classes.container} ${classes.mobile}`}>
          <h2>{stringRes["res.baggage.details.title"]}</h2>
          <ul>
            {Object.keys(BAG_TYPE_MAP).map((k) => {
              const hasFree = hasFreeBaggageIncluded(baggage, k);
              const weight = baggage[k] ? getBaggageWeight(baggage[k][0]) : null;
              return (
                <li className={classes.baggage}>
                  <span className={classes.type}>
                    {BAG_TYPE_MAP[k].icon}
                    <span>
                      {stringRes[BAG_TYPE_MAP[k].strKey]}
                      {weight ? ` (${weight})` : null}
                    </span>
                  </span>
                  <span className={`${classes.state} ${hasFree ? classes.included : ""}`}>
                    {hasFree ? (
                      stringRes["res.baggage.details.included"]
                    ) : k !== BAGGAGE.personal ? (
                      <AddBaggageButton onClick={() => onAddBaggage(k)} />
                    ) : (
                      stringRes["res.baggage.details.unknown"]
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
          <Button onClick={onClose} wide name={stringRes["res.button.close"]} secondary />
        </div>
      </Modal>
    </ModalElementContext.Provider>
  );
}

export function DesktopBaggageDetails({ baggage }) {
  const { stringRes } = useLocaleContext();
  const dispatch = useDispatch();

  const onAddCheckedBaggage = () => {
    dispatch(filterSortActions.applyFilter({ type: BAGGAGE_TYPE_FILTER.checked, value: 1 }));
  };

  const onAddCabinBaggage = () => {
    dispatch(filterSortActions.applyFilter({ type: BAGGAGE_TYPE_FILTER.cabin, value: 1 }));
  };

  const onAddBaggage = (type) => {
    if (type === BAGGAGE.cabin) {
      onAddCabinBaggage();
    } else if (type === BAGGAGE.checked) {
      onAddCheckedBaggage();
    }
  };

  return (
    <div className={`${classes.container} ${classes.desktop}`}>
      <h2>{stringRes["res.baggage.details.title"]}</h2>
      <ul>
        {Object.keys(BAG_TYPE_MAP).map((k) => {
          const hasFree = hasFreeBaggageIncluded(baggage, k);
          let weight;
          if (hasFree && k === BAGGAGE.checked) {
            const freeBags = baggage[k].filter((bag) => bag.is_r_baggage_included);
            freeBags.sort(
              (a, b) =>
                a.weight - b.weight ||
                a.r_baggage_price.total_price_per_person.price -
                  b.r_baggage_price.total_price_per_person.price
            );
            weight = getBaggageWeight(freeBags[0]);
          } else if (hasFree) {
            weight = getBaggageWeight(baggage[k][0]);
          }

          return (
            <li key={k} className={classes.baggage}>
              <span className={classes.type}>
                {BAG_TYPE_MAP[k].icon}
                <span>
                  {stringRes[BAG_TYPE_MAP[k].strKey]}
                  {weight ? ` (${weight})` : null}
                </span>
              </span>
              <span className={`${classes.state} ${hasFree ? classes.included : ""}`}>
                {hasFree ? (
                  stringRes["res.baggage.details.included"]
                ) : k !== BAGGAGE.personal ? (
                  <AddBaggageButton onClick={() => onAddBaggage(k)} />
                ) : (
                  stringRes["res.baggage.details.unknown"]
                )}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
