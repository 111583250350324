import classes from "./BillingVATDetails.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { FormCountryInputDropdown, FormInputLabel, FormInputText } from "../../../shared/input/FormInput/FormInput";
import { getCountryGroups } from "./BillingVATDetails";

export function CompanyVATDetails({
  values,
  isValidField,
  onChangeCompanyField,
  onFocusField,
  onBlurField,
  state,
  placeholder,
}) {
  const { stringRes } = useLocaleContext();
  const countryGroups = getCountryGroups();

  return (
    <fieldset id="vat-company-form-fields" className={classes.fields}>
      <FormInputLabel text={stringRes["booking.checkout.billing.company.name"]}>
        <FormInputText
          name={"vat-company-form-name"}
          onChange={(e) => onChangeCompanyField("companyName", e.target.value)}
          value={values.company.companyName}
          isValid={isValidField(state.company.companyName)}
          isActive={state.company.companyName.isActive}
          onFocus={() => onFocusField("companyName", true)}
          onBlur={() => onBlurField("companyName", true)}
          placeholder={placeholder.companyName}
        />
      </FormInputLabel>
      <FormInputLabel text={stringRes["booking.checkout.billing.company.address"]}>
        <FormInputText
          name={"vat-company-form-address"}
          onChange={(e) => onChangeCompanyField("address", e.target.value)}
          value={values.company.address}
          isValid={isValidField(state.company.address)}
          isActive={state.company.address.isActive}
          onFocus={() => onFocusField("address", true)}
          onBlur={() => onBlurField("address", true)}
          placeholder={placeholder.address}
        />
      </FormInputLabel>
      <FormInputLabel text={stringRes["booking.checkout.billing.company.registration"]}>
        <FormInputText
          name={"vat-company-form-registration-num"}
          onChange={(e) => onChangeCompanyField("companyID", e.target.value)}
          value={values.company.companyID}
          isValid={isValidField(state.company.companyID)}
          isActive={state.company.companyID.isActive}
          onFocus={() => onFocusField("companyID", true)}
          onBlur={() => onBlurField("companyID", true)}
          placeholder={placeholder.companyID}
        />
      </FormInputLabel>
      <FormInputLabel text={stringRes["booking.checkout.billing.company.vat"]}>
        <FormInputText
          name={"vat-company-form-vat-num"}
          onChange={(e) => onChangeCompanyField("VATNumber", e.target.value)}
          value={values.company.VATNumber}
          isValid={isValidField(state.company.VATNumber)}
          isActive={state.company.VATNumber.isActive}
          onFocus={() => onFocusField("VATNumber", true)}
          onBlur={() => onBlurField("VATNumber", true)}
          placeholder={placeholder.VATNumber}
        />
      </FormInputLabel>
      <FormInputLabel text={stringRes["booking.checkout.billing.company.country"]}>
        <FormCountryInputDropdown
          name={"vat-company-form-country"}
          onSelect={(value) => onChangeCompanyField("country", value)}
          optionGroups={countryGroups}
          value={values.company.country}
          isValid={isValidField(state.company.country)}
          isActive={state.company.country.isActive}
          onFocus={() => onFocusField("country", true)}
          onBlur={() => onBlurField("country", true)}
          placeholder={stringRes["booking.form.passenger.field.select"]}
        />
      </FormInputLabel>
    </fieldset>
  );
}
