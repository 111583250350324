import { nanoid } from "@reduxjs/toolkit";
import { Markup } from "interweave";
import AddButtonOval from "src/components/UI/AddButtonOval/AddButtonOval";
import Card from "src/components/UI/Card/Card";
import { Checkmark, FatCheckmark } from "src/components/UI/Icon/Checkmark";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import classes from "../ExtraServicesSelection.module.css";
import { EXTRAS_IMGS } from "../../ServiceDescription/ServiceDescription";

export function ServiceFooterDesktop({
  isSelected,
  price,
  onSelect,
  priceBasis,
  serviceId,
  onShowDetails,
}) {
  const { stringRes, currencySymbol } = useLocaleContext();

  return (
    <div className={classes.footer}>
      <div className={classes.hr}></div>
      <button value={serviceId} onClick={onShowDetails} className={classes.info}>
        {stringRes["link.text.moreInformation"]}
      </button>
      <div className={classes.bottom}>
        <span className={classes.price}>
          <span>{`${price} ${currencySymbol}`}</span>
          <span>{priceBasis}</span>
        </span>
        <AddButtonOval onClick={onSelect} isAdded={isSelected} />
      </div>
    </div>
  );
}

export function ServiceFeatures({ id, features }) {
  const { isMobile } = useDeviceContext();
  return (
    <ul className={classes.features}>
      {features.map((ft) => (
        <li key={id + nanoid()} className={classes.feature}>
          {isMobile ? <FatCheckmark /> : <Checkmark color={"#00A991"} />}
          <span>
            <Markup content={ft} />
          </span>
        </li>
      ))}
    </ul>
  );
}
export function ExtraServiceOptionDesktop(props) {
  return (
    <Card cornerLg className={classes.serviceOption}>
      <div className={classes.body}>
        <h1>
          {props.name}
          {props.tagText && (
            <span className={classes.tag}>
              <span className={classes.tagText}>{props.tagText}</span>
            </span>
          )}
        </h1>
        <span className={classes.frame}>
          <img src={EXTRAS_IMGS[props.serviceId]} alt="service" />
        </span>
        <ServiceFeatures id={props.id} features={props.features} />
      </div>
      <ServiceFooterDesktop {...props} />
    </Card>
  );
}
