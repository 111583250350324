function HeartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M10.1624 16.9191C9.86902 17.027 9.38588 17.027 9.09255 16.9191C6.59059 16.0292 1 12.3169 1 6.02472C1 3.24719 3.14823 1 5.79686 1C7.36706 1 8.75608 1.79101 9.62745 3.01348C10.0707 2.38955 10.6481 1.88245 11.3133 1.5328C11.9784 1.18316 12.713 1.00068 13.458 1C16.1067 1 18.2549 3.24719 18.2549 6.02472C18.2549 12.3169 12.6643 16.0292 10.1624 16.9191Z"
        stroke="#001B38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HeartIcon;
